*{margin:0;padding:0;background-repeat:no-repeat;-webkit-text-size-adjust:none}

*,*:before,*:after{-moz-box-sizing:border-box;-webkit-box-sizing:border-box;box-sizing:border-box}

ul{list-style:none}

ol{list-style-position:inside}

ul.bullets{list-style:disc}

[dir="ltr"] ul.bullets{padding:0 0 0 40px}

[dir="rtl"] ul.bullets{padding:0 40px 0 0}

a{cursor:pointer;text-decoration:none;color:inherit}

a img{border:none}

button{font-size:inherit}

button,input[type=submit],input[type=button],input[type=reset]{background:rgba(0,0,0,0);border:none;-webkit-appearance:none;cursor:pointer;border-radius:0}

button[disabled],input[type=submit][disabled],input[type=button][disabled],input[type=reset][disabled]{cursor:default}

button i,input[type=submit] i,input[type=button] i,input[type=reset] i{vertical-align:middle}

i{font-style:normal;display:inline-block}

hr{display:block;height:1px;border:0;border-top:1px solid;margin:1em 0;padding:0}

textarea{resize:vertical}

iframe{max-width:100%}

body{line-height:1.3}

p{line-height:1.5em;padding:0 0 1em}

p:only-of-type,p:last-child{padding:0}

p:only-of-type+ul.bullets,p:last-child+ul.bullets{padding-top:1em}

fieldset{border:none;padding:0;margin:0}

legend{display:table;margin:0;padding:0;width:100%}

[dir="ltr"] legend{float:left}

[dir="rtl"] legend{float:right}

legend+*{clear:both}

blockquote{margin:1rem 4rem}

[dir="ltr"] a:before{padding-right:.5rem}

[dir="rtl"] a:before{padding-left:.5rem}

address{font-style:normal}

cite{font-style:normal}

article,aside,dialog,figcaption,figure,footer,header,hgroup,main,nav,section{display:block}

img{-ms-interpolation-mode:bicubic}

.a11y-hide{position:absolute;top:auto;width:1px;height:1px;overflow:hidden}

[dir="ltr"] .a11y-hide{left:-10000px}

[dir="rtl"] .a11y-hide{right:-10000px}

.keyboard-focus .a11y-hide:not(.hide-focus):focus{width:auto;height:auto}

[dir="ltr"] .keyboard-focus .a11y-hide:not(.hide-focus):focus{left:auto}

[dir="rtl"] .keyboard-focus .a11y-hide:not(.hide-focus):focus{right:auto}

@media(max-width: 768px){
.a11y-hide.mobile-only{position:inherit;width:auto;height:auto;overflow:inherit}

[dir="ltr"] .a11y-hide.mobile-only{left:auto}

[dir="rtl"] .a11y-hide.mobile-only{right:auto}}

.a11y-hide-text{color:rgba(0,0,0,0);font-size:0;text-shadow:none;overflow:hidden;line-height:0}

.skip-nav{background-color:#fff;color:#000;font-weight:bold;display:inline-block;padding:1rem;text-decoration:underline}

.mouse-focus a:focus,.mouse-focus input:focus,.mouse-focus textarea:focus,.mouse-focus button:focus,.mouse-focus select:focus,.mouse-focus [tabindex]:focus,.mouse-focus [contenteditable=true]:focus{outline:0}

.click-focus:focus,a:focus click-focus,button:focus click-focus{color:getColor("blue")}

.a11y-main-live-region{height:0}

.font-italic{font-style:oblique}

.guts-p-t-auto{padding-top:auto !important}

.guts-p-t-quart{padding-top:0.25rem !important}

.guts-p-t-half{padding-top:0.5rem !important}

.guts-p-t-1-half{padding-top:1.5rem !important}

.guts-p-t-2-half{padding-top:2.5rem !important}

.guts-p-t-3-half{padding-top:3.5rem !important}

.guts-p-t-0{padding-top:0rem !important}

.guts-p-t-1{padding-top:1rem !important}

.guts-p-t-2{padding-top:2rem !important}

.guts-p-t-3{padding-top:3rem !important}

.guts-p-t-4{padding-top:4rem !important}

.guts-p-t-5{padding-top:5rem !important}

.guts-p-t-6{padding-top:6rem !important}

.guts-p-t-7{padding-top:7rem !important}

.guts-p-t-8{padding-top:8rem !important}

.guts-p-t-9{padding-top:9rem !important}

.guts-p-t-10{padding-top:10rem !important}

.guts-p-t-11{padding-top:11rem !important}

.guts-p-t-12{padding-top:12rem !important}

.guts-p-t-13{padding-top:13rem !important}

.guts-p-t-14{padding-top:14rem !important}

.guts-p-t-15{padding-top:15rem !important}

@media(max-width: 768px){
.m-guts-p-t-quart{padding-top:0.25rem !important}

.m-guts-p-t-half{padding-top:0.5rem !important}

.m-guts-p-t-1-half{padding-top:1.5rem !important}

.m-guts-p-t-2-half{padding-top:2.5rem !important}

.m-guts-p-t-3-half{padding-top:3.5rem !important}

.m-guts-p-t-0{padding-top:0rem !important}

.m-guts-p-t-1{padding-top:1rem !important}

.m-guts-p-t-2{padding-top:2rem !important}

.m-guts-p-t-3{padding-top:3rem !important}

.m-guts-p-t-4{padding-top:4rem !important}

.m-guts-p-t-5{padding-top:5rem !important}

.m-guts-p-t-6{padding-top:6rem !important}

.m-guts-p-t-7{padding-top:7rem !important}

.m-guts-p-t-8{padding-top:8rem !important}

.m-guts-p-t-9{padding-top:9rem !important}

.m-guts-p-t-10{padding-top:10rem !important}

.m-guts-p-t-11{padding-top:11rem !important}

.m-guts-p-t-12{padding-top:12rem !important}

.m-guts-p-t-13{padding-top:13rem !important}

.m-guts-p-t-14{padding-top:14rem !important}

.m-guts-p-t-15{padding-top:15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.guts-p-b-auto{padding-bottom:auto !important}

.guts-p-b-quart{padding-bottom:0.25rem !important}

.guts-p-b-half{padding-bottom:0.5rem !important}

.guts-p-b-1-half{padding-bottom:1.5rem !important}

.guts-p-b-2-half{padding-bottom:2.5rem !important}

.guts-p-b-3-half{padding-bottom:3.5rem !important}

.guts-p-b-0{padding-bottom:0rem !important}

.guts-p-b-1{padding-bottom:1rem !important}

.guts-p-b-2{padding-bottom:2rem !important}

.guts-p-b-3{padding-bottom:3rem !important}

.guts-p-b-4{padding-bottom:4rem !important}

.guts-p-b-5{padding-bottom:5rem !important}

.guts-p-b-6{padding-bottom:6rem !important}

.guts-p-b-7{padding-bottom:7rem !important}

.guts-p-b-8{padding-bottom:8rem !important}

.guts-p-b-9{padding-bottom:9rem !important}

.guts-p-b-10{padding-bottom:10rem !important}

.guts-p-b-11{padding-bottom:11rem !important}

.guts-p-b-12{padding-bottom:12rem !important}

.guts-p-b-13{padding-bottom:13rem !important}

.guts-p-b-14{padding-bottom:14rem !important}

.guts-p-b-15{padding-bottom:15rem !important}

@media(max-width: 768px){
.m-guts-p-b-quart{padding-bottom:0.25rem !important}

.m-guts-p-b-half{padding-bottom:0.5rem !important}

.m-guts-p-b-1-half{padding-bottom:1.5rem !important}

.m-guts-p-b-2-half{padding-bottom:2.5rem !important}

.m-guts-p-b-3-half{padding-bottom:3.5rem !important}

.m-guts-p-b-0{padding-bottom:0rem !important}

.m-guts-p-b-1{padding-bottom:1rem !important}

.m-guts-p-b-2{padding-bottom:2rem !important}

.m-guts-p-b-3{padding-bottom:3rem !important}

.m-guts-p-b-4{padding-bottom:4rem !important}

.m-guts-p-b-5{padding-bottom:5rem !important}

.m-guts-p-b-6{padding-bottom:6rem !important}

.m-guts-p-b-7{padding-bottom:7rem !important}

.m-guts-p-b-8{padding-bottom:8rem !important}

.m-guts-p-b-9{padding-bottom:9rem !important}

.m-guts-p-b-10{padding-bottom:10rem !important}

.m-guts-p-b-11{padding-bottom:11rem !important}

.m-guts-p-b-12{padding-bottom:12rem !important}

.m-guts-p-b-13{padding-bottom:13rem !important}

.m-guts-p-b-14{padding-bottom:14rem !important}

.m-guts-p-b-15{padding-bottom:15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

[dir="ltr"] .guts-p-r-auto{padding-right:auto !important}

[dir="rtl"] .guts-p-r-auto{padding-left:auto !important}

[dir="ltr"] .guts-p-r-quart{padding-right:0.25rem !important}

[dir="rtl"] .guts-p-r-quart{padding-left:0.25rem !important}

[dir="ltr"] .guts-p-r-half{padding-right:0.5rem !important}

[dir="rtl"] .guts-p-r-half{padding-left:0.5rem !important}

[dir="ltr"] .guts-p-r-1-half{padding-right:1.5rem !important}

[dir="rtl"] .guts-p-r-1-half{padding-left:1.5rem !important}

[dir="ltr"] .guts-p-r-2-half{padding-right:2.5rem !important}

[dir="rtl"] .guts-p-r-2-half{padding-left:2.5rem !important}

[dir="ltr"] .guts-p-r-3-half{padding-right:3.5rem !important}

[dir="rtl"] .guts-p-r-3-half{padding-left:3.5rem !important}

[dir="ltr"] .guts-p-r-0{padding-right:0rem !important}

[dir="rtl"] .guts-p-r-0{padding-left:0rem !important}

[dir="ltr"] .guts-p-r-1{padding-right:1rem !important}

[dir="rtl"] .guts-p-r-1{padding-left:1rem !important}

[dir="ltr"] .guts-p-r-2{padding-right:2rem !important}

[dir="rtl"] .guts-p-r-2{padding-left:2rem !important}

[dir="ltr"] .guts-p-r-3{padding-right:3rem !important}

[dir="rtl"] .guts-p-r-3{padding-left:3rem !important}

[dir="ltr"] .guts-p-r-4{padding-right:4rem !important}

[dir="rtl"] .guts-p-r-4{padding-left:4rem !important}

[dir="ltr"] .guts-p-r-5{padding-right:5rem !important}

[dir="rtl"] .guts-p-r-5{padding-left:5rem !important}

[dir="ltr"] .guts-p-r-6{padding-right:6rem !important}

[dir="rtl"] .guts-p-r-6{padding-left:6rem !important}

[dir="ltr"] .guts-p-r-7{padding-right:7rem !important}

[dir="rtl"] .guts-p-r-7{padding-left:7rem !important}

[dir="ltr"] .guts-p-r-8{padding-right:8rem !important}

[dir="rtl"] .guts-p-r-8{padding-left:8rem !important}

[dir="ltr"] .guts-p-r-9{padding-right:9rem !important}

[dir="rtl"] .guts-p-r-9{padding-left:9rem !important}

[dir="ltr"] .guts-p-r-10{padding-right:10rem !important}

[dir="rtl"] .guts-p-r-10{padding-left:10rem !important}

[dir="ltr"] .guts-p-r-11{padding-right:11rem !important}

[dir="rtl"] .guts-p-r-11{padding-left:11rem !important}

[dir="ltr"] .guts-p-r-12{padding-right:12rem !important}

[dir="rtl"] .guts-p-r-12{padding-left:12rem !important}

[dir="ltr"] .guts-p-r-13{padding-right:13rem !important}

[dir="rtl"] .guts-p-r-13{padding-left:13rem !important}

[dir="ltr"] .guts-p-r-14{padding-right:14rem !important}

[dir="rtl"] .guts-p-r-14{padding-left:14rem !important}

[dir="ltr"] .guts-p-r-15{padding-right:15rem !important}

[dir="rtl"] .guts-p-r-15{padding-left:15rem !important}

@media(max-width: 768px){
[dir="ltr"] .m-guts-p-r-quart{padding-right:0.25rem !important}

[dir="rtl"] .m-guts-p-r-quart{padding-left:0.25rem !important}

[dir="ltr"] .m-guts-p-r-half{padding-right:0.5rem !important}

[dir="rtl"] .m-guts-p-r-half{padding-left:0.5rem !important}

[dir="ltr"] .m-guts-p-r-1-half{padding-right:1.5rem !important}

[dir="rtl"] .m-guts-p-r-1-half{padding-left:1.5rem !important}

[dir="ltr"] .m-guts-p-r-2-half{padding-right:2.5rem !important}

[dir="rtl"] .m-guts-p-r-2-half{padding-left:2.5rem !important}

[dir="ltr"] .m-guts-p-r-3-half{padding-right:3.5rem !important}

[dir="rtl"] .m-guts-p-r-3-half{padding-left:3.5rem !important}

[dir="ltr"] .m-guts-p-r-0{padding-right:0rem !important}

[dir="rtl"] .m-guts-p-r-0{padding-left:0rem !important}

[dir="ltr"] .m-guts-p-r-1{padding-right:1rem !important}

[dir="rtl"] .m-guts-p-r-1{padding-left:1rem !important}

[dir="ltr"] .m-guts-p-r-2{padding-right:2rem !important}

[dir="rtl"] .m-guts-p-r-2{padding-left:2rem !important}

[dir="ltr"] .m-guts-p-r-3{padding-right:3rem !important}

[dir="rtl"] .m-guts-p-r-3{padding-left:3rem !important}

[dir="ltr"] .m-guts-p-r-4{padding-right:4rem !important}

[dir="rtl"] .m-guts-p-r-4{padding-left:4rem !important}

[dir="ltr"] .m-guts-p-r-5{padding-right:5rem !important}

[dir="rtl"] .m-guts-p-r-5{padding-left:5rem !important}

[dir="ltr"] .m-guts-p-r-6{padding-right:6rem !important}

[dir="rtl"] .m-guts-p-r-6{padding-left:6rem !important}

[dir="ltr"] .m-guts-p-r-7{padding-right:7rem !important}

[dir="rtl"] .m-guts-p-r-7{padding-left:7rem !important}

[dir="ltr"] .m-guts-p-r-8{padding-right:8rem !important}

[dir="rtl"] .m-guts-p-r-8{padding-left:8rem !important}

[dir="ltr"] .m-guts-p-r-9{padding-right:9rem !important}

[dir="rtl"] .m-guts-p-r-9{padding-left:9rem !important}

[dir="ltr"] .m-guts-p-r-10{padding-right:10rem !important}

[dir="rtl"] .m-guts-p-r-10{padding-left:10rem !important}

[dir="ltr"] .m-guts-p-r-11{padding-right:11rem !important}

[dir="rtl"] .m-guts-p-r-11{padding-left:11rem !important}

[dir="ltr"] .m-guts-p-r-12{padding-right:12rem !important}

[dir="rtl"] .m-guts-p-r-12{padding-left:12rem !important}

[dir="ltr"] .m-guts-p-r-13{padding-right:13rem !important}

[dir="rtl"] .m-guts-p-r-13{padding-left:13rem !important}

[dir="ltr"] .m-guts-p-r-14{padding-right:14rem !important}

[dir="rtl"] .m-guts-p-r-14{padding-left:14rem !important}

[dir="ltr"] .m-guts-p-r-15{padding-right:15rem !important}

[dir="rtl"] .m-guts-p-r-15{padding-left:15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

[dir="ltr"] .guts-p-l-auto{padding-left:auto !important}

[dir="rtl"] .guts-p-l-auto{padding-right:auto !important}

[dir="ltr"] .guts-p-l-quart{padding-left:0.25rem !important}

[dir="rtl"] .guts-p-l-quart{padding-right:0.25rem !important}

[dir="ltr"] .guts-p-l-half{padding-left:0.5rem !important}

[dir="rtl"] .guts-p-l-half{padding-right:0.5rem !important}

[dir="ltr"] .guts-p-l-1-half{padding-left:1.5rem !important}

[dir="rtl"] .guts-p-l-1-half{padding-right:1.5rem !important}

[dir="ltr"] .guts-p-l-2-half{padding-left:2.5rem !important}

[dir="rtl"] .guts-p-l-2-half{padding-right:2.5rem !important}

[dir="ltr"] .guts-p-l-3-half{padding-left:3.5rem !important}

[dir="rtl"] .guts-p-l-3-half{padding-right:3.5rem !important}

[dir="ltr"] .guts-p-l-0{padding-left:0rem !important}

[dir="rtl"] .guts-p-l-0{padding-right:0rem !important}

[dir="ltr"] .guts-p-l-1{padding-left:1rem !important}

[dir="rtl"] .guts-p-l-1{padding-right:1rem !important}

[dir="ltr"] .guts-p-l-2{padding-left:2rem !important}

[dir="rtl"] .guts-p-l-2{padding-right:2rem !important}

[dir="ltr"] .guts-p-l-3{padding-left:3rem !important}

[dir="rtl"] .guts-p-l-3{padding-right:3rem !important}

[dir="ltr"] .guts-p-l-4{padding-left:4rem !important}

[dir="rtl"] .guts-p-l-4{padding-right:4rem !important}

[dir="ltr"] .guts-p-l-5{padding-left:5rem !important}

[dir="rtl"] .guts-p-l-5{padding-right:5rem !important}

[dir="ltr"] .guts-p-l-6{padding-left:6rem !important}

[dir="rtl"] .guts-p-l-6{padding-right:6rem !important}

[dir="ltr"] .guts-p-l-7{padding-left:7rem !important}

[dir="rtl"] .guts-p-l-7{padding-right:7rem !important}

[dir="ltr"] .guts-p-l-8{padding-left:8rem !important}

[dir="rtl"] .guts-p-l-8{padding-right:8rem !important}

[dir="ltr"] .guts-p-l-9{padding-left:9rem !important}

[dir="rtl"] .guts-p-l-9{padding-right:9rem !important}

[dir="ltr"] .guts-p-l-10{padding-left:10rem !important}

[dir="rtl"] .guts-p-l-10{padding-right:10rem !important}

[dir="ltr"] .guts-p-l-11{padding-left:11rem !important}

[dir="rtl"] .guts-p-l-11{padding-right:11rem !important}

[dir="ltr"] .guts-p-l-12{padding-left:12rem !important}

[dir="rtl"] .guts-p-l-12{padding-right:12rem !important}

[dir="ltr"] .guts-p-l-13{padding-left:13rem !important}

[dir="rtl"] .guts-p-l-13{padding-right:13rem !important}

[dir="ltr"] .guts-p-l-14{padding-left:14rem !important}

[dir="rtl"] .guts-p-l-14{padding-right:14rem !important}

[dir="ltr"] .guts-p-l-15{padding-left:15rem !important}

[dir="rtl"] .guts-p-l-15{padding-right:15rem !important}

@media(max-width: 768px){
[dir="ltr"] .m-guts-p-l-quart{padding-left:0.25rem !important}

[dir="rtl"] .m-guts-p-l-quart{padding-right:0.25rem !important}

[dir="ltr"] .m-guts-p-l-half{padding-left:0.5rem !important}

[dir="rtl"] .m-guts-p-l-half{padding-right:0.5rem !important}

[dir="ltr"] .m-guts-p-l-1-half{padding-left:1.5rem !important}

[dir="rtl"] .m-guts-p-l-1-half{padding-right:1.5rem !important}

[dir="ltr"] .m-guts-p-l-2-half{padding-left:2.5rem !important}

[dir="rtl"] .m-guts-p-l-2-half{padding-right:2.5rem !important}

[dir="ltr"] .m-guts-p-l-3-half{padding-left:3.5rem !important}

[dir="rtl"] .m-guts-p-l-3-half{padding-right:3.5rem !important}

[dir="ltr"] .m-guts-p-l-0{padding-left:0rem !important}

[dir="rtl"] .m-guts-p-l-0{padding-right:0rem !important}

[dir="ltr"] .m-guts-p-l-1{padding-left:1rem !important}

[dir="rtl"] .m-guts-p-l-1{padding-right:1rem !important}

[dir="ltr"] .m-guts-p-l-2{padding-left:2rem !important}

[dir="rtl"] .m-guts-p-l-2{padding-right:2rem !important}

[dir="ltr"] .m-guts-p-l-3{padding-left:3rem !important}

[dir="rtl"] .m-guts-p-l-3{padding-right:3rem !important}

[dir="ltr"] .m-guts-p-l-4{padding-left:4rem !important}

[dir="rtl"] .m-guts-p-l-4{padding-right:4rem !important}

[dir="ltr"] .m-guts-p-l-5{padding-left:5rem !important}

[dir="rtl"] .m-guts-p-l-5{padding-right:5rem !important}

[dir="ltr"] .m-guts-p-l-6{padding-left:6rem !important}

[dir="rtl"] .m-guts-p-l-6{padding-right:6rem !important}

[dir="ltr"] .m-guts-p-l-7{padding-left:7rem !important}

[dir="rtl"] .m-guts-p-l-7{padding-right:7rem !important}

[dir="ltr"] .m-guts-p-l-8{padding-left:8rem !important}

[dir="rtl"] .m-guts-p-l-8{padding-right:8rem !important}

[dir="ltr"] .m-guts-p-l-9{padding-left:9rem !important}

[dir="rtl"] .m-guts-p-l-9{padding-right:9rem !important}

[dir="ltr"] .m-guts-p-l-10{padding-left:10rem !important}

[dir="rtl"] .m-guts-p-l-10{padding-right:10rem !important}

[dir="ltr"] .m-guts-p-l-11{padding-left:11rem !important}

[dir="rtl"] .m-guts-p-l-11{padding-right:11rem !important}

[dir="ltr"] .m-guts-p-l-12{padding-left:12rem !important}

[dir="rtl"] .m-guts-p-l-12{padding-right:12rem !important}

[dir="ltr"] .m-guts-p-l-13{padding-left:13rem !important}

[dir="rtl"] .m-guts-p-l-13{padding-right:13rem !important}

[dir="ltr"] .m-guts-p-l-14{padding-left:14rem !important}

[dir="rtl"] .m-guts-p-l-14{padding-right:14rem !important}

[dir="ltr"] .m-guts-p-l-15{padding-left:15rem !important}

[dir="rtl"] .m-guts-p-l-15{padding-right:15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.guts-p-v-auto{padding-top:auto !important;padding-bottom:auto !important}

.guts-p-v-quart{padding-top:0.25rem !important;padding-bottom:0.25rem !important}

.guts-p-v-half{padding-top:0.5rem !important;padding-bottom:0.5rem !important}

.guts-p-v-1-half{padding-top:1.5rem !important;padding-bottom:1.5rem !important}

.guts-p-v-2-half{padding-top:2.5rem !important;padding-bottom:2.5rem !important}

.guts-p-v-3-half{padding-top:3.5rem !important;padding-bottom:3.5rem !important}

.guts-p-v-0{padding-top:0rem !important;padding-bottom:0rem !important}

.guts-p-v-1{padding-top:1rem !important;padding-bottom:1rem !important}

.guts-p-v-2{padding-top:2rem !important;padding-bottom:2rem !important}

.guts-p-v-3{padding-top:3rem !important;padding-bottom:3rem !important}

.guts-p-v-4{padding-top:4rem !important;padding-bottom:4rem !important}

.guts-p-v-5{padding-top:5rem !important;padding-bottom:5rem !important}

.guts-p-v-6{padding-top:6rem !important;padding-bottom:6rem !important}

.guts-p-v-7{padding-top:7rem !important;padding-bottom:7rem !important}

.guts-p-v-8{padding-top:8rem !important;padding-bottom:8rem !important}

.guts-p-v-9{padding-top:9rem !important;padding-bottom:9rem !important}

.guts-p-v-10{padding-top:10rem !important;padding-bottom:10rem !important}

.guts-p-v-11{padding-top:11rem !important;padding-bottom:11rem !important}

.guts-p-v-12{padding-top:12rem !important;padding-bottom:12rem !important}

.guts-p-v-13{padding-top:13rem !important;padding-bottom:13rem !important}

.guts-p-v-14{padding-top:14rem !important;padding-bottom:14rem !important}

.guts-p-v-15{padding-top:15rem !important;padding-bottom:15rem !important}

@media(max-width: 768px){
.m-guts-p-v-quart{padding-top:0.25rem !important;padding-bottom:0.25rem !important}

.m-guts-p-v-half{padding-top:0.5rem !important;padding-bottom:0.5rem !important}

.m-guts-p-v-1-half{padding-top:1.5rem !important;padding-bottom:1.5rem !important}

.m-guts-p-v-2-half{padding-top:2.5rem !important;padding-bottom:2.5rem !important}

.m-guts-p-v-3-half{padding-top:3.5rem !important;padding-bottom:3.5rem !important}

.m-guts-p-v-0{padding-top:0rem !important;padding-bottom:0rem !important}

.m-guts-p-v-1{padding-top:1rem !important;padding-bottom:1rem !important}

.m-guts-p-v-2{padding-top:2rem !important;padding-bottom:2rem !important}

.m-guts-p-v-3{padding-top:3rem !important;padding-bottom:3rem !important}

.m-guts-p-v-4{padding-top:4rem !important;padding-bottom:4rem !important}

.m-guts-p-v-5{padding-top:5rem !important;padding-bottom:5rem !important}

.m-guts-p-v-6{padding-top:6rem !important;padding-bottom:6rem !important}

.m-guts-p-v-7{padding-top:7rem !important;padding-bottom:7rem !important}

.m-guts-p-v-8{padding-top:8rem !important;padding-bottom:8rem !important}

.m-guts-p-v-9{padding-top:9rem !important;padding-bottom:9rem !important}

.m-guts-p-v-10{padding-top:10rem !important;padding-bottom:10rem !important}

.m-guts-p-v-11{padding-top:11rem !important;padding-bottom:11rem !important}

.m-guts-p-v-12{padding-top:12rem !important;padding-bottom:12rem !important}

.m-guts-p-v-13{padding-top:13rem !important;padding-bottom:13rem !important}

.m-guts-p-v-14{padding-top:14rem !important;padding-bottom:14rem !important}

.m-guts-p-v-15{padding-top:15rem !important;padding-bottom:15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.guts-p-h-auto{padding-right:auto !important;padding-left:auto !important}

.guts-p-h-quart{padding-right:0.25rem !important;padding-left:0.25rem !important}

.guts-p-h-half{padding-right:0.5rem !important;padding-left:0.5rem !important}

.guts-p-h-1-half{padding-right:1.5rem !important;padding-left:1.5rem !important}

.guts-p-h-2-half{padding-right:2.5rem !important;padding-left:2.5rem !important}

.guts-p-h-3-half{padding-right:3.5rem !important;padding-left:3.5rem !important}

.guts-p-h-0{padding-right:0rem !important;padding-left:0rem !important}

.guts-p-h-1{padding-right:1rem !important;padding-left:1rem !important}

.guts-p-h-2{padding-right:2rem !important;padding-left:2rem !important}

.guts-p-h-3{padding-right:3rem !important;padding-left:3rem !important}

.guts-p-h-4{padding-right:4rem !important;padding-left:4rem !important}

.guts-p-h-5{padding-right:5rem !important;padding-left:5rem !important}

.guts-p-h-6{padding-right:6rem !important;padding-left:6rem !important}

.guts-p-h-7{padding-right:7rem !important;padding-left:7rem !important}

.guts-p-h-8{padding-right:8rem !important;padding-left:8rem !important}

.guts-p-h-9{padding-right:9rem !important;padding-left:9rem !important}

.guts-p-h-10{padding-right:10rem !important;padding-left:10rem !important}

.guts-p-h-11{padding-right:11rem !important;padding-left:11rem !important}

.guts-p-h-12{padding-right:12rem !important;padding-left:12rem !important}

.guts-p-h-13{padding-right:13rem !important;padding-left:13rem !important}

.guts-p-h-14{padding-right:14rem !important;padding-left:14rem !important}

.guts-p-h-15{padding-right:15rem !important;padding-left:15rem !important}

@media(max-width: 768px){
.m-guts-p-h-quart{padding-right:0.25rem !important;padding-left:0.25rem !important}

.m-guts-p-h-half{padding-right:0.5rem !important;padding-left:0.5rem !important}

.m-guts-p-h-1-half{padding-right:1.5rem !important;padding-left:1.5rem !important}

.m-guts-p-h-2-half{padding-right:2.5rem !important;padding-left:2.5rem !important}

.m-guts-p-h-3-half{padding-right:3.5rem !important;padding-left:3.5rem !important}

.m-guts-p-h-0{padding-right:0rem !important;padding-left:0rem !important}

.m-guts-p-h-1{padding-right:1rem !important;padding-left:1rem !important}

.m-guts-p-h-2{padding-right:2rem !important;padding-left:2rem !important}

.m-guts-p-h-3{padding-right:3rem !important;padding-left:3rem !important}

.m-guts-p-h-4{padding-right:4rem !important;padding-left:4rem !important}

.m-guts-p-h-5{padding-right:5rem !important;padding-left:5rem !important}

.m-guts-p-h-6{padding-right:6rem !important;padding-left:6rem !important}

.m-guts-p-h-7{padding-right:7rem !important;padding-left:7rem !important}

.m-guts-p-h-8{padding-right:8rem !important;padding-left:8rem !important}

.m-guts-p-h-9{padding-right:9rem !important;padding-left:9rem !important}

.m-guts-p-h-10{padding-right:10rem !important;padding-left:10rem !important}

.m-guts-p-h-11{padding-right:11rem !important;padding-left:11rem !important}

.m-guts-p-h-12{padding-right:12rem !important;padding-left:12rem !important}

.m-guts-p-h-13{padding-right:13rem !important;padding-left:13rem !important}

.m-guts-p-h-14{padding-right:14rem !important;padding-left:14rem !important}

.m-guts-p-h-15{padding-right:15rem !important;padding-left:15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.guts-p-full-auto{padding:auto !important}

.guts-p-full-quart{padding:0.25rem !important}

.guts-p-full-half{padding:0.5rem !important}

.guts-p-full-1-half{padding:1.5rem !important}

.guts-p-full-2-half{padding:2.5rem !important}

.guts-p-full-3-half{padding:3.5rem !important}

.guts-p-full-0{padding:0rem !important}

.guts-p-full-1{padding:1rem !important}

.guts-p-full-2{padding:2rem !important}

.guts-p-full-3{padding:3rem !important}

.guts-p-full-4{padding:4rem !important}

.guts-p-full-5{padding:5rem !important}

.guts-p-full-6{padding:6rem !important}

.guts-p-full-7{padding:7rem !important}

.guts-p-full-8{padding:8rem !important}

.guts-p-full-9{padding:9rem !important}

.guts-p-full-10{padding:10rem !important}

.guts-p-full-11{padding:11rem !important}

.guts-p-full-12{padding:12rem !important}

.guts-p-full-13{padding:13rem !important}

.guts-p-full-14{padding:14rem !important}

.guts-p-full-15{padding:15rem !important}

@media(max-width: 768px){
.m-guts-p-full-quart{padding:0.25rem !important}

.m-guts-p-full-half{padding:0.5rem !important}

.m-guts-p-full-1-half{padding:1.5rem !important}

.m-guts-p-full-2-half{padding:2.5rem !important}

.m-guts-p-full-3-half{padding:3.5rem !important}

.m-guts-p-full-0{padding:0rem !important}

.m-guts-p-full-1{padding:1rem !important}

.m-guts-p-full-2{padding:2rem !important}

.m-guts-p-full-3{padding:3rem !important}

.m-guts-p-full-4{padding:4rem !important}

.m-guts-p-full-5{padding:5rem !important}

.m-guts-p-full-6{padding:6rem !important}

.m-guts-p-full-7{padding:7rem !important}

.m-guts-p-full-8{padding:8rem !important}

.m-guts-p-full-9{padding:9rem !important}

.m-guts-p-full-10{padding:10rem !important}

.m-guts-p-full-11{padding:11rem !important}

.m-guts-p-full-12{padding:12rem !important}

.m-guts-p-full-13{padding:13rem !important}

.m-guts-p-full-14{padding:14rem !important}

.m-guts-p-full-15{padding:15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.guts-m-t-auto{margin-top:auto !important}

.guts-m-t-quart{margin-top:0.25rem !important}

.guts-m-t-half{margin-top:0.5rem !important}

.guts-m-t-1-half{margin-top:1.5rem !important}

.guts-m-t-2-half{margin-top:2.5rem !important}

.guts-m-t-3-half{margin-top:3.5rem !important}

.guts-m-t-0{margin-top:0rem !important}

.guts-m-t-1{margin-top:1rem !important}

.guts-m-t-2{margin-top:2rem !important}

.guts-m-t-3{margin-top:3rem !important}

.guts-m-t-4{margin-top:4rem !important}

.guts-m-t-5{margin-top:5rem !important}

.guts-m-t-6{margin-top:6rem !important}

.guts-m-t-7{margin-top:7rem !important}

.guts-m-t-8{margin-top:8rem !important}

.guts-m-t-9{margin-top:9rem !important}

.guts-m-t-10{margin-top:10rem !important}

.guts-m-t-11{margin-top:11rem !important}

.guts-m-t-12{margin-top:12rem !important}

.guts-m-t-13{margin-top:13rem !important}

.guts-m-t-14{margin-top:14rem !important}

.guts-m-t-15{margin-top:15rem !important}

@media(max-width: 768px){
.m-guts-m-t-quart{margin-top:0.25rem !important}

.m-guts-m-t-half{margin-top:0.5rem !important}

.m-guts-m-t-1-half{margin-top:1.5rem !important}

.m-guts-m-t-2-half{margin-top:2.5rem !important}

.m-guts-m-t-3-half{margin-top:3.5rem !important}

.m-guts-m-t-0{margin-top:0rem !important}

.m-guts-m-t-1{margin-top:1rem !important}

.m-guts-m-t-2{margin-top:2rem !important}

.m-guts-m-t-3{margin-top:3rem !important}

.m-guts-m-t-4{margin-top:4rem !important}

.m-guts-m-t-5{margin-top:5rem !important}

.m-guts-m-t-6{margin-top:6rem !important}

.m-guts-m-t-7{margin-top:7rem !important}

.m-guts-m-t-8{margin-top:8rem !important}

.m-guts-m-t-9{margin-top:9rem !important}

.m-guts-m-t-10{margin-top:10rem !important}

.m-guts-m-t-11{margin-top:11rem !important}

.m-guts-m-t-12{margin-top:12rem !important}

.m-guts-m-t-13{margin-top:13rem !important}

.m-guts-m-t-14{margin-top:14rem !important}

.m-guts-m-t-15{margin-top:15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.guts-m-b-auto{margin-bottom:auto !important}

.guts-m-b-quart{margin-bottom:0.25rem !important}

.guts-m-b-half{margin-bottom:0.5rem !important}

.guts-m-b-1-half{margin-bottom:1.5rem !important}

.guts-m-b-2-half{margin-bottom:2.5rem !important}

.guts-m-b-3-half{margin-bottom:3.5rem !important}

.guts-m-b-0{margin-bottom:0rem !important}

.guts-m-b-1{margin-bottom:1rem !important}

.guts-m-b-2{margin-bottom:2rem !important}

.guts-m-b-3{margin-bottom:3rem !important}

.guts-m-b-4{margin-bottom:4rem !important}

.guts-m-b-5{margin-bottom:5rem !important}

.guts-m-b-6{margin-bottom:6rem !important}

.guts-m-b-7{margin-bottom:7rem !important}

.guts-m-b-8{margin-bottom:8rem !important}

.guts-m-b-9{margin-bottom:9rem !important}

.guts-m-b-10{margin-bottom:10rem !important}

.guts-m-b-11{margin-bottom:11rem !important}

.guts-m-b-12{margin-bottom:12rem !important}

.guts-m-b-13{margin-bottom:13rem !important}

.guts-m-b-14{margin-bottom:14rem !important}

.guts-m-b-15{margin-bottom:15rem !important}

@media(max-width: 768px){
.m-guts-m-b-quart{margin-bottom:0.25rem !important}

.m-guts-m-b-half{margin-bottom:0.5rem !important}

.m-guts-m-b-1-half{margin-bottom:1.5rem !important}

.m-guts-m-b-2-half{margin-bottom:2.5rem !important}

.m-guts-m-b-3-half{margin-bottom:3.5rem !important}

.m-guts-m-b-0{margin-bottom:0rem !important}

.m-guts-m-b-1{margin-bottom:1rem !important}

.m-guts-m-b-2{margin-bottom:2rem !important}

.m-guts-m-b-3{margin-bottom:3rem !important}

.m-guts-m-b-4{margin-bottom:4rem !important}

.m-guts-m-b-5{margin-bottom:5rem !important}

.m-guts-m-b-6{margin-bottom:6rem !important}

.m-guts-m-b-7{margin-bottom:7rem !important}

.m-guts-m-b-8{margin-bottom:8rem !important}

.m-guts-m-b-9{margin-bottom:9rem !important}

.m-guts-m-b-10{margin-bottom:10rem !important}

.m-guts-m-b-11{margin-bottom:11rem !important}

.m-guts-m-b-12{margin-bottom:12rem !important}

.m-guts-m-b-13{margin-bottom:13rem !important}

.m-guts-m-b-14{margin-bottom:14rem !important}

.m-guts-m-b-15{margin-bottom:15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

[dir="ltr"] .guts-m-r-auto{margin-right:auto !important}

[dir="rtl"] .guts-m-r-auto{margin-left:auto !important}

[dir="ltr"] .guts-m-r-quart{margin-right:0.25rem !important}

[dir="rtl"] .guts-m-r-quart{margin-left:0.25rem !important}

[dir="ltr"] .guts-m-r-half{margin-right:0.5rem !important}

[dir="rtl"] .guts-m-r-half{margin-left:0.5rem !important}

[dir="ltr"] .guts-m-r-1-half{margin-right:1.5rem !important}

[dir="rtl"] .guts-m-r-1-half{margin-left:1.5rem !important}

[dir="ltr"] .guts-m-r-2-half{margin-right:2.5rem !important}

[dir="rtl"] .guts-m-r-2-half{margin-left:2.5rem !important}

[dir="ltr"] .guts-m-r-3-half{margin-right:3.5rem !important}

[dir="rtl"] .guts-m-r-3-half{margin-left:3.5rem !important}

[dir="ltr"] .guts-m-r-0{margin-right:0rem !important}

[dir="rtl"] .guts-m-r-0{margin-left:0rem !important}

[dir="ltr"] .guts-m-r-1{margin-right:1rem !important}

[dir="rtl"] .guts-m-r-1{margin-left:1rem !important}

[dir="ltr"] .guts-m-r-2{margin-right:2rem !important}

[dir="rtl"] .guts-m-r-2{margin-left:2rem !important}

[dir="ltr"] .guts-m-r-3{margin-right:3rem !important}

[dir="rtl"] .guts-m-r-3{margin-left:3rem !important}

[dir="ltr"] .guts-m-r-4{margin-right:4rem !important}

[dir="rtl"] .guts-m-r-4{margin-left:4rem !important}

[dir="ltr"] .guts-m-r-5{margin-right:5rem !important}

[dir="rtl"] .guts-m-r-5{margin-left:5rem !important}

[dir="ltr"] .guts-m-r-6{margin-right:6rem !important}

[dir="rtl"] .guts-m-r-6{margin-left:6rem !important}

[dir="ltr"] .guts-m-r-7{margin-right:7rem !important}

[dir="rtl"] .guts-m-r-7{margin-left:7rem !important}

[dir="ltr"] .guts-m-r-8{margin-right:8rem !important}

[dir="rtl"] .guts-m-r-8{margin-left:8rem !important}

[dir="ltr"] .guts-m-r-9{margin-right:9rem !important}

[dir="rtl"] .guts-m-r-9{margin-left:9rem !important}

[dir="ltr"] .guts-m-r-10{margin-right:10rem !important}

[dir="rtl"] .guts-m-r-10{margin-left:10rem !important}

[dir="ltr"] .guts-m-r-11{margin-right:11rem !important}

[dir="rtl"] .guts-m-r-11{margin-left:11rem !important}

[dir="ltr"] .guts-m-r-12{margin-right:12rem !important}

[dir="rtl"] .guts-m-r-12{margin-left:12rem !important}

[dir="ltr"] .guts-m-r-13{margin-right:13rem !important}

[dir="rtl"] .guts-m-r-13{margin-left:13rem !important}

[dir="ltr"] .guts-m-r-14{margin-right:14rem !important}

[dir="rtl"] .guts-m-r-14{margin-left:14rem !important}

[dir="ltr"] .guts-m-r-15{margin-right:15rem !important}

[dir="rtl"] .guts-m-r-15{margin-left:15rem !important}

@media(max-width: 768px){
[dir="ltr"] .m-guts-m-r-quart{margin-right:0.25rem !important}

[dir="rtl"] .m-guts-m-r-quart{margin-left:0.25rem !important}

[dir="ltr"] .m-guts-m-r-half{margin-right:0.5rem !important}

[dir="rtl"] .m-guts-m-r-half{margin-left:0.5rem !important}

[dir="ltr"] .m-guts-m-r-1-half{margin-right:1.5rem !important}

[dir="rtl"] .m-guts-m-r-1-half{margin-left:1.5rem !important}

[dir="ltr"] .m-guts-m-r-2-half{margin-right:2.5rem !important}

[dir="rtl"] .m-guts-m-r-2-half{margin-left:2.5rem !important}

[dir="ltr"] .m-guts-m-r-3-half{margin-right:3.5rem !important}

[dir="rtl"] .m-guts-m-r-3-half{margin-left:3.5rem !important}

[dir="ltr"] .m-guts-m-r-0{margin-right:0rem !important}

[dir="rtl"] .m-guts-m-r-0{margin-left:0rem !important}

[dir="ltr"] .m-guts-m-r-1{margin-right:1rem !important}

[dir="rtl"] .m-guts-m-r-1{margin-left:1rem !important}

[dir="ltr"] .m-guts-m-r-2{margin-right:2rem !important}

[dir="rtl"] .m-guts-m-r-2{margin-left:2rem !important}

[dir="ltr"] .m-guts-m-r-3{margin-right:3rem !important}

[dir="rtl"] .m-guts-m-r-3{margin-left:3rem !important}

[dir="ltr"] .m-guts-m-r-4{margin-right:4rem !important}

[dir="rtl"] .m-guts-m-r-4{margin-left:4rem !important}

[dir="ltr"] .m-guts-m-r-5{margin-right:5rem !important}

[dir="rtl"] .m-guts-m-r-5{margin-left:5rem !important}

[dir="ltr"] .m-guts-m-r-6{margin-right:6rem !important}

[dir="rtl"] .m-guts-m-r-6{margin-left:6rem !important}

[dir="ltr"] .m-guts-m-r-7{margin-right:7rem !important}

[dir="rtl"] .m-guts-m-r-7{margin-left:7rem !important}

[dir="ltr"] .m-guts-m-r-8{margin-right:8rem !important}

[dir="rtl"] .m-guts-m-r-8{margin-left:8rem !important}

[dir="ltr"] .m-guts-m-r-9{margin-right:9rem !important}

[dir="rtl"] .m-guts-m-r-9{margin-left:9rem !important}

[dir="ltr"] .m-guts-m-r-10{margin-right:10rem !important}

[dir="rtl"] .m-guts-m-r-10{margin-left:10rem !important}

[dir="ltr"] .m-guts-m-r-11{margin-right:11rem !important}

[dir="rtl"] .m-guts-m-r-11{margin-left:11rem !important}

[dir="ltr"] .m-guts-m-r-12{margin-right:12rem !important}

[dir="rtl"] .m-guts-m-r-12{margin-left:12rem !important}

[dir="ltr"] .m-guts-m-r-13{margin-right:13rem !important}

[dir="rtl"] .m-guts-m-r-13{margin-left:13rem !important}

[dir="ltr"] .m-guts-m-r-14{margin-right:14rem !important}

[dir="rtl"] .m-guts-m-r-14{margin-left:14rem !important}

[dir="ltr"] .m-guts-m-r-15{margin-right:15rem !important}

[dir="rtl"] .m-guts-m-r-15{margin-left:15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

[dir="ltr"] .guts-m-l-auto{margin-left:auto !important}

[dir="rtl"] .guts-m-l-auto{margin-right:auto !important}

[dir="ltr"] .guts-m-l-quart{margin-left:0.25rem !important}

[dir="rtl"] .guts-m-l-quart{margin-right:0.25rem !important}

[dir="ltr"] .guts-m-l-half{margin-left:0.5rem !important}

[dir="rtl"] .guts-m-l-half{margin-right:0.5rem !important}

[dir="ltr"] .guts-m-l-1-half{margin-left:1.5rem !important}

[dir="rtl"] .guts-m-l-1-half{margin-right:1.5rem !important}

[dir="ltr"] .guts-m-l-2-half{margin-left:2.5rem !important}

[dir="rtl"] .guts-m-l-2-half{margin-right:2.5rem !important}

[dir="ltr"] .guts-m-l-3-half{margin-left:3.5rem !important}

[dir="rtl"] .guts-m-l-3-half{margin-right:3.5rem !important}

[dir="ltr"] .guts-m-l-0{margin-left:0rem !important}

[dir="rtl"] .guts-m-l-0{margin-right:0rem !important}

[dir="ltr"] .guts-m-l-1{margin-left:1rem !important}

[dir="rtl"] .guts-m-l-1{margin-right:1rem !important}

[dir="ltr"] .guts-m-l-2{margin-left:2rem !important}

[dir="rtl"] .guts-m-l-2{margin-right:2rem !important}

[dir="ltr"] .guts-m-l-3{margin-left:3rem !important}

[dir="rtl"] .guts-m-l-3{margin-right:3rem !important}

[dir="ltr"] .guts-m-l-4{margin-left:4rem !important}

[dir="rtl"] .guts-m-l-4{margin-right:4rem !important}

[dir="ltr"] .guts-m-l-5{margin-left:5rem !important}

[dir="rtl"] .guts-m-l-5{margin-right:5rem !important}

[dir="ltr"] .guts-m-l-6{margin-left:6rem !important}

[dir="rtl"] .guts-m-l-6{margin-right:6rem !important}

[dir="ltr"] .guts-m-l-7{margin-left:7rem !important}

[dir="rtl"] .guts-m-l-7{margin-right:7rem !important}

[dir="ltr"] .guts-m-l-8{margin-left:8rem !important}

[dir="rtl"] .guts-m-l-8{margin-right:8rem !important}

[dir="ltr"] .guts-m-l-9{margin-left:9rem !important}

[dir="rtl"] .guts-m-l-9{margin-right:9rem !important}

[dir="ltr"] .guts-m-l-10{margin-left:10rem !important}

[dir="rtl"] .guts-m-l-10{margin-right:10rem !important}

[dir="ltr"] .guts-m-l-11{margin-left:11rem !important}

[dir="rtl"] .guts-m-l-11{margin-right:11rem !important}

[dir="ltr"] .guts-m-l-12{margin-left:12rem !important}

[dir="rtl"] .guts-m-l-12{margin-right:12rem !important}

[dir="ltr"] .guts-m-l-13{margin-left:13rem !important}

[dir="rtl"] .guts-m-l-13{margin-right:13rem !important}

[dir="ltr"] .guts-m-l-14{margin-left:14rem !important}

[dir="rtl"] .guts-m-l-14{margin-right:14rem !important}

[dir="ltr"] .guts-m-l-15{margin-left:15rem !important}

[dir="rtl"] .guts-m-l-15{margin-right:15rem !important}

@media(max-width: 768px){
[dir="ltr"] .m-guts-m-l-quart{margin-left:0.25rem !important}

[dir="rtl"] .m-guts-m-l-quart{margin-right:0.25rem !important}

[dir="ltr"] .m-guts-m-l-half{margin-left:0.5rem !important}

[dir="rtl"] .m-guts-m-l-half{margin-right:0.5rem !important}

[dir="ltr"] .m-guts-m-l-1-half{margin-left:1.5rem !important}

[dir="rtl"] .m-guts-m-l-1-half{margin-right:1.5rem !important}

[dir="ltr"] .m-guts-m-l-2-half{margin-left:2.5rem !important}

[dir="rtl"] .m-guts-m-l-2-half{margin-right:2.5rem !important}

[dir="ltr"] .m-guts-m-l-3-half{margin-left:3.5rem !important}

[dir="rtl"] .m-guts-m-l-3-half{margin-right:3.5rem !important}

[dir="ltr"] .m-guts-m-l-0{margin-left:0rem !important}

[dir="rtl"] .m-guts-m-l-0{margin-right:0rem !important}

[dir="ltr"] .m-guts-m-l-1{margin-left:1rem !important}

[dir="rtl"] .m-guts-m-l-1{margin-right:1rem !important}

[dir="ltr"] .m-guts-m-l-2{margin-left:2rem !important}

[dir="rtl"] .m-guts-m-l-2{margin-right:2rem !important}

[dir="ltr"] .m-guts-m-l-3{margin-left:3rem !important}

[dir="rtl"] .m-guts-m-l-3{margin-right:3rem !important}

[dir="ltr"] .m-guts-m-l-4{margin-left:4rem !important}

[dir="rtl"] .m-guts-m-l-4{margin-right:4rem !important}

[dir="ltr"] .m-guts-m-l-5{margin-left:5rem !important}

[dir="rtl"] .m-guts-m-l-5{margin-right:5rem !important}

[dir="ltr"] .m-guts-m-l-6{margin-left:6rem !important}

[dir="rtl"] .m-guts-m-l-6{margin-right:6rem !important}

[dir="ltr"] .m-guts-m-l-7{margin-left:7rem !important}

[dir="rtl"] .m-guts-m-l-7{margin-right:7rem !important}

[dir="ltr"] .m-guts-m-l-8{margin-left:8rem !important}

[dir="rtl"] .m-guts-m-l-8{margin-right:8rem !important}

[dir="ltr"] .m-guts-m-l-9{margin-left:9rem !important}

[dir="rtl"] .m-guts-m-l-9{margin-right:9rem !important}

[dir="ltr"] .m-guts-m-l-10{margin-left:10rem !important}

[dir="rtl"] .m-guts-m-l-10{margin-right:10rem !important}

[dir="ltr"] .m-guts-m-l-11{margin-left:11rem !important}

[dir="rtl"] .m-guts-m-l-11{margin-right:11rem !important}

[dir="ltr"] .m-guts-m-l-12{margin-left:12rem !important}

[dir="rtl"] .m-guts-m-l-12{margin-right:12rem !important}

[dir="ltr"] .m-guts-m-l-13{margin-left:13rem !important}

[dir="rtl"] .m-guts-m-l-13{margin-right:13rem !important}

[dir="ltr"] .m-guts-m-l-14{margin-left:14rem !important}

[dir="rtl"] .m-guts-m-l-14{margin-right:14rem !important}

[dir="ltr"] .m-guts-m-l-15{margin-left:15rem !important}

[dir="rtl"] .m-guts-m-l-15{margin-right:15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.guts-m-v-auto{margin-top:auto !important;margin-bottom:auto !important}

.guts-m-v-quart{margin-top:0.25rem !important;margin-bottom:0.25rem !important}

.guts-m-v-half{margin-top:0.5rem !important;margin-bottom:0.5rem !important}

.guts-m-v-1-half{margin-top:1.5rem !important;margin-bottom:1.5rem !important}

.guts-m-v-2-half{margin-top:2.5rem !important;margin-bottom:2.5rem !important}

.guts-m-v-3-half{margin-top:3.5rem !important;margin-bottom:3.5rem !important}

.guts-m-v-0{margin-top:0rem !important;margin-bottom:0rem !important}

.guts-m-v-1{margin-top:1rem !important;margin-bottom:1rem !important}

.guts-m-v-2{margin-top:2rem !important;margin-bottom:2rem !important}

.guts-m-v-3{margin-top:3rem !important;margin-bottom:3rem !important}

.guts-m-v-4{margin-top:4rem !important;margin-bottom:4rem !important}

.guts-m-v-5{margin-top:5rem !important;margin-bottom:5rem !important}

.guts-m-v-6{margin-top:6rem !important;margin-bottom:6rem !important}

.guts-m-v-7{margin-top:7rem !important;margin-bottom:7rem !important}

.guts-m-v-8{margin-top:8rem !important;margin-bottom:8rem !important}

.guts-m-v-9{margin-top:9rem !important;margin-bottom:9rem !important}

.guts-m-v-10{margin-top:10rem !important;margin-bottom:10rem !important}

.guts-m-v-11{margin-top:11rem !important;margin-bottom:11rem !important}

.guts-m-v-12{margin-top:12rem !important;margin-bottom:12rem !important}

.guts-m-v-13{margin-top:13rem !important;margin-bottom:13rem !important}

.guts-m-v-14{margin-top:14rem !important;margin-bottom:14rem !important}

.guts-m-v-15{margin-top:15rem !important;margin-bottom:15rem !important}

@media(max-width: 768px){
.m-guts-m-v-quart{margin-top:0.25rem !important;margin-bottom:0.25rem !important}

.m-guts-m-v-half{margin-top:0.5rem !important;margin-bottom:0.5rem !important}

.m-guts-m-v-1-half{margin-top:1.5rem !important;margin-bottom:1.5rem !important}

.m-guts-m-v-2-half{margin-top:2.5rem !important;margin-bottom:2.5rem !important}

.m-guts-m-v-3-half{margin-top:3.5rem !important;margin-bottom:3.5rem !important}

.m-guts-m-v-0{margin-top:0rem !important;margin-bottom:0rem !important}

.m-guts-m-v-1{margin-top:1rem !important;margin-bottom:1rem !important}

.m-guts-m-v-2{margin-top:2rem !important;margin-bottom:2rem !important}

.m-guts-m-v-3{margin-top:3rem !important;margin-bottom:3rem !important}

.m-guts-m-v-4{margin-top:4rem !important;margin-bottom:4rem !important}

.m-guts-m-v-5{margin-top:5rem !important;margin-bottom:5rem !important}

.m-guts-m-v-6{margin-top:6rem !important;margin-bottom:6rem !important}

.m-guts-m-v-7{margin-top:7rem !important;margin-bottom:7rem !important}

.m-guts-m-v-8{margin-top:8rem !important;margin-bottom:8rem !important}

.m-guts-m-v-9{margin-top:9rem !important;margin-bottom:9rem !important}

.m-guts-m-v-10{margin-top:10rem !important;margin-bottom:10rem !important}

.m-guts-m-v-11{margin-top:11rem !important;margin-bottom:11rem !important}

.m-guts-m-v-12{margin-top:12rem !important;margin-bottom:12rem !important}

.m-guts-m-v-13{margin-top:13rem !important;margin-bottom:13rem !important}

.m-guts-m-v-14{margin-top:14rem !important;margin-bottom:14rem !important}

.m-guts-m-v-15{margin-top:15rem !important;margin-bottom:15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.guts-m-h-auto{margin-right:auto !important;margin-left:auto !important}

.guts-m-h-quart{margin-right:0.25rem !important;margin-left:0.25rem !important}

.guts-m-h-half{margin-right:0.5rem !important;margin-left:0.5rem !important}

.guts-m-h-1-half{margin-right:1.5rem !important;margin-left:1.5rem !important}

.guts-m-h-2-half{margin-right:2.5rem !important;margin-left:2.5rem !important}

.guts-m-h-3-half{margin-right:3.5rem !important;margin-left:3.5rem !important}

.guts-m-h-0{margin-right:0rem !important;margin-left:0rem !important}

.guts-m-h-1{margin-right:1rem !important;margin-left:1rem !important}

.guts-m-h-2{margin-right:2rem !important;margin-left:2rem !important}

.guts-m-h-3{margin-right:3rem !important;margin-left:3rem !important}

.guts-m-h-4{margin-right:4rem !important;margin-left:4rem !important}

.guts-m-h-5{margin-right:5rem !important;margin-left:5rem !important}

.guts-m-h-6{margin-right:6rem !important;margin-left:6rem !important}

.guts-m-h-7{margin-right:7rem !important;margin-left:7rem !important}

.guts-m-h-8{margin-right:8rem !important;margin-left:8rem !important}

.guts-m-h-9{margin-right:9rem !important;margin-left:9rem !important}

.guts-m-h-10{margin-right:10rem !important;margin-left:10rem !important}

.guts-m-h-11{margin-right:11rem !important;margin-left:11rem !important}

.guts-m-h-12{margin-right:12rem !important;margin-left:12rem !important}

.guts-m-h-13{margin-right:13rem !important;margin-left:13rem !important}

.guts-m-h-14{margin-right:14rem !important;margin-left:14rem !important}

.guts-m-h-15{margin-right:15rem !important;margin-left:15rem !important}

@media(max-width: 768px){
.m-guts-m-h-quart{margin-right:0.25rem !important;margin-left:0.25rem !important}

.m-guts-m-h-half{margin-right:0.5rem !important;margin-left:0.5rem !important}

.m-guts-m-h-1-half{margin-right:1.5rem !important;margin-left:1.5rem !important}

.m-guts-m-h-2-half{margin-right:2.5rem !important;margin-left:2.5rem !important}

.m-guts-m-h-3-half{margin-right:3.5rem !important;margin-left:3.5rem !important}

.m-guts-m-h-0{margin-right:0rem !important;margin-left:0rem !important}

.m-guts-m-h-1{margin-right:1rem !important;margin-left:1rem !important}

.m-guts-m-h-2{margin-right:2rem !important;margin-left:2rem !important}

.m-guts-m-h-3{margin-right:3rem !important;margin-left:3rem !important}

.m-guts-m-h-4{margin-right:4rem !important;margin-left:4rem !important}

.m-guts-m-h-5{margin-right:5rem !important;margin-left:5rem !important}

.m-guts-m-h-6{margin-right:6rem !important;margin-left:6rem !important}

.m-guts-m-h-7{margin-right:7rem !important;margin-left:7rem !important}

.m-guts-m-h-8{margin-right:8rem !important;margin-left:8rem !important}

.m-guts-m-h-9{margin-right:9rem !important;margin-left:9rem !important}

.m-guts-m-h-10{margin-right:10rem !important;margin-left:10rem !important}

.m-guts-m-h-11{margin-right:11rem !important;margin-left:11rem !important}

.m-guts-m-h-12{margin-right:12rem !important;margin-left:12rem !important}

.m-guts-m-h-13{margin-right:13rem !important;margin-left:13rem !important}

.m-guts-m-h-14{margin-right:14rem !important;margin-left:14rem !important}

.m-guts-m-h-15{margin-right:15rem !important;margin-left:15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.guts-m-full-auto{margin:auto !important}

.guts-m-full-quart{margin:0.25rem !important}

.guts-m-full-half{margin:0.5rem !important}

.guts-m-full-1-half{margin:1.5rem !important}

.guts-m-full-2-half{margin:2.5rem !important}

.guts-m-full-3-half{margin:3.5rem !important}

.guts-m-full-0{margin:0rem !important}

.guts-m-full-1{margin:1rem !important}

.guts-m-full-2{margin:2rem !important}

.guts-m-full-3{margin:3rem !important}

.guts-m-full-4{margin:4rem !important}

.guts-m-full-5{margin:5rem !important}

.guts-m-full-6{margin:6rem !important}

.guts-m-full-7{margin:7rem !important}

.guts-m-full-8{margin:8rem !important}

.guts-m-full-9{margin:9rem !important}

.guts-m-full-10{margin:10rem !important}

.guts-m-full-11{margin:11rem !important}

.guts-m-full-12{margin:12rem !important}

.guts-m-full-13{margin:13rem !important}

.guts-m-full-14{margin:14rem !important}

.guts-m-full-15{margin:15rem !important}

@media(max-width: 768px){
.m-guts-m-full-quart{margin:0.25rem !important}

.m-guts-m-full-half{margin:0.5rem !important}

.m-guts-m-full-1-half{margin:1.5rem !important}

.m-guts-m-full-2-half{margin:2.5rem !important}

.m-guts-m-full-3-half{margin:3.5rem !important}

.m-guts-m-full-0{margin:0rem !important}

.m-guts-m-full-1{margin:1rem !important}

.m-guts-m-full-2{margin:2rem !important}

.m-guts-m-full-3{margin:3rem !important}

.m-guts-m-full-4{margin:4rem !important}

.m-guts-m-full-5{margin:5rem !important}

.m-guts-m-full-6{margin:6rem !important}

.m-guts-m-full-7{margin:7rem !important}

.m-guts-m-full-8{margin:8rem !important}

.m-guts-m-full-9{margin:9rem !important}

.m-guts-m-full-10{margin:10rem !important}

.m-guts-m-full-11{margin:11rem !important}

.m-guts-m-full-12{margin:12rem !important}

.m-guts-m-full-13{margin:13rem !important}

.m-guts-m-full-14{margin:14rem !important}

.m-guts-m-full-15{margin:15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.color-blue{color:#0062e3 !important}

.hover-color-blue:hover,.hover-color-blue:focus{color:#0062e3 !important}

.bg-color-blue{background-color:#0062e3 !important}

.hover-bg-color-blue:hover,.hover-bg-color-blue:focus{background-color:#0062e3 !important}

.color-blue-hue{color:#0062e3 !important}

.hover-color-blue-hue:hover,.hover-color-blue-hue:focus{color:#0062e3 !important}

.bg-color-blue-hue{background-color:#0062e3 !important}

.hover-bg-color-blue-hue:hover,.hover-bg-color-blue-hue:focus{background-color:#0062e3 !important}

.color-blue-light{color:#cce0f9 !important}

.hover-color-blue-light:hover,.hover-color-blue-light:focus{color:#cce0f9 !important}

.bg-color-blue-light{background-color:#cce0f9 !important}

.hover-bg-color-blue-light:hover,.hover-bg-color-blue-light:focus{background-color:#cce0f9 !important}

.color-blue-dark{color:#0046b0 !important}

.hover-color-blue-dark:hover,.hover-color-blue-dark:focus{color:#0046b0 !important}

.bg-color-blue-dark{background-color:#0046b0 !important}

.hover-bg-color-blue-dark:hover,.hover-bg-color-blue-dark:focus{background-color:#0046b0 !important}

.color-blue-a08{color:rgba(0,98,227,.08) !important}

.hover-color-blue-a08:hover,.hover-color-blue-a08:focus{color:rgba(0,98,227,.08) !important}

.bg-color-blue-a08{background-color:rgba(0,98,227,.08) !important}

.hover-bg-color-blue-a08:hover,.hover-bg-color-blue-a08:focus{background-color:rgba(0,98,227,.08) !important}

.color-blue-a50{color:rgba(0,98,227,.5) !important}

.hover-color-blue-a50:hover,.hover-color-blue-a50:focus{color:rgba(0,98,227,.5) !important}

.bg-color-blue-a50{background-color:rgba(0,98,227,.5) !important}

.hover-bg-color-blue-a50:hover,.hover-bg-color-blue-a50:focus{background-color:rgba(0,98,227,.5) !important}

.color-blue-a80{color:rgba(0,98,227,.8) !important}

.hover-color-blue-a80:hover,.hover-color-blue-a80:focus{color:rgba(0,98,227,.8) !important}

.bg-color-blue-a80{background-color:rgba(0,98,227,.8) !important}

.hover-bg-color-blue-a80:hover,.hover-bg-color-blue-a80:focus{background-color:rgba(0,98,227,.8) !important}

.color-green{color:#00a866 !important}

.hover-color-green:hover,.hover-color-green:focus{color:#00a866 !important}

.bg-color-green{background-color:#00a866 !important}

.hover-bg-color-green:hover,.hover-bg-color-green:focus{background-color:#00a866 !important}

.color-green-hue{color:#00a866 !important}

.hover-color-green-hue:hover,.hover-color-green-hue:focus{color:#00a866 !important}

.bg-color-green-hue{background-color:#00a866 !important}

.hover-bg-color-green-hue:hover,.hover-bg-color-green-hue:focus{background-color:#00a866 !important}

.color-green-light{color:#cceee0 !important}

.hover-color-green-light:hover,.hover-color-green-light:focus{color:#cceee0 !important}

.bg-color-green-light{background-color:#cceee0 !important}

.hover-bg-color-green-light:hover,.hover-bg-color-green-light:focus{background-color:#cceee0 !important}

.color-green-dark{color:#007547 !important}

.hover-color-green-dark:hover,.hover-color-green-dark:focus{color:#007547 !important}

.bg-color-green-dark{background-color:#007547 !important}

.hover-bg-color-green-dark:hover,.hover-bg-color-green-dark:focus{background-color:#007547 !important}

.color-yellow{color:#f0b400 !important}

.hover-color-yellow:hover,.hover-color-yellow:focus{color:#f0b400 !important}

.bg-color-yellow{background-color:#f0b400 !important}

.hover-bg-color-yellow:hover,.hover-bg-color-yellow:focus{background-color:#f0b400 !important}

.color-yellow-hue{color:#f0b400 !important}

.hover-color-yellow-hue:hover,.hover-color-yellow-hue:focus{color:#f0b400 !important}

.bg-color-yellow-hue{background-color:#f0b400 !important}

.hover-bg-color-yellow-hue:hover,.hover-bg-color-yellow-hue:focus{background-color:#f0b400 !important}

.color-yellow-light{color:#fcf0cc !important}

.hover-color-yellow-light:hover,.hover-color-yellow-light:focus{color:#fcf0cc !important}

.bg-color-yellow-light{background-color:#fcf0cc !important}

.hover-bg-color-yellow-light:hover,.hover-bg-color-yellow-light:focus{background-color:#fcf0cc !important}

.color-yellow-dark{color:#916600 !important}

.hover-color-yellow-dark:hover,.hover-color-yellow-dark:focus{color:#916600 !important}

.bg-color-yellow-dark{background-color:#916600 !important}

.hover-bg-color-yellow-dark:hover,.hover-bg-color-yellow-dark:focus{background-color:#916600 !important}

.color-red{color:#de0546 !important}

.hover-color-red:hover,.hover-color-red:focus{color:#de0546 !important}

.bg-color-red{background-color:#de0546 !important}

.hover-bg-color-red:hover,.hover-bg-color-red:focus{background-color:#de0546 !important}

.color-red-hue{color:#de0546 !important}

.hover-color-red-hue:hover,.hover-color-red-hue:focus{color:#de0546 !important}

.bg-color-red-hue{background-color:#de0546 !important}

.hover-bg-color-red-hue:hover,.hover-bg-color-red-hue:focus{background-color:#de0546 !important}

.color-red-light{color:#f8cdda !important}

.hover-color-red-light:hover,.hover-color-red-light:focus{color:#f8cdda !important}

.bg-color-red-light{background-color:#f8cdda !important}

.hover-bg-color-red-light:hover,.hover-bg-color-red-light:focus{background-color:#f8cdda !important}

.color-red-dark{color:#ab0341 !important}

.hover-color-red-dark:hover,.hover-color-red-dark:focus{color:#ab0341 !important}

.bg-color-red-dark{background-color:#ab0341 !important}

.hover-bg-color-red-dark:hover,.hover-bg-color-red-dark:focus{background-color:#ab0341 !important}

.color-ebony{color:#0f1f2c !important}

.hover-color-ebony:hover,.hover-color-ebony:focus{color:#0f1f2c !important}

.bg-color-ebony{background-color:#0f1f2c !important}

.hover-bg-color-ebony:hover,.hover-bg-color-ebony:focus{background-color:#0f1f2c !important}

.color-ebony-hue{color:#0f1f2c !important}

.hover-color-ebony-hue:hover,.hover-color-ebony-hue:focus{color:#0f1f2c !important}

.bg-color-ebony-hue{background-color:#0f1f2c !important}

.hover-bg-color-ebony-hue:hover,.hover-bg-color-ebony-hue:focus{background-color:#0f1f2c !important}

.color-ebony-a75{color:rgba(15,31,44,.75) !important}

.hover-color-ebony-a75:hover,.hover-color-ebony-a75:focus{color:rgba(15,31,44,.75) !important}

.bg-color-ebony-a75{background-color:rgba(15,31,44,.75) !important}

.hover-bg-color-ebony-a75:hover,.hover-bg-color-ebony-a75:focus{background-color:rgba(15,31,44,.75) !important}

.color-ebony-a75-hue{color:#4b5761 !important}

.hover-color-ebony-a75-hue:hover,.hover-color-ebony-a75-hue:focus{color:#4b5761 !important}

.bg-color-ebony-a75-hue{background-color:#4b5761 !important}

.hover-bg-color-ebony-a75-hue:hover,.hover-bg-color-ebony-a75-hue:focus{background-color:#4b5761 !important}

.color-ebony-a61{color:rgba(15,31,44,.61) !important}

.hover-color-ebony-a61:hover,.hover-color-ebony-a61:focus{color:rgba(15,31,44,.61) !important}

.bg-color-ebony-a61{background-color:rgba(15,31,44,.61) !important}

.hover-bg-color-ebony-a61:hover,.hover-bg-color-ebony-a61:focus{background-color:rgba(15,31,44,.61) !important}

.color-ebony-a61-hue{color:#6d767e !important}

.hover-color-ebony-a61-hue:hover,.hover-color-ebony-a61-hue:focus{color:#6d767e !important}

.bg-color-ebony-a61-hue{background-color:#6d767e !important}

.hover-bg-color-ebony-a61-hue:hover,.hover-bg-color-ebony-a61-hue:focus{background-color:#6d767e !important}

.color-ebony-a45{color:rgba(15,31,44,.45) !important}

.hover-color-ebony-a45:hover,.hover-color-ebony-a45:focus{color:rgba(15,31,44,.45) !important}

.bg-color-ebony-a45{background-color:rgba(15,31,44,.45) !important}

.hover-bg-color-ebony-a45:hover,.hover-bg-color-ebony-a45:focus{background-color:rgba(15,31,44,.45) !important}

.color-ebony-a45-hue{color:#939aa0 !important}

.hover-color-ebony-a45-hue:hover,.hover-color-ebony-a45-hue:focus{color:#939aa0 !important}

.bg-color-ebony-a45-hue{background-color:#939aa0 !important}

.hover-bg-color-ebony-a45-hue:hover,.hover-bg-color-ebony-a45-hue:focus{background-color:#939aa0 !important}

.color-ebony-a25{color:rgba(15,31,44,.25) !important}

.hover-color-ebony-a25:hover,.hover-color-ebony-a25:focus{color:rgba(15,31,44,.25) !important}

.bg-color-ebony-a25{background-color:rgba(15,31,44,.25) !important}

.hover-bg-color-ebony-a25:hover,.hover-bg-color-ebony-a25:focus{background-color:rgba(15,31,44,.25) !important}

.color-ebony-a25-hue{color:#c3c7ca !important}

.hover-color-ebony-a25-hue:hover,.hover-color-ebony-a25-hue:focus{color:#c3c7ca !important}

.bg-color-ebony-a25-hue{background-color:#c3c7ca !important}

.hover-bg-color-ebony-a25-hue:hover,.hover-bg-color-ebony-a25-hue:focus{background-color:#c3c7ca !important}

.color-ebony-a20{color:rgba(15,31,44,.2) !important}

.hover-color-ebony-a20:hover,.hover-color-ebony-a20:focus{color:rgba(15,31,44,.2) !important}

.bg-color-ebony-a20{background-color:rgba(15,31,44,.2) !important}

.hover-bg-color-ebony-a20:hover,.hover-bg-color-ebony-a20:focus{background-color:rgba(15,31,44,.2) !important}

.color-ebony-a20-hue{color:#cfd2d5 !important}

.hover-color-ebony-a20-hue:hover,.hover-color-ebony-a20-hue:focus{color:#cfd2d5 !important}

.bg-color-ebony-a20-hue{background-color:#cfd2d5 !important}

.hover-bg-color-ebony-a20-hue:hover,.hover-bg-color-ebony-a20-hue:focus{background-color:#cfd2d5 !important}

.color-ebony-a18{color:rgba(15,31,44,.18) !important}

.hover-color-ebony-a18:hover,.hover-color-ebony-a18:focus{color:rgba(15,31,44,.18) !important}

.bg-color-ebony-a18{background-color:rgba(15,31,44,.18) !important}

.hover-bg-color-ebony-a18:hover,.hover-bg-color-ebony-a18:focus{background-color:rgba(15,31,44,.18) !important}

.color-ebony-a18-hue{color:#d4d7d9 !important}

.hover-color-ebony-a18-hue:hover,.hover-color-ebony-a18-hue:focus{color:#d4d7d9 !important}

.bg-color-ebony-a18-hue{background-color:#d4d7d9 !important}

.hover-bg-color-ebony-a18-hue:hover,.hover-bg-color-ebony-a18-hue:focus{background-color:#d4d7d9 !important}

.color-ebony-a08{color:rgba(15,31,44,.08) !important}

.hover-color-ebony-a08:hover,.hover-color-ebony-a08:focus{color:rgba(15,31,44,.08) !important}

.bg-color-ebony-a08{background-color:rgba(15,31,44,.08) !important}

.hover-bg-color-ebony-a08:hover,.hover-bg-color-ebony-a08:focus{background-color:rgba(15,31,44,.08) !important}

.color-ebony-a08-hue{color:#ecedee !important}

.hover-color-ebony-a08-hue:hover,.hover-color-ebony-a08-hue:focus{color:#ecedee !important}

.bg-color-ebony-a08-hue{background-color:#ecedee !important}

.hover-bg-color-ebony-a08-hue:hover,.hover-bg-color-ebony-a08-hue:focus{background-color:#ecedee !important}

.color-ebony-a03{color:rgba(15,31,44,.03) !important}

.hover-color-ebony-a03:hover,.hover-color-ebony-a03:focus{color:rgba(15,31,44,.03) !important}

.bg-color-ebony-a03{background-color:rgba(15,31,44,.03) !important}

.hover-bg-color-ebony-a03:hover,.hover-bg-color-ebony-a03:focus{background-color:rgba(15,31,44,.03) !important}

.color-ebony-a03-hue{color:#f8f8f9 !important}

.hover-color-ebony-a03-hue:hover,.hover-color-ebony-a03-hue:focus{color:#f8f8f9 !important}

.bg-color-ebony-a03-hue{background-color:#f8f8f9 !important}

.hover-bg-color-ebony-a03-hue:hover,.hover-bg-color-ebony-a03-hue:focus{background-color:#f8f8f9 !important}

.color-white{color:#fff !important}

.hover-color-white:hover,.hover-color-white:focus{color:#fff !important}

.bg-color-white{background-color:#fff !important}

.hover-bg-color-white:hover,.hover-bg-color-white:focus{background-color:#fff !important}

.color-white-hue{color:#fff !important}

.hover-color-white-hue:hover,.hover-color-white-hue:focus{color:#fff !important}

.bg-color-white-hue{background-color:#fff !important}

.hover-bg-color-white-hue:hover,.hover-bg-color-white-hue:focus{background-color:#fff !important}

.color-white-a90{color:rgba(255,255,255,.9) !important}

.hover-color-white-a90:hover,.hover-color-white-a90:focus{color:rgba(255,255,255,.9) !important}

.bg-color-white-a90{background-color:rgba(255,255,255,.9) !important}

.hover-bg-color-white-a90:hover,.hover-bg-color-white-a90:focus{background-color:rgba(255,255,255,.9) !important}

.color-white-a80{color:rgba(255,255,255,.8) !important}

.hover-color-white-a80:hover,.hover-color-white-a80:focus{color:rgba(255,255,255,.8) !important}

.bg-color-white-a80{background-color:rgba(255,255,255,.8) !important}

.hover-bg-color-white-a80:hover,.hover-bg-color-white-a80:focus{background-color:rgba(255,255,255,.8) !important}

.color-white-a75{color:rgba(255,255,255,.75) !important}

.hover-color-white-a75:hover,.hover-color-white-a75:focus{color:rgba(255,255,255,.75) !important}

.bg-color-white-a75{background-color:rgba(255,255,255,.75) !important}

.hover-bg-color-white-a75:hover,.hover-bg-color-white-a75:focus{background-color:rgba(255,255,255,.75) !important}

.color-white-a50{color:rgba(255,255,255,.5) !important}

.hover-color-white-a50:hover,.hover-color-white-a50:focus{color:rgba(255,255,255,.5) !important}

.bg-color-white-a50{background-color:rgba(255,255,255,.5) !important}

.hover-bg-color-white-a50:hover,.hover-bg-color-white-a50:focus{background-color:rgba(255,255,255,.5) !important}

.color-white-a20{color:rgba(255,255,255,.2) !important}

.hover-color-white-a20:hover,.hover-color-white-a20:focus{color:rgba(255,255,255,.2) !important}

.bg-color-white-a20{background-color:rgba(255,255,255,.2) !important}

.hover-bg-color-white-a20:hover,.hover-bg-color-white-a20:focus{background-color:rgba(255,255,255,.2) !important}

.color-coral{color:#f54e31 !important}

.hover-color-coral:hover,.hover-color-coral:focus{color:#f54e31 !important}

.bg-color-coral{background-color:#f54e31 !important}

.hover-bg-color-coral:hover,.hover-bg-color-coral:focus{background-color:#f54e31 !important}

.color-coral-hue{color:#f54e31 !important}

.hover-color-coral-hue:hover,.hover-color-coral-hue:focus{color:#f54e31 !important}

.bg-color-coral-hue{background-color:#f54e31 !important}

.hover-bg-color-coral-hue:hover,.hover-bg-color-coral-hue:focus{background-color:#f54e31 !important}

.color-coral-light{color:#fddcd6 !important}

.hover-color-coral-light:hover,.hover-color-coral-light:focus{color:#fddcd6 !important}

.bg-color-coral-light{background-color:#fddcd6 !important}

.hover-bg-color-coral-light:hover,.hover-bg-color-coral-light:focus{background-color:#fddcd6 !important}

.color-coral-medium{color:#f6a294 !important}

.hover-color-coral-medium:hover,.hover-color-coral-medium:focus{color:#f6a294 !important}

.bg-color-coral-medium{background-color:#f6a294 !important}

.hover-bg-color-coral-medium:hover,.hover-bg-color-coral-medium:focus{background-color:#f6a294 !important}

.color-coral-dark{color:#c2341b !important}

.hover-color-coral-dark:hover,.hover-color-coral-dark:focus{color:#c2341b !important}

.bg-color-coral-dark{background-color:#c2341b !important}

.hover-bg-color-coral-dark:hover,.hover-bg-color-coral-dark:focus{background-color:#c2341b !important}

.color-forest{color:#3da807 !important}

.hover-color-forest:hover,.hover-color-forest:focus{color:#3da807 !important}

.bg-color-forest{background-color:#3da807 !important}

.hover-bg-color-forest:hover,.hover-bg-color-forest:focus{background-color:#3da807 !important}

.color-forest-hue{color:#3da807 !important}

.hover-color-forest-hue:hover,.hover-color-forest-hue:focus{color:#3da807 !important}

.bg-color-forest-hue{background-color:#3da807 !important}

.hover-bg-color-forest-hue:hover,.hover-bg-color-forest-hue:focus{background-color:#3da807 !important}

.color-forest-light{color:#d8eecd !important}

.hover-color-forest-light:hover,.hover-color-forest-light:focus{color:#d8eecd !important}

.bg-color-forest-light{background-color:#d8eecd !important}

.hover-bg-color-forest-light:hover,.hover-bg-color-forest-light:focus{background-color:#d8eecd !important}

.color-forest-medium{color:#99cf7e !important}

.hover-color-forest-medium:hover,.hover-color-forest-medium:focus{color:#99cf7e !important}

.bg-color-forest-medium{background-color:#99cf7e !important}

.hover-bg-color-forest-medium:hover,.hover-bg-color-forest-medium:focus{background-color:#99cf7e !important}

.color-forest-dark{color:#297504 !important}

.hover-color-forest-dark:hover,.hover-color-forest-dark:focus{color:#297504 !important}

.bg-color-forest-dark{background-color:#297504 !important}

.hover-bg-color-forest-dark:hover,.hover-bg-color-forest-dark:focus{background-color:#297504 !important}

.color-fuchsia{color:#b844bd !important}

.hover-color-fuchsia:hover,.hover-color-fuchsia:focus{color:#b844bd !important}

.bg-color-fuchsia{background-color:#b844bd !important}

.hover-bg-color-fuchsia:hover,.hover-bg-color-fuchsia:focus{background-color:#b844bd !important}

.color-fuchsia-hue{color:#b844bd !important}

.hover-color-fuchsia-hue:hover,.hover-color-fuchsia-hue:focus{color:#b844bd !important}

.bg-color-fuchsia-hue{background-color:#b844bd !important}

.hover-bg-color-fuchsia-hue:hover,.hover-bg-color-fuchsia-hue:focus{background-color:#b844bd !important}

.color-fuchsia-light{color:#f1d7f2 !important}

.hover-color-fuchsia-light:hover,.hover-color-fuchsia-light:focus{color:#f1d7f2 !important}

.bg-color-fuchsia-light{background-color:#f1d7f2 !important}

.hover-bg-color-fuchsia-light:hover,.hover-bg-color-fuchsia-light:focus{background-color:#f1d7f2 !important}

.color-fuchsia-medium{color:#d79dd9 !important}

.hover-color-fuchsia-medium:hover,.hover-color-fuchsia-medium:focus{color:#d79dd9 !important}

.bg-color-fuchsia-medium{background-color:#d79dd9 !important}

.hover-bg-color-fuchsia-medium:hover,.hover-bg-color-fuchsia-medium:focus{background-color:#d79dd9 !important}

.color-fuchsia-dark{color:#85128a !important}

.hover-color-fuchsia-dark:hover,.hover-color-fuchsia-dark:focus{color:#85128a !important}

.bg-color-fuchsia-dark{background-color:#85128a !important}

.hover-bg-color-fuchsia-dark:hover,.hover-bg-color-fuchsia-dark:focus{background-color:#85128a !important}

.color-ocean{color:#00a6a6 !important}

.hover-color-ocean:hover,.hover-color-ocean:focus{color:#00a6a6 !important}

.bg-color-ocean{background-color:#00a6a6 !important}

.hover-bg-color-ocean:hover,.hover-bg-color-ocean:focus{background-color:#00a6a6 !important}

.color-ocean-hue{color:#00a6a6 !important}

.hover-color-ocean-hue:hover,.hover-color-ocean-hue:focus{color:#00a6a6 !important}

.bg-color-ocean-hue{background-color:#00a6a6 !important}

.hover-bg-color-ocean-hue:hover,.hover-bg-color-ocean-hue:focus{background-color:#00a6a6 !important}

.color-ocean-light{color:#cceded !important}

.hover-color-ocean-light:hover,.hover-color-ocean-light:focus{color:#cceded !important}

.bg-color-ocean-light{background-color:#cceded !important}

.hover-bg-color-ocean-light:hover,.hover-bg-color-ocean-light:focus{background-color:#cceded !important}

.color-ocean-medium{color:#7bcece !important}

.hover-color-ocean-medium:hover,.hover-color-ocean-medium:focus{color:#7bcece !important}

.bg-color-ocean-medium{background-color:#7bcece !important}

.hover-bg-color-ocean-medium:hover,.hover-bg-color-ocean-medium:focus{background-color:#7bcece !important}

.color-ocean-dark{color:#007373 !important}

.hover-color-ocean-dark:hover,.hover-color-ocean-dark:focus{color:#007373 !important}

.bg-color-ocean-dark{background-color:#007373 !important}

.hover-bg-color-ocean-dark:hover,.hover-bg-color-ocean-dark:focus{background-color:#007373 !important}

.color-orange{color:#e87400 !important}

.hover-color-orange:hover,.hover-color-orange:focus{color:#e87400 !important}

.bg-color-orange{background-color:#e87400 !important}

.hover-bg-color-orange:hover,.hover-bg-color-orange:focus{background-color:#e87400 !important}

.color-orange-hue{color:#e87400 !important}

.hover-color-orange-hue:hover,.hover-color-orange-hue:focus{color:#e87400 !important}

.bg-color-orange-hue{background-color:#e87400 !important}

.hover-bg-color-orange-hue:hover,.hover-bg-color-orange-hue:focus{background-color:#e87400 !important}

.color-orange-light{color:#fae3cc !important}

.hover-color-orange-light:hover,.hover-color-orange-light:focus{color:#fae3cc !important}

.bg-color-orange-light{background-color:#fae3cc !important}

.hover-bg-color-orange-light:hover,.hover-bg-color-orange-light:focus{background-color:#fae3cc !important}

.color-orange-medium{color:#efb57b !important}

.hover-color-orange-medium:hover,.hover-color-orange-medium:focus{color:#efb57b !important}

.bg-color-orange-medium{background-color:#efb57b !important}

.hover-bg-color-orange-medium:hover,.hover-bg-color-orange-medium:focus{background-color:#efb57b !important}

.color-orange-dark{color:#b54e00 !important}

.hover-color-orange-dark:hover,.hover-color-orange-dark:focus{color:#b54e00 !important}

.bg-color-orange-dark{background-color:#b54e00 !important}

.hover-bg-color-orange-dark:hover,.hover-bg-color-orange-dark:focus{background-color:#b54e00 !important}

.color-pink{color:#eb3d7d !important}

.hover-color-pink:hover,.hover-color-pink:focus{color:#eb3d7d !important}

.bg-color-pink{background-color:#eb3d7d !important}

.hover-bg-color-pink:hover,.hover-bg-color-pink:focus{background-color:#eb3d7d !important}

.color-pink-hue{color:#eb3d7d !important}

.hover-color-pink-hue:hover,.hover-color-pink-hue:focus{color:#eb3d7d !important}

.bg-color-pink-hue{background-color:#eb3d7d !important}

.hover-bg-color-pink-hue:hover,.hover-bg-color-pink-hue:focus{background-color:#eb3d7d !important}

.color-pink-light{color:#fbd8e8 !important}

.hover-color-pink-light:hover,.hover-color-pink-light:focus{color:#fbd8e8 !important}

.bg-color-pink-light{background-color:#fbd8e8 !important}

.hover-bg-color-pink-light:hover,.hover-bg-color-pink-light:focus{background-color:#fbd8e8 !important}

.color-pink-medium{color:#f099b9 !important}

.hover-color-pink-medium:hover,.hover-color-pink-medium:focus{color:#f099b9 !important}

.bg-color-pink-medium{background-color:#f099b9 !important}

.hover-bg-color-pink-medium:hover,.hover-bg-color-pink-medium:focus{background-color:#f099b9 !important}

.color-pink-dark{color:#b81c62 !important}

.hover-color-pink-dark:hover,.hover-color-pink-dark:focus{color:#b81c62 !important}

.bg-color-pink-dark{background-color:#b81c62 !important}

.hover-bg-color-pink-dark:hover,.hover-bg-color-pink-dark:focus{background-color:#b81c62 !important}

.color-purple{color:#7f43e0 !important}

.hover-color-purple:hover,.hover-color-purple:focus{color:#7f43e0 !important}

.bg-color-purple{background-color:#7f43e0 !important}

.hover-bg-color-purple:hover,.hover-bg-color-purple:focus{background-color:#7f43e0 !important}

.color-purple-hue{color:#7f43e0 !important}

.hover-color-purple-hue:hover,.hover-color-purple-hue:focus{color:#7f43e0 !important}

.bg-color-purple-hue{background-color:#7f43e0 !important}

.hover-bg-color-purple-hue:hover,.hover-bg-color-purple-hue:focus{background-color:#7f43e0 !important}

.color-purple-light{color:#e5d9f9 !important}

.hover-color-purple-light:hover,.hover-color-purple-light:focus{color:#e5d9f9 !important}

.bg-color-purple-light{background-color:#e5d9f9 !important}

.hover-bg-color-purple-light:hover,.hover-bg-color-purple-light:focus{background-color:#e5d9f9 !important}

.color-purple-medium{color:#bb9deb !important}

.hover-color-purple-medium:hover,.hover-color-purple-medium:focus{color:#bb9deb !important}

.bg-color-purple-medium{background-color:#bb9deb !important}

.hover-bg-color-purple-medium:hover,.hover-bg-color-purple-medium:focus{background-color:#bb9deb !important}

.color-purple-dark{color:#562aad !important}

.hover-color-purple-dark:hover,.hover-color-purple-dark:focus{color:#562aad !important}

.bg-color-purple-dark{background-color:#562aad !important}

.hover-bg-color-purple-dark:hover,.hover-bg-color-purple-dark:focus{background-color:#562aad !important}

.color-sky{color:#009fd9 !important}

.hover-color-sky:hover,.hover-color-sky:focus{color:#009fd9 !important}

.bg-color-sky{background-color:#009fd9 !important}

.hover-bg-color-sky:hover,.hover-bg-color-sky:focus{background-color:#009fd9 !important}

.color-sky-hue{color:#009fd9 !important}

.hover-color-sky-hue:hover,.hover-color-sky-hue:focus{color:#009fd9 !important}

.bg-color-sky-hue{background-color:#009fd9 !important}

.hover-bg-color-sky-hue:hover,.hover-bg-color-sky-hue:focus{background-color:#009fd9 !important}

.color-sky-light{color:#ccecf7 !important}

.hover-color-sky-light:hover,.hover-color-sky-light:focus{color:#ccecf7 !important}

.bg-color-sky-light{background-color:#ccecf7 !important}

.hover-bg-color-sky-light:hover,.hover-bg-color-sky-light:focus{background-color:#ccecf7 !important}

.color-sky-medium{color:#7bcae7 !important}

.hover-color-sky-medium:hover,.hover-color-sky-medium:focus{color:#7bcae7 !important}

.bg-color-sky-medium{background-color:#7bcae7 !important}

.hover-bg-color-sky-medium:hover,.hover-bg-color-sky-medium:focus{background-color:#7bcae7 !important}

.color-sky-dark{color:#007099 !important}

.hover-color-sky-dark:hover,.hover-color-sky-dark:focus{color:#007099 !important}

.bg-color-sky-dark{background-color:#007099 !important}

.hover-bg-color-sky-dark:hover,.hover-bg-color-sky-dark:focus{background-color:#007099 !important}

.color-success{color:#00a866 !important}

.hover-color-success:hover,.hover-color-success:focus{color:#00a866 !important}

.bg-color-success{background-color:#00a866 !important}

.hover-bg-color-success:hover,.hover-bg-color-success:focus{background-color:#00a866 !important}

.color-success-hue{color:#00a866 !important}

.hover-color-success-hue:hover,.hover-color-success-hue:focus{color:#00a866 !important}

.bg-color-success-hue{background-color:#00a866 !important}

.hover-bg-color-success-hue:hover,.hover-bg-color-success-hue:focus{background-color:#00a866 !important}

.color-success-light{color:#cceee0 !important}

.hover-color-success-light:hover,.hover-color-success-light:focus{color:#cceee0 !important}

.bg-color-success-light{background-color:#cceee0 !important}

.hover-bg-color-success-light:hover,.hover-bg-color-success-light:focus{background-color:#cceee0 !important}

.color-success-dark{color:#007547 !important}

.hover-color-success-dark:hover,.hover-color-success-dark:focus{color:#007547 !important}

.bg-color-success-dark{background-color:#007547 !important}

.hover-bg-color-success-dark:hover,.hover-bg-color-success-dark:focus{background-color:#007547 !important}

.color-warning{color:#f0b400 !important}

.hover-color-warning:hover,.hover-color-warning:focus{color:#f0b400 !important}

.bg-color-warning{background-color:#f0b400 !important}

.hover-bg-color-warning:hover,.hover-bg-color-warning:focus{background-color:#f0b400 !important}

.color-warning-hue{color:#f0b400 !important}

.hover-color-warning-hue:hover,.hover-color-warning-hue:focus{color:#f0b400 !important}

.bg-color-warning-hue{background-color:#f0b400 !important}

.hover-bg-color-warning-hue:hover,.hover-bg-color-warning-hue:focus{background-color:#f0b400 !important}

.color-warning-light{color:#fcf0cc !important}

.hover-color-warning-light:hover,.hover-color-warning-light:focus{color:#fcf0cc !important}

.bg-color-warning-light{background-color:#fcf0cc !important}

.hover-bg-color-warning-light:hover,.hover-bg-color-warning-light:focus{background-color:#fcf0cc !important}

.color-warning-dark{color:#916600 !important}

.hover-color-warning-dark:hover,.hover-color-warning-dark:focus{color:#916600 !important}

.bg-color-warning-dark{background-color:#916600 !important}

.hover-bg-color-warning-dark:hover,.hover-bg-color-warning-dark:focus{background-color:#916600 !important}

.color-static{color:#f0b400 !important}

.hover-color-static:hover,.hover-color-static:focus{color:#f0b400 !important}

.bg-color-static{background-color:#f0b400 !important}

.hover-bg-color-static:hover,.hover-bg-color-static:focus{background-color:#f0b400 !important}

.color-static-hue{color:#f0b400 !important}

.hover-color-static-hue:hover,.hover-color-static-hue:focus{color:#f0b400 !important}

.bg-color-static-hue{background-color:#f0b400 !important}

.hover-bg-color-static-hue:hover,.hover-bg-color-static-hue:focus{background-color:#f0b400 !important}

.color-static-light{color:#fcf0cc !important}

.hover-color-static-light:hover,.hover-color-static-light:focus{color:#fcf0cc !important}

.bg-color-static-light{background-color:#fcf0cc !important}

.hover-bg-color-static-light:hover,.hover-bg-color-static-light:focus{background-color:#fcf0cc !important}

.color-static-dark{color:#916600 !important}

.hover-color-static-dark:hover,.hover-color-static-dark:focus{color:#916600 !important}

.bg-color-static-dark{background-color:#916600 !important}

.hover-bg-color-static-dark:hover,.hover-bg-color-static-dark:focus{background-color:#916600 !important}

.color-pending{color:#f0b400 !important}

.hover-color-pending:hover,.hover-color-pending:focus{color:#f0b400 !important}

.bg-color-pending{background-color:#f0b400 !important}

.hover-bg-color-pending:hover,.hover-bg-color-pending:focus{background-color:#f0b400 !important}

.color-pending-hue{color:#f0b400 !important}

.hover-color-pending-hue:hover,.hover-color-pending-hue:focus{color:#f0b400 !important}

.bg-color-pending-hue{background-color:#f0b400 !important}

.hover-bg-color-pending-hue:hover,.hover-bg-color-pending-hue:focus{background-color:#f0b400 !important}

.color-pending-light{color:#fcf0cc !important}

.hover-color-pending-light:hover,.hover-color-pending-light:focus{color:#fcf0cc !important}

.bg-color-pending-light{background-color:#fcf0cc !important}

.hover-bg-color-pending-light:hover,.hover-bg-color-pending-light:focus{background-color:#fcf0cc !important}

.color-pending-dark{color:#916600 !important}

.hover-color-pending-dark:hover,.hover-color-pending-dark:focus{color:#916600 !important}

.bg-color-pending-dark{background-color:#916600 !important}

.hover-bg-color-pending-dark:hover,.hover-bg-color-pending-dark:focus{background-color:#916600 !important}

.color-error{color:#de0546 !important}

.hover-color-error:hover,.hover-color-error:focus{color:#de0546 !important}

.bg-color-error{background-color:#de0546 !important}

.hover-bg-color-error:hover,.hover-bg-color-error:focus{background-color:#de0546 !important}

.color-error-hue{color:#de0546 !important}

.hover-color-error-hue:hover,.hover-color-error-hue:focus{color:#de0546 !important}

.bg-color-error-hue{background-color:#de0546 !important}

.hover-bg-color-error-hue:hover,.hover-bg-color-error-hue:focus{background-color:#de0546 !important}

.color-error-light{color:#f8cdda !important}

.hover-color-error-light:hover,.hover-color-error-light:focus{color:#f8cdda !important}

.bg-color-error-light{background-color:#f8cdda !important}

.hover-bg-color-error-light:hover,.hover-bg-color-error-light:focus{background-color:#f8cdda !important}

.color-error-dark{color:#ab0341 !important}

.hover-color-error-dark:hover,.hover-color-error-dark:focus{color:#ab0341 !important}

.bg-color-error-dark{background-color:#ab0341 !important}

.hover-bg-color-error-dark:hover,.hover-bg-color-error-dark:focus{background-color:#ab0341 !important}

.color-decrease{color:#de0546 !important}

.hover-color-decrease:hover,.hover-color-decrease:focus{color:#de0546 !important}

.bg-color-decrease{background-color:#de0546 !important}

.hover-bg-color-decrease:hover,.hover-bg-color-decrease:focus{background-color:#de0546 !important}

.color-decrease-hue{color:#de0546 !important}

.hover-color-decrease-hue:hover,.hover-color-decrease-hue:focus{color:#de0546 !important}

.bg-color-decrease-hue{background-color:#de0546 !important}

.hover-bg-color-decrease-hue:hover,.hover-bg-color-decrease-hue:focus{background-color:#de0546 !important}

.color-decrease-light{color:#f8cdda !important}

.hover-color-decrease-light:hover,.hover-color-decrease-light:focus{color:#f8cdda !important}

.bg-color-decrease-light{background-color:#f8cdda !important}

.hover-bg-color-decrease-light:hover,.hover-bg-color-decrease-light:focus{background-color:#f8cdda !important}

.color-decrease-dark{color:#ab0341 !important}

.hover-color-decrease-dark:hover,.hover-color-decrease-dark:focus{color:#ab0341 !important}

.bg-color-decrease-dark{background-color:#ab0341 !important}

.hover-bg-color-decrease-dark:hover,.hover-bg-color-decrease-dark:focus{background-color:#ab0341 !important}

.color-destructive{color:#de0546 !important}

.hover-color-destructive:hover,.hover-color-destructive:focus{color:#de0546 !important}

.bg-color-destructive{background-color:#de0546 !important}

.hover-bg-color-destructive:hover,.hover-bg-color-destructive:focus{background-color:#de0546 !important}

.color-destructive-hue{color:#de0546 !important}

.hover-color-destructive-hue:hover,.hover-color-destructive-hue:focus{color:#de0546 !important}

.bg-color-destructive-hue{background-color:#de0546 !important}

.hover-bg-color-destructive-hue:hover,.hover-bg-color-destructive-hue:focus{background-color:#de0546 !important}

.color-destructive-light{color:#f8cdda !important}

.hover-color-destructive-light:hover,.hover-color-destructive-light:focus{color:#f8cdda !important}

.bg-color-destructive-light{background-color:#f8cdda !important}

.hover-bg-color-destructive-light:hover,.hover-bg-color-destructive-light:focus{background-color:#f8cdda !important}

.color-destructive-dark{color:#ab0341 !important}

.hover-color-destructive-dark:hover,.hover-color-destructive-dark:focus{color:#ab0341 !important}

.bg-color-destructive-dark{background-color:#ab0341 !important}

.hover-bg-color-destructive-dark:hover,.hover-bg-color-destructive-dark:focus{background-color:#ab0341 !important}

@-webkit-keyframes anim-effect-jelena{
0%{opacity:1;transform:scale3d(0.5, 0.5, 1)}

to{opacity:0;transform:scale3d(1.2, 1.2, 1)}}

@keyframes anim-effect-jelena{
0%{opacity:1;transform:scale3d(0.5, 0.5, 1)}

to{opacity:0;transform:scale3d(1.2, 1.2, 1)}}

.oh-animating.ng-animating{overflow:hidden}

.hover-only,.highlight-on-hover{opacity:0;-webkit-transition:opacity .15s linear;transition:opacity .15s linear}

.hover-only:hover,.highlight-on-hover:hover{opacity:1}

.highlight-on-hover{opacity:.5}

.grow{transition:all .1s ease-in-out}

.grow:hover{transform:scale(1.05)}

.shrink{transition:all .1s ease-in-out}

.shrink:hover{transform:scale(0.95)}

.fade-in{opacity:1;transition:all .3s ease-in}

.fade-out{opacity:0;transition:all .3s ease-out}

.flip{transition:transform 600ms linear}

[dir="ltr"] .flip{transform:rotateY(180deg)}

[dir="rtl"] .flip{transform:rotateY(-180deg)}

.border{border:1px solid rgba(15,31,44,.18)}

.border-lite{border:1px solid rgba(15,31,44,.08)}

[dir="ltr"] .border-right{border-right:1px solid rgba(15,31,44,.18)}

[dir="rtl"] .border-right{border-left:1px solid rgba(15,31,44,.18)}

[dir="ltr"] .border-left{border-left:1px solid rgba(15,31,44,.18) !important}

[dir="rtl"] .border-left{border-right:1px solid rgba(15,31,44,.18) !important}

.border-left.no-border{border-top:none !important;border-bottom:none !important}

[dir="ltr"] .border-left.no-border{border-left:1px solid rgba(15,31,44,.18) !important;border-right:none !important}

[dir="rtl"] .border-left.no-border{border-right:1px solid rgba(15,31,44,.18) !important;border-left:none !important}

.border-top{border-top:1px solid rgba(15,31,44,.18)}

.border-top-behind{margin-top:-1px}

.border-top.not-first:first-child{border-top:none}

.border-top-none{border-top:none !important}

.border-bottom{border-bottom:1px solid rgba(15,31,44,.18)}

.border-bottom.not-last:last-child{border-bottom:none}

.border-bottom--smaller:after{content:"";width:90%;height:1px;background:rgba(15,31,44,.18);display:block;position:absolute;bottom:0}

[dir="ltr"] .border-bottom--smaller:after{left:5%}

[dir="rtl"] .border-bottom--smaller:after{right:5%}

.border--error{border-color:#de0546 !important}

.border-arrow{position:absolute;width:0;height:0;border-width:5px 6px;border-style:solid;border-color:rgba(0,0,0,0)}

.border-arrow:after{content:"";position:absolute;width:0;height:0;border-width:5px 6px;border-style:solid;border-color:rgba(0,0,0,0)}

.border-arrow--top{top:-5px;border-top-width:0;border-bottom-color:rgba(15,31,44,.18)}

[dir="ltr"] .border-arrow--top{left:0;margin-left:17px}

[dir="rtl"] .border-arrow--top{right:0;margin-right:17px}

.border-arrow--top:after{top:1px;border-top-width:0;border-bottom-color:#fff}

[dir="ltr"] .border-arrow--top:after{margin-left:-6px}

[dir="rtl"] .border-arrow--top:after{margin-right:-6px}

.border-arrow--bottom{bottom:-5px;border-bottom-width:0;border-top-color:rgba(15,31,44,.18)}

[dir="ltr"] .border-arrow--bottom{left:0;margin-left:17px}

[dir="rtl"] .border-arrow--bottom{right:0;margin-right:17px}

.border-arrow--bottom:after{bottom:1px;border-bottom-width:0;border-top-color:#fff}

[dir="ltr"] .border-arrow--bottom:after{margin-left:-6px}

[dir="rtl"] .border-arrow--bottom:after{margin-right:-6px}

table.border-collapse{border-collapse:collapse}

.squared{border-radius:0px !important}

.rounded{border-radius:.25rem}

.rounded--large{border-radius:.5rem}

.rounded-top{border-top-left-radius:.5rem;border-top-right-radius:.5rem}

.rounded-bottom{border-bottom-left-radius:.5rem !important;border-bottom-right-radius:.5rem !important}

.round{border-radius:50% !important}

[dir="ltr"] .radius-t-l-0{border-top-left-radius:0 !important}

[dir="rtl"] .radius-t-l-0{border-top-right-radius:0 !important}

[dir="ltr"] .radius-t-r-0{border-top-right-radius:0 !important}

[dir="rtl"] .radius-t-r-0{border-top-left-radius:0 !important}

.inherit{position:inherit}

.absolute{position:absolute}

.absolute--top-left-offset{top:-6px}

[dir="ltr"] .absolute--top-left-offset{left:-10px}

[dir="rtl"] .absolute--top-left-offset{right:-10px}

.absolute--bottom-right-offset{bottom:-2px}

[dir="ltr"] .absolute--bottom-right-offset{right:0px}

[dir="rtl"] .absolute--bottom-right-offset{left:0px}

@media(max-width: 768px){
.absolute.m-rel{position:relative}}

.b-0{bottom:0}

[dir="ltr"] .r-0{right:0}

[dir="rtl"] .r-0{left:0}

.lh1{line-height:1}

.lh2{line-height:2em}

.lh3{line-height:2em}

.no-pad{padding:0 !important}

.block{display:block !important}

.inline{display:inline !important}

[dir="ltr"] .float-left{float:left}

[dir="rtl"] .float-left{float:right}

.ib{display:inline-block !important}

.oh{overflow:hidden}

.os{overflow:scroll}

.ov{overflow:visible !important}

.oauto{overflow:auto}

.oxh{overflow-x:hidden}

.oxa{overflow-x:auto}

.full-screen-grid-buster{width:100vw}

[dir="ltr"] .full-screen-grid-buster{left:calc(-1*(100vw - 100%)/2)}

[dir="rtl"] .full-screen-grid-buster{right:calc(-1*(100vw - 100%)/2)}

.full-width{width:100%}

.auto-width{width:auto !important}

.min-width-0{min-width:0}

.no-min-width{min-width:auto !important}

.not-too-wide{max-width:100%}

.wide-width{width:1200px}

.full-height{height:100%}

.centering-wrapper{display:table}

.cell,.v-middle{float:none;display:table-cell;vertical-align:middle}

.vertical-center{position:relative;top:50%;transform:translateY(-50%)}

.vertical-center.absolute{position:absolute}

.horizontal-center{position:relative}

[dir="ltr"] .horizontal-center{left:50%;transform:translateX(-50%)}

[dir="rtl"] .horizontal-center{right:50%;transform:translateX(50%)}

.horizontal-center.absolute{position:absolute}

.abs-center{position:relative;top:50%}

[dir="ltr"] .abs-center{left:50%;transform:translateX(-50%) translateY(-50%)}

[dir="rtl"] .abs-center{right:50%;transform:translateX(50%) translateY(-50%)}

.abs-center.absolute{position:absolute}

@media(max-width: 768px){
.abs-center.absolute.m-rel{position:relative}}

@media(max-width: 768px){
.abs-center.m-no-abs-center{top:auto;transform:none}

[dir="ltr"] .abs-center.m-no-abs-center{left:auto}

[dir="rtl"] .abs-center.m-no-abs-center{right:auto}}

.ib-v-middle{display:inline-block !important;vertical-align:middle !important}

.d-inherit{display:inherit}

.v-middle-children *{vertical-align:middle}

.v-top{vertical-align:top !important}

.v-bottom{vertical-align:bottom !important}

.v-baseline{vertical-align:baseline !important}

.v-super{vertical-align:super !important}

.center-wrap{margin:0 auto;display:block !important}

.center-item,.grid.center-item{margin:0 auto}

.rel{position:relative}

.pos-init{position:initial !important}

.nudge-right{position:relative}

[dir="ltr"] .nudge-right{left:.1rem}

[dir="rtl"] .nudge-right{right:.1rem}

.height-auto{height:auto !important}

.stretch,.overlay{position:absolute;top:0;bottom:0;left:0;right:0}

.overlay{zoom:1}

.overlay.behind-modal{z-index:1049}

.overlay.disabled{background:#fff;background:rgba(255,255,255,.8);z-index:3}

.bg-cover{background-size:cover;width:100%}

.cover{background-size:cover;background-position:center center}

.cover-left{background-size:cover}

[dir="ltr"] .cover-left{background-position:left center}

[dir="rtl"] .cover-left{background-position:right center}

.bg-pattern{background-position:center center;background-repeat:repeat}

.m-h-neg1{margin-left:-1rem !important;margin-right:-1rem !important}

.img-responsive{display:block;max-width:100%;height:auto}

@media(max-width: 768px){
[dir="ltr"] .m-right-text{text-align:right !important}

[dir="rtl"] .m-right-text{text-align:left !important}

.m-center-text{text-align:center !important}

[dir="ltr"] .m-left-text{text-align:left !important}

[dir="rtl"] .m-left-text{text-align:right !important}

.m-full-width{width:100% !important}

.m-absolute{position:absolute !important}

.m-block{display:block !important}

.m-lh-1{line-height:1rem}

.m-lh-1-half{line-height:1.5rem}

.m-lh-2{line-height:2rem}}

@media(min-width: 769px){
.d-full-width{width:100% !important}

[dir="ltr"] .d-right-text{text-align:right}

[dir="rtl"] .d-right-text{text-align:left}

.d-center-text{text-align:center}

[dir="ltr"] .d-border-right{border-right:1px solid rgba(15,31,44,.18)}

[dir="rtl"] .d-border-right{border-left:1px solid rgba(15,31,44,.18)}

[dir="ltr"] .d-border-left{border-left:1px solid rgba(15,31,44,.18)}

[dir="rtl"] .d-border-left{border-right:1px solid rgba(15,31,44,.18)}

[dir="ltr"] .d-right-pad{padding-right:2rem}

[dir="rtl"] .d-right-pad{padding-left:2rem}

[dir="ltr"] .d-left-pad{padding-left:2rem}

[dir="rtl"] .d-left-pad{padding-right:2rem}

[dir="ltr"] .d-sm-left-pad{padding-left:1rem !important}

[dir="rtl"] .d-sm-left-pad{padding-right:1rem !important}}

.scrollable{height:100%;overflow-y:auto;overflow-x:hidden}

.mdzr-no-hiddenscroll .hide-scrollbars{overflow-y:scroll}

[dir="ltr"] .mdzr-no-hiddenscroll .hide-scrollbars{margin-right:-17px}

[dir="rtl"] .mdzr-no-hiddenscroll .hide-scrollbars{margin-left:-17px}

.scroll-effect{-moz-transition:all 1s ease-out;-webkit-transition:all 1s ease-out;transition:all 1s ease-out}

.mac-webkit-force-scrollbar,.scrollbar{scrollbar-width:thin;scrollbar-color:rgba(0,0,0,.3) #fff}

.mac-webkit-force-scrollbar::-webkit-scrollbar,.scrollbar::-webkit-scrollbar{-webkit-appearance:none}

.mac-webkit-force-scrollbar::-webkit-scrollbar:vertical,.scrollbar::-webkit-scrollbar:vertical{width:11px}

.mac-webkit-force-scrollbar::-webkit-scrollbar:horizontal,.scrollbar::-webkit-scrollbar:horizontal{height:11px}

.mac-webkit-force-scrollbar::-webkit-scrollbar-thumb,.scrollbar::-webkit-scrollbar-thumb{border-radius:8px;border:2px solid #fff;background-color:rgba(0,0,0,.3)}

.mac-webkit-force-scrollbar::-webkit-scrollbar-track,.scrollbar::-webkit-scrollbar-track{background-color:#fff;border-radius:8px}

.cap-me{text-transform:capitalize !important}

.up-me{text-transform:uppercase !important}

.lower-me{text-transform:lowercase !important}

.no-transform{text-transform:none !important}

.underline{text-decoration:underline}

[dir="ltr"] .right-text{text-align:right !important}

[dir="rtl"] .right-text{text-align:left !important}

.center-text{text-align:center !important}

.justify-text{text-align:justify}

.center-last{text-align-last:center}

[dir="ltr"] .left-text{text-align:left !important}

[dir="rtl"] .left-text{text-align:right !important}

.ls{letter-spacing:normal}

.ws{word-spacing:normal}

.strikeout{font-size:4em;line-height:1em;position:relative}

.strikeout:after{border-bottom:.04em solid;content:"";left:0;margin-top:-.0416666667em;position:absolute;right:0;top:50%}

.collapse-white-space{font-size:0}

.due-date{color:#0f1f2c;font-weight:500}

.cursor-default{cursor:default}

.cursor-pointer{cursor:pointer !important}

.break,.hyphenate{word-wrap:break-word;overflow-wrap:break-word;word-break:break-word}

.no-wrap{white-space:nowrap}

.no-content:before,.no-content:after{display:none !important}

.no-select{user-select:none}

.ellipsis{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}

.clamp{position:relative;overflow:hidden;text-overflow:ellipsis;display:-webkit-box !important;-webkit-line-clamp:2;-webkit-box-orient:vertical}

.clamp p{padding-bottom:0;margin-bottom:1rem}

.clamp p:only-of-type,.clamp p:last-child{margin-bottom:0}

.no-opacity{opacity:0}

.half-opacity{opacity:.5}

.prewrap{white-space:pre-wrap}

.white-space{white-space:normal !important}

.box-shadow{box-shadow:0px 1px 5px 0px rgba(15,31,44,.25)}

.no-shadow{box-shadow:none !important}

.ms-hide-x::-ms-clear{display:none}

.numbered{list-style-type:decimal}

[dir="ltr"] .numbered{margin-left:2rem;padding-left:0}

[dir="rtl"] .numbered{margin-right:2rem;padding-right:0}

.numbered>li{padding-bottom:.5rem}

ul.inline-list li{display:inline-block}

.z-index--below-base{z-index:-1}

.thumb-large{height:7rem;width:7rem;font-size:7rem;line-height:7rem}

.thumb-smaller{height:3rem;width:3rem;font-size:3rem;line-height:3rem}

.thumb-mini{height:2rem;width:2rem;font-size:2rem;line-height:2rem}

.vertical-divider{border-radius:1px;background-color:currentColor;display:inline-block;height:2rem;width:2px}

*{margin:0;padding:0;background-repeat:no-repeat;-webkit-text-size-adjust:none}

*,*:before,*:after{-moz-box-sizing:border-box;-webkit-box-sizing:border-box;box-sizing:border-box}

ul{list-style:none}

ol{list-style-position:inside}

ul.bullets{list-style:disc}

[dir="ltr"] ul.bullets{padding:0 0 0 40px}

[dir="rtl"] ul.bullets{padding:0 40px 0 0}

a{cursor:pointer;text-decoration:none;color:inherit}

a img{border:none}

button{font-size:inherit}

button,input[type=submit],input[type=button],input[type=reset]{background:rgba(0,0,0,0);border:none;-webkit-appearance:none;cursor:pointer;border-radius:0}

button[disabled],input[type=submit][disabled],input[type=button][disabled],input[type=reset][disabled]{cursor:default}

button i,input[type=submit] i,input[type=button] i,input[type=reset] i{vertical-align:middle}

i{font-style:normal;display:inline-block}

hr{display:block;height:1px;border:0;border-top:1px solid rgba(15,31,44,.18);margin:1em 0;padding:0}

textarea{resize:vertical}

iframe{max-width:100%}

body{line-height:1.3}

p{line-height:1.5em;padding:0 0 1em}

p:only-of-type,p:last-child{padding:0}

p:only-of-type+ul.bullets,p:last-child+ul.bullets{padding-top:1em}

fieldset{border:none;padding:0;margin:0}

legend{display:table;margin:0;padding:0;width:100%}

[dir="ltr"] legend{float:left}

[dir="rtl"] legend{float:right}

legend+*{clear:both}

blockquote{margin:1rem 4rem}

[dir="ltr"] a:before{padding-right:.5rem}

[dir="rtl"] a:before{padding-left:.5rem}

[dir="ltr"] a.icon-only:before{padding-right:0}

[dir="rtl"] a.icon-only:before{padding-left:0}

address{font-style:normal}

cite{font-style:normal}

article,aside,dialog,figcaption,figure,footer,header,hgroup,main,nav,section{display:block}

img{-ms-interpolation-mode:bicubic}

body{font-size:1.1666666667rem;font-weight:400}

h1,h2,h3,h4,h5,h6{font-weight:400}

.h1,.h2,.h3,.h4{color:#0f1f2c}

.h1{font-size:2.1666666667rem;line-height:2.5rem;font-weight:700}

.h2,input[type=text].h2{font-size:1.8333333333rem;line-height:2.5rem;font-weight:600}

.h2 strong,input[type=text].h2 strong{font-weight:700 !important}

input[type=text].h2{padding-top:0;padding-bottom:0;height:4.5rem}

.h3,input[type=text].h3{font-size:1.3333333333rem;line-height:2rem;font-weight:600}

.h3 strong,input[type=text].h3 strong{font-weight:700 !important}

input[type=text].h2::placeholder,input[type=text].h3::placeholder{font-weight:600}

input[type=text].h2:-ms-input-placeholder,input[type=text].h3:-ms-input-placeholder{font-weight:600}

.h4{font-size:1.1666666667rem;font-weight:600;line-height:1.5rem}

.h4 strong{font-weight:700 !important}

.par{font-size:1.3333333333rem;line-height:2rem;font-weight:400;color:#0f1f2c}

.par--light{color:#6d767e}

.par--light_a{color:rgba(15,31,44,.61)}

.par--small{font-size:1rem;line-height:1.5rem}

.par--xsmall{font-size:.9rem;line-height:1.4rem}

@media(max-width: 768px){
.m-par--small{font-size:1rem;line-height:1.5rem}}

.icon-only{font-size:0}

.icon-only:before{font-size:14px}

.icon-only.giga{font-size:0}

.icon-only.giga:before{font-size:55px}

.icon-only.hyper{font-size:0}

.icon-only.hyper:before{font-size:42px}

.icon-only.mega{font-size:0}

.icon-only.mega:before{font-size:32px}

.icon-only.alpha{font-size:0}

.icon-only.alpha:before{font-size:24px}

.icon-only.beta{font-size:0}

.icon-only.beta:before{font-size:20px}

.icon-only.gamma{font-size:0}

.icon-only.gamma:before{font-size:18px}

.icon-only.delta{font-size:0}

.icon-only.delta:before{font-size:16px}

.icon-only.epsilon{font-size:0}

.icon-only.epsilon:before{font-size:14px}

.icon-only.zeta{font-size:0}

.icon-only.zeta:before{font-size:12px}

.icon-only.micro{font-size:0}

.icon-only.micro:before{font-size:11px}

@font-face{font-family:"degreedicons";src:url("https://d1azc1qln24ryf.cloudfront.net/39411/DegreedIcons/degreedicons.woff2?2zwxej") format("woff2"),url("https://d1azc1qln24ryf.cloudfront.net/39411/DegreedIcons/degreedicons.ttf?2zwxej") format("truetype"),url("https://d1azc1qln24ryf.cloudfront.net/39411/DegreedIcons/degreedicons.woff?2zwxej") format("woff"),url("https://d1azc1qln24ryf.cloudfront.net/39411/DegreedIcons/degreedicons.svg?2zwxej#degreedicons") format("svg");font-weight:normal;font-style:normal;font-display:swap}

.sans-serif{font-family:"Inter","Helvetica Neue",Helvetica,Arial,sans-serif}

.monospace{font-family:monospace}

.italic{font-style:oblique}

.font-light{font-weight:300 !important}

.font-regular{font-weight:400 !important}

.font-medium{font-weight:500 !important}

.font-semibold,b,strong{font-weight:600 !important}

.font-bold{font-weight:700 !important}

.inter-bold{font-family:"Inter-Bold";font-style:normal;font-weight:700}

.inter-medium{font-family:"Inter-Medium";font-style:normal;font-weight:500}

.inter-regular{font-family:"Inter-Regular";font-style:normal;font-weight:400}

.giga{font-size:4.5833333333rem}

.giga.button{font-size:4.5833333333rem}

.hyper{font-size:3.5rem}

.hyper.button{font-size:3.5rem}

.mega{font-size:2.6666666667rem}

.mega.button{font-size:2.6666666667rem}

.alpha{font-size:2rem}

.alpha.button{font-size:2rem}

.beta{font-size:1.6666666667rem}

.beta.button{font-size:1.6666666667rem}

.gamma{font-size:1.5rem}

.gamma.button{font-size:1.5rem}

.delta{font-size:1.3333333333rem}

.delta.button{font-size:1.3333333333rem}

.epsilon{font-size:1.1666666667rem}

.epsilon.button{font-size:1.1666666667rem}

.zeta{font-size:1rem}

.zeta.button{font-size:1rem}

.micro{font-size:.9166666667rem}

.micro.button{font-size:.9166666667rem}

@media(max-width: 768px){
.m-giga{font-size:4.5833333333rem}

.m-hyper{font-size:3.5rem}

.m-mega{font-size:2.6666666667rem}

.m-alpha{font-size:2rem}

.m-beta{font-size:1.6666666667rem}

.m-gamma{font-size:1.5rem}

.m-delta{font-size:1.3333333333rem}

.m-epsilon{font-size:1.1666666667rem}

.m-zeta{font-size:1rem}

.m-micro{font-size:.9166666667rem}}

.a11y-hide{position:absolute;top:auto;width:1px;height:1px;overflow:hidden}

[dir="ltr"] .a11y-hide{left:-10000px}

[dir="rtl"] .a11y-hide{right:-10000px}

.keyboard-focus .a11y-hide:not(.hide-focus):focus{width:auto;height:auto}

[dir="ltr"] .keyboard-focus .a11y-hide:not(.hide-focus):focus{left:auto}

[dir="rtl"] .keyboard-focus .a11y-hide:not(.hide-focus):focus{right:auto}

@media(max-width: 768px){
.a11y-hide.mobile-only{position:inherit;width:auto;height:auto;overflow:inherit}

[dir="ltr"] .a11y-hide.mobile-only{left:auto}

[dir="rtl"] .a11y-hide.mobile-only{right:auto}}

.a11y-hide-text{color:rgba(0,0,0,0);font-size:0;text-shadow:none;overflow:hidden;line-height:0}

.mouse-focus a:focus,.mouse-focus input:focus,.mouse-focus textarea:focus,.mouse-focus button:focus,.mouse-focus select:focus,.mouse-focus [tabindex]:focus,.mouse-focus [contenteditable=true]:focus{outline:0}

.click-focus:focus,a:focus click-focus,button:focus click-focus{color:#0062e3}

.a11y-main-live-region{height:0}

.guts-p-t-auto{padding-top:auto !important}

.guts-p-t-quart{padding-top:0.25rem !important}

.guts-p-t-half{padding-top:0.5rem !important}

.guts-p-t-3-quart{padding-top:0.75rem !important}

.guts-p-t-1-half{padding-top:1.5rem !important}

.guts-p-t-1-quart{padding-top:1.25rem !important}

.guts-p-t-2-half{padding-top:2.5rem !important}

.guts-p-t-3-half{padding-top:3.5rem !important}

.guts-p-t-0{padding-top:0rem !important}

.guts-p-t-1{padding-top:1rem !important}

.guts-p-t-2{padding-top:2rem !important}

.guts-p-t-3{padding-top:3rem !important}

.guts-p-t-4{padding-top:4rem !important}

.guts-p-t-5{padding-top:5rem !important}

.guts-p-t-6{padding-top:6rem !important}

.guts-p-t-7{padding-top:7rem !important}

.guts-p-t-8{padding-top:8rem !important}

.guts-p-t-9{padding-top:9rem !important}

.guts-p-t-10{padding-top:10rem !important}

.guts-p-t-11{padding-top:11rem !important}

.guts-p-t-12{padding-top:12rem !important}

.guts-p-t-13{padding-top:13rem !important}

.guts-p-t-14{padding-top:14rem !important}

.guts-p-t-15{padding-top:15rem !important}

.guts-p-t-0--negative{padding-top:0rem !important}

.guts-p-t-1--negative{padding-top:-1rem !important}

.guts-p-t-2--negative{padding-top:-2rem !important}

.guts-p-t-3--negative{padding-top:-3rem !important}

.guts-p-t-4--negative{padding-top:-4rem !important}

.guts-p-t-5--negative{padding-top:-5rem !important}

.guts-p-t-6--negative{padding-top:-6rem !important}

.guts-p-t-7--negative{padding-top:-7rem !important}

.guts-p-t-8--negative{padding-top:-8rem !important}

.guts-p-t-9--negative{padding-top:-9rem !important}

.guts-p-t-10--negative{padding-top:-10rem !important}

.guts-p-t-11--negative{padding-top:-11rem !important}

.guts-p-t-12--negative{padding-top:-12rem !important}

.guts-p-t-13--negative{padding-top:-13rem !important}

.guts-p-t-14--negative{padding-top:-14rem !important}

.guts-p-t-15--negative{padding-top:-15rem !important}

@media(max-width: 768px){
.m-guts-p-t-auto{padding-top:auto !important}

.m-guts-p-t-quart{padding-top:0.25rem !important}

.m-guts-p-t-half{padding-top:0.5rem !important}

.m-guts-p-t-half--negative{padding-top:-0.5rem !important}

.m-guts-p-t-3-quart{padding-top:0.75rem !important}

.m-guts-p-t-1-half{padding-top:1.5rem !important}

.m-guts-p-t-2-half{padding-top:2.5rem !important}

.m-guts-p-t-3-half{padding-top:3.5rem !important}

.m-guts-p-t-0{padding-top:0rem !important}

.m-guts-p-t-1{padding-top:1rem !important}

.m-guts-p-t-2{padding-top:2rem !important}

.m-guts-p-t-3{padding-top:3rem !important}

.m-guts-p-t-4{padding-top:4rem !important}

.m-guts-p-t-5{padding-top:5rem !important}

.m-guts-p-t-6{padding-top:6rem !important}

.m-guts-p-t-7{padding-top:7rem !important}

.m-guts-p-t-8{padding-top:8rem !important}

.m-guts-p-t-9{padding-top:9rem !important}

.m-guts-p-t-10{padding-top:10rem !important}

.m-guts-p-t-11{padding-top:11rem !important}

.m-guts-p-t-12{padding-top:12rem !important}

.m-guts-p-t-13{padding-top:13rem !important}

.m-guts-p-t-14{padding-top:14rem !important}

.m-guts-p-t-15{padding-top:15rem !important}

.m-guts-p-t-0--negative{padding-top:0rem !important}

.m-guts-p-t-1--negative{padding-top:-1rem !important}

.m-guts-p-t-2--negative{padding-top:-2rem !important}

.m-guts-p-t-3--negative{padding-top:-3rem !important}

.m-guts-p-t-4--negative{padding-top:-4rem !important}

.m-guts-p-t-5--negative{padding-top:-5rem !important}

.m-guts-p-t-6--negative{padding-top:-6rem !important}

.m-guts-p-t-7--negative{padding-top:-7rem !important}

.m-guts-p-t-8--negative{padding-top:-8rem !important}

.m-guts-p-t-9--negative{padding-top:-9rem !important}

.m-guts-p-t-10--negative{padding-top:-10rem !important}

.m-guts-p-t-11--negative{padding-top:-11rem !important}

.m-guts-p-t-12--negative{padding-top:-12rem !important}

.m-guts-p-t-13--negative{padding-top:-13rem !important}

.m-guts-p-t-14--negative{padding-top:-14rem !important}

.m-guts-p-t-15--negative{padding-top:-15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.guts-p-b-auto{padding-bottom:auto !important}

.guts-p-b-quart{padding-bottom:0.25rem !important}

.guts-p-b-half{padding-bottom:0.5rem !important}

.guts-p-b-3-quart{padding-bottom:0.75rem !important}

.guts-p-b-1-half{padding-bottom:1.5rem !important}

.guts-p-b-1-quart{padding-bottom:1.25rem !important}

.guts-p-b-2-half{padding-bottom:2.5rem !important}

.guts-p-b-3-half{padding-bottom:3.5rem !important}

.guts-p-b-0{padding-bottom:0rem !important}

.guts-p-b-1{padding-bottom:1rem !important}

.guts-p-b-2{padding-bottom:2rem !important}

.guts-p-b-3{padding-bottom:3rem !important}

.guts-p-b-4{padding-bottom:4rem !important}

.guts-p-b-5{padding-bottom:5rem !important}

.guts-p-b-6{padding-bottom:6rem !important}

.guts-p-b-7{padding-bottom:7rem !important}

.guts-p-b-8{padding-bottom:8rem !important}

.guts-p-b-9{padding-bottom:9rem !important}

.guts-p-b-10{padding-bottom:10rem !important}

.guts-p-b-11{padding-bottom:11rem !important}

.guts-p-b-12{padding-bottom:12rem !important}

.guts-p-b-13{padding-bottom:13rem !important}

.guts-p-b-14{padding-bottom:14rem !important}

.guts-p-b-15{padding-bottom:15rem !important}

.guts-p-b-0--negative{padding-bottom:0rem !important}

.guts-p-b-1--negative{padding-bottom:-1rem !important}

.guts-p-b-2--negative{padding-bottom:-2rem !important}

.guts-p-b-3--negative{padding-bottom:-3rem !important}

.guts-p-b-4--negative{padding-bottom:-4rem !important}

.guts-p-b-5--negative{padding-bottom:-5rem !important}

.guts-p-b-6--negative{padding-bottom:-6rem !important}

.guts-p-b-7--negative{padding-bottom:-7rem !important}

.guts-p-b-8--negative{padding-bottom:-8rem !important}

.guts-p-b-9--negative{padding-bottom:-9rem !important}

.guts-p-b-10--negative{padding-bottom:-10rem !important}

.guts-p-b-11--negative{padding-bottom:-11rem !important}

.guts-p-b-12--negative{padding-bottom:-12rem !important}

.guts-p-b-13--negative{padding-bottom:-13rem !important}

.guts-p-b-14--negative{padding-bottom:-14rem !important}

.guts-p-b-15--negative{padding-bottom:-15rem !important}

@media(max-width: 768px){
.m-guts-p-b-auto{padding-bottom:auto !important}

.m-guts-p-b-quart{padding-bottom:0.25rem !important}

.m-guts-p-b-half{padding-bottom:0.5rem !important}

.m-guts-p-b-half--negative{padding-bottom:-0.5rem !important}

.m-guts-p-b-3-quart{padding-bottom:0.75rem !important}

.m-guts-p-b-1-half{padding-bottom:1.5rem !important}

.m-guts-p-b-2-half{padding-bottom:2.5rem !important}

.m-guts-p-b-3-half{padding-bottom:3.5rem !important}

.m-guts-p-b-0{padding-bottom:0rem !important}

.m-guts-p-b-1{padding-bottom:1rem !important}

.m-guts-p-b-2{padding-bottom:2rem !important}

.m-guts-p-b-3{padding-bottom:3rem !important}

.m-guts-p-b-4{padding-bottom:4rem !important}

.m-guts-p-b-5{padding-bottom:5rem !important}

.m-guts-p-b-6{padding-bottom:6rem !important}

.m-guts-p-b-7{padding-bottom:7rem !important}

.m-guts-p-b-8{padding-bottom:8rem !important}

.m-guts-p-b-9{padding-bottom:9rem !important}

.m-guts-p-b-10{padding-bottom:10rem !important}

.m-guts-p-b-11{padding-bottom:11rem !important}

.m-guts-p-b-12{padding-bottom:12rem !important}

.m-guts-p-b-13{padding-bottom:13rem !important}

.m-guts-p-b-14{padding-bottom:14rem !important}

.m-guts-p-b-15{padding-bottom:15rem !important}

.m-guts-p-b-0--negative{padding-bottom:0rem !important}

.m-guts-p-b-1--negative{padding-bottom:-1rem !important}

.m-guts-p-b-2--negative{padding-bottom:-2rem !important}

.m-guts-p-b-3--negative{padding-bottom:-3rem !important}

.m-guts-p-b-4--negative{padding-bottom:-4rem !important}

.m-guts-p-b-5--negative{padding-bottom:-5rem !important}

.m-guts-p-b-6--negative{padding-bottom:-6rem !important}

.m-guts-p-b-7--negative{padding-bottom:-7rem !important}

.m-guts-p-b-8--negative{padding-bottom:-8rem !important}

.m-guts-p-b-9--negative{padding-bottom:-9rem !important}

.m-guts-p-b-10--negative{padding-bottom:-10rem !important}

.m-guts-p-b-11--negative{padding-bottom:-11rem !important}

.m-guts-p-b-12--negative{padding-bottom:-12rem !important}

.m-guts-p-b-13--negative{padding-bottom:-13rem !important}

.m-guts-p-b-14--negative{padding-bottom:-14rem !important}

.m-guts-p-b-15--negative{padding-bottom:-15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

[dir="ltr"] .guts-p-r-auto{padding-right:auto !important}

[dir="rtl"] .guts-p-r-auto{padding-left:auto !important}

[dir="ltr"] .guts-p-r-quart{padding-right:0.25rem !important}

[dir="rtl"] .guts-p-r-quart{padding-left:0.25rem !important}

[dir="ltr"] .guts-p-r-half{padding-right:0.5rem !important}

[dir="rtl"] .guts-p-r-half{padding-left:0.5rem !important}

[dir="ltr"] .guts-p-r-3-quart{padding-right:0.75rem !important}

[dir="rtl"] .guts-p-r-3-quart{padding-left:0.75rem !important}

[dir="ltr"] .guts-p-r-1-half{padding-right:1.5rem !important}

[dir="rtl"] .guts-p-r-1-half{padding-left:1.5rem !important}

[dir="ltr"] .guts-p-r-1-quart{padding-right:1.25rem !important}

[dir="rtl"] .guts-p-r-1-quart{padding-left:1.25rem !important}

[dir="ltr"] .guts-p-r-2-half{padding-right:2.5rem !important}

[dir="rtl"] .guts-p-r-2-half{padding-left:2.5rem !important}

[dir="ltr"] .guts-p-r-3-half{padding-right:3.5rem !important}

[dir="rtl"] .guts-p-r-3-half{padding-left:3.5rem !important}

[dir="ltr"] .guts-p-r-0{padding-right:0rem !important}

[dir="rtl"] .guts-p-r-0{padding-left:0rem !important}

[dir="ltr"] .guts-p-r-1{padding-right:1rem !important}

[dir="rtl"] .guts-p-r-1{padding-left:1rem !important}

[dir="ltr"] .guts-p-r-2{padding-right:2rem !important}

[dir="rtl"] .guts-p-r-2{padding-left:2rem !important}

[dir="ltr"] .guts-p-r-3{padding-right:3rem !important}

[dir="rtl"] .guts-p-r-3{padding-left:3rem !important}

[dir="ltr"] .guts-p-r-4{padding-right:4rem !important}

[dir="rtl"] .guts-p-r-4{padding-left:4rem !important}

[dir="ltr"] .guts-p-r-5{padding-right:5rem !important}

[dir="rtl"] .guts-p-r-5{padding-left:5rem !important}

[dir="ltr"] .guts-p-r-6{padding-right:6rem !important}

[dir="rtl"] .guts-p-r-6{padding-left:6rem !important}

[dir="ltr"] .guts-p-r-7{padding-right:7rem !important}

[dir="rtl"] .guts-p-r-7{padding-left:7rem !important}

[dir="ltr"] .guts-p-r-8{padding-right:8rem !important}

[dir="rtl"] .guts-p-r-8{padding-left:8rem !important}

[dir="ltr"] .guts-p-r-9{padding-right:9rem !important}

[dir="rtl"] .guts-p-r-9{padding-left:9rem !important}

[dir="ltr"] .guts-p-r-10{padding-right:10rem !important}

[dir="rtl"] .guts-p-r-10{padding-left:10rem !important}

[dir="ltr"] .guts-p-r-11{padding-right:11rem !important}

[dir="rtl"] .guts-p-r-11{padding-left:11rem !important}

[dir="ltr"] .guts-p-r-12{padding-right:12rem !important}

[dir="rtl"] .guts-p-r-12{padding-left:12rem !important}

[dir="ltr"] .guts-p-r-13{padding-right:13rem !important}

[dir="rtl"] .guts-p-r-13{padding-left:13rem !important}

[dir="ltr"] .guts-p-r-14{padding-right:14rem !important}

[dir="rtl"] .guts-p-r-14{padding-left:14rem !important}

[dir="ltr"] .guts-p-r-15{padding-right:15rem !important}

[dir="rtl"] .guts-p-r-15{padding-left:15rem !important}

[dir="ltr"] .guts-p-r-0--negative{padding-right:0rem !important}

[dir="rtl"] .guts-p-r-0--negative{padding-left:0rem !important}

[dir="ltr"] .guts-p-r-1--negative{padding-right:-1rem !important}

[dir="rtl"] .guts-p-r-1--negative{padding-left:-1rem !important}

[dir="ltr"] .guts-p-r-2--negative{padding-right:-2rem !important}

[dir="rtl"] .guts-p-r-2--negative{padding-left:-2rem !important}

[dir="ltr"] .guts-p-r-3--negative{padding-right:-3rem !important}

[dir="rtl"] .guts-p-r-3--negative{padding-left:-3rem !important}

[dir="ltr"] .guts-p-r-4--negative{padding-right:-4rem !important}

[dir="rtl"] .guts-p-r-4--negative{padding-left:-4rem !important}

[dir="ltr"] .guts-p-r-5--negative{padding-right:-5rem !important}

[dir="rtl"] .guts-p-r-5--negative{padding-left:-5rem !important}

[dir="ltr"] .guts-p-r-6--negative{padding-right:-6rem !important}

[dir="rtl"] .guts-p-r-6--negative{padding-left:-6rem !important}

[dir="ltr"] .guts-p-r-7--negative{padding-right:-7rem !important}

[dir="rtl"] .guts-p-r-7--negative{padding-left:-7rem !important}

[dir="ltr"] .guts-p-r-8--negative{padding-right:-8rem !important}

[dir="rtl"] .guts-p-r-8--negative{padding-left:-8rem !important}

[dir="ltr"] .guts-p-r-9--negative{padding-right:-9rem !important}

[dir="rtl"] .guts-p-r-9--negative{padding-left:-9rem !important}

[dir="ltr"] .guts-p-r-10--negative{padding-right:-10rem !important}

[dir="rtl"] .guts-p-r-10--negative{padding-left:-10rem !important}

[dir="ltr"] .guts-p-r-11--negative{padding-right:-11rem !important}

[dir="rtl"] .guts-p-r-11--negative{padding-left:-11rem !important}

[dir="ltr"] .guts-p-r-12--negative{padding-right:-12rem !important}

[dir="rtl"] .guts-p-r-12--negative{padding-left:-12rem !important}

[dir="ltr"] .guts-p-r-13--negative{padding-right:-13rem !important}

[dir="rtl"] .guts-p-r-13--negative{padding-left:-13rem !important}

[dir="ltr"] .guts-p-r-14--negative{padding-right:-14rem !important}

[dir="rtl"] .guts-p-r-14--negative{padding-left:-14rem !important}

[dir="ltr"] .guts-p-r-15--negative{padding-right:-15rem !important}

[dir="rtl"] .guts-p-r-15--negative{padding-left:-15rem !important}

@media(max-width: 768px){
[dir="ltr"] .m-guts-p-r-auto{padding-right:auto !important}

[dir="rtl"] .m-guts-p-r-auto{padding-left:auto !important}

[dir="ltr"] .m-guts-p-r-quart{padding-right:0.25rem !important}

[dir="rtl"] .m-guts-p-r-quart{padding-left:0.25rem !important}

[dir="ltr"] .m-guts-p-r-half{padding-right:0.5rem !important}

[dir="rtl"] .m-guts-p-r-half{padding-left:0.5rem !important}

[dir="ltr"] .m-guts-p-r-half--negative{padding-right:-0.5rem !important}

[dir="rtl"] .m-guts-p-r-half--negative{padding-left:-0.5rem !important}

[dir="ltr"] .m-guts-p-r-3-quart{padding-right:0.75rem !important}

[dir="rtl"] .m-guts-p-r-3-quart{padding-left:0.75rem !important}

[dir="ltr"] .m-guts-p-r-1-half{padding-right:1.5rem !important}

[dir="rtl"] .m-guts-p-r-1-half{padding-left:1.5rem !important}

[dir="ltr"] .m-guts-p-r-2-half{padding-right:2.5rem !important}

[dir="rtl"] .m-guts-p-r-2-half{padding-left:2.5rem !important}

[dir="ltr"] .m-guts-p-r-3-half{padding-right:3.5rem !important}

[dir="rtl"] .m-guts-p-r-3-half{padding-left:3.5rem !important}

[dir="ltr"] .m-guts-p-r-0{padding-right:0rem !important}

[dir="rtl"] .m-guts-p-r-0{padding-left:0rem !important}

[dir="ltr"] .m-guts-p-r-1{padding-right:1rem !important}

[dir="rtl"] .m-guts-p-r-1{padding-left:1rem !important}

[dir="ltr"] .m-guts-p-r-2{padding-right:2rem !important}

[dir="rtl"] .m-guts-p-r-2{padding-left:2rem !important}

[dir="ltr"] .m-guts-p-r-3{padding-right:3rem !important}

[dir="rtl"] .m-guts-p-r-3{padding-left:3rem !important}

[dir="ltr"] .m-guts-p-r-4{padding-right:4rem !important}

[dir="rtl"] .m-guts-p-r-4{padding-left:4rem !important}

[dir="ltr"] .m-guts-p-r-5{padding-right:5rem !important}

[dir="rtl"] .m-guts-p-r-5{padding-left:5rem !important}

[dir="ltr"] .m-guts-p-r-6{padding-right:6rem !important}

[dir="rtl"] .m-guts-p-r-6{padding-left:6rem !important}

[dir="ltr"] .m-guts-p-r-7{padding-right:7rem !important}

[dir="rtl"] .m-guts-p-r-7{padding-left:7rem !important}

[dir="ltr"] .m-guts-p-r-8{padding-right:8rem !important}

[dir="rtl"] .m-guts-p-r-8{padding-left:8rem !important}

[dir="ltr"] .m-guts-p-r-9{padding-right:9rem !important}

[dir="rtl"] .m-guts-p-r-9{padding-left:9rem !important}

[dir="ltr"] .m-guts-p-r-10{padding-right:10rem !important}

[dir="rtl"] .m-guts-p-r-10{padding-left:10rem !important}

[dir="ltr"] .m-guts-p-r-11{padding-right:11rem !important}

[dir="rtl"] .m-guts-p-r-11{padding-left:11rem !important}

[dir="ltr"] .m-guts-p-r-12{padding-right:12rem !important}

[dir="rtl"] .m-guts-p-r-12{padding-left:12rem !important}

[dir="ltr"] .m-guts-p-r-13{padding-right:13rem !important}

[dir="rtl"] .m-guts-p-r-13{padding-left:13rem !important}

[dir="ltr"] .m-guts-p-r-14{padding-right:14rem !important}

[dir="rtl"] .m-guts-p-r-14{padding-left:14rem !important}

[dir="ltr"] .m-guts-p-r-15{padding-right:15rem !important}

[dir="rtl"] .m-guts-p-r-15{padding-left:15rem !important}

[dir="ltr"] .m-guts-p-r-0--negative{padding-right:0rem !important}

[dir="rtl"] .m-guts-p-r-0--negative{padding-left:0rem !important}

[dir="ltr"] .m-guts-p-r-1--negative{padding-right:-1rem !important}

[dir="rtl"] .m-guts-p-r-1--negative{padding-left:-1rem !important}

[dir="ltr"] .m-guts-p-r-2--negative{padding-right:-2rem !important}

[dir="rtl"] .m-guts-p-r-2--negative{padding-left:-2rem !important}

[dir="ltr"] .m-guts-p-r-3--negative{padding-right:-3rem !important}

[dir="rtl"] .m-guts-p-r-3--negative{padding-left:-3rem !important}

[dir="ltr"] .m-guts-p-r-4--negative{padding-right:-4rem !important}

[dir="rtl"] .m-guts-p-r-4--negative{padding-left:-4rem !important}

[dir="ltr"] .m-guts-p-r-5--negative{padding-right:-5rem !important}

[dir="rtl"] .m-guts-p-r-5--negative{padding-left:-5rem !important}

[dir="ltr"] .m-guts-p-r-6--negative{padding-right:-6rem !important}

[dir="rtl"] .m-guts-p-r-6--negative{padding-left:-6rem !important}

[dir="ltr"] .m-guts-p-r-7--negative{padding-right:-7rem !important}

[dir="rtl"] .m-guts-p-r-7--negative{padding-left:-7rem !important}

[dir="ltr"] .m-guts-p-r-8--negative{padding-right:-8rem !important}

[dir="rtl"] .m-guts-p-r-8--negative{padding-left:-8rem !important}

[dir="ltr"] .m-guts-p-r-9--negative{padding-right:-9rem !important}

[dir="rtl"] .m-guts-p-r-9--negative{padding-left:-9rem !important}

[dir="ltr"] .m-guts-p-r-10--negative{padding-right:-10rem !important}

[dir="rtl"] .m-guts-p-r-10--negative{padding-left:-10rem !important}

[dir="ltr"] .m-guts-p-r-11--negative{padding-right:-11rem !important}

[dir="rtl"] .m-guts-p-r-11--negative{padding-left:-11rem !important}

[dir="ltr"] .m-guts-p-r-12--negative{padding-right:-12rem !important}

[dir="rtl"] .m-guts-p-r-12--negative{padding-left:-12rem !important}

[dir="ltr"] .m-guts-p-r-13--negative{padding-right:-13rem !important}

[dir="rtl"] .m-guts-p-r-13--negative{padding-left:-13rem !important}

[dir="ltr"] .m-guts-p-r-14--negative{padding-right:-14rem !important}

[dir="rtl"] .m-guts-p-r-14--negative{padding-left:-14rem !important}

[dir="ltr"] .m-guts-p-r-15--negative{padding-right:-15rem !important}

[dir="rtl"] .m-guts-p-r-15--negative{padding-left:-15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

[dir="ltr"] .guts-p-l-auto{padding-left:auto !important}

[dir="rtl"] .guts-p-l-auto{padding-right:auto !important}

[dir="ltr"] .guts-p-l-quart{padding-left:0.25rem !important}

[dir="rtl"] .guts-p-l-quart{padding-right:0.25rem !important}

[dir="ltr"] .guts-p-l-half{padding-left:0.5rem !important}

[dir="rtl"] .guts-p-l-half{padding-right:0.5rem !important}

[dir="ltr"] .guts-p-l-3-quart{padding-left:0.75rem !important}

[dir="rtl"] .guts-p-l-3-quart{padding-right:0.75rem !important}

[dir="ltr"] .guts-p-l-1-half{padding-left:1.5rem !important}

[dir="rtl"] .guts-p-l-1-half{padding-right:1.5rem !important}

[dir="ltr"] .guts-p-l-1-quart{padding-left:1.25rem !important}

[dir="rtl"] .guts-p-l-1-quart{padding-right:1.25rem !important}

[dir="ltr"] .guts-p-l-2-half{padding-left:2.5rem !important}

[dir="rtl"] .guts-p-l-2-half{padding-right:2.5rem !important}

[dir="ltr"] .guts-p-l-3-half{padding-left:3.5rem !important}

[dir="rtl"] .guts-p-l-3-half{padding-right:3.5rem !important}

[dir="ltr"] .guts-p-l-0{padding-left:0rem !important}

[dir="rtl"] .guts-p-l-0{padding-right:0rem !important}

[dir="ltr"] .guts-p-l-1{padding-left:1rem !important}

[dir="rtl"] .guts-p-l-1{padding-right:1rem !important}

[dir="ltr"] .guts-p-l-2{padding-left:2rem !important}

[dir="rtl"] .guts-p-l-2{padding-right:2rem !important}

[dir="ltr"] .guts-p-l-3{padding-left:3rem !important}

[dir="rtl"] .guts-p-l-3{padding-right:3rem !important}

[dir="ltr"] .guts-p-l-4{padding-left:4rem !important}

[dir="rtl"] .guts-p-l-4{padding-right:4rem !important}

[dir="ltr"] .guts-p-l-5{padding-left:5rem !important}

[dir="rtl"] .guts-p-l-5{padding-right:5rem !important}

[dir="ltr"] .guts-p-l-6{padding-left:6rem !important}

[dir="rtl"] .guts-p-l-6{padding-right:6rem !important}

[dir="ltr"] .guts-p-l-7{padding-left:7rem !important}

[dir="rtl"] .guts-p-l-7{padding-right:7rem !important}

[dir="ltr"] .guts-p-l-8{padding-left:8rem !important}

[dir="rtl"] .guts-p-l-8{padding-right:8rem !important}

[dir="ltr"] .guts-p-l-9{padding-left:9rem !important}

[dir="rtl"] .guts-p-l-9{padding-right:9rem !important}

[dir="ltr"] .guts-p-l-10{padding-left:10rem !important}

[dir="rtl"] .guts-p-l-10{padding-right:10rem !important}

[dir="ltr"] .guts-p-l-11{padding-left:11rem !important}

[dir="rtl"] .guts-p-l-11{padding-right:11rem !important}

[dir="ltr"] .guts-p-l-12{padding-left:12rem !important}

[dir="rtl"] .guts-p-l-12{padding-right:12rem !important}

[dir="ltr"] .guts-p-l-13{padding-left:13rem !important}

[dir="rtl"] .guts-p-l-13{padding-right:13rem !important}

[dir="ltr"] .guts-p-l-14{padding-left:14rem !important}

[dir="rtl"] .guts-p-l-14{padding-right:14rem !important}

[dir="ltr"] .guts-p-l-15{padding-left:15rem !important}

[dir="rtl"] .guts-p-l-15{padding-right:15rem !important}

[dir="ltr"] .guts-p-l-0--negative{padding-left:0rem !important}

[dir="rtl"] .guts-p-l-0--negative{padding-right:0rem !important}

[dir="ltr"] .guts-p-l-1--negative{padding-left:-1rem !important}

[dir="rtl"] .guts-p-l-1--negative{padding-right:-1rem !important}

[dir="ltr"] .guts-p-l-2--negative{padding-left:-2rem !important}

[dir="rtl"] .guts-p-l-2--negative{padding-right:-2rem !important}

[dir="ltr"] .guts-p-l-3--negative{padding-left:-3rem !important}

[dir="rtl"] .guts-p-l-3--negative{padding-right:-3rem !important}

[dir="ltr"] .guts-p-l-4--negative{padding-left:-4rem !important}

[dir="rtl"] .guts-p-l-4--negative{padding-right:-4rem !important}

[dir="ltr"] .guts-p-l-5--negative{padding-left:-5rem !important}

[dir="rtl"] .guts-p-l-5--negative{padding-right:-5rem !important}

[dir="ltr"] .guts-p-l-6--negative{padding-left:-6rem !important}

[dir="rtl"] .guts-p-l-6--negative{padding-right:-6rem !important}

[dir="ltr"] .guts-p-l-7--negative{padding-left:-7rem !important}

[dir="rtl"] .guts-p-l-7--negative{padding-right:-7rem !important}

[dir="ltr"] .guts-p-l-8--negative{padding-left:-8rem !important}

[dir="rtl"] .guts-p-l-8--negative{padding-right:-8rem !important}

[dir="ltr"] .guts-p-l-9--negative{padding-left:-9rem !important}

[dir="rtl"] .guts-p-l-9--negative{padding-right:-9rem !important}

[dir="ltr"] .guts-p-l-10--negative{padding-left:-10rem !important}

[dir="rtl"] .guts-p-l-10--negative{padding-right:-10rem !important}

[dir="ltr"] .guts-p-l-11--negative{padding-left:-11rem !important}

[dir="rtl"] .guts-p-l-11--negative{padding-right:-11rem !important}

[dir="ltr"] .guts-p-l-12--negative{padding-left:-12rem !important}

[dir="rtl"] .guts-p-l-12--negative{padding-right:-12rem !important}

[dir="ltr"] .guts-p-l-13--negative{padding-left:-13rem !important}

[dir="rtl"] .guts-p-l-13--negative{padding-right:-13rem !important}

[dir="ltr"] .guts-p-l-14--negative{padding-left:-14rem !important}

[dir="rtl"] .guts-p-l-14--negative{padding-right:-14rem !important}

[dir="ltr"] .guts-p-l-15--negative{padding-left:-15rem !important}

[dir="rtl"] .guts-p-l-15--negative{padding-right:-15rem !important}

@media(max-width: 768px){
[dir="ltr"] .m-guts-p-l-auto{padding-left:auto !important}

[dir="rtl"] .m-guts-p-l-auto{padding-right:auto !important}

[dir="ltr"] .m-guts-p-l-quart{padding-left:0.25rem !important}

[dir="rtl"] .m-guts-p-l-quart{padding-right:0.25rem !important}

[dir="ltr"] .m-guts-p-l-half{padding-left:0.5rem !important}

[dir="rtl"] .m-guts-p-l-half{padding-right:0.5rem !important}

[dir="ltr"] .m-guts-p-l-half--negative{padding-left:-0.5rem !important}

[dir="rtl"] .m-guts-p-l-half--negative{padding-right:-0.5rem !important}

[dir="ltr"] .m-guts-p-l-3-quart{padding-left:0.75rem !important}

[dir="rtl"] .m-guts-p-l-3-quart{padding-right:0.75rem !important}

[dir="ltr"] .m-guts-p-l-1-half{padding-left:1.5rem !important}

[dir="rtl"] .m-guts-p-l-1-half{padding-right:1.5rem !important}

[dir="ltr"] .m-guts-p-l-2-half{padding-left:2.5rem !important}

[dir="rtl"] .m-guts-p-l-2-half{padding-right:2.5rem !important}

[dir="ltr"] .m-guts-p-l-3-half{padding-left:3.5rem !important}

[dir="rtl"] .m-guts-p-l-3-half{padding-right:3.5rem !important}

[dir="ltr"] .m-guts-p-l-0{padding-left:0rem !important}

[dir="rtl"] .m-guts-p-l-0{padding-right:0rem !important}

[dir="ltr"] .m-guts-p-l-1{padding-left:1rem !important}

[dir="rtl"] .m-guts-p-l-1{padding-right:1rem !important}

[dir="ltr"] .m-guts-p-l-2{padding-left:2rem !important}

[dir="rtl"] .m-guts-p-l-2{padding-right:2rem !important}

[dir="ltr"] .m-guts-p-l-3{padding-left:3rem !important}

[dir="rtl"] .m-guts-p-l-3{padding-right:3rem !important}

[dir="ltr"] .m-guts-p-l-4{padding-left:4rem !important}

[dir="rtl"] .m-guts-p-l-4{padding-right:4rem !important}

[dir="ltr"] .m-guts-p-l-5{padding-left:5rem !important}

[dir="rtl"] .m-guts-p-l-5{padding-right:5rem !important}

[dir="ltr"] .m-guts-p-l-6{padding-left:6rem !important}

[dir="rtl"] .m-guts-p-l-6{padding-right:6rem !important}

[dir="ltr"] .m-guts-p-l-7{padding-left:7rem !important}

[dir="rtl"] .m-guts-p-l-7{padding-right:7rem !important}

[dir="ltr"] .m-guts-p-l-8{padding-left:8rem !important}

[dir="rtl"] .m-guts-p-l-8{padding-right:8rem !important}

[dir="ltr"] .m-guts-p-l-9{padding-left:9rem !important}

[dir="rtl"] .m-guts-p-l-9{padding-right:9rem !important}

[dir="ltr"] .m-guts-p-l-10{padding-left:10rem !important}

[dir="rtl"] .m-guts-p-l-10{padding-right:10rem !important}

[dir="ltr"] .m-guts-p-l-11{padding-left:11rem !important}

[dir="rtl"] .m-guts-p-l-11{padding-right:11rem !important}

[dir="ltr"] .m-guts-p-l-12{padding-left:12rem !important}

[dir="rtl"] .m-guts-p-l-12{padding-right:12rem !important}

[dir="ltr"] .m-guts-p-l-13{padding-left:13rem !important}

[dir="rtl"] .m-guts-p-l-13{padding-right:13rem !important}

[dir="ltr"] .m-guts-p-l-14{padding-left:14rem !important}

[dir="rtl"] .m-guts-p-l-14{padding-right:14rem !important}

[dir="ltr"] .m-guts-p-l-15{padding-left:15rem !important}

[dir="rtl"] .m-guts-p-l-15{padding-right:15rem !important}

[dir="ltr"] .m-guts-p-l-0--negative{padding-left:0rem !important}

[dir="rtl"] .m-guts-p-l-0--negative{padding-right:0rem !important}

[dir="ltr"] .m-guts-p-l-1--negative{padding-left:-1rem !important}

[dir="rtl"] .m-guts-p-l-1--negative{padding-right:-1rem !important}

[dir="ltr"] .m-guts-p-l-2--negative{padding-left:-2rem !important}

[dir="rtl"] .m-guts-p-l-2--negative{padding-right:-2rem !important}

[dir="ltr"] .m-guts-p-l-3--negative{padding-left:-3rem !important}

[dir="rtl"] .m-guts-p-l-3--negative{padding-right:-3rem !important}

[dir="ltr"] .m-guts-p-l-4--negative{padding-left:-4rem !important}

[dir="rtl"] .m-guts-p-l-4--negative{padding-right:-4rem !important}

[dir="ltr"] .m-guts-p-l-5--negative{padding-left:-5rem !important}

[dir="rtl"] .m-guts-p-l-5--negative{padding-right:-5rem !important}

[dir="ltr"] .m-guts-p-l-6--negative{padding-left:-6rem !important}

[dir="rtl"] .m-guts-p-l-6--negative{padding-right:-6rem !important}

[dir="ltr"] .m-guts-p-l-7--negative{padding-left:-7rem !important}

[dir="rtl"] .m-guts-p-l-7--negative{padding-right:-7rem !important}

[dir="ltr"] .m-guts-p-l-8--negative{padding-left:-8rem !important}

[dir="rtl"] .m-guts-p-l-8--negative{padding-right:-8rem !important}

[dir="ltr"] .m-guts-p-l-9--negative{padding-left:-9rem !important}

[dir="rtl"] .m-guts-p-l-9--negative{padding-right:-9rem !important}

[dir="ltr"] .m-guts-p-l-10--negative{padding-left:-10rem !important}

[dir="rtl"] .m-guts-p-l-10--negative{padding-right:-10rem !important}

[dir="ltr"] .m-guts-p-l-11--negative{padding-left:-11rem !important}

[dir="rtl"] .m-guts-p-l-11--negative{padding-right:-11rem !important}

[dir="ltr"] .m-guts-p-l-12--negative{padding-left:-12rem !important}

[dir="rtl"] .m-guts-p-l-12--negative{padding-right:-12rem !important}

[dir="ltr"] .m-guts-p-l-13--negative{padding-left:-13rem !important}

[dir="rtl"] .m-guts-p-l-13--negative{padding-right:-13rem !important}

[dir="ltr"] .m-guts-p-l-14--negative{padding-left:-14rem !important}

[dir="rtl"] .m-guts-p-l-14--negative{padding-right:-14rem !important}

[dir="ltr"] .m-guts-p-l-15--negative{padding-left:-15rem !important}

[dir="rtl"] .m-guts-p-l-15--negative{padding-right:-15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.guts-p-v-auto{padding-top:auto !important;padding-bottom:auto !important}

.guts-p-v-quart{padding-top:0.25rem !important;padding-bottom:0.25rem !important}

.guts-p-v-half{padding-top:0.5rem !important;padding-bottom:0.5rem !important}

.guts-p-v-3-quart{padding-top:0.75rem !important;padding-bottom:0.75rem !important}

.guts-p-v-1-half{padding-top:1.5rem !important;padding-bottom:1.5rem !important}

.guts-p-v-1-quart{padding-top:1.25rem !important;padding-bottom:1.25rem !important}

.guts-p-v-2-half{padding-top:2.5rem !important;padding-bottom:2.5rem !important}

.guts-p-v-3-half{padding-top:3.5rem !important;padding-bottom:3.5rem !important}

.guts-p-v-0{padding-top:0rem !important;padding-bottom:0rem !important}

.guts-p-v-1{padding-top:1rem !important;padding-bottom:1rem !important}

.guts-p-v-2{padding-top:2rem !important;padding-bottom:2rem !important}

.guts-p-v-3{padding-top:3rem !important;padding-bottom:3rem !important}

.guts-p-v-4{padding-top:4rem !important;padding-bottom:4rem !important}

.guts-p-v-5{padding-top:5rem !important;padding-bottom:5rem !important}

.guts-p-v-6{padding-top:6rem !important;padding-bottom:6rem !important}

.guts-p-v-7{padding-top:7rem !important;padding-bottom:7rem !important}

.guts-p-v-8{padding-top:8rem !important;padding-bottom:8rem !important}

.guts-p-v-9{padding-top:9rem !important;padding-bottom:9rem !important}

.guts-p-v-10{padding-top:10rem !important;padding-bottom:10rem !important}

.guts-p-v-11{padding-top:11rem !important;padding-bottom:11rem !important}

.guts-p-v-12{padding-top:12rem !important;padding-bottom:12rem !important}

.guts-p-v-13{padding-top:13rem !important;padding-bottom:13rem !important}

.guts-p-v-14{padding-top:14rem !important;padding-bottom:14rem !important}

.guts-p-v-15{padding-top:15rem !important;padding-bottom:15rem !important}

.guts-p-v-0--negative{padding-top:0rem !important;padding-bottom:0rem !important}

.guts-p-v-1--negative{padding-top:-1rem !important;padding-bottom:-1rem !important}

.guts-p-v-2--negative{padding-top:-2rem !important;padding-bottom:-2rem !important}

.guts-p-v-3--negative{padding-top:-3rem !important;padding-bottom:-3rem !important}

.guts-p-v-4--negative{padding-top:-4rem !important;padding-bottom:-4rem !important}

.guts-p-v-5--negative{padding-top:-5rem !important;padding-bottom:-5rem !important}

.guts-p-v-6--negative{padding-top:-6rem !important;padding-bottom:-6rem !important}

.guts-p-v-7--negative{padding-top:-7rem !important;padding-bottom:-7rem !important}

.guts-p-v-8--negative{padding-top:-8rem !important;padding-bottom:-8rem !important}

.guts-p-v-9--negative{padding-top:-9rem !important;padding-bottom:-9rem !important}

.guts-p-v-10--negative{padding-top:-10rem !important;padding-bottom:-10rem !important}

.guts-p-v-11--negative{padding-top:-11rem !important;padding-bottom:-11rem !important}

.guts-p-v-12--negative{padding-top:-12rem !important;padding-bottom:-12rem !important}

.guts-p-v-13--negative{padding-top:-13rem !important;padding-bottom:-13rem !important}

.guts-p-v-14--negative{padding-top:-14rem !important;padding-bottom:-14rem !important}

.guts-p-v-15--negative{padding-top:-15rem !important;padding-bottom:-15rem !important}

@media(max-width: 768px){
.m-guts-p-v-auto{padding-top:auto !important;padding-bottom:auto !important}

.m-guts-p-v-quart{padding-top:0.25rem !important;padding-bottom:0.25rem !important}

.m-guts-p-v-half{padding-top:0.5rem !important;padding-bottom:0.5rem !important}

.m-guts-p-v-half--negative{padding-top:-0.5rem !important;padding-bottom:-0.5rem !important}

.m-guts-p-v-3-quart{padding-top:0.75rem !important;padding-bottom:0.75rem !important}

.m-guts-p-v-1-half{padding-top:1.5rem !important;padding-bottom:1.5rem !important}

.m-guts-p-v-2-half{padding-top:2.5rem !important;padding-bottom:2.5rem !important}

.m-guts-p-v-3-half{padding-top:3.5rem !important;padding-bottom:3.5rem !important}

.m-guts-p-v-0{padding-top:0rem !important;padding-bottom:0rem !important}

.m-guts-p-v-1{padding-top:1rem !important;padding-bottom:1rem !important}

.m-guts-p-v-2{padding-top:2rem !important;padding-bottom:2rem !important}

.m-guts-p-v-3{padding-top:3rem !important;padding-bottom:3rem !important}

.m-guts-p-v-4{padding-top:4rem !important;padding-bottom:4rem !important}

.m-guts-p-v-5{padding-top:5rem !important;padding-bottom:5rem !important}

.m-guts-p-v-6{padding-top:6rem !important;padding-bottom:6rem !important}

.m-guts-p-v-7{padding-top:7rem !important;padding-bottom:7rem !important}

.m-guts-p-v-8{padding-top:8rem !important;padding-bottom:8rem !important}

.m-guts-p-v-9{padding-top:9rem !important;padding-bottom:9rem !important}

.m-guts-p-v-10{padding-top:10rem !important;padding-bottom:10rem !important}

.m-guts-p-v-11{padding-top:11rem !important;padding-bottom:11rem !important}

.m-guts-p-v-12{padding-top:12rem !important;padding-bottom:12rem !important}

.m-guts-p-v-13{padding-top:13rem !important;padding-bottom:13rem !important}

.m-guts-p-v-14{padding-top:14rem !important;padding-bottom:14rem !important}

.m-guts-p-v-15{padding-top:15rem !important;padding-bottom:15rem !important}

.m-guts-p-v-0--negative{padding-top:0rem !important;padding-bottom:0rem !important}

.m-guts-p-v-1--negative{padding-top:-1rem !important;padding-bottom:-1rem !important}

.m-guts-p-v-2--negative{padding-top:-2rem !important;padding-bottom:-2rem !important}

.m-guts-p-v-3--negative{padding-top:-3rem !important;padding-bottom:-3rem !important}

.m-guts-p-v-4--negative{padding-top:-4rem !important;padding-bottom:-4rem !important}

.m-guts-p-v-5--negative{padding-top:-5rem !important;padding-bottom:-5rem !important}

.m-guts-p-v-6--negative{padding-top:-6rem !important;padding-bottom:-6rem !important}

.m-guts-p-v-7--negative{padding-top:-7rem !important;padding-bottom:-7rem !important}

.m-guts-p-v-8--negative{padding-top:-8rem !important;padding-bottom:-8rem !important}

.m-guts-p-v-9--negative{padding-top:-9rem !important;padding-bottom:-9rem !important}

.m-guts-p-v-10--negative{padding-top:-10rem !important;padding-bottom:-10rem !important}

.m-guts-p-v-11--negative{padding-top:-11rem !important;padding-bottom:-11rem !important}

.m-guts-p-v-12--negative{padding-top:-12rem !important;padding-bottom:-12rem !important}

.m-guts-p-v-13--negative{padding-top:-13rem !important;padding-bottom:-13rem !important}

.m-guts-p-v-14--negative{padding-top:-14rem !important;padding-bottom:-14rem !important}

.m-guts-p-v-15--negative{padding-top:-15rem !important;padding-bottom:-15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.guts-p-h-auto{padding-right:auto !important;padding-left:auto !important}

.guts-p-h-quart{padding-right:0.25rem !important;padding-left:0.25rem !important}

.guts-p-h-half{padding-right:0.5rem !important;padding-left:0.5rem !important}

.guts-p-h-3-quart{padding-right:0.75rem !important;padding-left:0.75rem !important}

.guts-p-h-1-half{padding-right:1.5rem !important;padding-left:1.5rem !important}

.guts-p-h-1-quart{padding-right:1.25rem !important;padding-left:1.25rem !important}

.guts-p-h-2-half{padding-right:2.5rem !important;padding-left:2.5rem !important}

.guts-p-h-3-half{padding-right:3.5rem !important;padding-left:3.5rem !important}

.guts-p-h-0{padding-right:0rem !important;padding-left:0rem !important}

.guts-p-h-1{padding-right:1rem !important;padding-left:1rem !important}

.guts-p-h-2{padding-right:2rem !important;padding-left:2rem !important}

.guts-p-h-3{padding-right:3rem !important;padding-left:3rem !important}

.guts-p-h-4{padding-right:4rem !important;padding-left:4rem !important}

.guts-p-h-5{padding-right:5rem !important;padding-left:5rem !important}

.guts-p-h-6{padding-right:6rem !important;padding-left:6rem !important}

.guts-p-h-7{padding-right:7rem !important;padding-left:7rem !important}

.guts-p-h-8{padding-right:8rem !important;padding-left:8rem !important}

.guts-p-h-9{padding-right:9rem !important;padding-left:9rem !important}

.guts-p-h-10{padding-right:10rem !important;padding-left:10rem !important}

.guts-p-h-11{padding-right:11rem !important;padding-left:11rem !important}

.guts-p-h-12{padding-right:12rem !important;padding-left:12rem !important}

.guts-p-h-13{padding-right:13rem !important;padding-left:13rem !important}

.guts-p-h-14{padding-right:14rem !important;padding-left:14rem !important}

.guts-p-h-15{padding-right:15rem !important;padding-left:15rem !important}

.guts-p-h-0--negative{padding-right:0rem !important;padding-left:0rem !important}

.guts-p-h-1--negative{padding-right:-1rem !important;padding-left:-1rem !important}

.guts-p-h-2--negative{padding-right:-2rem !important;padding-left:-2rem !important}

.guts-p-h-3--negative{padding-right:-3rem !important;padding-left:-3rem !important}

.guts-p-h-4--negative{padding-right:-4rem !important;padding-left:-4rem !important}

.guts-p-h-5--negative{padding-right:-5rem !important;padding-left:-5rem !important}

.guts-p-h-6--negative{padding-right:-6rem !important;padding-left:-6rem !important}

.guts-p-h-7--negative{padding-right:-7rem !important;padding-left:-7rem !important}

.guts-p-h-8--negative{padding-right:-8rem !important;padding-left:-8rem !important}

.guts-p-h-9--negative{padding-right:-9rem !important;padding-left:-9rem !important}

.guts-p-h-10--negative{padding-right:-10rem !important;padding-left:-10rem !important}

.guts-p-h-11--negative{padding-right:-11rem !important;padding-left:-11rem !important}

.guts-p-h-12--negative{padding-right:-12rem !important;padding-left:-12rem !important}

.guts-p-h-13--negative{padding-right:-13rem !important;padding-left:-13rem !important}

.guts-p-h-14--negative{padding-right:-14rem !important;padding-left:-14rem !important}

.guts-p-h-15--negative{padding-right:-15rem !important;padding-left:-15rem !important}

@media(max-width: 768px){
.m-guts-p-h-auto{padding-right:auto !important;padding-left:auto !important}

.m-guts-p-h-quart{padding-right:0.25rem !important;padding-left:0.25rem !important}

.m-guts-p-h-half{padding-right:0.5rem !important;padding-left:0.5rem !important}

.m-guts-p-h-half--negative{padding-right:-0.5rem !important;padding-left:-0.5rem !important}

.m-guts-p-h-3-quart{padding-right:0.75rem !important;padding-left:0.75rem !important}

.m-guts-p-h-1-half{padding-right:1.5rem !important;padding-left:1.5rem !important}

.m-guts-p-h-2-half{padding-right:2.5rem !important;padding-left:2.5rem !important}

.m-guts-p-h-3-half{padding-right:3.5rem !important;padding-left:3.5rem !important}

.m-guts-p-h-0{padding-right:0rem !important;padding-left:0rem !important}

.m-guts-p-h-1{padding-right:1rem !important;padding-left:1rem !important}

.m-guts-p-h-2{padding-right:2rem !important;padding-left:2rem !important}

.m-guts-p-h-3{padding-right:3rem !important;padding-left:3rem !important}

.m-guts-p-h-4{padding-right:4rem !important;padding-left:4rem !important}

.m-guts-p-h-5{padding-right:5rem !important;padding-left:5rem !important}

.m-guts-p-h-6{padding-right:6rem !important;padding-left:6rem !important}

.m-guts-p-h-7{padding-right:7rem !important;padding-left:7rem !important}

.m-guts-p-h-8{padding-right:8rem !important;padding-left:8rem !important}

.m-guts-p-h-9{padding-right:9rem !important;padding-left:9rem !important}

.m-guts-p-h-10{padding-right:10rem !important;padding-left:10rem !important}

.m-guts-p-h-11{padding-right:11rem !important;padding-left:11rem !important}

.m-guts-p-h-12{padding-right:12rem !important;padding-left:12rem !important}

.m-guts-p-h-13{padding-right:13rem !important;padding-left:13rem !important}

.m-guts-p-h-14{padding-right:14rem !important;padding-left:14rem !important}

.m-guts-p-h-15{padding-right:15rem !important;padding-left:15rem !important}

.m-guts-p-h-0--negative{padding-right:0rem !important;padding-left:0rem !important}

.m-guts-p-h-1--negative{padding-right:-1rem !important;padding-left:-1rem !important}

.m-guts-p-h-2--negative{padding-right:-2rem !important;padding-left:-2rem !important}

.m-guts-p-h-3--negative{padding-right:-3rem !important;padding-left:-3rem !important}

.m-guts-p-h-4--negative{padding-right:-4rem !important;padding-left:-4rem !important}

.m-guts-p-h-5--negative{padding-right:-5rem !important;padding-left:-5rem !important}

.m-guts-p-h-6--negative{padding-right:-6rem !important;padding-left:-6rem !important}

.m-guts-p-h-7--negative{padding-right:-7rem !important;padding-left:-7rem !important}

.m-guts-p-h-8--negative{padding-right:-8rem !important;padding-left:-8rem !important}

.m-guts-p-h-9--negative{padding-right:-9rem !important;padding-left:-9rem !important}

.m-guts-p-h-10--negative{padding-right:-10rem !important;padding-left:-10rem !important}

.m-guts-p-h-11--negative{padding-right:-11rem !important;padding-left:-11rem !important}

.m-guts-p-h-12--negative{padding-right:-12rem !important;padding-left:-12rem !important}

.m-guts-p-h-13--negative{padding-right:-13rem !important;padding-left:-13rem !important}

.m-guts-p-h-14--negative{padding-right:-14rem !important;padding-left:-14rem !important}

.m-guts-p-h-15--negative{padding-right:-15rem !important;padding-left:-15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.guts-p-full-auto{padding:auto !important}

.guts-p-full-quart{padding:0.25rem !important}

.guts-p-full-half{padding:0.5rem !important}

.guts-p-full-3-quart{padding:0.75rem !important}

.guts-p-full-1-half{padding:1.5rem !important}

.guts-p-full-1-quart{padding:1.25rem !important}

.guts-p-full-2-half{padding:2.5rem !important}

.guts-p-full-3-half{padding:3.5rem !important}

.guts-p-full-0{padding:0rem !important}

.guts-p-full-1{padding:1rem !important}

.guts-p-full-2{padding:2rem !important}

.guts-p-full-3{padding:3rem !important}

.guts-p-full-4{padding:4rem !important}

.guts-p-full-5{padding:5rem !important}

.guts-p-full-6{padding:6rem !important}

.guts-p-full-7{padding:7rem !important}

.guts-p-full-8{padding:8rem !important}

.guts-p-full-9{padding:9rem !important}

.guts-p-full-10{padding:10rem !important}

.guts-p-full-11{padding:11rem !important}

.guts-p-full-12{padding:12rem !important}

.guts-p-full-13{padding:13rem !important}

.guts-p-full-14{padding:14rem !important}

.guts-p-full-15{padding:15rem !important}

.guts-p-full-0--negative{padding:0rem !important}

.guts-p-full-1--negative{padding:-1rem !important}

.guts-p-full-2--negative{padding:-2rem !important}

.guts-p-full-3--negative{padding:-3rem !important}

.guts-p-full-4--negative{padding:-4rem !important}

.guts-p-full-5--negative{padding:-5rem !important}

.guts-p-full-6--negative{padding:-6rem !important}

.guts-p-full-7--negative{padding:-7rem !important}

.guts-p-full-8--negative{padding:-8rem !important}

.guts-p-full-9--negative{padding:-9rem !important}

.guts-p-full-10--negative{padding:-10rem !important}

.guts-p-full-11--negative{padding:-11rem !important}

.guts-p-full-12--negative{padding:-12rem !important}

.guts-p-full-13--negative{padding:-13rem !important}

.guts-p-full-14--negative{padding:-14rem !important}

.guts-p-full-15--negative{padding:-15rem !important}

@media(max-width: 768px){
.m-guts-p-full-auto{padding:auto !important}

.m-guts-p-full-quart{padding:0.25rem !important}

.m-guts-p-full-half{padding:0.5rem !important}

.m-guts-p-full-half--negative{padding:-0.5rem !important}

.m-guts-p-full-3-quart{padding:0.75rem !important}

.m-guts-p-full-1-half{padding:1.5rem !important}

.m-guts-p-full-2-half{padding:2.5rem !important}

.m-guts-p-full-3-half{padding:3.5rem !important}

.m-guts-p-full-0{padding:0rem !important}

.m-guts-p-full-1{padding:1rem !important}

.m-guts-p-full-2{padding:2rem !important}

.m-guts-p-full-3{padding:3rem !important}

.m-guts-p-full-4{padding:4rem !important}

.m-guts-p-full-5{padding:5rem !important}

.m-guts-p-full-6{padding:6rem !important}

.m-guts-p-full-7{padding:7rem !important}

.m-guts-p-full-8{padding:8rem !important}

.m-guts-p-full-9{padding:9rem !important}

.m-guts-p-full-10{padding:10rem !important}

.m-guts-p-full-11{padding:11rem !important}

.m-guts-p-full-12{padding:12rem !important}

.m-guts-p-full-13{padding:13rem !important}

.m-guts-p-full-14{padding:14rem !important}

.m-guts-p-full-15{padding:15rem !important}

.m-guts-p-full-0--negative{padding:0rem !important}

.m-guts-p-full-1--negative{padding:-1rem !important}

.m-guts-p-full-2--negative{padding:-2rem !important}

.m-guts-p-full-3--negative{padding:-3rem !important}

.m-guts-p-full-4--negative{padding:-4rem !important}

.m-guts-p-full-5--negative{padding:-5rem !important}

.m-guts-p-full-6--negative{padding:-6rem !important}

.m-guts-p-full-7--negative{padding:-7rem !important}

.m-guts-p-full-8--negative{padding:-8rem !important}

.m-guts-p-full-9--negative{padding:-9rem !important}

.m-guts-p-full-10--negative{padding:-10rem !important}

.m-guts-p-full-11--negative{padding:-11rem !important}

.m-guts-p-full-12--negative{padding:-12rem !important}

.m-guts-p-full-13--negative{padding:-13rem !important}

.m-guts-p-full-14--negative{padding:-14rem !important}

.m-guts-p-full-15--negative{padding:-15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.guts-m-t-auto{margin-top:auto !important}

.guts-m-t-quart{margin-top:0.25rem !important}

.guts-m-t-half{margin-top:0.5rem !important}

.guts-m-t-3-quart{margin-top:0.75rem !important}

.guts-m-t-1-half{margin-top:1.5rem !important}

.guts-m-t-1-quart{margin-top:1.25rem !important}

.guts-m-t-2-half{margin-top:2.5rem !important}

.guts-m-t-3-half{margin-top:3.5rem !important}

.guts-m-t-0{margin-top:0rem !important}

.guts-m-t-1{margin-top:1rem !important}

.guts-m-t-2{margin-top:2rem !important}

.guts-m-t-3{margin-top:3rem !important}

.guts-m-t-4{margin-top:4rem !important}

.guts-m-t-5{margin-top:5rem !important}

.guts-m-t-6{margin-top:6rem !important}

.guts-m-t-7{margin-top:7rem !important}

.guts-m-t-8{margin-top:8rem !important}

.guts-m-t-9{margin-top:9rem !important}

.guts-m-t-10{margin-top:10rem !important}

.guts-m-t-11{margin-top:11rem !important}

.guts-m-t-12{margin-top:12rem !important}

.guts-m-t-13{margin-top:13rem !important}

.guts-m-t-14{margin-top:14rem !important}

.guts-m-t-15{margin-top:15rem !important}

.guts-m-t-0--negative{margin-top:0rem !important}

.guts-m-t-1--negative{margin-top:-1rem !important}

.guts-m-t-2--negative{margin-top:-2rem !important}

.guts-m-t-3--negative{margin-top:-3rem !important}

.guts-m-t-4--negative{margin-top:-4rem !important}

.guts-m-t-5--negative{margin-top:-5rem !important}

.guts-m-t-6--negative{margin-top:-6rem !important}

.guts-m-t-7--negative{margin-top:-7rem !important}

.guts-m-t-8--negative{margin-top:-8rem !important}

.guts-m-t-9--negative{margin-top:-9rem !important}

.guts-m-t-10--negative{margin-top:-10rem !important}

.guts-m-t-11--negative{margin-top:-11rem !important}

.guts-m-t-12--negative{margin-top:-12rem !important}

.guts-m-t-13--negative{margin-top:-13rem !important}

.guts-m-t-14--negative{margin-top:-14rem !important}

.guts-m-t-15--negative{margin-top:-15rem !important}

@media(max-width: 768px){
.m-guts-m-t-auto{margin-top:auto !important}

.m-guts-m-t-quart{margin-top:0.25rem !important}

.m-guts-m-t-half{margin-top:0.5rem !important}

.m-guts-m-t-half--negative{margin-top:-0.5rem !important}

.m-guts-m-t-3-quart{margin-top:0.75rem !important}

.m-guts-m-t-1-half{margin-top:1.5rem !important}

.m-guts-m-t-2-half{margin-top:2.5rem !important}

.m-guts-m-t-3-half{margin-top:3.5rem !important}

.m-guts-m-t-0{margin-top:0rem !important}

.m-guts-m-t-1{margin-top:1rem !important}

.m-guts-m-t-2{margin-top:2rem !important}

.m-guts-m-t-3{margin-top:3rem !important}

.m-guts-m-t-4{margin-top:4rem !important}

.m-guts-m-t-5{margin-top:5rem !important}

.m-guts-m-t-6{margin-top:6rem !important}

.m-guts-m-t-7{margin-top:7rem !important}

.m-guts-m-t-8{margin-top:8rem !important}

.m-guts-m-t-9{margin-top:9rem !important}

.m-guts-m-t-10{margin-top:10rem !important}

.m-guts-m-t-11{margin-top:11rem !important}

.m-guts-m-t-12{margin-top:12rem !important}

.m-guts-m-t-13{margin-top:13rem !important}

.m-guts-m-t-14{margin-top:14rem !important}

.m-guts-m-t-15{margin-top:15rem !important}

.m-guts-m-t-0--negative{margin-top:0rem !important}

.m-guts-m-t-1--negative{margin-top:-1rem !important}

.m-guts-m-t-2--negative{margin-top:-2rem !important}

.m-guts-m-t-3--negative{margin-top:-3rem !important}

.m-guts-m-t-4--negative{margin-top:-4rem !important}

.m-guts-m-t-5--negative{margin-top:-5rem !important}

.m-guts-m-t-6--negative{margin-top:-6rem !important}

.m-guts-m-t-7--negative{margin-top:-7rem !important}

.m-guts-m-t-8--negative{margin-top:-8rem !important}

.m-guts-m-t-9--negative{margin-top:-9rem !important}

.m-guts-m-t-10--negative{margin-top:-10rem !important}

.m-guts-m-t-11--negative{margin-top:-11rem !important}

.m-guts-m-t-12--negative{margin-top:-12rem !important}

.m-guts-m-t-13--negative{margin-top:-13rem !important}

.m-guts-m-t-14--negative{margin-top:-14rem !important}

.m-guts-m-t-15--negative{margin-top:-15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.guts-m-b-auto{margin-bottom:auto !important}

.guts-m-b-quart{margin-bottom:0.25rem !important}

.guts-m-b-half{margin-bottom:0.5rem !important}

.guts-m-b-3-quart{margin-bottom:0.75rem !important}

.guts-m-b-1-half{margin-bottom:1.5rem !important}

.guts-m-b-1-quart{margin-bottom:1.25rem !important}

.guts-m-b-2-half{margin-bottom:2.5rem !important}

.guts-m-b-3-half{margin-bottom:3.5rem !important}

.guts-m-b-0{margin-bottom:0rem !important}

.guts-m-b-1{margin-bottom:1rem !important}

.guts-m-b-2{margin-bottom:2rem !important}

.guts-m-b-3{margin-bottom:3rem !important}

.guts-m-b-4{margin-bottom:4rem !important}

.guts-m-b-5{margin-bottom:5rem !important}

.guts-m-b-6{margin-bottom:6rem !important}

.guts-m-b-7{margin-bottom:7rem !important}

.guts-m-b-8{margin-bottom:8rem !important}

.guts-m-b-9{margin-bottom:9rem !important}

.guts-m-b-10{margin-bottom:10rem !important}

.guts-m-b-11{margin-bottom:11rem !important}

.guts-m-b-12{margin-bottom:12rem !important}

.guts-m-b-13{margin-bottom:13rem !important}

.guts-m-b-14{margin-bottom:14rem !important}

.guts-m-b-15{margin-bottom:15rem !important}

.guts-m-b-0--negative{margin-bottom:0rem !important}

.guts-m-b-1--negative{margin-bottom:-1rem !important}

.guts-m-b-2--negative{margin-bottom:-2rem !important}

.guts-m-b-3--negative{margin-bottom:-3rem !important}

.guts-m-b-4--negative{margin-bottom:-4rem !important}

.guts-m-b-5--negative{margin-bottom:-5rem !important}

.guts-m-b-6--negative{margin-bottom:-6rem !important}

.guts-m-b-7--negative{margin-bottom:-7rem !important}

.guts-m-b-8--negative{margin-bottom:-8rem !important}

.guts-m-b-9--negative{margin-bottom:-9rem !important}

.guts-m-b-10--negative{margin-bottom:-10rem !important}

.guts-m-b-11--negative{margin-bottom:-11rem !important}

.guts-m-b-12--negative{margin-bottom:-12rem !important}

.guts-m-b-13--negative{margin-bottom:-13rem !important}

.guts-m-b-14--negative{margin-bottom:-14rem !important}

.guts-m-b-15--negative{margin-bottom:-15rem !important}

@media(max-width: 768px){
.m-guts-m-b-auto{margin-bottom:auto !important}

.m-guts-m-b-quart{margin-bottom:0.25rem !important}

.m-guts-m-b-half{margin-bottom:0.5rem !important}

.m-guts-m-b-half--negative{margin-bottom:-0.5rem !important}

.m-guts-m-b-3-quart{margin-bottom:0.75rem !important}

.m-guts-m-b-1-half{margin-bottom:1.5rem !important}

.m-guts-m-b-2-half{margin-bottom:2.5rem !important}

.m-guts-m-b-3-half{margin-bottom:3.5rem !important}

.m-guts-m-b-0{margin-bottom:0rem !important}

.m-guts-m-b-1{margin-bottom:1rem !important}

.m-guts-m-b-2{margin-bottom:2rem !important}

.m-guts-m-b-3{margin-bottom:3rem !important}

.m-guts-m-b-4{margin-bottom:4rem !important}

.m-guts-m-b-5{margin-bottom:5rem !important}

.m-guts-m-b-6{margin-bottom:6rem !important}

.m-guts-m-b-7{margin-bottom:7rem !important}

.m-guts-m-b-8{margin-bottom:8rem !important}

.m-guts-m-b-9{margin-bottom:9rem !important}

.m-guts-m-b-10{margin-bottom:10rem !important}

.m-guts-m-b-11{margin-bottom:11rem !important}

.m-guts-m-b-12{margin-bottom:12rem !important}

.m-guts-m-b-13{margin-bottom:13rem !important}

.m-guts-m-b-14{margin-bottom:14rem !important}

.m-guts-m-b-15{margin-bottom:15rem !important}

.m-guts-m-b-0--negative{margin-bottom:0rem !important}

.m-guts-m-b-1--negative{margin-bottom:-1rem !important}

.m-guts-m-b-2--negative{margin-bottom:-2rem !important}

.m-guts-m-b-3--negative{margin-bottom:-3rem !important}

.m-guts-m-b-4--negative{margin-bottom:-4rem !important}

.m-guts-m-b-5--negative{margin-bottom:-5rem !important}

.m-guts-m-b-6--negative{margin-bottom:-6rem !important}

.m-guts-m-b-7--negative{margin-bottom:-7rem !important}

.m-guts-m-b-8--negative{margin-bottom:-8rem !important}

.m-guts-m-b-9--negative{margin-bottom:-9rem !important}

.m-guts-m-b-10--negative{margin-bottom:-10rem !important}

.m-guts-m-b-11--negative{margin-bottom:-11rem !important}

.m-guts-m-b-12--negative{margin-bottom:-12rem !important}

.m-guts-m-b-13--negative{margin-bottom:-13rem !important}

.m-guts-m-b-14--negative{margin-bottom:-14rem !important}

.m-guts-m-b-15--negative{margin-bottom:-15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

[dir="ltr"] .guts-m-r-auto{margin-right:auto !important}

[dir="rtl"] .guts-m-r-auto{margin-left:auto !important}

[dir="ltr"] .guts-m-r-quart{margin-right:0.25rem !important}

[dir="rtl"] .guts-m-r-quart{margin-left:0.25rem !important}

[dir="ltr"] .guts-m-r-half{margin-right:0.5rem !important}

[dir="rtl"] .guts-m-r-half{margin-left:0.5rem !important}

[dir="ltr"] .guts-m-r-3-quart{margin-right:0.75rem !important}

[dir="rtl"] .guts-m-r-3-quart{margin-left:0.75rem !important}

[dir="ltr"] .guts-m-r-1-half{margin-right:1.5rem !important}

[dir="rtl"] .guts-m-r-1-half{margin-left:1.5rem !important}

[dir="ltr"] .guts-m-r-1-quart{margin-right:1.25rem !important}

[dir="rtl"] .guts-m-r-1-quart{margin-left:1.25rem !important}

[dir="ltr"] .guts-m-r-2-half{margin-right:2.5rem !important}

[dir="rtl"] .guts-m-r-2-half{margin-left:2.5rem !important}

[dir="ltr"] .guts-m-r-3-half{margin-right:3.5rem !important}

[dir="rtl"] .guts-m-r-3-half{margin-left:3.5rem !important}

[dir="ltr"] .guts-m-r-0{margin-right:0rem !important}

[dir="rtl"] .guts-m-r-0{margin-left:0rem !important}

[dir="ltr"] .guts-m-r-1{margin-right:1rem !important}

[dir="rtl"] .guts-m-r-1{margin-left:1rem !important}

[dir="ltr"] .guts-m-r-2{margin-right:2rem !important}

[dir="rtl"] .guts-m-r-2{margin-left:2rem !important}

[dir="ltr"] .guts-m-r-3{margin-right:3rem !important}

[dir="rtl"] .guts-m-r-3{margin-left:3rem !important}

[dir="ltr"] .guts-m-r-4{margin-right:4rem !important}

[dir="rtl"] .guts-m-r-4{margin-left:4rem !important}

[dir="ltr"] .guts-m-r-5{margin-right:5rem !important}

[dir="rtl"] .guts-m-r-5{margin-left:5rem !important}

[dir="ltr"] .guts-m-r-6{margin-right:6rem !important}

[dir="rtl"] .guts-m-r-6{margin-left:6rem !important}

[dir="ltr"] .guts-m-r-7{margin-right:7rem !important}

[dir="rtl"] .guts-m-r-7{margin-left:7rem !important}

[dir="ltr"] .guts-m-r-8{margin-right:8rem !important}

[dir="rtl"] .guts-m-r-8{margin-left:8rem !important}

[dir="ltr"] .guts-m-r-9{margin-right:9rem !important}

[dir="rtl"] .guts-m-r-9{margin-left:9rem !important}

[dir="ltr"] .guts-m-r-10{margin-right:10rem !important}

[dir="rtl"] .guts-m-r-10{margin-left:10rem !important}

[dir="ltr"] .guts-m-r-11{margin-right:11rem !important}

[dir="rtl"] .guts-m-r-11{margin-left:11rem !important}

[dir="ltr"] .guts-m-r-12{margin-right:12rem !important}

[dir="rtl"] .guts-m-r-12{margin-left:12rem !important}

[dir="ltr"] .guts-m-r-13{margin-right:13rem !important}

[dir="rtl"] .guts-m-r-13{margin-left:13rem !important}

[dir="ltr"] .guts-m-r-14{margin-right:14rem !important}

[dir="rtl"] .guts-m-r-14{margin-left:14rem !important}

[dir="ltr"] .guts-m-r-15{margin-right:15rem !important}

[dir="rtl"] .guts-m-r-15{margin-left:15rem !important}

[dir="ltr"] .guts-m-r-0--negative{margin-right:0rem !important}

[dir="rtl"] .guts-m-r-0--negative{margin-left:0rem !important}

[dir="ltr"] .guts-m-r-1--negative{margin-right:-1rem !important}

[dir="rtl"] .guts-m-r-1--negative{margin-left:-1rem !important}

[dir="ltr"] .guts-m-r-2--negative{margin-right:-2rem !important}

[dir="rtl"] .guts-m-r-2--negative{margin-left:-2rem !important}

[dir="ltr"] .guts-m-r-3--negative{margin-right:-3rem !important}

[dir="rtl"] .guts-m-r-3--negative{margin-left:-3rem !important}

[dir="ltr"] .guts-m-r-4--negative{margin-right:-4rem !important}

[dir="rtl"] .guts-m-r-4--negative{margin-left:-4rem !important}

[dir="ltr"] .guts-m-r-5--negative{margin-right:-5rem !important}

[dir="rtl"] .guts-m-r-5--negative{margin-left:-5rem !important}

[dir="ltr"] .guts-m-r-6--negative{margin-right:-6rem !important}

[dir="rtl"] .guts-m-r-6--negative{margin-left:-6rem !important}

[dir="ltr"] .guts-m-r-7--negative{margin-right:-7rem !important}

[dir="rtl"] .guts-m-r-7--negative{margin-left:-7rem !important}

[dir="ltr"] .guts-m-r-8--negative{margin-right:-8rem !important}

[dir="rtl"] .guts-m-r-8--negative{margin-left:-8rem !important}

[dir="ltr"] .guts-m-r-9--negative{margin-right:-9rem !important}

[dir="rtl"] .guts-m-r-9--negative{margin-left:-9rem !important}

[dir="ltr"] .guts-m-r-10--negative{margin-right:-10rem !important}

[dir="rtl"] .guts-m-r-10--negative{margin-left:-10rem !important}

[dir="ltr"] .guts-m-r-11--negative{margin-right:-11rem !important}

[dir="rtl"] .guts-m-r-11--negative{margin-left:-11rem !important}

[dir="ltr"] .guts-m-r-12--negative{margin-right:-12rem !important}

[dir="rtl"] .guts-m-r-12--negative{margin-left:-12rem !important}

[dir="ltr"] .guts-m-r-13--negative{margin-right:-13rem !important}

[dir="rtl"] .guts-m-r-13--negative{margin-left:-13rem !important}

[dir="ltr"] .guts-m-r-14--negative{margin-right:-14rem !important}

[dir="rtl"] .guts-m-r-14--negative{margin-left:-14rem !important}

[dir="ltr"] .guts-m-r-15--negative{margin-right:-15rem !important}

[dir="rtl"] .guts-m-r-15--negative{margin-left:-15rem !important}

@media(max-width: 768px){
[dir="ltr"] .m-guts-m-r-auto{margin-right:auto !important}

[dir="rtl"] .m-guts-m-r-auto{margin-left:auto !important}

[dir="ltr"] .m-guts-m-r-quart{margin-right:0.25rem !important}

[dir="rtl"] .m-guts-m-r-quart{margin-left:0.25rem !important}

[dir="ltr"] .m-guts-m-r-half{margin-right:0.5rem !important}

[dir="rtl"] .m-guts-m-r-half{margin-left:0.5rem !important}

[dir="ltr"] .m-guts-m-r-half--negative{margin-right:-0.5rem !important}

[dir="rtl"] .m-guts-m-r-half--negative{margin-left:-0.5rem !important}

[dir="ltr"] .m-guts-m-r-3-quart{margin-right:0.75rem !important}

[dir="rtl"] .m-guts-m-r-3-quart{margin-left:0.75rem !important}

[dir="ltr"] .m-guts-m-r-1-half{margin-right:1.5rem !important}

[dir="rtl"] .m-guts-m-r-1-half{margin-left:1.5rem !important}

[dir="ltr"] .m-guts-m-r-2-half{margin-right:2.5rem !important}

[dir="rtl"] .m-guts-m-r-2-half{margin-left:2.5rem !important}

[dir="ltr"] .m-guts-m-r-3-half{margin-right:3.5rem !important}

[dir="rtl"] .m-guts-m-r-3-half{margin-left:3.5rem !important}

[dir="ltr"] .m-guts-m-r-0{margin-right:0rem !important}

[dir="rtl"] .m-guts-m-r-0{margin-left:0rem !important}

[dir="ltr"] .m-guts-m-r-1{margin-right:1rem !important}

[dir="rtl"] .m-guts-m-r-1{margin-left:1rem !important}

[dir="ltr"] .m-guts-m-r-2{margin-right:2rem !important}

[dir="rtl"] .m-guts-m-r-2{margin-left:2rem !important}

[dir="ltr"] .m-guts-m-r-3{margin-right:3rem !important}

[dir="rtl"] .m-guts-m-r-3{margin-left:3rem !important}

[dir="ltr"] .m-guts-m-r-4{margin-right:4rem !important}

[dir="rtl"] .m-guts-m-r-4{margin-left:4rem !important}

[dir="ltr"] .m-guts-m-r-5{margin-right:5rem !important}

[dir="rtl"] .m-guts-m-r-5{margin-left:5rem !important}

[dir="ltr"] .m-guts-m-r-6{margin-right:6rem !important}

[dir="rtl"] .m-guts-m-r-6{margin-left:6rem !important}

[dir="ltr"] .m-guts-m-r-7{margin-right:7rem !important}

[dir="rtl"] .m-guts-m-r-7{margin-left:7rem !important}

[dir="ltr"] .m-guts-m-r-8{margin-right:8rem !important}

[dir="rtl"] .m-guts-m-r-8{margin-left:8rem !important}

[dir="ltr"] .m-guts-m-r-9{margin-right:9rem !important}

[dir="rtl"] .m-guts-m-r-9{margin-left:9rem !important}

[dir="ltr"] .m-guts-m-r-10{margin-right:10rem !important}

[dir="rtl"] .m-guts-m-r-10{margin-left:10rem !important}

[dir="ltr"] .m-guts-m-r-11{margin-right:11rem !important}

[dir="rtl"] .m-guts-m-r-11{margin-left:11rem !important}

[dir="ltr"] .m-guts-m-r-12{margin-right:12rem !important}

[dir="rtl"] .m-guts-m-r-12{margin-left:12rem !important}

[dir="ltr"] .m-guts-m-r-13{margin-right:13rem !important}

[dir="rtl"] .m-guts-m-r-13{margin-left:13rem !important}

[dir="ltr"] .m-guts-m-r-14{margin-right:14rem !important}

[dir="rtl"] .m-guts-m-r-14{margin-left:14rem !important}

[dir="ltr"] .m-guts-m-r-15{margin-right:15rem !important}

[dir="rtl"] .m-guts-m-r-15{margin-left:15rem !important}

[dir="ltr"] .m-guts-m-r-0--negative{margin-right:0rem !important}

[dir="rtl"] .m-guts-m-r-0--negative{margin-left:0rem !important}

[dir="ltr"] .m-guts-m-r-1--negative{margin-right:-1rem !important}

[dir="rtl"] .m-guts-m-r-1--negative{margin-left:-1rem !important}

[dir="ltr"] .m-guts-m-r-2--negative{margin-right:-2rem !important}

[dir="rtl"] .m-guts-m-r-2--negative{margin-left:-2rem !important}

[dir="ltr"] .m-guts-m-r-3--negative{margin-right:-3rem !important}

[dir="rtl"] .m-guts-m-r-3--negative{margin-left:-3rem !important}

[dir="ltr"] .m-guts-m-r-4--negative{margin-right:-4rem !important}

[dir="rtl"] .m-guts-m-r-4--negative{margin-left:-4rem !important}

[dir="ltr"] .m-guts-m-r-5--negative{margin-right:-5rem !important}

[dir="rtl"] .m-guts-m-r-5--negative{margin-left:-5rem !important}

[dir="ltr"] .m-guts-m-r-6--negative{margin-right:-6rem !important}

[dir="rtl"] .m-guts-m-r-6--negative{margin-left:-6rem !important}

[dir="ltr"] .m-guts-m-r-7--negative{margin-right:-7rem !important}

[dir="rtl"] .m-guts-m-r-7--negative{margin-left:-7rem !important}

[dir="ltr"] .m-guts-m-r-8--negative{margin-right:-8rem !important}

[dir="rtl"] .m-guts-m-r-8--negative{margin-left:-8rem !important}

[dir="ltr"] .m-guts-m-r-9--negative{margin-right:-9rem !important}

[dir="rtl"] .m-guts-m-r-9--negative{margin-left:-9rem !important}

[dir="ltr"] .m-guts-m-r-10--negative{margin-right:-10rem !important}

[dir="rtl"] .m-guts-m-r-10--negative{margin-left:-10rem !important}

[dir="ltr"] .m-guts-m-r-11--negative{margin-right:-11rem !important}

[dir="rtl"] .m-guts-m-r-11--negative{margin-left:-11rem !important}

[dir="ltr"] .m-guts-m-r-12--negative{margin-right:-12rem !important}

[dir="rtl"] .m-guts-m-r-12--negative{margin-left:-12rem !important}

[dir="ltr"] .m-guts-m-r-13--negative{margin-right:-13rem !important}

[dir="rtl"] .m-guts-m-r-13--negative{margin-left:-13rem !important}

[dir="ltr"] .m-guts-m-r-14--negative{margin-right:-14rem !important}

[dir="rtl"] .m-guts-m-r-14--negative{margin-left:-14rem !important}

[dir="ltr"] .m-guts-m-r-15--negative{margin-right:-15rem !important}

[dir="rtl"] .m-guts-m-r-15--negative{margin-left:-15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

[dir="ltr"] .guts-m-l-auto{margin-left:auto !important}

[dir="rtl"] .guts-m-l-auto{margin-right:auto !important}

[dir="ltr"] .guts-m-l-quart{margin-left:0.25rem !important}

[dir="rtl"] .guts-m-l-quart{margin-right:0.25rem !important}

[dir="ltr"] .guts-m-l-half{margin-left:0.5rem !important}

[dir="rtl"] .guts-m-l-half{margin-right:0.5rem !important}

[dir="ltr"] .guts-m-l-3-quart{margin-left:0.75rem !important}

[dir="rtl"] .guts-m-l-3-quart{margin-right:0.75rem !important}

[dir="ltr"] .guts-m-l-1-half{margin-left:1.5rem !important}

[dir="rtl"] .guts-m-l-1-half{margin-right:1.5rem !important}

[dir="ltr"] .guts-m-l-1-quart{margin-left:1.25rem !important}

[dir="rtl"] .guts-m-l-1-quart{margin-right:1.25rem !important}

[dir="ltr"] .guts-m-l-2-half{margin-left:2.5rem !important}

[dir="rtl"] .guts-m-l-2-half{margin-right:2.5rem !important}

[dir="ltr"] .guts-m-l-3-half{margin-left:3.5rem !important}

[dir="rtl"] .guts-m-l-3-half{margin-right:3.5rem !important}

[dir="ltr"] .guts-m-l-0{margin-left:0rem !important}

[dir="rtl"] .guts-m-l-0{margin-right:0rem !important}

[dir="ltr"] .guts-m-l-1{margin-left:1rem !important}

[dir="rtl"] .guts-m-l-1{margin-right:1rem !important}

[dir="ltr"] .guts-m-l-2{margin-left:2rem !important}

[dir="rtl"] .guts-m-l-2{margin-right:2rem !important}

[dir="ltr"] .guts-m-l-3{margin-left:3rem !important}

[dir="rtl"] .guts-m-l-3{margin-right:3rem !important}

[dir="ltr"] .guts-m-l-4{margin-left:4rem !important}

[dir="rtl"] .guts-m-l-4{margin-right:4rem !important}

[dir="ltr"] .guts-m-l-5{margin-left:5rem !important}

[dir="rtl"] .guts-m-l-5{margin-right:5rem !important}

[dir="ltr"] .guts-m-l-6{margin-left:6rem !important}

[dir="rtl"] .guts-m-l-6{margin-right:6rem !important}

[dir="ltr"] .guts-m-l-7{margin-left:7rem !important}

[dir="rtl"] .guts-m-l-7{margin-right:7rem !important}

[dir="ltr"] .guts-m-l-8{margin-left:8rem !important}

[dir="rtl"] .guts-m-l-8{margin-right:8rem !important}

[dir="ltr"] .guts-m-l-9{margin-left:9rem !important}

[dir="rtl"] .guts-m-l-9{margin-right:9rem !important}

[dir="ltr"] .guts-m-l-10{margin-left:10rem !important}

[dir="rtl"] .guts-m-l-10{margin-right:10rem !important}

[dir="ltr"] .guts-m-l-11{margin-left:11rem !important}

[dir="rtl"] .guts-m-l-11{margin-right:11rem !important}

[dir="ltr"] .guts-m-l-12{margin-left:12rem !important}

[dir="rtl"] .guts-m-l-12{margin-right:12rem !important}

[dir="ltr"] .guts-m-l-13{margin-left:13rem !important}

[dir="rtl"] .guts-m-l-13{margin-right:13rem !important}

[dir="ltr"] .guts-m-l-14{margin-left:14rem !important}

[dir="rtl"] .guts-m-l-14{margin-right:14rem !important}

[dir="ltr"] .guts-m-l-15{margin-left:15rem !important}

[dir="rtl"] .guts-m-l-15{margin-right:15rem !important}

[dir="ltr"] .guts-m-l-0--negative{margin-left:0rem !important}

[dir="rtl"] .guts-m-l-0--negative{margin-right:0rem !important}

[dir="ltr"] .guts-m-l-1--negative{margin-left:-1rem !important}

[dir="rtl"] .guts-m-l-1--negative{margin-right:-1rem !important}

[dir="ltr"] .guts-m-l-2--negative{margin-left:-2rem !important}

[dir="rtl"] .guts-m-l-2--negative{margin-right:-2rem !important}

[dir="ltr"] .guts-m-l-3--negative{margin-left:-3rem !important}

[dir="rtl"] .guts-m-l-3--negative{margin-right:-3rem !important}

[dir="ltr"] .guts-m-l-4--negative{margin-left:-4rem !important}

[dir="rtl"] .guts-m-l-4--negative{margin-right:-4rem !important}

[dir="ltr"] .guts-m-l-5--negative{margin-left:-5rem !important}

[dir="rtl"] .guts-m-l-5--negative{margin-right:-5rem !important}

[dir="ltr"] .guts-m-l-6--negative{margin-left:-6rem !important}

[dir="rtl"] .guts-m-l-6--negative{margin-right:-6rem !important}

[dir="ltr"] .guts-m-l-7--negative{margin-left:-7rem !important}

[dir="rtl"] .guts-m-l-7--negative{margin-right:-7rem !important}

[dir="ltr"] .guts-m-l-8--negative{margin-left:-8rem !important}

[dir="rtl"] .guts-m-l-8--negative{margin-right:-8rem !important}

[dir="ltr"] .guts-m-l-9--negative{margin-left:-9rem !important}

[dir="rtl"] .guts-m-l-9--negative{margin-right:-9rem !important}

[dir="ltr"] .guts-m-l-10--negative{margin-left:-10rem !important}

[dir="rtl"] .guts-m-l-10--negative{margin-right:-10rem !important}

[dir="ltr"] .guts-m-l-11--negative{margin-left:-11rem !important}

[dir="rtl"] .guts-m-l-11--negative{margin-right:-11rem !important}

[dir="ltr"] .guts-m-l-12--negative{margin-left:-12rem !important}

[dir="rtl"] .guts-m-l-12--negative{margin-right:-12rem !important}

[dir="ltr"] .guts-m-l-13--negative{margin-left:-13rem !important}

[dir="rtl"] .guts-m-l-13--negative{margin-right:-13rem !important}

[dir="ltr"] .guts-m-l-14--negative{margin-left:-14rem !important}

[dir="rtl"] .guts-m-l-14--negative{margin-right:-14rem !important}

[dir="ltr"] .guts-m-l-15--negative{margin-left:-15rem !important}

[dir="rtl"] .guts-m-l-15--negative{margin-right:-15rem !important}

@media(max-width: 768px){
[dir="ltr"] .m-guts-m-l-auto{margin-left:auto !important}

[dir="rtl"] .m-guts-m-l-auto{margin-right:auto !important}

[dir="ltr"] .m-guts-m-l-quart{margin-left:0.25rem !important}

[dir="rtl"] .m-guts-m-l-quart{margin-right:0.25rem !important}

[dir="ltr"] .m-guts-m-l-half{margin-left:0.5rem !important}

[dir="rtl"] .m-guts-m-l-half{margin-right:0.5rem !important}

[dir="ltr"] .m-guts-m-l-half--negative{margin-left:-0.5rem !important}

[dir="rtl"] .m-guts-m-l-half--negative{margin-right:-0.5rem !important}

[dir="ltr"] .m-guts-m-l-3-quart{margin-left:0.75rem !important}

[dir="rtl"] .m-guts-m-l-3-quart{margin-right:0.75rem !important}

[dir="ltr"] .m-guts-m-l-1-half{margin-left:1.5rem !important}

[dir="rtl"] .m-guts-m-l-1-half{margin-right:1.5rem !important}

[dir="ltr"] .m-guts-m-l-2-half{margin-left:2.5rem !important}

[dir="rtl"] .m-guts-m-l-2-half{margin-right:2.5rem !important}

[dir="ltr"] .m-guts-m-l-3-half{margin-left:3.5rem !important}

[dir="rtl"] .m-guts-m-l-3-half{margin-right:3.5rem !important}

[dir="ltr"] .m-guts-m-l-0{margin-left:0rem !important}

[dir="rtl"] .m-guts-m-l-0{margin-right:0rem !important}

[dir="ltr"] .m-guts-m-l-1{margin-left:1rem !important}

[dir="rtl"] .m-guts-m-l-1{margin-right:1rem !important}

[dir="ltr"] .m-guts-m-l-2{margin-left:2rem !important}

[dir="rtl"] .m-guts-m-l-2{margin-right:2rem !important}

[dir="ltr"] .m-guts-m-l-3{margin-left:3rem !important}

[dir="rtl"] .m-guts-m-l-3{margin-right:3rem !important}

[dir="ltr"] .m-guts-m-l-4{margin-left:4rem !important}

[dir="rtl"] .m-guts-m-l-4{margin-right:4rem !important}

[dir="ltr"] .m-guts-m-l-5{margin-left:5rem !important}

[dir="rtl"] .m-guts-m-l-5{margin-right:5rem !important}

[dir="ltr"] .m-guts-m-l-6{margin-left:6rem !important}

[dir="rtl"] .m-guts-m-l-6{margin-right:6rem !important}

[dir="ltr"] .m-guts-m-l-7{margin-left:7rem !important}

[dir="rtl"] .m-guts-m-l-7{margin-right:7rem !important}

[dir="ltr"] .m-guts-m-l-8{margin-left:8rem !important}

[dir="rtl"] .m-guts-m-l-8{margin-right:8rem !important}

[dir="ltr"] .m-guts-m-l-9{margin-left:9rem !important}

[dir="rtl"] .m-guts-m-l-9{margin-right:9rem !important}

[dir="ltr"] .m-guts-m-l-10{margin-left:10rem !important}

[dir="rtl"] .m-guts-m-l-10{margin-right:10rem !important}

[dir="ltr"] .m-guts-m-l-11{margin-left:11rem !important}

[dir="rtl"] .m-guts-m-l-11{margin-right:11rem !important}

[dir="ltr"] .m-guts-m-l-12{margin-left:12rem !important}

[dir="rtl"] .m-guts-m-l-12{margin-right:12rem !important}

[dir="ltr"] .m-guts-m-l-13{margin-left:13rem !important}

[dir="rtl"] .m-guts-m-l-13{margin-right:13rem !important}

[dir="ltr"] .m-guts-m-l-14{margin-left:14rem !important}

[dir="rtl"] .m-guts-m-l-14{margin-right:14rem !important}

[dir="ltr"] .m-guts-m-l-15{margin-left:15rem !important}

[dir="rtl"] .m-guts-m-l-15{margin-right:15rem !important}

[dir="ltr"] .m-guts-m-l-0--negative{margin-left:0rem !important}

[dir="rtl"] .m-guts-m-l-0--negative{margin-right:0rem !important}

[dir="ltr"] .m-guts-m-l-1--negative{margin-left:-1rem !important}

[dir="rtl"] .m-guts-m-l-1--negative{margin-right:-1rem !important}

[dir="ltr"] .m-guts-m-l-2--negative{margin-left:-2rem !important}

[dir="rtl"] .m-guts-m-l-2--negative{margin-right:-2rem !important}

[dir="ltr"] .m-guts-m-l-3--negative{margin-left:-3rem !important}

[dir="rtl"] .m-guts-m-l-3--negative{margin-right:-3rem !important}

[dir="ltr"] .m-guts-m-l-4--negative{margin-left:-4rem !important}

[dir="rtl"] .m-guts-m-l-4--negative{margin-right:-4rem !important}

[dir="ltr"] .m-guts-m-l-5--negative{margin-left:-5rem !important}

[dir="rtl"] .m-guts-m-l-5--negative{margin-right:-5rem !important}

[dir="ltr"] .m-guts-m-l-6--negative{margin-left:-6rem !important}

[dir="rtl"] .m-guts-m-l-6--negative{margin-right:-6rem !important}

[dir="ltr"] .m-guts-m-l-7--negative{margin-left:-7rem !important}

[dir="rtl"] .m-guts-m-l-7--negative{margin-right:-7rem !important}

[dir="ltr"] .m-guts-m-l-8--negative{margin-left:-8rem !important}

[dir="rtl"] .m-guts-m-l-8--negative{margin-right:-8rem !important}

[dir="ltr"] .m-guts-m-l-9--negative{margin-left:-9rem !important}

[dir="rtl"] .m-guts-m-l-9--negative{margin-right:-9rem !important}

[dir="ltr"] .m-guts-m-l-10--negative{margin-left:-10rem !important}

[dir="rtl"] .m-guts-m-l-10--negative{margin-right:-10rem !important}

[dir="ltr"] .m-guts-m-l-11--negative{margin-left:-11rem !important}

[dir="rtl"] .m-guts-m-l-11--negative{margin-right:-11rem !important}

[dir="ltr"] .m-guts-m-l-12--negative{margin-left:-12rem !important}

[dir="rtl"] .m-guts-m-l-12--negative{margin-right:-12rem !important}

[dir="ltr"] .m-guts-m-l-13--negative{margin-left:-13rem !important}

[dir="rtl"] .m-guts-m-l-13--negative{margin-right:-13rem !important}

[dir="ltr"] .m-guts-m-l-14--negative{margin-left:-14rem !important}

[dir="rtl"] .m-guts-m-l-14--negative{margin-right:-14rem !important}

[dir="ltr"] .m-guts-m-l-15--negative{margin-left:-15rem !important}

[dir="rtl"] .m-guts-m-l-15--negative{margin-right:-15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.guts-m-v-auto{margin-top:auto !important;margin-bottom:auto !important}

.guts-m-v-quart{margin-top:0.25rem !important;margin-bottom:0.25rem !important}

.guts-m-v-half{margin-top:0.5rem !important;margin-bottom:0.5rem !important}

.guts-m-v-3-quart{margin-top:0.75rem !important;margin-bottom:0.75rem !important}

.guts-m-v-1-half{margin-top:1.5rem !important;margin-bottom:1.5rem !important}

.guts-m-v-1-quart{margin-top:1.25rem !important;margin-bottom:1.25rem !important}

.guts-m-v-2-half{margin-top:2.5rem !important;margin-bottom:2.5rem !important}

.guts-m-v-3-half{margin-top:3.5rem !important;margin-bottom:3.5rem !important}

.guts-m-v-0{margin-top:0rem !important;margin-bottom:0rem !important}

.guts-m-v-1{margin-top:1rem !important;margin-bottom:1rem !important}

.guts-m-v-2{margin-top:2rem !important;margin-bottom:2rem !important}

.guts-m-v-3{margin-top:3rem !important;margin-bottom:3rem !important}

.guts-m-v-4{margin-top:4rem !important;margin-bottom:4rem !important}

.guts-m-v-5{margin-top:5rem !important;margin-bottom:5rem !important}

.guts-m-v-6{margin-top:6rem !important;margin-bottom:6rem !important}

.guts-m-v-7{margin-top:7rem !important;margin-bottom:7rem !important}

.guts-m-v-8{margin-top:8rem !important;margin-bottom:8rem !important}

.guts-m-v-9{margin-top:9rem !important;margin-bottom:9rem !important}

.guts-m-v-10{margin-top:10rem !important;margin-bottom:10rem !important}

.guts-m-v-11{margin-top:11rem !important;margin-bottom:11rem !important}

.guts-m-v-12{margin-top:12rem !important;margin-bottom:12rem !important}

.guts-m-v-13{margin-top:13rem !important;margin-bottom:13rem !important}

.guts-m-v-14{margin-top:14rem !important;margin-bottom:14rem !important}

.guts-m-v-15{margin-top:15rem !important;margin-bottom:15rem !important}

.guts-m-v-0--negative{margin-top:0rem !important;margin-bottom:0rem !important}

.guts-m-v-1--negative{margin-top:-1rem !important;margin-bottom:-1rem !important}

.guts-m-v-2--negative{margin-top:-2rem !important;margin-bottom:-2rem !important}

.guts-m-v-3--negative{margin-top:-3rem !important;margin-bottom:-3rem !important}

.guts-m-v-4--negative{margin-top:-4rem !important;margin-bottom:-4rem !important}

.guts-m-v-5--negative{margin-top:-5rem !important;margin-bottom:-5rem !important}

.guts-m-v-6--negative{margin-top:-6rem !important;margin-bottom:-6rem !important}

.guts-m-v-7--negative{margin-top:-7rem !important;margin-bottom:-7rem !important}

.guts-m-v-8--negative{margin-top:-8rem !important;margin-bottom:-8rem !important}

.guts-m-v-9--negative{margin-top:-9rem !important;margin-bottom:-9rem !important}

.guts-m-v-10--negative{margin-top:-10rem !important;margin-bottom:-10rem !important}

.guts-m-v-11--negative{margin-top:-11rem !important;margin-bottom:-11rem !important}

.guts-m-v-12--negative{margin-top:-12rem !important;margin-bottom:-12rem !important}

.guts-m-v-13--negative{margin-top:-13rem !important;margin-bottom:-13rem !important}

.guts-m-v-14--negative{margin-top:-14rem !important;margin-bottom:-14rem !important}

.guts-m-v-15--negative{margin-top:-15rem !important;margin-bottom:-15rem !important}

@media(max-width: 768px){
.m-guts-m-v-auto{margin-top:auto !important;margin-bottom:auto !important}

.m-guts-m-v-quart{margin-top:0.25rem !important;margin-bottom:0.25rem !important}

.m-guts-m-v-half{margin-top:0.5rem !important;margin-bottom:0.5rem !important}

.m-guts-m-v-half--negative{margin-top:-0.5rem !important;margin-bottom:-0.5rem !important}

.m-guts-m-v-3-quart{margin-top:0.75rem !important;margin-bottom:0.75rem !important}

.m-guts-m-v-1-half{margin-top:1.5rem !important;margin-bottom:1.5rem !important}

.m-guts-m-v-2-half{margin-top:2.5rem !important;margin-bottom:2.5rem !important}

.m-guts-m-v-3-half{margin-top:3.5rem !important;margin-bottom:3.5rem !important}

.m-guts-m-v-0{margin-top:0rem !important;margin-bottom:0rem !important}

.m-guts-m-v-1{margin-top:1rem !important;margin-bottom:1rem !important}

.m-guts-m-v-2{margin-top:2rem !important;margin-bottom:2rem !important}

.m-guts-m-v-3{margin-top:3rem !important;margin-bottom:3rem !important}

.m-guts-m-v-4{margin-top:4rem !important;margin-bottom:4rem !important}

.m-guts-m-v-5{margin-top:5rem !important;margin-bottom:5rem !important}

.m-guts-m-v-6{margin-top:6rem !important;margin-bottom:6rem !important}

.m-guts-m-v-7{margin-top:7rem !important;margin-bottom:7rem !important}

.m-guts-m-v-8{margin-top:8rem !important;margin-bottom:8rem !important}

.m-guts-m-v-9{margin-top:9rem !important;margin-bottom:9rem !important}

.m-guts-m-v-10{margin-top:10rem !important;margin-bottom:10rem !important}

.m-guts-m-v-11{margin-top:11rem !important;margin-bottom:11rem !important}

.m-guts-m-v-12{margin-top:12rem !important;margin-bottom:12rem !important}

.m-guts-m-v-13{margin-top:13rem !important;margin-bottom:13rem !important}

.m-guts-m-v-14{margin-top:14rem !important;margin-bottom:14rem !important}

.m-guts-m-v-15{margin-top:15rem !important;margin-bottom:15rem !important}

.m-guts-m-v-0--negative{margin-top:0rem !important;margin-bottom:0rem !important}

.m-guts-m-v-1--negative{margin-top:-1rem !important;margin-bottom:-1rem !important}

.m-guts-m-v-2--negative{margin-top:-2rem !important;margin-bottom:-2rem !important}

.m-guts-m-v-3--negative{margin-top:-3rem !important;margin-bottom:-3rem !important}

.m-guts-m-v-4--negative{margin-top:-4rem !important;margin-bottom:-4rem !important}

.m-guts-m-v-5--negative{margin-top:-5rem !important;margin-bottom:-5rem !important}

.m-guts-m-v-6--negative{margin-top:-6rem !important;margin-bottom:-6rem !important}

.m-guts-m-v-7--negative{margin-top:-7rem !important;margin-bottom:-7rem !important}

.m-guts-m-v-8--negative{margin-top:-8rem !important;margin-bottom:-8rem !important}

.m-guts-m-v-9--negative{margin-top:-9rem !important;margin-bottom:-9rem !important}

.m-guts-m-v-10--negative{margin-top:-10rem !important;margin-bottom:-10rem !important}

.m-guts-m-v-11--negative{margin-top:-11rem !important;margin-bottom:-11rem !important}

.m-guts-m-v-12--negative{margin-top:-12rem !important;margin-bottom:-12rem !important}

.m-guts-m-v-13--negative{margin-top:-13rem !important;margin-bottom:-13rem !important}

.m-guts-m-v-14--negative{margin-top:-14rem !important;margin-bottom:-14rem !important}

.m-guts-m-v-15--negative{margin-top:-15rem !important;margin-bottom:-15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.guts-m-h-auto{margin-right:auto !important;margin-left:auto !important}

.guts-m-h-quart{margin-right:0.25rem !important;margin-left:0.25rem !important}

.guts-m-h-half{margin-right:0.5rem !important;margin-left:0.5rem !important}

.guts-m-h-3-quart{margin-right:0.75rem !important;margin-left:0.75rem !important}

.guts-m-h-1-half{margin-right:1.5rem !important;margin-left:1.5rem !important}

.guts-m-h-1-quart{margin-right:1.25rem !important;margin-left:1.25rem !important}

.guts-m-h-2-half{margin-right:2.5rem !important;margin-left:2.5rem !important}

.guts-m-h-3-half{margin-right:3.5rem !important;margin-left:3.5rem !important}

.guts-m-h-0{margin-right:0rem !important;margin-left:0rem !important}

.guts-m-h-1{margin-right:1rem !important;margin-left:1rem !important}

.guts-m-h-2{margin-right:2rem !important;margin-left:2rem !important}

.guts-m-h-3{margin-right:3rem !important;margin-left:3rem !important}

.guts-m-h-4{margin-right:4rem !important;margin-left:4rem !important}

.guts-m-h-5{margin-right:5rem !important;margin-left:5rem !important}

.guts-m-h-6{margin-right:6rem !important;margin-left:6rem !important}

.guts-m-h-7{margin-right:7rem !important;margin-left:7rem !important}

.guts-m-h-8{margin-right:8rem !important;margin-left:8rem !important}

.guts-m-h-9{margin-right:9rem !important;margin-left:9rem !important}

.guts-m-h-10{margin-right:10rem !important;margin-left:10rem !important}

.guts-m-h-11{margin-right:11rem !important;margin-left:11rem !important}

.guts-m-h-12{margin-right:12rem !important;margin-left:12rem !important}

.guts-m-h-13{margin-right:13rem !important;margin-left:13rem !important}

.guts-m-h-14{margin-right:14rem !important;margin-left:14rem !important}

.guts-m-h-15{margin-right:15rem !important;margin-left:15rem !important}

.guts-m-h-0--negative{margin-right:0rem !important;margin-left:0rem !important}

.guts-m-h-1--negative{margin-right:-1rem !important;margin-left:-1rem !important}

.guts-m-h-2--negative{margin-right:-2rem !important;margin-left:-2rem !important}

.guts-m-h-3--negative{margin-right:-3rem !important;margin-left:-3rem !important}

.guts-m-h-4--negative{margin-right:-4rem !important;margin-left:-4rem !important}

.guts-m-h-5--negative{margin-right:-5rem !important;margin-left:-5rem !important}

.guts-m-h-6--negative{margin-right:-6rem !important;margin-left:-6rem !important}

.guts-m-h-7--negative{margin-right:-7rem !important;margin-left:-7rem !important}

.guts-m-h-8--negative{margin-right:-8rem !important;margin-left:-8rem !important}

.guts-m-h-9--negative{margin-right:-9rem !important;margin-left:-9rem !important}

.guts-m-h-10--negative{margin-right:-10rem !important;margin-left:-10rem !important}

.guts-m-h-11--negative{margin-right:-11rem !important;margin-left:-11rem !important}

.guts-m-h-12--negative{margin-right:-12rem !important;margin-left:-12rem !important}

.guts-m-h-13--negative{margin-right:-13rem !important;margin-left:-13rem !important}

.guts-m-h-14--negative{margin-right:-14rem !important;margin-left:-14rem !important}

.guts-m-h-15--negative{margin-right:-15rem !important;margin-left:-15rem !important}

@media(max-width: 768px){
.m-guts-m-h-auto{margin-right:auto !important;margin-left:auto !important}

.m-guts-m-h-quart{margin-right:0.25rem !important;margin-left:0.25rem !important}

.m-guts-m-h-half{margin-right:0.5rem !important;margin-left:0.5rem !important}

.m-guts-m-h-half--negative{margin-right:-0.5rem !important;margin-left:-0.5rem !important}

.m-guts-m-h-3-quart{margin-right:0.75rem !important;margin-left:0.75rem !important}

.m-guts-m-h-1-half{margin-right:1.5rem !important;margin-left:1.5rem !important}

.m-guts-m-h-2-half{margin-right:2.5rem !important;margin-left:2.5rem !important}

.m-guts-m-h-3-half{margin-right:3.5rem !important;margin-left:3.5rem !important}

.m-guts-m-h-0{margin-right:0rem !important;margin-left:0rem !important}

.m-guts-m-h-1{margin-right:1rem !important;margin-left:1rem !important}

.m-guts-m-h-2{margin-right:2rem !important;margin-left:2rem !important}

.m-guts-m-h-3{margin-right:3rem !important;margin-left:3rem !important}

.m-guts-m-h-4{margin-right:4rem !important;margin-left:4rem !important}

.m-guts-m-h-5{margin-right:5rem !important;margin-left:5rem !important}

.m-guts-m-h-6{margin-right:6rem !important;margin-left:6rem !important}

.m-guts-m-h-7{margin-right:7rem !important;margin-left:7rem !important}

.m-guts-m-h-8{margin-right:8rem !important;margin-left:8rem !important}

.m-guts-m-h-9{margin-right:9rem !important;margin-left:9rem !important}

.m-guts-m-h-10{margin-right:10rem !important;margin-left:10rem !important}

.m-guts-m-h-11{margin-right:11rem !important;margin-left:11rem !important}

.m-guts-m-h-12{margin-right:12rem !important;margin-left:12rem !important}

.m-guts-m-h-13{margin-right:13rem !important;margin-left:13rem !important}

.m-guts-m-h-14{margin-right:14rem !important;margin-left:14rem !important}

.m-guts-m-h-15{margin-right:15rem !important;margin-left:15rem !important}

.m-guts-m-h-0--negative{margin-right:0rem !important;margin-left:0rem !important}

.m-guts-m-h-1--negative{margin-right:-1rem !important;margin-left:-1rem !important}

.m-guts-m-h-2--negative{margin-right:-2rem !important;margin-left:-2rem !important}

.m-guts-m-h-3--negative{margin-right:-3rem !important;margin-left:-3rem !important}

.m-guts-m-h-4--negative{margin-right:-4rem !important;margin-left:-4rem !important}

.m-guts-m-h-5--negative{margin-right:-5rem !important;margin-left:-5rem !important}

.m-guts-m-h-6--negative{margin-right:-6rem !important;margin-left:-6rem !important}

.m-guts-m-h-7--negative{margin-right:-7rem !important;margin-left:-7rem !important}

.m-guts-m-h-8--negative{margin-right:-8rem !important;margin-left:-8rem !important}

.m-guts-m-h-9--negative{margin-right:-9rem !important;margin-left:-9rem !important}

.m-guts-m-h-10--negative{margin-right:-10rem !important;margin-left:-10rem !important}

.m-guts-m-h-11--negative{margin-right:-11rem !important;margin-left:-11rem !important}

.m-guts-m-h-12--negative{margin-right:-12rem !important;margin-left:-12rem !important}

.m-guts-m-h-13--negative{margin-right:-13rem !important;margin-left:-13rem !important}

.m-guts-m-h-14--negative{margin-right:-14rem !important;margin-left:-14rem !important}

.m-guts-m-h-15--negative{margin-right:-15rem !important;margin-left:-15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}

.guts-m-full-auto{margin:auto !important}

.guts-m-full-quart{margin:0.25rem !important}

.guts-m-full-half{margin:0.5rem !important}

.guts-m-full-3-quart{margin:0.75rem !important}

.guts-m-full-1-half{margin:1.5rem !important}

.guts-m-full-1-quart{margin:1.25rem !important}

.guts-m-full-2-half{margin:2.5rem !important}

.guts-m-full-3-half{margin:3.5rem !important}

.guts-m-full-0{margin:0rem !important}

.guts-m-full-1{margin:1rem !important}

.guts-m-full-2{margin:2rem !important}

.guts-m-full-3{margin:3rem !important}

.guts-m-full-4{margin:4rem !important}

.guts-m-full-5{margin:5rem !important}

.guts-m-full-6{margin:6rem !important}

.guts-m-full-7{margin:7rem !important}

.guts-m-full-8{margin:8rem !important}

.guts-m-full-9{margin:9rem !important}

.guts-m-full-10{margin:10rem !important}

.guts-m-full-11{margin:11rem !important}

.guts-m-full-12{margin:12rem !important}

.guts-m-full-13{margin:13rem !important}

.guts-m-full-14{margin:14rem !important}

.guts-m-full-15{margin:15rem !important}

.guts-m-full-0--negative{margin:0rem !important}

.guts-m-full-1--negative{margin:-1rem !important}

.guts-m-full-2--negative{margin:-2rem !important}

.guts-m-full-3--negative{margin:-3rem !important}

.guts-m-full-4--negative{margin:-4rem !important}

.guts-m-full-5--negative{margin:-5rem !important}

.guts-m-full-6--negative{margin:-6rem !important}

.guts-m-full-7--negative{margin:-7rem !important}

.guts-m-full-8--negative{margin:-8rem !important}

.guts-m-full-9--negative{margin:-9rem !important}

.guts-m-full-10--negative{margin:-10rem !important}

.guts-m-full-11--negative{margin:-11rem !important}

.guts-m-full-12--negative{margin:-12rem !important}

.guts-m-full-13--negative{margin:-13rem !important}

.guts-m-full-14--negative{margin:-14rem !important}

.guts-m-full-15--negative{margin:-15rem !important}

@media(max-width: 768px){
.m-guts-m-full-auto{margin:auto !important}

.m-guts-m-full-quart{margin:0.25rem !important}

.m-guts-m-full-half{margin:0.5rem !important}

.m-guts-m-full-half--negative{margin:-0.5rem !important}

.m-guts-m-full-3-quart{margin:0.75rem !important}

.m-guts-m-full-1-half{margin:1.5rem !important}

.m-guts-m-full-2-half{margin:2.5rem !important}

.m-guts-m-full-3-half{margin:3.5rem !important}

.m-guts-m-full-0{margin:0rem !important}

.m-guts-m-full-1{margin:1rem !important}

.m-guts-m-full-2{margin:2rem !important}

.m-guts-m-full-3{margin:3rem !important}

.m-guts-m-full-4{margin:4rem !important}

.m-guts-m-full-5{margin:5rem !important}

.m-guts-m-full-6{margin:6rem !important}

.m-guts-m-full-7{margin:7rem !important}

.m-guts-m-full-8{margin:8rem !important}

.m-guts-m-full-9{margin:9rem !important}

.m-guts-m-full-10{margin:10rem !important}

.m-guts-m-full-11{margin:11rem !important}

.m-guts-m-full-12{margin:12rem !important}

.m-guts-m-full-13{margin:13rem !important}

.m-guts-m-full-14{margin:14rem !important}

.m-guts-m-full-15{margin:15rem !important}

.m-guts-m-full-0--negative{margin:0rem !important}

.m-guts-m-full-1--negative{margin:-1rem !important}

.m-guts-m-full-2--negative{margin:-2rem !important}

.m-guts-m-full-3--negative{margin:-3rem !important}

.m-guts-m-full-4--negative{margin:-4rem !important}

.m-guts-m-full-5--negative{margin:-5rem !important}

.m-guts-m-full-6--negative{margin:-6rem !important}

.m-guts-m-full-7--negative{margin:-7rem !important}

.m-guts-m-full-8--negative{margin:-8rem !important}

.m-guts-m-full-9--negative{margin:-9rem !important}

.m-guts-m-full-10--negative{margin:-10rem !important}

.m-guts-m-full-11--negative{margin:-11rem !important}

.m-guts-m-full-12--negative{margin:-12rem !important}

.m-guts-m-full-13--negative{margin:-13rem !important}

.m-guts-m-full-14--negative{margin:-14rem !important}

.m-guts-m-full-15--negative{margin:-15rem !important}

.m-no-guts{padding:0 !important;margin:0 !important}}/*! Reflex v1.0.9 - https://github.com/leejordan/reflex */*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}

*:before,*:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}

.grid{display:inline-block;display:-ms-flexbox;display:-webkit-flex;display:flex;zoom:1;-ms-flex-wrap:wrap;-webkit-flex-wrap:wrap;flex-wrap:wrap;padding:0;margin:0;position:relative;width:99.99999%;max-width:96rem;letter-spacing:-0.31em !important;word-spacing:-0.43em !important;list-style-type:none}

.grid:before,.grid:after{letter-spacing:normal;word-spacing:normal;white-space:normal;max-width:100%}

.grid *:before,.grid *:after{letter-spacing:normal;word-spacing:normal;white-space:normal}

.grid .grid{-ms-flex:1 1 auto;-webkit-flex:1 1 auto;flex:1 1 auto}

[class*=grid__col-]{display:inline-block;display:-ms-flexbox;display:-webkit-flex;display:flex;zoom:1;-ms-flex-direction:column;-webkit-flex-direction:column;flex-direction:column;letter-spacing:normal;word-spacing:normal;white-space:normal;position:relative;width:100%;vertical-align:top;padding:1.5rem}

.grid__cell{position:relative;display:block;-ms-flex:1 1 auto;-webkit-flex:1 1 auto;flex:1 1 auto}

_:-ms-fullscreen,:root .grid__cell{width:100%}

.grid__col-12{width:100%}

.grid__col-11{width:91.6666666667%}

.grid__col-10{width:83.3333333333%}

.grid__col-9{width:75%}

.grid__col-8{width:66.6666666667%}

.grid__col-7{width:58.3333333333%}

.grid__col-6{width:50%}

.grid__col-5{width:41.6666666667%}

.grid__col-4{width:33.3333333333%}

.grid__col-3{width:25%}

.grid__col-2{width:16.6666666667%}

.grid__col-1{width:8.3333333333%}

@media(min-width: 481px){
.grid__col-xs-12{width:100%}

.grid__col-xs-11{width:91.6666666667%}

.grid__col-xs-10{width:83.3333333333%}

.grid__col-xs-9{width:75%}

.grid__col-xs-8{width:66.6666666667%}

.grid__col-xs-7{width:58.3333333333%}

.grid__col-xs-6{width:50%}

.grid__col-xs-5{width:41.6666666667%}

.grid__col-xs-4{width:33.3333333333%}

.grid__col-xs-3{width:25%}

.grid__col-xs-2{width:16.6666666667%}

.grid__col-xs-1{width:8.3333333333%}}

@media(min-width: 769px){
.grid__col-sm-12{width:100%}

.grid__col-sm-11{width:91.6666666667%}

.grid__col-sm-10{width:83.3333333333%}

.grid__col-sm-9{width:75%}

.grid__col-sm-8{width:66.6666666667%}

.grid__col-sm-7{width:58.3333333333%}

.grid__col-sm-6{width:50%}

.grid__col-sm-5{width:41.6666666667%}

.grid__col-sm-4{width:33.3333333333%}

.grid__col-sm-3{width:25%}

.grid__col-sm-2{width:16.6666666667%}

.grid__col-sm-1{width:8.3333333333%}}

@media(min-width: 992px){
.grid__col-md-12{width:100%}

.grid__col-md-11{width:91.6666666667%}

.grid__col-md-10{width:83.3333333333%}

.grid__col-md-9{width:75%}

.grid__col-md-8{width:66.6666666667%}

.grid__col-md-7{width:58.3333333333%}

.grid__col-md-6{width:50%}

.grid__col-md-5{width:41.6666666667%}

.grid__col-md-4{width:33.3333333333%}

.grid__col-md-3{width:25%}

.grid__col-md-2{width:16.6666666667%}

.grid__col-md-1{width:8.3333333333%}}

@media(min-width: 1025px){
.grid__col-lg-12{width:100%}

.grid__col-lg-11{width:91.6666666667%}

.grid__col-lg-10{width:83.3333333333%}

.grid__col-lg-9{width:75%}

.grid__col-lg-8{width:66.6666666667%}

.grid__col-lg-7{width:58.3333333333%}

.grid__col-lg-6{width:50%}

.grid__col-lg-5{width:41.6666666667%}

.grid__col-lg-4{width:33.3333333333%}

.grid__col-lg-3{width:25%}

.grid__col-lg-2{width:16.6666666667%}

.grid__col-lg-1{width:8.3333333333%}}

.grid__col-auto{-ms-flex:1 0 0px;-webkit-flex:1 0 0px;flex:1 0 0px;width:auto !important;max-width:100%}

@media(min-width: 481px){
.grid__col-xs-auto{-ms-flex:1 0 0px;-webkit-flex:1 0 0px;flex:1 0 0px;width:auto !important;max-width:100%}}

@media(min-width: 769px){
.grid__col-sm-auto{-ms-flex:1 0 0px;-webkit-flex:1 0 0px;flex:1 0 0px;width:auto !important;max-width:100%}}

@media(min-width: 992px){
.grid__col-md-auto{-ms-flex:1 0 0px;-webkit-flex:1 0 0px;flex:1 0 0px;width:auto !important;max-width:100%}}

@media(min-width: 1025px){
.grid__col-lg-auto{-ms-flex:1 0 0px;-webkit-flex:1 0 0px;flex:1 0 0px;width:auto !important;max-width:100%}}

.grid--order-12{order:12}

.grid--order-11{order:11}

.grid--order-10{order:10}

.grid--order-9{order:9}

.grid--order-8{order:8}

.grid--order-7{order:7}

.grid--order-6{order:6}

.grid--order-5{order:5}

.grid--order-4{order:4}

.grid--order-3{order:3}

.grid--order-2{order:2}

.grid--order-1{order:1}

.grid--order-0{order:0}

.grid{margin:0 auto}

@media(max-width: 769px){
.grid--isMobile-max-width{max-width:480px}

[class*=grid__col-]{padding:1rem}}

.grid--bleed [class*=grid__col-]{padding:0}

.grid--wrap{-ms-flex-wrap:wrap;-webkit-flex-wrap:wrap;flex-wrap:wrap}

.grid--no-wrap{-ms-flex-wrap:nowrap;-webkit-flex-wrap:nowrap;flex-wrap:nowrap}

.grid--wrap-reverse{-ms-flex-wrap:wrap-reverse;-webkit-flex-wrap:wrap-reverse;flex-wrap:wrap-reverse}

.grid--direction-row{-ms-flex-direction:row;-webkit-flex-direction:row;flex-direction:row}

.grid--direction-row-reverse{-ms-flex-direction:row-reverse;-webkit-flex-direction:row-reverse;flex-direction:row-reverse}

.grid--direction-column{-ms-flex-direction:column;-webkit-flex-direction:column;flex-direction:column}

.grid--direction-column-reverse{-ms-flex-direction:column-reverse;-webkit-flex-direction:column-reverse;flex-direction:column-reverse}

.grid--align-start{-ms-flex-align:flex-start;-webkit-align-items:flex-start;align-items:flex-start}

.grid--align-end{-ms-flex-align:flex-end;-webkit-align-items:flex-end;align-items:flex-end}

.grid--align-end [class*=grid__col-]{vertical-align:bottom}

.grid--align-center{-ms-flex-align:center;-webkit-align-items:center;align-items:center}

.grid--align-center [class*=grid__col-]{vertical-align:middle}

.grid--align-baseline{-ms-flex-align:baseline;-webkit-align-items:baseline;align-items:baseline}

.grid--align-baseline [class*=grid__col-]{vertical-align:baseline}

.grid--align-content-start{-ms-flex-line-pack:flex-start;-webkit-align-content:flex-start;align-content:flex-start}

.grid--align-content-end{-ms-flex-line-pack:flex-end;-webkit-align-content:flex-end;align-content:flex-end}

.grid--align-content-end [class*=grid__col-]{vertical-align:bottom}

.grid--align-content-center{-ms-flex-line-pack:center;-webkit-align-content:center;align-content:center}

.grid--align-content-space-between{-ms-flex-line-pack:space-between;-webkit-align-content:space-between;align-content:space-between}

.grid--align-content-space-around{-ms-flex-line-pack:space-around;-webkit-align-content:space-around;align-content:space-around}

.grid--align-self-stretch{-ms-flex-item-align:stretch;-webkit-align-self:stretch;align-self:stretch}

.grid--align-self-start{-ms-flex-item-align:flex-start;-webkit-align-self:flex-start;align-self:flex-start}

.grid--align-self-end{-ms-flex-item-align:flex-end;-webkit-align-self:flex-end;align-self:flex-end;vertical-align:bottom}

.grid--align-self-center{-ms-flex-item-align:center;-webkit-align-self:center;align-self:center;vertical-align:middle}

.grid--align-self-baseline{-ms-flex-item-align:baseline;-webkit-align-self:baseline;align-self:baseline;vertical-align:baseline}

.grid--justify-start{-ms-flex-pack:start;text-align-last:start;-webkit-justify-content:flex-start;text-align-last:start;justify-content:flex-start}

[dir="ltr"] .grid--justify-start{text-align:left}

[dir="rtl"] .grid--justify-start{text-align:right}

.grid--justify-end{-ms-flex-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end}

[dir="ltr"] .grid--justify-end{text-align:right;-moz-text-align-last:right;text-align-last:right}

[dir="rtl"] .grid--justify-end{text-align:left;-moz-text-align-last:left;text-align-last:left}

.grid--justify-end .grid__cell,.grid--justify-end [class*=grid__col-]{text-align:left;text-align:start;text-align:initial;-moz-text-align-last:left;-moz-text-align-last:start;-moz-text-align-last:initial;text-align-last:left;text-align-last:start;text-align-last:initial}

.grid--justify-center{text-align:center;-moz-text-align-last:center;text-align-last:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center}

.grid--justify-center .grid__cell,.grid--justify-center [class*=grid__col-]{text-align:left;text-align:start;text-align:initial;-moz-text-align-last:left;-moz-text-align-last:start;-moz-text-align-last:initial;text-align-last:left;text-align-last:start;text-align-last:initial}

.grid--justify-space-between{text-align:justify;-moz-text-align-last:justify;text-align-last:justify;-ms-flex-pack:justify;-webkit-justify-content:space-between;justify-content:space-between}

.grid--justify-space-between .grid__cell,.grid--justify-space-between [class*=grid__col-]{text-align:left;text-align:start;text-align:initial;-moz-text-align-last:left;-moz-text-align-last:start;-moz-text-align-last:initial;text-align-last:left;text-align-last:start;text-align-last:initial}

.grid--justify-space-around{text-align:justify;-moz-text-align-last:justify;text-align-last:justify;-ms-flex-pack:justify;-webkit-justify-content:space-around;justify-content:space-around}

.grid--justify-space-around .grid__cell,.grid--justify-space-around [class*=grid__col-]{text-align:left;text-align:start;text-align:initial;-moz-text-align-last:left;-moz-text-align-last:start;-moz-text-align-last:initial;text-align-last:left;text-align-last:start;text-align-last:initial}

.grid__col--bleed{padding:0}

.grid__cell--padding-sm{padding:.75rem}

.grid__cell--padding-md{padding:1.5rem}

.grid__cell--padding-lg{padding:3rem}

.grid__cell-img{display:block;display:-ms-flexbox;display:-webkit-flex;display:flex;-ms-flex:0 0 auto;-webkit-flex:0 0 auto;flex:0 0 auto;margin-left:0;margin-right:0;max-width:100%;width:100%;height:auto}

.grid__cell-footer{display:inline-block;display:-ms-flexbox;display:-webkit-flex;display:flex;zoom:1;width:100%;margin-top:auto}

html{font-size:12px}

html,body{height:100%}

html.no-scroll,body.no-scroll{overflow:hidden}

.l_max-screen{padding-top:5.5rem;width:100%;height:100%;background-color:#fff}

@media print{
.l_max-screen{padding-top:0}}

.l_max-screen--no-header{padding-top:0}

.l_header{height:5.5rem;position:fixed;left:0;right:0;top:0;display:block}

@media print{
.l_header{display:none}}

.l_body-wrap{position:relative;background-color:#fff}

.account-bg .l_body-wrap{background-color:rgba(0,0,0,0)}

.l_content{position:relative;background-color:#fff;width:100%;margin:0 auto;padding:0}

.l_content .scrollable{-webkit-transform:translate3d(0, 0, 0);-webkit-overflow-scrolling:touch}

[dir="ltr"] .mdzr-no-hiddenscroll .modal-open{margin-right:17px}

[dir="rtl"] .mdzr-no-hiddenscroll .modal-open{margin-left:17px}

[dir="ltr"] .mdzr-no-hiddenscroll .modal-open .l_header .utility-bar{margin-right:17px}

[dir="rtl"] .mdzr-no-hiddenscroll .modal-open .l_header .utility-bar{margin-left:17px}

@media(max-width: 768px){
.l_content{transition:margin .3s ease-in}}

.l_body-wrap{z-index:0}

.l_content{z-index:1}

.l_feedback{z-index:1}

.l_header{z-index:6}

@media(max-width: 1024px){
.l_w95,.l_w90,.l_w85,.l_w80,.l_w75,.l_w70,.l_w65,.l_w60,.l_w55,.l_w50,.l_w45,.l_w40,.l_w35,.l_w30,.l_w25,.l_w20,.l_w15,.l_w10,.l_w5{width:auto !important}}

.l_w5{width:5%}

@media(max-width: 1024px){
.m-l_w5{width:5% !important}}

.l_w10{width:10%}

@media(max-width: 1024px){
.m-l_w10{width:10% !important}}

.l_w15{width:15%}

@media(max-width: 1024px){
.m-l_w15{width:15% !important}}

.l_w20{width:20%}

@media(max-width: 1024px){
.m-l_w20{width:20% !important}}

.l_w25{width:25%}

@media(max-width: 1024px){
.m-l_w25{width:25% !important}}

.l_w30{width:30%}

@media(max-width: 1024px){
.m-l_w30{width:30% !important}}

.l_w35{width:35%}

@media(max-width: 1024px){
.m-l_w35{width:35% !important}}

.l_w40{width:40%}

@media(max-width: 1024px){
.m-l_w40{width:40% !important}}

.l_w45{width:45%}

@media(max-width: 1024px){
.m-l_w45{width:45% !important}}

.l_w50{width:50%}

@media(max-width: 1024px){
.m-l_w50{width:50% !important}}

.l_w55{width:55%}

@media(max-width: 1024px){
.m-l_w55{width:55% !important}}

.l_w60{width:60%}

@media(max-width: 1024px){
.m-l_w60{width:60% !important}}

.l_w65{width:65%}

@media(max-width: 1024px){
.m-l_w65{width:65% !important}}

.l_w70{width:70%}

@media(max-width: 1024px){
.m-l_w70{width:70% !important}}

.l_w75{width:75%}

@media(max-width: 1024px){
.m-l_w75{width:75% !important}}

.l_w80{width:80%}

@media(max-width: 1024px){
.m-l_w80{width:80% !important}}

.l_w85{width:85%}

@media(max-width: 1024px){
.m-l_w85{width:85% !important}}

.l_w90{width:90%}

@media(max-width: 1024px){
.m-l_w90{width:90% !important}}

.l_w95{width:95%}

@media(max-width: 1024px){
.m-l_w95{width:95% !important}}

.l_w-min{width:1%}

.l_flex{display:flex}

@media(max-width: 768px){
.l_flex{display:block}

.l_flex.m-l_flex{display:flex}}

.l_flex.l_flex-wrap{flex-wrap:wrap;flex-direction:row}

.l_flex.l_flex-wrap-reverse{flex-wrap:wrap-reverse}

@media(max-width: 768px){
.l_flex.l_flex-wrap{display:flex}}

.l_flex.l_flex-middle{align-items:center}

.l_flex__ellipsis-wrapper{min-width:0}

.l_flex--align-right{display:flex;flex-flow:row-reverse}

[dir="ltr"] .l_flex--align-right{margin-left:auto}

[dir="rtl"] .l_flex--align-right{margin-right:auto}

@media(max-width: 768px){
.l_flex--align-right--no-mobile{flex-flow:row}

[dir="ltr"] .l_flex--align-right--no-mobile{margin-left:0}

[dir="rtl"] .l_flex--align-right--no-mobile{margin-right:0}}

.l_flex--inline{display:inline-flex}

.l_flex-col-1{order:1}

.l_flex-col-2{order:2}

.l_flex-col-3{order:3}

.l_flex-col-4{order:4}

@media(max-width: 1024px){
.t-l_flex-col-1{order:1}

.t-l_flex-col-2{order:2}

.t-l_flex-col-3{order:3}

.t-l_flex-col-4{order:4}}

@media(max-width: 768px){
.m-l_flex-col-1{order:1}

.m-l_flex-col-2{order:2}

.m-l_flex-col-3{order:3}

.m-l_flex-col-4{order:4}}

.l_flex-shrink{flex-shrink:0}

.l_flex-grow{flex-grow:1;-ms-flex:1 1 0%}

.l_flex-grow-vertical{flex-grow:1}

.l_flex-one{flex:1 1 0%}

.l_flex-full{flex:0 0 100%}

.l_flex-column{flex-direction:column}

.l_flex-row{flex-direction:row}

.l_flex-center-children{align-items:center}

.l_flex-center{align-self:center}

.l_flex-bottom{align-self:flex-end}

.l_flex-bottom-children{align-items:flex-end}

.l_flex-top-children{align-items:flex-start}

.l_flex-top{align-self:flex-start}

.l_flex-stretch-children{align-items:stretch}

.l_flex-justify{justify-content:space-between}

.l_flex-justify-right{justify-content:flex-end}

.l_flex-justify-left{justify-content:flex-start}

.l_flex-justify-center{justify-content:center}

@media(max-width: 768px){
.m-l_flex-justify-right{justify-content:flex-end}

.m-l_flex-justify-left{justify-content:flex-start}

.m-l_flex-justify-center{justify-content:center}

.m_l-flex-wrap{flex-direction:row;flex-wrap:wrap}

.m-l_flex-wrap-reverse{flex-wrap:wrap-reverse}

.m-l_flex-shrink{flex-shrink:0}

.m-l_flex-grow{flex-grow:1;-ms-flex:1 1 0%}

.m-l_flex-grow-vertical{flex-grow:1}}

.l_flexbar{display:flex;align-items:center}

@media(max-width: 768px){
.l_flexbar.m-l_flexbar-col{flex-direction:column}

.l_flexbar.m-l_flexbar-block{display:block}}

.l_flex-none{flex:none !important}

@media(max-width: 768px){
.drawer{width:100%;margin-top:5.5rem}

.drawer-right{position:fixed;padding-bottom:0;top:0;bottom:0;height:calc(100vh - 5.5rem);overflow-y:auto;z-index:2}

[dir="ltr"] .drawer-right{right:-100%;transition:right .3s ease-in}

[dir="rtl"] .drawer-right{left:-100%;transition:left .3s ease-in}

.drawer-right~.l_main-panel{z-index:0}

[dir="ltr"] .drawer-isopen-right{margin-left:-100%}

[dir="rtl"] .drawer-isopen-right{margin-right:-100%}

[dir="ltr"] .drawer-isopen-right .drawer-right{right:0}

[dir="rtl"] .drawer-isopen-right .drawer-right{left:0}

.l_header.l_el-header .action-items{top:.5em}

[dir="ltr"] .l_header.l_el-header .action-items{right:.5em}

[dir="rtl"] .l_header.l_el-header .action-items{left:.5em}}

.scrollX{overflow:scroll}

.twocol{column-count:2}

.threecol{column-count:3;gap:halfrem !important}

.fourcol{column-count:4;gap:halfrem !important}

.gap-v-auto{row-gap:normalrem !important}

.gap-v-quart{row-gap:0.25rem !important}

.gap-v-half{row-gap:0.5rem !important}

.gap-v-3-quart{row-gap:0.75rem !important}

.gap-v-1-half{row-gap:1.5rem !important}

.gap-v-1-quart{row-gap:1.25rem !important}

.gap-v-2-half{row-gap:2.5rem !important}

.gap-v-3-half{row-gap:3.5rem !important}

.gap-v-0{row-gap:0rem !important}

.gap-v-1{row-gap:1rem !important}

.gap-v-2{row-gap:2rem !important}

.gap-v-3{row-gap:3rem !important}

.gap-v-4{row-gap:4rem !important}

.gap-v-5{row-gap:5rem !important}

.gap-v-6{row-gap:6rem !important}

.gap-v-7{row-gap:7rem !important}

.gap-v-8{row-gap:8rem !important}

.gap-v-9{row-gap:9rem !important}

.gap-v-10{row-gap:10rem !important}

.gap-v-11{row-gap:11rem !important}

.gap-v-12{row-gap:12rem !important}

.gap-v-13{row-gap:13rem !important}

.gap-v-14{row-gap:14rem !important}

.gap-v-15{row-gap:15rem !important}

.gap-v-0--negative{row-gap:0rem !important}

.gap-v-1--negative{row-gap:-1rem !important}

.gap-v-2--negative{row-gap:-2rem !important}

.gap-v-3--negative{row-gap:-3rem !important}

.gap-v-4--negative{row-gap:-4rem !important}

.gap-v-5--negative{row-gap:-5rem !important}

.gap-v-6--negative{row-gap:-6rem !important}

.gap-v-7--negative{row-gap:-7rem !important}

.gap-v-8--negative{row-gap:-8rem !important}

.gap-v-9--negative{row-gap:-9rem !important}

.gap-v-10--negative{row-gap:-10rem !important}

.gap-v-11--negative{row-gap:-11rem !important}

.gap-v-12--negative{row-gap:-12rem !important}

.gap-v-13--negative{row-gap:-13rem !important}

.gap-v-14--negative{row-gap:-14rem !important}

.gap-v-15--negative{row-gap:-15rem !important}

@media(max-width: 768px){
.m-gap-v-auto{row-gap:auto !important}

.m-gap-v-quart{row-gap:0.25rem !important}

.m-gap-v-half{row-gap:0.5rem !important}

.m-gap-v-half--negative{row-gap:-0.5rem !important}

.m-gap-v-3-quart{row-gap:0.75rem !important}

.m-gap-v-1-half{row-gap:1.5rem !important}

.m-gap-v-2-half{row-gap:2.5rem !important}

.m-gap-v-3-half{row-gap:3.5rem !important}

.m-gap-v-0{row-gap:0rem !important}

.m-gap-v-1{row-gap:1rem !important}

.m-gap-v-2{row-gap:2rem !important}

.m-gap-v-3{row-gap:3rem !important}

.m-gap-v-4{row-gap:4rem !important}

.m-gap-v-5{row-gap:5rem !important}

.m-gap-v-6{row-gap:6rem !important}

.m-gap-v-7{row-gap:7rem !important}

.m-gap-v-8{row-gap:8rem !important}

.m-gap-v-9{row-gap:9rem !important}

.m-gap-v-10{row-gap:10rem !important}

.m-gap-v-11{row-gap:11rem !important}

.m-gap-v-12{row-gap:12rem !important}

.m-gap-v-13{row-gap:13rem !important}

.m-gap-v-14{row-gap:14rem !important}

.m-gap-v-15{row-gap:15rem !important}

.m-gap-v-0--negative{row-gap:0rem !important}

.m-gap-v-1--negative{row-gap:-1rem !important}

.m-gap-v-2--negative{row-gap:-2rem !important}

.m-gap-v-3--negative{row-gap:-3rem !important}

.m-gap-v-4--negative{row-gap:-4rem !important}

.m-gap-v-5--negative{row-gap:-5rem !important}

.m-gap-v-6--negative{row-gap:-6rem !important}

.m-gap-v-7--negative{row-gap:-7rem !important}

.m-gap-v-8--negative{row-gap:-8rem !important}

.m-gap-v-9--negative{row-gap:-9rem !important}

.m-gap-v-10--negative{row-gap:-10rem !important}

.m-gap-v-11--negative{row-gap:-11rem !important}

.m-gap-v-12--negative{row-gap:-12rem !important}

.m-gap-v-13--negative{row-gap:-13rem !important}

.m-gap-v-14--negative{row-gap:-14rem !important}

.m-gap-v-15--negative{row-gap:-15rem !important}

.m-no-gap{gap:0}}

.gap-h-auto{column-gap:normalrem !important}

.gap-h-quart{column-gap:0.25rem !important}

.gap-h-half{column-gap:0.5rem !important}

.gap-h-3-quart{column-gap:0.75rem !important}

.gap-h-1-half{column-gap:1.5rem !important}

.gap-h-1-quart{column-gap:1.25rem !important}

.gap-h-2-half{column-gap:2.5rem !important}

.gap-h-3-half{column-gap:3.5rem !important}

.gap-h-0{column-gap:0rem !important}

.gap-h-1{column-gap:1rem !important}

.gap-h-2{column-gap:2rem !important}

.gap-h-3{column-gap:3rem !important}

.gap-h-4{column-gap:4rem !important}

.gap-h-5{column-gap:5rem !important}

.gap-h-6{column-gap:6rem !important}

.gap-h-7{column-gap:7rem !important}

.gap-h-8{column-gap:8rem !important}

.gap-h-9{column-gap:9rem !important}

.gap-h-10{column-gap:10rem !important}

.gap-h-11{column-gap:11rem !important}

.gap-h-12{column-gap:12rem !important}

.gap-h-13{column-gap:13rem !important}

.gap-h-14{column-gap:14rem !important}

.gap-h-15{column-gap:15rem !important}

.gap-h-0--negative{column-gap:0rem !important}

.gap-h-1--negative{column-gap:-1rem !important}

.gap-h-2--negative{column-gap:-2rem !important}

.gap-h-3--negative{column-gap:-3rem !important}

.gap-h-4--negative{column-gap:-4rem !important}

.gap-h-5--negative{column-gap:-5rem !important}

.gap-h-6--negative{column-gap:-6rem !important}

.gap-h-7--negative{column-gap:-7rem !important}

.gap-h-8--negative{column-gap:-8rem !important}

.gap-h-9--negative{column-gap:-9rem !important}

.gap-h-10--negative{column-gap:-10rem !important}

.gap-h-11--negative{column-gap:-11rem !important}

.gap-h-12--negative{column-gap:-12rem !important}

.gap-h-13--negative{column-gap:-13rem !important}

.gap-h-14--negative{column-gap:-14rem !important}

.gap-h-15--negative{column-gap:-15rem !important}

@media(max-width: 768px){
.m-gap-h-auto{column-gap:auto !important}

.m-gap-h-quart{column-gap:0.25rem !important}

.m-gap-h-half{column-gap:0.5rem !important}

.m-gap-h-half--negative{column-gap:-0.5rem !important}

.m-gap-h-3-quart{column-gap:0.75rem !important}

.m-gap-h-1-half{column-gap:1.5rem !important}

.m-gap-h-2-half{column-gap:2.5rem !important}

.m-gap-h-3-half{column-gap:3.5rem !important}

.m-gap-h-0{column-gap:0rem !important}

.m-gap-h-1{column-gap:1rem !important}

.m-gap-h-2{column-gap:2rem !important}

.m-gap-h-3{column-gap:3rem !important}

.m-gap-h-4{column-gap:4rem !important}

.m-gap-h-5{column-gap:5rem !important}

.m-gap-h-6{column-gap:6rem !important}

.m-gap-h-7{column-gap:7rem !important}

.m-gap-h-8{column-gap:8rem !important}

.m-gap-h-9{column-gap:9rem !important}

.m-gap-h-10{column-gap:10rem !important}

.m-gap-h-11{column-gap:11rem !important}

.m-gap-h-12{column-gap:12rem !important}

.m-gap-h-13{column-gap:13rem !important}

.m-gap-h-14{column-gap:14rem !important}

.m-gap-h-15{column-gap:15rem !important}

.m-gap-h-0--negative{column-gap:0rem !important}

.m-gap-h-1--negative{column-gap:-1rem !important}

.m-gap-h-2--negative{column-gap:-2rem !important}

.m-gap-h-3--negative{column-gap:-3rem !important}

.m-gap-h-4--negative{column-gap:-4rem !important}

.m-gap-h-5--negative{column-gap:-5rem !important}

.m-gap-h-6--negative{column-gap:-6rem !important}

.m-gap-h-7--negative{column-gap:-7rem !important}

.m-gap-h-8--negative{column-gap:-8rem !important}

.m-gap-h-9--negative{column-gap:-9rem !important}

.m-gap-h-10--negative{column-gap:-10rem !important}

.m-gap-h-11--negative{column-gap:-11rem !important}

.m-gap-h-12--negative{column-gap:-12rem !important}

.m-gap-h-13--negative{column-gap:-13rem !important}

.m-gap-h-14--negative{column-gap:-14rem !important}

.m-gap-h-15--negative{column-gap:-15rem !important}

.m-no-gap{gap:0}}

.gap-full-auto{gap:normalrem !important}

.gap-full-quart{gap:0.25rem !important}

.gap-full-half{gap:0.5rem !important}

.gap-full-3-quart{gap:0.75rem !important}

.gap-full-1-half{gap:1.5rem !important}

.gap-full-1-quart{gap:1.25rem !important}

.gap-full-2-half{gap:2.5rem !important}

.gap-full-3-half{gap:3.5rem !important}

.gap-full-0{gap:0rem !important}

.gap-full-1{gap:1rem !important}

.gap-full-2{gap:2rem !important}

.gap-full-3{gap:3rem !important}

.gap-full-4{gap:4rem !important}

.gap-full-5{gap:5rem !important}

.gap-full-6{gap:6rem !important}

.gap-full-7{gap:7rem !important}

.gap-full-8{gap:8rem !important}

.gap-full-9{gap:9rem !important}

.gap-full-10{gap:10rem !important}

.gap-full-11{gap:11rem !important}

.gap-full-12{gap:12rem !important}

.gap-full-13{gap:13rem !important}

.gap-full-14{gap:14rem !important}

.gap-full-15{gap:15rem !important}

.gap-full-0--negative{gap:0rem !important}

.gap-full-1--negative{gap:-1rem !important}

.gap-full-2--negative{gap:-2rem !important}

.gap-full-3--negative{gap:-3rem !important}

.gap-full-4--negative{gap:-4rem !important}

.gap-full-5--negative{gap:-5rem !important}

.gap-full-6--negative{gap:-6rem !important}

.gap-full-7--negative{gap:-7rem !important}

.gap-full-8--negative{gap:-8rem !important}

.gap-full-9--negative{gap:-9rem !important}

.gap-full-10--negative{gap:-10rem !important}

.gap-full-11--negative{gap:-11rem !important}

.gap-full-12--negative{gap:-12rem !important}

.gap-full-13--negative{gap:-13rem !important}

.gap-full-14--negative{gap:-14rem !important}

.gap-full-15--negative{gap:-15rem !important}

@media(max-width: 768px){
.m-gap-full-auto{gap:auto !important}

.m-gap-full-quart{gap:0.25rem !important}

.m-gap-full-half{gap:0.5rem !important}

.m-gap-full-half--negative{gap:-0.5rem !important}

.m-gap-full-3-quart{gap:0.75rem !important}

.m-gap-full-1-half{gap:1.5rem !important}

.m-gap-full-2-half{gap:2.5rem !important}

.m-gap-full-3-half{gap:3.5rem !important}

.m-gap-full-0{gap:0rem !important}

.m-gap-full-1{gap:1rem !important}

.m-gap-full-2{gap:2rem !important}

.m-gap-full-3{gap:3rem !important}

.m-gap-full-4{gap:4rem !important}

.m-gap-full-5{gap:5rem !important}

.m-gap-full-6{gap:6rem !important}

.m-gap-full-7{gap:7rem !important}

.m-gap-full-8{gap:8rem !important}

.m-gap-full-9{gap:9rem !important}

.m-gap-full-10{gap:10rem !important}

.m-gap-full-11{gap:11rem !important}

.m-gap-full-12{gap:12rem !important}

.m-gap-full-13{gap:13rem !important}

.m-gap-full-14{gap:14rem !important}

.m-gap-full-15{gap:15rem !important}

.m-gap-full-0--negative{gap:0rem !important}

.m-gap-full-1--negative{gap:-1rem !important}

.m-gap-full-2--negative{gap:-2rem !important}

.m-gap-full-3--negative{gap:-3rem !important}

.m-gap-full-4--negative{gap:-4rem !important}

.m-gap-full-5--negative{gap:-5rem !important}

.m-gap-full-6--negative{gap:-6rem !important}

.m-gap-full-7--negative{gap:-7rem !important}

.m-gap-full-8--negative{gap:-8rem !important}

.m-gap-full-9--negative{gap:-9rem !important}

.m-gap-full-10--negative{gap:-10rem !important}

.m-gap-full-11--negative{gap:-11rem !important}

.m-gap-full-12--negative{gap:-12rem !important}

.m-gap-full-13--negative{gap:-13rem !important}

.m-gap-full-14--negative{gap:-14rem !important}

.m-gap-full-15--negative{gap:-15rem !important}

.m-no-gap{gap:0}}

.mobile-only{display:none}

.phone-only{display:none}

.tablet-only{display:none}

@media(max-width: 1024px){
.tablet-only{display:block}

.no-tablet{display:none !important}

.tablet-center-text{text-align:center !important}}

@media(min-width: 769px){
.mobile-only{display:none !important}}

@media(max-width: 768px){
.mobile-only{display:block}

.mobile-only.ib{display:inline-block}

.no-mobile{display:none !important}}

@media(min-width: 769px){
.mobile-only{display:none !important}}

@media(max-width: 480px){
.no-phone{display:none !important}

.phone-only{display:block}}

.no-border{border:none !important}

@media(max-width: 768px){
.m-no-border{border:none !important}

.m-no-bg{background-image:none !important}}

.sort{cursor:pointer}

.disabled{cursor:default !important;pointer-events:none}

.pointer-events-none,.children-pointer-events-none>*{pointer-events:none}

.show{display:block}

.hidden,.hide{display:none !important}

.invisible{visibility:hidden !important}

.visible{visibility:visible !important}

.hidden-without-display{position:absolute;top:-1000px;z-index:-1}

[dir="ltr"] .hidden-without-display{left:-1000px}

[dir="rtl"] .hidden-without-display{right:-1000px}

.error-text{color:#de0546 !important}

.error-text a{text-decoration:underline}

.error-text a:hover{text-decoration:none}

.success-text{color:#00a866}

.is_fixed{position:fixed !important;z-index:100}

.is_fixed--full-page{left:0;right:0}

.is_fixed--top{top:0}

.is_fixed--bottom{bottom:0}

.is_sticky{position:-webkit-sticky !important;position:sticky !important;z-index:100}

::-moz-selection{background:#b3d4fc;text-shadow:none}

::selection{background:#b3d4fc;text-shadow:none}

body{font-family:"Inter","Helvetica Neue",Helvetica,Arial,sans-serif;color:#0f1f2c;overflow-x:hidden}

body#ng-app,#ng-app body{background-color:#fff}

a:hover{text-decoration:none}

a.light-blue,a.callout{transition:opacity .2s linear;color:#0062e3}

a.light-blue:hover,a.callout:hover{text-decoration:underline}

a.underline{transition:color .2s linear;text-decoration:underline}

a.underline:hover{opacity:.9}

a.has_hover{transition:color .2s linear}

a.has_hover:hover{opacity:.9}

a.link,button.link{color:#0062e3}

a.link:hover,a.link:focus,button.link:hover,button.link:focus{text-decoration:underline}

a.link:hover i,a.link:focus i,button.link:hover i,button.link:focus i{text-decoration:normal}

.link-passive-item,a.link-passive,button.link-passive{color:rgba(15,31,44,.61)}

.link-passive-item:hover,.link-passive-item:focus,a.link-passive:hover,a.link-passive:focus,button.link-passive:hover,button.link-passive:focus{color:#0062e3}

.link-passive-item:hover[disabled],.link-passive-item:focus[disabled],a.link-passive:hover[disabled],a.link-passive:focus[disabled],button.link-passive:hover[disabled],button.link-passive:focus[disabled]{color:rgba(15,31,44,.61)}

a.uhover:hover,a.uhover:focus,span.uhover:hover,span.uhover:focus,button.uhover:hover,button.uhover:focus{text-decoration:underline}

button{font-family:"Inter","Helvetica Neue",Helvetica,Arial,sans-serif}

blockquote{text-align:justify}

h1,h2,h3,h4,h5,h6{color:#0f1f2c}

h1{font-weight:600}

.body-text{color:#0f1f2c}

*::placeholder{opacity:1;color:#0f1f2c !important;font-weight:400;font-style:italic}

*:-ms-input-placeholder{opacity:1;color:#0f1f2c !important;font-weight:400;font-style:italic}

[contenteditable=true]:empty:before{color:rgba(15,31,44,.45) !important;content:attr(placeholder);display:block;position:absolute;top:1rem;cursor:text}

[dir="ltr"] [contenteditable=true]:empty:before{left:1rem}

[dir="rtl"] [contenteditable=true]:empty:before{right:1rem}

[type=color],[type=date],[type=datetime],[type=datetime-local],[type=email],[type=month],[type=number],[type=password],[type=search],[type=tel],[type=text],[type=time],[type=url],[type=week],input:not([type]),textarea{border-radius:.25rem;background-color:#fff;border:1px solid rgba(15,31,44,.18);box-shadow:none;padding:.5rem 1rem;height:3rem;font-size:1rem;font-family:"Inter","Helvetica Neue",Helvetica,Arial,sans-serif;width:100%;font-weight:400;color:#0f1f2c;vertical-align:middle}

[type=color] .empty,[type=color] .placeholder,[type=date] .empty,[type=date] .placeholder,[type=datetime] .empty,[type=datetime] .placeholder,[type=datetime-local] .empty,[type=datetime-local] .placeholder,[type=email] .empty,[type=email] .placeholder,[type=month] .empty,[type=month] .placeholder,[type=number] .empty,[type=number] .placeholder,[type=password] .empty,[type=password] .placeholder,[type=search] .empty,[type=search] .placeholder,[type=tel] .empty,[type=tel] .placeholder,[type=text] .empty,[type=text] .placeholder,[type=time] .empty,[type=time] .placeholder,[type=url] .empty,[type=url] .placeholder,[type=week] .empty,[type=week] .placeholder,input:not([type]) .empty,input:not([type]) .placeholder,textarea .empty,textarea .placeholder{color:rgba(15,31,44,.45) !important}

[type=color].zeta,[type=date].zeta,[type=datetime].zeta,[type=datetime-local].zeta,[type=email].zeta,[type=month].zeta,[type=number].zeta,[type=password].zeta,[type=search].zeta,[type=tel].zeta,[type=text].zeta,[type=time].zeta,[type=url].zeta,[type=week].zeta,input:not([type]).zeta,textarea.zeta{padding:.55em .8em;font-size:12px}

[type=color].ghost,[type=date].ghost,[type=datetime].ghost,[type=datetime-local].ghost,[type=email].ghost,[type=month].ghost,[type=number].ghost,[type=password].ghost,[type=search].ghost,[type=tel].ghost,[type=text].ghost,[type=time].ghost,[type=url].ghost,[type=week].ghost,input:not([type]).ghost,textarea.ghost{border:none}

[type=color]:focus,[type=date]:focus,[type=datetime]:focus,[type=datetime-local]:focus,[type=email]:focus,[type=month]:focus,[type=number]:focus,[type=password]:focus,[type=search]:focus,[type=tel]:focus,[type=text]:focus,[type=time]:focus,[type=url]:focus,[type=week]:focus,input:not([type]):focus,textarea:focus{color:#0f1f2c !important;transition:all .25s ease-in-out;border-color:#0062e3}

.sidebar [type=color]:focus,.sidebar [type=date]:focus,.sidebar [type=datetime]:focus,.sidebar [type=datetime-local]:focus,.sidebar [type=email]:focus,.sidebar [type=month]:focus,.sidebar [type=number]:focus,.sidebar [type=password]:focus,.sidebar [type=search]:focus,.sidebar [type=tel]:focus,.sidebar [type=text]:focus,.sidebar [type=time]:focus,.sidebar [type=url]:focus,.sidebar [type=week]:focus,.sidebar input:not([type]):focus,.sidebar textarea:focus{background:#fff}

[type=color][disabled],[type=date][disabled],[type=datetime][disabled],[type=datetime-local][disabled],[type=email][disabled],[type=month][disabled],[type=number][disabled],[type=password][disabled],[type=search][disabled],[type=tel][disabled],[type=text][disabled],[type=time][disabled],[type=url][disabled],[type=week][disabled],input:not([type])[disabled],textarea[disabled],[type=color]:focus[disabled],[type=date]:focus[disabled],[type=datetime]:focus[disabled],[type=datetime-local]:focus[disabled],[type=email]:focus[disabled],[type=month]:focus[disabled],[type=number]:focus[disabled],[type=password]:focus[disabled],[type=search]:focus[disabled],[type=tel]:focus[disabled],[type=text]:focus[disabled],[type=time]:focus[disabled],[type=url]:focus[disabled],[type=week]:focus[disabled],input:not([type]):focus[disabled],textarea:focus[disabled],select[disabled],.combobox__value[disabled]{color:rgba(15,31,44,.45);background-color:rgba(15,31,44,.03);border-color:rgba(15,31,44,.18)}

[type=color][disabled].readonly-override,[type=date][disabled].readonly-override,[type=datetime][disabled].readonly-override,[type=datetime-local][disabled].readonly-override,[type=email][disabled].readonly-override,[type=month][disabled].readonly-override,[type=number][disabled].readonly-override,[type=password][disabled].readonly-override,[type=search][disabled].readonly-override,[type=tel][disabled].readonly-override,[type=text][disabled].readonly-override,[type=time][disabled].readonly-override,[type=url][disabled].readonly-override,[type=week][disabled].readonly-override,input:not([type])[disabled].readonly-override,textarea[disabled].readonly-override,[type=color]:focus[disabled].readonly-override,[type=date]:focus[disabled].readonly-override,[type=datetime]:focus[disabled].readonly-override,[type=datetime-local]:focus[disabled].readonly-override,[type=email]:focus[disabled].readonly-override,[type=month]:focus[disabled].readonly-override,[type=number]:focus[disabled].readonly-override,[type=password]:focus[disabled].readonly-override,[type=search]:focus[disabled].readonly-override,[type=tel]:focus[disabled].readonly-override,[type=text]:focus[disabled].readonly-override,[type=time]:focus[disabled].readonly-override,[type=url]:focus[disabled].readonly-override,[type=week]:focus[disabled].readonly-override,input:not([type]):focus[disabled].readonly-override,textarea:focus[disabled].readonly-override,select[disabled].readonly-override,.combobox__value[disabled].readonly-override{background-color:#fff}

[type=color].readonly-ebony,[type=date].readonly-ebony,[type=datetime].readonly-ebony,[type=datetime-local].readonly-ebony,[type=email].readonly-ebony,[type=month].readonly-ebony,[type=number].readonly-ebony,[type=password].readonly-ebony,[type=search].readonly-ebony,[type=tel].readonly-ebony,[type=text].readonly-ebony,[type=time].readonly-ebony,[type=url].readonly-ebony,[type=week].readonly-ebony,input:not([type]).readonly-ebony,textarea.readonly-ebony,[type=color]:focus.readonly-ebony,[type=date]:focus.readonly-ebony,[type=datetime]:focus.readonly-ebony,[type=datetime-local]:focus.readonly-ebony,[type=email]:focus.readonly-ebony,[type=month]:focus.readonly-ebony,[type=number]:focus.readonly-ebony,[type=password]:focus.readonly-ebony,[type=search]:focus.readonly-ebony,[type=tel]:focus.readonly-ebony,[type=text]:focus.readonly-ebony,[type=time]:focus.readonly-ebony,[type=url]:focus.readonly-ebony,[type=week]:focus.readonly-ebony,input:not([type]):focus.readonly-ebony,textarea:focus.readonly-ebony,select.readonly-ebony,.combobox__value.readonly-ebony{color:#0f1f2c !important}

input[type=search]{-webkit-appearance:textfield}

div.input{border:none;background:none;padding:0}

.textarea,textarea,[contenteditable=true],.contenteditable{padding:1rem;border:1px solid rgba(15,31,44,.18);width:100%;clear:both;font-size:1rem;font-family:"Inter","Helvetica Neue",Helvetica,Arial,sans-serif;resize:none;word-wrap:break-word;vertical-align:top}

.textarea.zeta,textarea.zeta,[contenteditable=true].zeta,.contenteditable.zeta{padding:.8em}

[contenteditable=true],.contenteditable{min-height:3rem;overflow:auto;overflow-x:visible;overflow-y:auto}

[contenteditable=true][dg-paste-as-plaintext] b,[contenteditable=true] strong,[contenteditable=true] u,[contenteditable=true] i,[contenteditable=true] em,.contenteditable[dg-paste-as-plaintext] b,.contenteditable strong,.contenteditable u,.contenteditable i,.contenteditable em{font-weight:normal !important;font-style:normal !important;text-decoration:none !important}

.textarea,textarea:not([dg-elastic]){min-height:8.5rem}

.textarea.textarea--short,textarea:not([dg-elastic]).textarea--short{min-height:6rem}

.textarea.textarea--long,textarea:not([dg-elastic]).textarea--long{min-height:15rem}

.textarea.par--small,textarea:not([dg-elastic]).par--small{min-height:7rem}

textarea[dg-elastic],textarea:not([dg-elastic]).elastic{min-height:auto;padding:.75rem 1rem}

hr{width:85%;margin:0 auto;border-top:1px solid rgba(15,31,44,.18);border-bottom:1px solid rgba(15,31,44,.18);padding:2px 0;background:#fff}

textarea.required{background-image:none !important}

input[type=checkbox]:hover,input[type=radio]:hover{cursor:pointer}

input[type=checkbox]:hover[disabled],input[type=radio]:hover[disabled]{cursor:not-allowed}

pre{font-family:inherit;font-weight:inherit}

q{quotes:"“" "”" "‘" "’"}

q:before{content:open-quote}

q:after{content:close-quote}

ul.link-list{list-style:none}

[dir="ltr"] ul.link-list{padding-left:2rem}

[dir="rtl"] ul.link-list{padding-right:2rem}

ul.link-list li{margin-bottom:.5rem}

ul.link-list li:before{content:"•";text-align:center;color:#0f1f2c;font-weight:bold;display:inline-block;width:2rem}

[dir="ltr"] ul.link-list li:before{margin-left:-2rem}

[dir="rtl"] ul.link-list li:before{margin-right:-2rem}

ul.link-list li a{font-weight:500}

ul.link-list li a:hover,ul.link-list li a:focus{text-decoration:underline}

ul.link-list--primary li:before{color:#0062e3}

ul.link-list--primary li a{color:#0062e3}

.action-items{position:relative;z-index:1}

.action-items.open{z-index:2}

[dir="ltr"] .action-items .action-btn{margin-left:7px}

[dir="rtl"] .action-items .action-btn{margin-right:7px}

.action-items-inside-btn{display:block;padding:.75rem 1.5rem;font-size:12px;font-weight:normal;line-height:1rem;color:rgba(15,31,44,.61);background-color:rgba(0,0,0,0);width:100%}

[dir="ltr"] .action-items-inside-btn{text-align:left}

[dir="rtl"] .action-items-inside-btn{text-align:right}

.action-items-inside-btn:hover{color:#0f1f2c;background-color:rgba(15,31,44,.03);outline:none}

.action-btn{font-size:0;background:none;transition:all .5s ease;padding:0;position:relative}

.action-btn:before{background-color:#fff;border:1px solid rgba(15,31,44,.61);border-radius:50%;font-size:16px;color:rgba(15,31,44,.61);display:inline-block;width:30px;height:30px;line-height:28px}

.action-btn.epsilon:before{font-size:14px}

.action-btn:hover:before,.action-btn:focus:before{border-color:#0062e3;background-color:rgba(15,31,44,.03);color:#0062e3}

.action-btn:active:before,.action-btn.active:before{border-color:#0062e3;background-color:#0062e3;color:#fff}

.action-btn.done:before{border-color:#00a866;background-color:#00a866;color:#fff}

.action-btn[disabled]:before,.action-btn.is_disabled:before{border-color:rgba(15,31,44,.18);background-color:rgba(15,31,44,.03);color:rgba(15,31,44,.18)}

.action-btn:after{content:"";position:absolute;top:50%;width:70px;height:70px;border-radius:50%;opacity:0;visibility:hidden;pointer-events:none;border:1px solid #0062e3}

[dir="ltr"] .action-btn:after{left:50%;margin:-35px 0 0 -35px}

[dir="rtl"] .action-btn:after{right:50%;margin:-35px -35px 0 0}

.action-btn.button-clicked:after{-webkit-animation:anim-effect-jelena .3s ease-out forwards;animation:anim-effect-jelena .3s ease-out forwards}

.action-link{border-radius:2rem;border:1px solid rgba(15,31,44,.18);color:rgba(15,31,44,.61);font-size:1rem;padding:.5rem 1rem;transition-property:border-color,box-shadow;transition-duration:.1s;transition-timing-function:ease-in}

.action-link:not([disabled]):focus,.action-link:not([disabled]):hover{border:1px solid rgba(0,0,0,0);box-shadow:0 6px 20px 0 rgba(15,31,44,.2);color:#0062e3}

.action-link:not([disabled]):focus{border:1px solid #0062e3}

.action-link:not([disabled]):focus.is_active{background-color:#004eb6}

.action-link.is_active{background-color:#0062e3;border:1px solid #186399}

[dir="ltr"] .action-link[data-icon] .action-label{padding-left:.1rem}

[dir="rtl"] .action-link[data-icon] .action-label{padding-right:.1rem}

[dir="ltr"] .action-link-item{margin-right:4rem}

[dir="rtl"] .action-link-item{margin-left:4rem}

[dir="ltr"] .action-link-item .action-link{margin-right:0}

[dir="rtl"] .action-link-item .action-link{margin-left:0}

[dir="ltr"] .action-link-item:last-of-type{margin-right:0}

[dir="rtl"] .action-link-item:last-of-type{margin-left:0}

.action-buttons-collapse .action-link-item{padding-right:.5em;padding-left:.5em}

[dir="ltr"] .action-buttons-collapse .action-link-item{margin-right:.5em}

[dir="rtl"] .action-buttons-collapse .action-link-item{margin-left:.5em}

[dir="ltr"] .action-buttons-collapse .action-link-item:first-child{padding-left:0}

[dir="rtl"] .action-buttons-collapse .action-link-item:first-child{padding-right:0}

[dir="ltr"] .action-buttons-collapse .action-link-item:last-child{padding-right:0}

[dir="rtl"] .action-buttons-collapse .action-link-item:last-child{padding-left:0}

@media(max-width: 768px){
.action-link-item{padding-right:.5em;padding-left:.5em}

[dir="ltr"] .action-link-item{margin-right:.5em}

[dir="rtl"] .action-link-item{margin-left:.5em}

[dir="ltr"] .action-link-item:first-child{padding-left:0}

[dir="rtl"] .action-link-item:first-child{padding-right:0}

[dir="ltr"] .action-link-item:last-child{padding-right:0}

[dir="rtl"] .action-link-item:last-child{padding-left:0}}

[dir="ltr"] .action-link:last-of-type{margin-right:0}

[dir="rtl"] .action-link:last-of-type{margin-left:0}

.action-link:before{font-size:12px;vertical-align:middle}

@media(max-width: 768px){
.action-link.action-count:not(.comments-action) .action-label{font-size:1.1rem;margin-top:.25rem}}

@media(max-width: 768px){
.action-link:not([data-icon=""]):not(.action-count):before{font-size:1.5em}

.action-link:not([data-icon=""]):not(.action-count) .action-label{display:none}}

.action-buttons-collapse .action-link:not([data-icon=""]):not(.action-count) .action-label{display:none}

.action-link.done{font-weight:bold;color:#0062e3}

.action-options{font-size:1rem;color:rgba(15,31,44,.61)}

.action-options__item{display:inline-block}

[dir="ltr"] .action-options__item{margin-right:1rem}

[dir="rtl"] .action-options__item{margin-left:1rem}

.action-options__item--viewed{padding-top:.7rem;font-weight:500;display:inline-block}

[dir="ltr"] .action-options__item:last-child{margin-right:0}

[dir="rtl"] .action-options__item:last-child{margin-left:0}

.action-options--card{justify-content:space-between}

.action-options__button{color:rgba(15,31,44,.61);overflow:hidden;transition-property:border-color,box-shadow;transition-duration:.1s;transition-timing-function:ease-in;white-space:nowrap;line-height:1.5rem}

[dir="ltr"] .action-options__button{text-align:left}

[dir="rtl"] .action-options__button{text-align:right}

.action-options__button.is_active .action-options__button-icon,.action-options__button.is_active .action-options__button-label,.action-options__button.is_active .dg-icon{color:#0062e3}

.action-options__button[disabled],.action-options__button.is_disabled{pointer-events:none;color:rgba(15,31,44,.45);border-color:rgba(15,31,44,.18)}

.action-options__button[disabled].is_active,.action-options__button.is_disabled.is_active{background-color:rgba(0,98,227,.5);border-color:rgba(15,31,44,.18)}

.action-options__button--bordered{border-radius:2rem;border:1px solid rgba(15,31,44,.18);padding:0 1rem;height:2.5rem}

.action-options__button--bordered-sm{height:2rem}

.action-options__button--bordered-lg{height:3rem}

.action-options__button--bordered:focus,.action-options__button--bordered:hover{box-shadow:0 6px 20px 0 rgba(15,31,44,.25)}

.action-options__button--bordered:focus{border:1px solid #0062e3}

.action-options__button--bordered:focus.is_active{background-color:#004eb6}

.action-options__button--bordered.is_active{background-color:#0062e3;border:1px solid #186399}

.action-options__button--bordered.is_active:hover .action-options__button-icon,.action-options__button--bordered.is_active:hover .action-options__button-label,.action-options__button--bordered.is_active:hover .dg-icon,.action-options__button--bordered.is_active:focus .action-options__button-icon,.action-options__button--bordered.is_active:focus .action-options__button-label,.action-options__button--bordered.is_active:focus .dg-icon{color:#fff}

.action-options__button--bordered.is_active .action-options__button-icon,.action-options__button--bordered.is_active .action-options__button-label,.action-options__button--bordered.is_active .dg-icon{color:#fff}

.action-options__button--bordered.is_active .action-options__button-label--incomplete{opacity:0;visibility:hidden;top:3rem}

.action-options__button--bordered.is_active .action-options__button-label--complete{opacity:1;visibility:visible;top:0}

.action-options__button:hover,.action-options__button:focus{color:#0062e3}

.action-options__button:hover .dg-icon,.action-options__button:focus .dg-icon{color:#0062e3}

.action-options__button:hover-label,.action-options__button:focus-label{color:#0062e3;text-decoration:none}

.action-options__button:hover{border-color:rgba(0,0,0,0)}

.action-options__button__points{color:#fff}

.action-options__button-label{overflow:hidden}

.action-options__button-label-wrap{position:relative}

.action-options__button-label-spacer{opacity:0;visibility:hidden;position:relative;z-index:-1}

.action-options__button-label--complete,.action-options__button-label--incomplete{display:inline-block;position:absolute;transition:top .2s ease-in,opacity .1s ease-in}

[dir="ltr"] .action-options__button-label--complete,[dir="ltr"] .action-options__button-label--incomplete{left:0}

[dir="rtl"] .action-options__button-label--complete,[dir="rtl"] .action-options__button-label--incomplete{right:0}

.action-options__button-label--complete{opacity:0;visibility:hidden;top:-3rem}

.action-options__button-label--incomplete{opacity:1;visibility:visible;top:0}

.action-options__button-icon{margin:0 .5rem}

.action-options__toggle-button{border:1px solid rgba(15,31,44,.18);color:rgba(15,31,44,.61);padding:0 1rem;height:2.5rem;transition-property:border-color;transition-duration:.1s;transition-timing-function:ease-in}

.action-options__toggle-button--lg{height:3rem}

[dir="ltr"] .action-options__toggle-button:first-child{border-right:none;border-radius:1.5rem 0 0 1.5rem}

[dir="rtl"] .action-options__toggle-button:first-child{border-left:none;border-radius:0 1.5rem 1.5rem 0}

[dir="ltr"] .action-options__toggle-button:last-child{border-radius:0 1.5rem 1.5rem 0}

[dir="rtl"] .action-options__toggle-button:last-child{border-radius:1.5rem 0 0 1.5rem}

.keyboard-focus .action-options__toggle-button:focus,.action-options__toggle-button:hover{color:#0062e3}

.keyboard-focus .action-options__toggle-button:focus{border-color:#0062e3}

[dir="ltr"] .keyboard-focus .action-options__toggle-button:focus:first-child{border-right:1px solid #0062e3}

[dir="rtl"] .keyboard-focus .action-options__toggle-button:focus:first-child{border-left:1px solid #0062e3}

.action-options__toggle-wrapper{border:none;border-radius:1.5rem;transition-property:border-color,box-shadow;transition-duration:.1s;transition-timing-function:ease-in}

.action-options__toggle-wrapper:hover,.action-options__toggle-wrapper:focus,.action-options__toggle-wrapper:focus-within{border-color:rgba(0,0,0,0);box-shadow:0 6px 20px 0 rgba(15,31,44,.25)}

.action-options__toggle-wrapper:hover .action-options__toggle-button{border-color:rgba(0,0,0,0)}

[dir="ltr"] .action-options__toggle-wrapper:hover .action-options__toggle-button:last-child{border-left-color:#fff}

[dir="rtl"] .action-options__toggle-wrapper:hover .action-options__toggle-button:last-child{border-right-color:#fff}

.action-options__icon{color:rgba(15,31,44,.61)}

.action-options__icon:hover,.action-options__icon:focus{color:#0062e3}

.action-options_spacer{font-size:6px;color:#6d767e;margin:0 1.5rem}

.action-options__points{color:#fff}

.action-options--tile{justify-content:flex-end}

[dir="ltr"] .action-options--tile .action-options__item{margin-right:.25rem}

[dir="rtl"] .action-options--tile .action-options__item{margin-left:.25rem}

[dir="ltr"] .action-options--tile .action-options__item:last-child{margin-right:0}

[dir="rtl"] .action-options--tile .action-options__item:last-child{margin-left:0}

.action-options--tile .action-options__button--bordered,.action-options--tile .action-options__toggle-button{padding:.4166666667rem .75rem}

[dir="ltr"] .action-options .dropdown-menu-right{right:-1rem}

[dir="rtl"] .action-options .dropdown-menu-right{left:-1rem}

[dir="ltr"] .action-options .dropdown-menu-right{right:-1rem}

[dir="rtl"] .action-options .dropdown-menu-right{left:-1rem}

.action-options__menu{max-width:17rem}

.action-options__menu.dropdown-menu{padding:0}

.action-options__menu-button--deleteRed{color:#de0546 !important}

.action-options__menu[role=menu]>li>button{padding:.75rem 1rem}

ngb-modal-window+.action-options__menu{z-index:1100}

[dir="ltr"] .action-options.is_collapsed .action-options__item{margin-right:1.5rem}

[dir="rtl"] .action-options.is_collapsed .action-options__item{margin-left:1.5rem}

@media(max-width: 1024px){
[dir="ltr"] .action-options.is_collapsed .action-options__item{margin-right:1rem}

[dir="rtl"] .action-options.is_collapsed .action-options__item{margin-left:1rem}}

[dir="ltr"] .action-options.is_collapsed .action-options__item:last-child{margin-right:0}

[dir="rtl"] .action-options.is_collapsed .action-options__item:last-child{margin-left:0}

[dir="ltr"] .action-options.is_collapsed .action-options__button-icon{margin-right:.25rem}

[dir="rtl"] .action-options.is_collapsed .action-options__button-icon{margin-left:.25rem}

.action-options.is_collapsed .action-options__button .action-options__button-label{display:none}

.action-options.is_collapsed .action-options__button .action-options__button-label--show{display:inline-block}

.action-options.is_collapsed .action-options__button.is_active .action-options__button-label--show{display:none}

.action-options.is_collapsed .action-options__button .action-options__button-label.action-options__points{display:inline-block}

[dir="ltr"] .action-options.is_collapsed .action-options__button .action-options__button-label.action-options__points{margin-left:0 !important}

[dir="rtl"] .action-options.is_collapsed .action-options__button .action-options__button-label.action-options__points{margin-right:0 !important}

.action-options.is_collapsed .action-options__button.action-options__count:hover{text-decoration:none}

[dir="ltr"] .action-options.is_collapsed .action-options__count--collapsible{padding:0 0 0 .1rem}

[dir="rtl"] .action-options.is_collapsed .action-options__count--collapsible{padding:0 .1rem 0 0}

.action-options.is_collapsed .action-options__count--collapsible:before{content:""}

.action-options.is_collapsed .action-options__count--collapsible:after{content:""}

@media(max-width: 768px){
.action-options.is_collapsed .action-options__count--collapsible{display:none}}

@media(max-width: 480px){
.action-options.is_collapsed .action-options__count--collapsible{display:inline-block}}

@media(min-width: 769px){
.action-options.is_collapsed .action-options__count--collapsible{display:inline-block}}

[dir="ltr"] .action-options .dropdown-menu-right{right:-1rem}

[dir="rtl"] .action-options .dropdown-menu-right{left:-1rem}

.hover-btn .on-hover{display:none}

.hover-btn .off-hover{display:flex}

.hover-btn:hover .on-hover,.hover-btn:focus .on-hover{display:flex}

.hover-btn:hover .off-hover,.hover-btn:focus .off-hover{display:none}

.btn{cursor:pointer;border:1px solid rgba(0,0,0,0);border-radius:3px;color:#fff;padding:calc(.8rem - 1px) 1.5rem;min-height:3rem;display:inline-block;position:relative;font-weight:600;font-size:1rem;line-height:1.4em;vertical-align:middle;text-align:center}

.btn.btn-sm{min-height:2rem;padding:calc(.3rem - 1px) .75rem;font-weight:500}

.btn.btn-sm.btn--square{width:2rem;min-width:2rem;height:2rem;padding:0 0}

.btn.btn--xs{min-height:1rem;padding:0 0}

.btn.btn-light{padding:calc(.3rem - 1px) .75rem;font-weight:400}

.btn:not([disabled]):not(.is_disabled):not(.inactive):not(:active):hover,.btn:not(.active):not(:active):focus{box-shadow:inset 0 100px rgba(15,31,44,.18)}

.btn.active,.btn:active,.btn:hover:active{box-shadow:inset 0 100px rgba(15,31,44,.61)}

.btn.btn-primary{background-color:#0062e3}

.btn.btn-dark{background-color:#0f1f2c}

.btn.btn-destructive{background-color:#de0546}

.btn.btn-passive,.btn.btn-add,.btn.btn--ghost{background-color:rgba(0,0,0,0);border-color:rgba(15,31,44,.18);color:rgba(15,31,44,.61);text-shadow:none}

.btn.btn-passive:not([disabled]):not(.is_disabled):not(.inactive):not(:active):hover,.btn.btn-passive:not(.active):not(:active):focus,.btn.btn-add:not([disabled]):not(.is_disabled):not(.inactive):not(:active):hover,.btn.btn-add:not(.active):not(:active):focus,.btn.btn--ghost:not([disabled]):not(.is_disabled):not(.inactive):not(:active):hover,.btn.btn--ghost:not(.active):not(:active):focus{box-shadow:none;border-color:rgba(15,31,44,.45);color:#0f1f2c}

.btn.btn-passive.active,.btn.btn-passive:active,.btn.btn-passive:hover:active,.btn.btn-add.active,.btn.btn-add:active,.btn.btn-add:hover:active,.btn.btn--ghost.active,.btn.btn--ghost:active,.btn.btn--ghost:hover:active{box-shadow:inset 0 100vh rgba(15,31,44,.03)}

.btn.btn--ghost{background-color:#fff;color:rgba(15,31,44,.61)}

.btn.btn--ghost--no-border{border:none}

.btn.btn--ghost--no-border:active{box-shadow:none}

.btn.btn-passive--no-border{border:none}

.btn.btn-passive--no-border:active{box-shadow:none}

.btn.btn-passive--dashed{border-width:2px;border-style:dashed}

.mouse-focus .btn.btn-passive--dashed:not(.active):not(:active):focus{border-color:rgba(15,31,44,.18)}

.btn.btn-passive-reverse{background-color:rgba(0,0,0,0);border-color:rgba(255,255,255,.75);color:rgba(255,255,255,.75)}

.btn.btn-passive-reverse:not([disabled]):not(.is_disabled):not(.inactive):not(:active):hover,.btn.btn-passive-reverse:not(.active):not(:active):focus{box-shadow:inset 0 100px rgba(15,31,44,.03);border-color:rgba(255,255,255,.75);color:rgba(255,255,255,.75)}

.btn.btn-passive-reverse.active,.btn.btn-passive-reverse:active,.btn.btn-passive-reverse:hover:active{box-shadow:inset 0 100px rgba(15,31,44,.03)}

.btn.btn-secondary{background-color:rgba(0,0,0,0);border-color:#0062e3;color:#0062e3}

.btn.btn-secondary:not([disabled]):not(.is_disabled):not(.inactive):not(:active):hover{box-shadow:none;background-color:#fff}

.btn.btn-secondary:not(.active):not(:active):focus{box-shadow:0px 0px 0px 2px rgba(0,98,227,.5);outline:none}

.btn.btn-secondary.active,.btn.btn-secondary:active,.btn.btn-secondary:hover:active{box-shadow:inset 0 100px rgba(15,31,44,.03)}

.btn.btn-danger{background-color:rgba(0,0,0,0);border-color:#de0546;color:#de0546}

.btn.btn-danger:not([disabled]):not(.is_disabled):not(.inactive):not(:active):hover{box-shadow:none;background-color:#fff}

.btn.btn-danger:not(.active):not(:active):focus{box-shadow:0px 0px 0px 2px #f8cdda;outline:none}

.btn.btn-danger.active,.btn.btn-danger:active,.btn.btn-danger:hover:active{box-shadow:inset 0 100px rgba(15,31,44,.03)}

.btn.btn__toggle{min-height:2rem;transition:background-color .2s linear}

[dir="ltr"] .btn.btn__toggle--active{padding-left:2.25rem;padding-right:.75rem}

[dir="rtl"] .btn.btn__toggle--active{padding-right:2.25rem;padding-left:.75rem}

.btn.btn__toggle--active.lower-me:before{text-transform:none}

[dir="ltr"] .btn .btn__icon[data-icon]:before{margin-right:.5rem}

[dir="rtl"] .btn .btn__icon[data-icon]:before{margin-left:.5rem}

.btn.btn-circle{color:#0062e3;width:3rem;border-radius:50% !important}

.btn.btn-circle[disabled]{color:rgba(15,31,44,.18) !important}

.btn.btn-circle.btn-sm{height:2.5rem;width:2.5rem}

.btn.btn-circle.btn--xs{height:2rem;width:2rem}

.btn.btn-circle.btn--ghost{color:rgba(15,31,44,.45)}

.btn.inactive{opacity:.5;cursor:default}

.btn.is_disabled{cursor:default;color:rgba(15,31,44,.45) !important;background-color:rgba(15,31,44,.03) !important;border:1px solid rgba(15,31,44,.18) !important;text-shadow:none}

.btn.is_disabled.btn-primary{background-color:rgba(0,98,227,.5) !important;border:none !important;color:#cce0f9 !important}

.btn.is_disabled.btn-secondary{background-color:rgba(15,31,44,.03) !important;color:rgba(0,98,227,.5) !important;border:1px solid rgba(0,98,227,.5) !important}

.btn.rounded--large{border-radius:.5rem}

[dir="ltr"] .btn:before{padding-right:.5em}

[dir="rtl"] .btn:before{padding-left:.5em}

[dir="ltr"] .btn:empty:before{padding-right:0}

[dir="rtl"] .btn:empty:before{padding-left:0}

[dir="ltr"] .btn:after{padding-left:.5em}

[dir="rtl"] .btn:after{padding-right:.5em}

[dir="ltr"] .btn:empty:after{padding-left:0}

[dir="rtl"] .btn:empty:after{padding-right:0}

.btn[dg-button-spin].is_spinning:before{visibility:hidden}

.strike-through:before{border-bottom:1px solid rgba(15,31,44,.18);content:" ";display:block;position:relative;transform:translateY(1.5rem)}

[dir="ltr"] .strike-through:before{text-align:left}

[dir="rtl"] .strike-through:before{text-align:right}

.btn.btn-dropdown{border:1px solid rgba(15,31,44,.18);color:#0f1f2c;background-color:#fff;white-space:nowrap;font-weight:400;width:100%}

[dir="ltr"] .btn.btn-dropdown{padding:0 2.5rem 0 0;text-align:left}

[dir="rtl"] .btn.btn-dropdown{padding:0 0 0 2.5rem;text-align:right}

.btn.btn-dropdown.is_disabled{background-color:inherit !important}

.btn.btn-dropdown.is_disabled:after{background-color:rgba(15,31,44,.03) !important}

.btn.btn-dropdown.is_disabled.btn-dropdown--no-toggle-border:after{background:rgba(0,0,0,0) !important}

.btn.btn-dropdown .btn-dropdown__icon{color:rgba(15,31,44,.61);position:absolute;top:0;bottom:0;line-height:34px;line-height:calc(3rem - 2px);padding:0 .75rem}

[dir="ltr"] .btn.btn-dropdown .btn-dropdown__icon{border-left:1px solid rgba(15,31,44,.18);right:0}

[dir="rtl"] .btn.btn-dropdown .btn-dropdown__icon{border-right:1px solid rgba(15,31,44,.18);left:0}

.btn.btn-dropdown .btn-dropdown__icon--sm{line-height:2rem !important}

[dir="ltr"] .btn.btn-dropdown .btn-dropdown__icon--sm{border-left:none !important}

[dir="rtl"] .btn.btn-dropdown .btn-dropdown__icon--sm{border-right:none !important}

.btn.btn-dropdown--sm{font-size:1rem !important;min-height:2rem !important}

[dir="ltr"] .btn.btn-dropdown--sm{padding:0 2rem 0 1rem !important}

[dir="rtl"] .btn.btn-dropdown--sm{padding:0 1rem 0 2rem !important}

[dir="ltr"] .btn.btn-dropdown--no-toggle-border:after{border-left:none !important}

[dir="rtl"] .btn.btn-dropdown--no-toggle-border:after{border-right:none !important}

.dropdown.open .btn.btn-dropdown{border:1px solid #0062e3}

.dropdown.open .btn.btn-dropdown:after{color:#0062e3}

.zeta .btn.btn-dropdown{font-size:12px}

.zeta .btn.btn-dropdown:after{font-size:14px}

.btn.btn-dropdown .placeholder,.btn.btn-dropdown.placeholder{font-style:italic}

.mobile-tabs .btn.btn-dropdown{border-color:#0062e3;color:#0062e3;font-size:12px;font-weight:500}

[dir="ltr"] .mobile-tabs .btn.btn-dropdown{padding:1em 4em 1em 1em}

[dir="rtl"] .mobile-tabs .btn.btn-dropdown{padding:1em 1em 1em 4em}

.mobile-tabs .btn.btn-dropdown:after{padding:0 1em;line-height:1.9em}

[dir="ltr"] .mobile-tabs .btn.btn-dropdown:after{border-left-color:#0062e3}

[dir="rtl"] .mobile-tabs .btn.btn-dropdown:after{border-right-color:#0062e3}

.btn.btn-dropdown:not(.is_disabled):not(.inactive):not(:active):hover,.btn.btn-dropdown:not(.active):not(:active):focus{box-shadow:none}

.btn.btn-dropdown.active,.btn.btn-dropdown:active,.btn.btn-dropdown:hover:active{box-shadow:none}

.field-group{display:flex}

.field-group__input{position:relative;flex-grow:1}

[dir="ltr"] .field-group__input input{border-top-right-radius:0 !important;border-bottom-right-radius:0 !important;border-right:none}

[dir="rtl"] .field-group__input input{border-top-left-radius:0 !important;border-bottom-left-radius:0 !important;border-left:none}

.field-group__input input::-ms-clear{display:none}

.field-group__input--tag{border:1px solid rgba(15,31,44,.18);border-radius:.25rem}

[dir="ltr"] .field-group__input--tag{padding-left:1rem}

[dir="rtl"] .field-group__input--tag{padding-right:1rem}

.field-group__input--tag input[type=text]{border:none;height:calc(3rem - 2px);width:auto;min-width:20rem}

[dir="ltr"] .field-group__input--tag input[type=text]{padding-left:0;margin-right:1px}

[dir="rtl"] .field-group__input--tag input[type=text]{padding-right:0;margin-left:1px}

.field-group__input--tag input[type=text].field-group--autowidth{min-width:auto}

@media(max-width: 768px){
.field-group__input--tag input[type=text]{min-width:auto}}

.field-group--addon{display:flex;position:relative;border-collapse:separate}

.field-group--addon input{align-self:center}

[dir="ltr"] .field-group--addon input{border-top-left-radius:0 !important;border-bottom-left-radius:0 !important}

[dir="rtl"] .field-group--addon input{border-top-right-radius:0 !important;border-bottom-right-radius:0 !important}

.field-group--addon span{align-self:center;background-color:rgba(15,31,44,.03);border:1px solid rgba(15,31,44,.18);font-size:1rem;line-height:1.9rem;padding:6px;font-weight:500;white-space:nowrap}

[dir="ltr"] .field-group--addon span{border-radius:4px 0 0 4px;border-right:0}

[dir="rtl"] .field-group--addon span{border-radius:0 4px 4px 0;border-left:0}

.field-group__btn{position:relative;white-space:nowrap;vertical-align:middle}

.field-group__btn .btn{position:relative}

[dir="ltr"] .field-group__btn .btn{margin-left:-1px;border-top-left-radius:0;border-bottom-left-radius:0}

[dir="rtl"] .field-group__btn .btn{margin-right:-1px;border-top-right-radius:0;border-bottom-right-radius:0}

.search-field .field-group__btn .combobox{max-width:16.6666666667rem;width:auto;min-width:auto}

.search-field .field-group__btn .btn{border-top:none;border-bottom:none;background-color:rgba(0,0,0,0)}

[dir="ltr"] .search-field .field-group__btn .btn{border-right:none}

[dir="rtl"] .search-field .field-group__btn .btn{border-left:none}

.search-field .field-group__btn .btn.btn-dropdown:after{line-height:3rem}

[dir="ltr"] .search-field .field-group__btn .btn.btn-dropdown:after{border-left:none}

[dir="rtl"] .search-field .field-group__btn .btn.btn-dropdown:after{border-right:none}

.field-group--focus .btn.btn-passive{transition:border-color .25s ease-in-out;border-color:#0062e3}

.field-group--focus .btn.btn-passive:not([disabled]):not(.is_disabled):not(.inactive):not(:active):hover{border-color:#0062e3}

.org-selector{z-index:1}

.org-selector__container{background-color:rgba(15,31,44,.08);border-radius:3rem;position:relative}

.org-selector__button{height:4rem;padding:.75rem 1.75rem .75rem 1.75rem;color:rgba(15,31,44,.61);font-size:1rem;white-space:nowrap;font-weight:500}

.org-selector__button-wrap{z-index:2}

.org-selector__button--active{color:#0f1f2c}

.org-selector__button:hover,.org-selector__button:focus{color:#0f1f2c}

.org-selector__slider{color:#0f1f2c;border:1px solid rgba(15,31,44,.18);background-color:#fff;border-radius:3rem;position:absolute;top:0}

@media(max-width: 768px){
.org-selector{text-align:center;width:100%}}

.btn-sort{position:relative;color:#0f1f2c;overflow:visible}

[dir="ltr"] .btn-sort{padding:.5rem 1.5rem .5rem 0;text-align:left}

[dir="rtl"] .btn-sort{padding:.5rem 0 .5rem 1.5rem;text-align:right}

.btn-sort:after{content:"";position:absolute;width:0;height:0;border-left:4px solid rgba(0,0,0,0);border-right:4px solid rgba(0,0,0,0);border-top:4px solid rgba(15,31,44,.45);bottom:calc(.5rem + 2px)}

[dir="ltr"] .btn-sort:after{right:5px}

[dir="rtl"] .btn-sort:after{left:5px}

.btn-sort:before{content:"";position:absolute;width:0;height:0;border-left:4px solid rgba(0,0,0,0);border-right:4px solid rgba(0,0,0,0);border-bottom:4px solid rgba(15,31,44,.45);bottom:calc(.5rem + 8px)}

[dir="ltr"] .btn-sort:before{right:5px}

[dir="rtl"] .btn-sort:before{left:5px}

.btn-sort--down:before{border-bottom:4px solid #0f1f2c}

.btn-sort--up:after{border-top:4px solid #0f1f2c}

.btn-sort--disabled{cursor:default}

[dir="ltr"] .btn-sort--disabled{padding-right:0}

[dir="rtl"] .btn-sort--disabled{padding-left:0}

.btn-sort--disabled:after,.btn-sort--disabled:before{display:none}

.keyboard-focus .btn-sort:focus{text-decoration:underline}

.file-upload-btn{position:relative;font-size:1rem;line-height:1.5rem;font-weight:400;color:rgba(15,31,44,.61);border:2px dashed rgba(15,31,44,.18);width:100%;padding:1rem;vertical-align:middle;border-radius:5px}

[dir="ltr"] .file-upload-btn{text-align:left}

[dir="rtl"] .file-upload-btn{text-align:right}

.file-upload-btn.dragover{opacity:.5}

.file-upload-btn__input{position:absolute;top:0;right:0;z-index:2;height:100%;width:100%;cursor:pointer;bottom:0;left:0;opacity:0;-ms-filter:"alpha(opacity=0)"}

.file-upload-btn__input[disabled]{cursor:default}

.file-upload-btn--focus{border-color:#0062e3}

button.file-upload-btn{position:static}

[dir="ltr"] button.file-upload-btn{padding:1rem 1rem 1rem 4rem}

[dir="rtl"] button.file-upload-btn{padding:1rem 4rem 1rem 1rem}

button.file-upload-btn:before{font-size:2rem;color:#0062e3;vertical-align:middle;position:absolute}

[dir="ltr"] button.file-upload-btn:before{left:2rem}

[dir="rtl"] button.file-upload-btn:before{right:2rem}

.add-btn{cursor:pointer;padding:.5rem;display:inline-block}

.add-btn__text{font-size:1rem;font-weight:600;vertical-align:middle;color:#0062e3}

[dir="ltr"] .add-btn__text{padding-left:.5rem}

[dir="rtl"] .add-btn__text{padding-right:.5rem}

.add-btn__icon{color:#0062e3;vertical-align:middle}

.split-button__primary-action{padding:calc(.8rem - 1px) 1.5rem}

[dir="ltr"] .split-button__primary-action{border-top-right-radius:0;border-bottom-right-radius:0;border-right:1px solid rgba(15,31,44,.18)}

[dir="rtl"] .split-button__primary-action{border-top-left-radius:0;border-bottom-left-radius:0;border-left:1px solid rgba(15,31,44,.18)}

.split-button__secondary-action{padding:calc(.8rem - 1px) .75rem}

[dir="ltr"] .split-button__secondary-action{border-top-left-radius:0;border-bottom-left-radius:0;border-left:1px solid rgba(15,31,44,.18);margin-left:-1px}

[dir="rtl"] .split-button__secondary-action{border-top-right-radius:0;border-bottom-right-radius:0;border-right:1px solid rgba(15,31,44,.18);margin-right:-1px}

.split-button--disabled{cursor:default;text-shadow:none;background-color:rgba(0,98,227,.5) !important;border:none !important;pointer-events:none;color:#cce0f9 !important}

.account{height:100%}

.account .l_max-screen{padding-top:0}

.account .l_body-wrap{height:100%;width:100%;z-index:auto}

.account .l_main{height:100%}

.account .grid.grid--full-width{max-width:none}

.account__title{font-family:Antonia-Heavy;font-size:5rem}

.account__label{line-height:2.6666666667rem}

.account__message{font-size:1.3333333333rem;margin:0 auto 2rem;text-align:center;max-width:28rem}

.account__message--error{color:#de0546}

.account__sub-message{color:rgba(15,31,44,.61);font-size:1rem;text-align:center;margin-bottom:2rem;margin-top:5rem}

.account__sub-message a{color:#0062e3;font-weight:800}

.account__form-message{color:rgba(15,31,44,.61);font-size:1rem;font-weight:600;margin:0 auto;padding:1rem 0 2rem;text-align:center;width:28rem}

@media(max-width: 768px){
.account__title{font-size:3.5rem}}

.account__photo{background:url("/content/img/public/login/login-photo-new.png") center center;background-size:cover}

.account__form{padding-left:1.25rem;padding-right:1.25rem}

[dir="ltr"] .account__form{text-align:right}

[dir="rtl"] .account__form{text-align:left}

.account__wrapper{margin:0 auto;width:37rem}

@media(max-width: 768px){
.account__wrapper{width:100%}}

.account__checkbox{font-size:1rem;line-height:1.5rem}

[dir="ltr"] .account__checkbox{margin-right:.75rem}

[dir="rtl"] .account__checkbox{margin-left:.75rem}

.account__hide-password{border:1px solid red;height:1px;overflow:hidden;position:absolute;top:1px;width:1px;z-index:-1}

[dir="ltr"] .account__hide-password{left:-1px}

[dir="rtl"] .account__hide-password{right:-1px}

.account__textfield{position:relative;margin-top:4.1666666667rem}

.account__textfield input{color:#80888f;font-size:1.5rem;font-weight:500;display:block;width:100%;border:none;border-bottom:2px solid #80888f;border-radius:0;line-height:normal;outline:none}

[dir="ltr"] .account__textfield input{padding:.8333333333rem .8333333333rem 1.25rem 0}

[dir="rtl"] .account__textfield input{padding:.8333333333rem 0 1.25rem .8333333333rem}

[dir="ltr"] .account__textfield input.account__textfield--username{padding-right:3rem}

[dir="rtl"] .account__textfield input.account__textfield--username{padding-left:3rem}

.account__textfield input::placeholder{opacity:1;transition:all .2s}

.account__textfield input:placeholder-shown:not(:focus)::placeholder{opacity:0}

.account__textfield input:placeholder-shown:not(:focus)+label{color:#80888f;top:.4166666667rem;font-size:1.5rem}

.account__textfield input:not(:placeholder-shown):not(:focus)+label{color:#80888f !important}

.account__textfield label{position:absolute;color:#0062e3;font-size:1.1666666667rem;font-weight:500;top:-2.5rem;pointer-events:none;transition:.2s ease all}

[dir="ltr"] .account__textfield label{left:0}

[dir="rtl"] .account__textfield label{right:0}

.account__textfield span{position:absolute;top:.4166666667rem}

[dir="ltr"] .account__textfield span{right:.8333333333rem}

[dir="rtl"] .account__textfield span{left:.8333333333rem}

.account .submit-button{font-size:1.1666666667rem;line-height:2rem;padding-top:1rem;padding-bottom:1rem}

.account .link{font-weight:700}

.account input.signup-textfield{line-height:2.6666666667rem;font-size:1.3333333333rem;font-weight:500}

.account .tooltip{opacity:1 !important}

.account__tooltip-icon{position:absolute;top:.9166666667rem}

[dir="ltr"] .account__tooltip-icon{right:.8333333333rem}

[dir="rtl"] .account__tooltip-icon{left:.8333333333rem}

.account__signup-login-now{line-height:2rem}

[dir="ltr"] .account__signup-invalid-input .form__invalid-msg{padding-left:1rem}

[dir="rtl"] .account__signup-invalid-input .form__invalid-msg{padding-right:1rem}

@media(max-width: 1020px){
.account__photo{display:none !important}}

@media(max-width: 768px){
.account__textfield label{font-size:1.0833333333rem !important}}

.dropdown:not(.absolute){position:relative}

.dropdown-menu{position:absolute;top:100%;z-index:5;min-width:200px;margin:.5rem 0 0 0;list-style:none;font-size:14px;background-color:#fff;border:1px solid rgba(15,31,44,.18);background-clip:padding-box;box-shadow:0 1px 2px 0 rgba(15,31,44,.18);transform:translate(0, -1rem);visibility:hidden;opacity:0;/*!!!!!!!!!!!!!!
  * IMPORTANT: If timing (0.4s) changes below you must also change it
  *            on the $timeouts in role.ts and actionOptions.ts as well
  * Also: don't use "all" here, it causes problems with dynamically positioned elements
  !!!!!!!!!!!!!!!*/transition:visibility .4s cubic-bezier(0.68, -0.55, 0.265, 1.55),opacity .4s cubic-bezier(0.68, -0.55, 0.265, 1.55),transform .4s cubic-bezier(0.68, -0.55, 0.265, 1.55)}

[dir="ltr"] .dropdown-menu{left:0}

[dir="rtl"] .dropdown-menu{right:0}

.dropdown-menu.typeahead-popup{max-height:50vh;overflow-y:scroll}

.dropdown-menu.typeahead-popup strong{font-weight:600}

.dropdown-menu.typeahead-popup.dropdown-menu__arrow{max-height:none;overflow:visible;width:100%}

.dropdown-menu.typeahead-popup.dropdown-menu__arrow .typeahead-list{overflow-y:scroll}

.dropdown-menu__min-height{min-height:31rem}

.dropdown-menu__min-height .typeahead-list{max-height:23rem}

.dropdown-menu__min-height .h4+.typeahead-list{max-height:20.5rem}

.dropdown-menu__min-height--with-header .typeahead-list{max-height:26rem}

.dropdown-menu__arrow{margin-top:.5rem}

.search-field .dropdown-menu__arrow{margin-top:1rem}

.dropdown-menu__arrow:before,.dropdown-menu__arrow:after{content:"";width:0;height:0;font-size:0;border:.5rem solid rgba(0,0,0,0);border-width:.5rem .75rem;position:absolute;top:-1rem}

[dir="ltr"] .dropdown-menu__arrow:before,[dir="ltr"] .dropdown-menu__arrow:after{left:1rem}

[dir="rtl"] .dropdown-menu__arrow:before,[dir="rtl"] .dropdown-menu__arrow:after{right:1rem}

.dropdown-menu__arrow:before{z-index:1;border-bottom-color:rgba(15,31,44,.18);margin-top:-1px}

.dropdown-menu__arrow:after{z-index:2;border-bottom-color:#fff;margin-top:1px}

.dropdown-menu__arrow.dark-arrow:after{border-bottom-color:rgba(15,31,44,.03)}

[dir="ltr"] .dropdown-menu__arrow.dropdown-menu-right:before,[dir="ltr"] .dropdown-menu__arrow.dropdown-menu-right:after{right:1rem;left:auto}

[dir="rtl"] .dropdown-menu__arrow.dropdown-menu-right:before,[dir="rtl"] .dropdown-menu__arrow.dropdown-menu-right:after{left:1rem;right:auto}

[dir="ltr"] .dropdown-menu__arrow--middle:before,[dir="ltr"] .dropdown-menu__arrow--middle:after,[dir="ltr"] .dropdown-menu__arrow.arrow--top-middle:before,[dir="ltr"] .dropdown-menu__arrow.arrow--top-middle:after,[dir="ltr"] .dropdown-menu__arrow.arrow--bottom-middle:before,[dir="ltr"] .dropdown-menu__arrow.arrow--bottom-middle:after{left:50%;transform:translateX(-50%)}

[dir="rtl"] .dropdown-menu__arrow--middle:before,[dir="rtl"] .dropdown-menu__arrow--middle:after,[dir="rtl"] .dropdown-menu__arrow.arrow--top-middle:before,[dir="rtl"] .dropdown-menu__arrow.arrow--top-middle:after,[dir="rtl"] .dropdown-menu__arrow.arrow--bottom-middle:before,[dir="rtl"] .dropdown-menu__arrow.arrow--bottom-middle:after{right:50%;transform:translateX(50%)}

.dropdown-menu__arrow.arrow--bottom-middle:before,.dropdown-menu__arrow.arrow--bottom-middle:after,.dropdown-menu__arrow.arrow--top-left:before,.dropdown-menu__arrow.arrow--top-left:after,.dropdown-menu__arrow.arrow--top-right:before,.dropdown-menu__arrow.arrow--top-right:after{border-bottom-color:rgba(0,0,0,0)}

.dropdown-menu__arrow.arrow--bottom-middle:before{border-top-color:rgba(15,31,44,.18);margin-bottom:-1px}

.dropdown-menu__arrow.arrow--bottom-middle:after{border-top-color:#fff;margin-bottom:1px}

[dir="ltr"] .dropdown-menu__arrow.arrow--top-left:before{border-right-color:rgba(15,31,44,.18);margin-left:-1px}

[dir="rtl"] .dropdown-menu__arrow.arrow--top-left:before{border-left-color:rgba(15,31,44,.18);margin-right:-1px}

[dir="ltr"] .dropdown-menu__arrow.arrow--top-left:after{border-right-color:#fff;margin-left:1px}

[dir="rtl"] .dropdown-menu__arrow.arrow--top-left:after{border-left-color:#fff;margin-right:1px}

[dir="ltr"] .dropdown-menu__arrow.arrow--top-right:before{border-left-color:rgba(15,31,44,.18);margin-right:-1px}

[dir="rtl"] .dropdown-menu__arrow.arrow--top-right:before{border-right-color:rgba(15,31,44,.18);margin-left:-1px}

[dir="ltr"] .dropdown-menu__arrow.arrow--top-right:after{border-left-color:#fff;margin-right:1px}

[dir="rtl"] .dropdown-menu__arrow.arrow--top-right:after{border-right-color:#fff;margin-left:1px}

.dropdown-menu__arrow.arrow--bottom-middle:before,.dropdown-menu__arrow.arrow--bottom-middle:after{top:auto;bottom:-1rem;margin-top:0}

.dropdown-menu__arrow.arrow--top-left:before,.dropdown-menu__arrow.arrow--top-left:after,.dropdown-menu__arrow.arrow--top-right:before,.dropdown-menu__arrow.arrow--top-right:after{top:1rem;margin-top:0;border-width:.75rem .5rem}

.dropdown-menu__arrow.arrow--top-left:before,.dropdown-menu__arrow.arrow--top-left:after{border-width:.75rem .5rem}

[dir="ltr"] .dropdown-menu__arrow.arrow--top-left:before,[dir="ltr"] .dropdown-menu__arrow.arrow--top-left:after{left:-1rem}

[dir="rtl"] .dropdown-menu__arrow.arrow--top-left:before,[dir="rtl"] .dropdown-menu__arrow.arrow--top-left:after{right:-1rem}

[dir="ltr"] .dropdown-menu__arrow.arrow--top-right:before,[dir="ltr"] .dropdown-menu__arrow.arrow--top-right:after{left:auto;right:-1rem}

[dir="rtl"] .dropdown-menu__arrow.arrow--top-right:before,[dir="rtl"] .dropdown-menu__arrow.arrow--top-right:after{right:auto;left:-1rem}

[dir="ltr"] .dropdown-menu__arrow.arrow--hard-right:before,[dir="ltr"] .dropdown-menu__arrow.arrow--hard-right:after{left:auto;right:.5rem}

[dir="rtl"] .dropdown-menu__arrow.arrow--hard-right:before,[dir="rtl"] .dropdown-menu__arrow.arrow--hard-right:after{right:auto;left:.5rem}

.dropdown-menu__arrow.dropdown-menu--left{margin-top:0}

[dir="ltr"] .dropdown-menu__arrow.dropdown-menu--left{margin-right:.5rem}

[dir="rtl"] .dropdown-menu__arrow.dropdown-menu--left{margin-left:.5rem}

.dropdown-menu__arrow.dropdown-menu--right{margin-top:0}

[dir="ltr"] .dropdown-menu__arrow.dropdown-menu--right{margin-left:.5rem}

[dir="rtl"] .dropdown-menu__arrow.dropdown-menu--right{margin-right:.5rem}

.dropdown-menu__arrow.dropdown-menu--top{margin-top:0;margin-bottom:.5rem}

.dropdown-menu--bottom{transform:translate(0, -1rem)}

.dropdown-menu--top{transform:translate(0, 1rem)}

[dir="ltr"] .dropdown-menu--left{transform:translate(1rem, 0)}

[dir="rtl"] .dropdown-menu--left{transform:translate(-1rem, 0)}

[dir="ltr"] .dropdown-menu--right{transform:translate(-1rem, 0)}

[dir="rtl"] .dropdown-menu--right{transform:translate(1rem, 0)}

.dropdown-menu--inline{padding:.75rem .5rem !important}

.dropdown-menu--inline li>a{clear:none !important;padding:.1rem !important}

.dropdown-menu--inline li>a:hover,.dropdown-menu--inline li>a:focus{text-decoration:underline}

.dropdown-menu--inline li>a:after{content:","}

.dropdown-menu--inline li:last-child>a:after{content:""}

ul.dropdown-menu,.typeahead-list{padding:.5rem 0}

[dir="ltr"] .dropdown-menu.pull-right{left:auto;right:0}

[dir="rtl"] .dropdown-menu.pull-right{right:auto;left:0}

[dir="ltr"] .dropdown-menu-right{left:auto;right:0}

[dir="rtl"] .dropdown-menu-right{right:auto;left:0}

[dir="ltr"] .dropdown-menu-left{left:0;right:auto}

[dir="rtl"] .dropdown-menu-left{right:0;left:auto}

[dir="ltr"] .dropdown-menu-middle{left:50%;right:auto;transform:translateX(-50%)}

[dir="rtl"] .dropdown-menu-middle{right:50%;left:auto;transform:translateX(50%)}

.dropdown-menu .divider{height:1px;margin:9px 0;overflow:hidden;background-color:#d4d7d9}

.dropdown-menu--borders .dropdown-menu>li,.dropdown-menu--borders .dropdown-menu .typeahead-item{margin:0 1rem;border-bottom:1px solid rgba(15,31,44,.18);position:relative}

.dropdown-menu--borders .dropdown-menu>li.active:before,.dropdown-menu--borders .dropdown-menu>li.active:after,.dropdown-menu--borders .dropdown-menu .typeahead-item.active:before,.dropdown-menu--borders .dropdown-menu .typeahead-item.active:after{content:"";position:absolute;top:0;bottom:0;width:1rem;background-color:rgba(15,31,44,.03)}

[dir="ltr"] .dropdown-menu--borders .dropdown-menu>li.active:before,[dir="ltr"] .dropdown-menu--borders .dropdown-menu .typeahead-item.active:before{right:100%}

[dir="rtl"] .dropdown-menu--borders .dropdown-menu>li.active:before,[dir="rtl"] .dropdown-menu--borders .dropdown-menu .typeahead-item.active:before{left:100%}

[dir="ltr"] .dropdown-menu--borders .dropdown-menu>li.active:after,[dir="ltr"] .dropdown-menu--borders .dropdown-menu .typeahead-item.active:after{left:100%}

[dir="rtl"] .dropdown-menu--borders .dropdown-menu>li.active:after,[dir="rtl"] .dropdown-menu--borders .dropdown-menu .typeahead-item.active:after{right:100%}

.dropdown-menu--borders .dropdown-menu>li:last-child,.dropdown-menu--borders .dropdown-menu .typeahead-item:last-child{border-bottom:none}

.dropdown-menu:not(.dropdown-menu--radio)>li>a,.dropdown-menu>li>div,.dropdown-menu[role=menu]>li>button,div[role=menu] li>button,div[role=menu] li>a,.listbox--option{padding:.5rem 1rem;font-size:12px;clear:both;font-weight:normal;line-height:1.5em;color:rgba(15,31,44,.61);background-color:rgba(0,0,0,0)}

.dropdown-menu:not(.dropdown-menu--radio)>li>a:disabled,.dropdown-menu:not(.dropdown-menu--radio)>li>a.is_disabled,.dropdown-menu>li>div:disabled,.dropdown-menu>li>div.is_disabled,.dropdown-menu[role=menu]>li>button:disabled,.dropdown-menu[role=menu]>li>button.is_disabled,div[role=menu] li>button:disabled,div[role=menu] li>button.is_disabled,div[role=menu] li>a:disabled,div[role=menu] li>a.is_disabled,.listbox--option:disabled,.listbox--option.is_disabled{opacity:.5;cursor:not-allowed}

.dropdown-menu:not(.dropdown-menu--radio)>li>a:disabled:hover,.dropdown-menu:not(.dropdown-menu--radio)>li>a.is_disabled:hover,.dropdown-menu>li>div:disabled:hover,.dropdown-menu>li>div.is_disabled:hover,.dropdown-menu[role=menu]>li>button:disabled:hover,.dropdown-menu[role=menu]>li>button.is_disabled:hover,div[role=menu] li>button:disabled:hover,div[role=menu] li>button.is_disabled:hover,div[role=menu] li>a:disabled:hover,div[role=menu] li>a.is_disabled:hover,.listbox--option:disabled:hover,.listbox--option.is_disabled:hover{background:none}

.dropdown-menu:not(.dropdown-menu--radio)>li>a:not(.l_flexbar):not(.l_flex),.dropdown-menu>li>div:not(.l_flexbar):not(.l_flex),.dropdown-menu[role=menu]>li>button:not(.l_flexbar):not(.l_flex),div[role=menu] li>button:not(.l_flexbar):not(.l_flex),div[role=menu] li>a:not(.l_flexbar):not(.l_flex),.listbox--option:not(.l_flexbar):not(.l_flex){display:block}

.dropdown-menu[role=menu]>li>a,.dropdown-menu[role=menu]>li>button,div[role=menu] li>button,div[role=menu] li>a,.listbox--option{word-wrap:break-word;overflow-wrap:break-word;word-break:break-word}

.dropdown-menu[role=menu]>li>button,div[role=menu] li>button,div[role=menu] li>a,.listbox--option{width:100%;background-color:rgba(0,0,0,0)}

[dir="ltr"] .dropdown-menu[role=menu]>li>button,[dir="ltr"] div[role=menu] li>button,[dir="ltr"] div[role=menu] li>a,[dir="ltr"] .listbox--option{text-align:left}

[dir="rtl"] .dropdown-menu[role=menu]>li>button,[dir="rtl"] div[role=menu] li>button,[dir="rtl"] div[role=menu] li>a,[dir="rtl"] .listbox--option{text-align:right}

.dropdown-menu[role=menu]:not(.dropdown-menu--radio) li>a:hover,.keyboard-focus .dropdown-menu[role=menu]:not(.dropdown-menu--radio) li>a:focus,.dropdown-menu[role=listbox]>li>a:hover,.keyboard-focus .dropdown-menu[role=listbox]>li>a:focus,.dropdown-menu[role=menu]>li>button:hover,.keyboard-focus .dropdown-menu[role=menu]>li>button:focus,.listbox--option:hover,.listbox--option:focus,.listbox--option-focus{color:#0f1f2c;background-color:rgba(15,31,44,.03)}

.listbox--option-selected{color:#0f1f2c;background-color:rgba(15,31,44,.08);font-weight:500}

.dropdown-menu>.active>a,.dropdown-menu>.active>a:hover,.dropdown-menu>.active>a:focus,.dropdown-menu>.active>div,.dropdown-menu>.active>div:hover,.dropdown-menu>.active>div:focus,.dropdown-menu[role=menu]>.active>button,.dropdown-menu[role=menu]>.active>button:hover,.dropdown-menu[role=menu]>.active>button:focus,.typeahead-item.active,.typeahead-item.active,.typeahead-item.active,.typeahead-item__hoverable:hover,.dropdown .active>.listbox--option,.dropdown .active>.listbox--option:hover,.dropdown .active>.listbox--option:focus,.dropdown .active>.listbox--option-focus{color:#0f1f2c;text-decoration:none;cursor:pointer;background-color:rgba(15,31,44,.03)}

.dropdown-menu>.disabled>a,.dropdown-menu>.disabled>div,.dropdown-menu[role=menu]>.disabled>button,.dropdown .disabled>.listbox--option{color:rgba(15,31,44,.61)}

.dropdown-menu>.disabled>a:hover,.dropdown-menu>.disabled>a:focus,.dropdown-menu>.disabled>div:hover,.dropdown-menu>.disabled>div:focus,.dropdown-menu[role=menu]>.disabled>button:hover,.dropdown-menu[role=menu]>.disabled>button:focus,.dropdown .disabled>.listbox--option:hover,.dropdown .disabled>.listbox--option:focus,.dropdown .disabled>.listbox--option-focus{color:rgba(15,31,44,.61);text-decoration:none;background-color:rgba(0,0,0,0);background-image:none;filter:progid:DXImageTransform.Microsoft.gradient(enabled = false);cursor:not-allowed}

.open>.dropdown-menu,.dropdown-menu--open{display:block;visibility:visible;opacity:1;transform:translate(0, 0)}

.dropdown-header{display:block;padding:3px 20px;font-size:12px;line-height:1.42857143;color:rgba(15,31,44,.61)}

.dropdown-backdrop{position:fixed;left:0;right:0;bottom:0;top:0;z-index:990}

[dir="ltr"] .pull-right>.dropdown-menu{right:0;left:auto}

[dir="rtl"] .pull-right>.dropdown-menu{left:0;right:auto}

.dropup .dropdown-menu{top:auto;bottom:100%;margin-bottom:1px}

.marketing.full-width .dropdown-menu{width:100%;overflow:hidden}

.marketing.full-width.scroll .dropdown-menu{overflow-y:scroll;max-height:260px}

.marketing .dropdown-menu li a{font-size:1rem;padding:1rem}

.badge{display:inline-block;min-width:10px;padding:3px 6px;font-size:12px;font-weight:bold;color:#fff;line-height:1;vertical-align:baseline;white-space:nowrap;text-align:center;background-color:rgba(15,31,44,.61);border-radius:10px}

.badge:empty{display:none}

.btn .badge{position:relative;top:-1px}

.btn-xs .badge{top:0;padding:1px 5px}

a.badge:hover,a.badge:focus{color:#fff;text-decoration:none;cursor:pointer}

.nav-pills>.active>a>.badge{color:#428bca;background-color:#fff}

[dir="ltr"] .nav-pills>li>a>.badge{margin-left:3px}

[dir="rtl"] .nav-pills>li>a>.badge{margin-right:3px}

.tooltip{white-space:normal;position:absolute;z-index:1100;display:block;visibility:visible;font-size:1rem;opacity:0;filter:alpha(opacity=0)}

.tooltip .tooltip-arrow:before,.tooltip .tooltip-arrow:after{position:absolute;width:0;height:0;border-color:rgba(0,0,0,0);border-style:solid}

.tooltip .tooltip-arrow:before{z-index:1}

.tooltip .tooltip-arrow:after{z-index:2}

.tooltip.top{margin-top:-3px;padding:.5rem 0}

.tooltip.top .tooltip-arrow:before,.tooltip.top .tooltip-arrow:after{content:"";bottom:0;border-width:.5rem .75rem 0}

[dir="ltr"] .tooltip.top .tooltip-arrow:before,[dir="ltr"] .tooltip.top .tooltip-arrow:after{left:50%;margin-left:-0.75rem}

[dir="rtl"] .tooltip.top .tooltip-arrow:before,[dir="rtl"] .tooltip.top .tooltip-arrow:after{right:50%;margin-right:-0.75rem}

.tooltip.top .tooltip-arrow:after{border-top-color:#0f1f2c;bottom:2px}

.tooltip.right{padding:0 .5rem}

[dir="ltr"] .tooltip.right{margin-left:3px}

[dir="rtl"] .tooltip.right{margin-right:3px}

.tooltip.right .tooltip-arrow:before,.tooltip.right .tooltip-arrow:after{content:"";top:50%;margin-top:-0.75rem}

[dir="ltr"] .tooltip.right .tooltip-arrow:before,[dir="ltr"] .tooltip.right .tooltip-arrow:after{left:0;border-width:.75rem .5rem .75rem 0}

[dir="rtl"] .tooltip.right .tooltip-arrow:before,[dir="rtl"] .tooltip.right .tooltip-arrow:after{right:0;border-width:.75rem 0 .75rem .5rem}

[dir="ltr"] .tooltip.right .tooltip-arrow:after{border-right-color:#0f1f2c;left:2px}

[dir="rtl"] .tooltip.right .tooltip-arrow:after{border-left-color:#0f1f2c;right:2px}

.tooltip.bottom{margin-top:3px;padding:.5rem 0}

.tooltip.bottom .tooltip-arrow:before,.tooltip.bottom .tooltip-arrow:after{content:"";top:0;border-width:0 .75rem .5rem}

[dir="ltr"] .tooltip.bottom .tooltip-arrow:before,[dir="ltr"] .tooltip.bottom .tooltip-arrow:after{left:50%;margin-left:-0.75rem}

[dir="rtl"] .tooltip.bottom .tooltip-arrow:before,[dir="rtl"] .tooltip.bottom .tooltip-arrow:after{right:50%;margin-right:-0.75rem}

.tooltip.bottom .tooltip-arrow:after{border-bottom-color:#0f1f2c;top:2px}

.tooltip.left{padding:0 .5rem}

[dir="ltr"] .tooltip.left{margin-left:-3px}

[dir="rtl"] .tooltip.left{margin-right:-3px}

.tooltip.left .tooltip-arrow:before,.tooltip.left .tooltip-arrow:after{content:"";top:50%;margin-top:-0.75rem}

[dir="ltr"] .tooltip.left .tooltip-arrow:before,[dir="ltr"] .tooltip.left .tooltip-arrow:after{right:0;border-width:.75rem 0 .75rem .5rem}

[dir="rtl"] .tooltip.left .tooltip-arrow:before,[dir="rtl"] .tooltip.left .tooltip-arrow:after{left:0;border-width:.75rem .5rem .75rem 0}

[dir="ltr"] .tooltip.left .tooltip-arrow:after{border-left-color:#0f1f2c;right:2px}

[dir="rtl"] .tooltip.left .tooltip-arrow:after{border-right-color:#0f1f2c;left:2px}

[dir="ltr"] .tooltip.top-left,[dir="ltr"] .tooltip.bottom-left{margin-left:-0.25rem}

[dir="rtl"] .tooltip.top-left,[dir="rtl"] .tooltip.bottom-left{margin-right:-0.25rem}

[dir="ltr"] .tooltip.top-left .tooltip-arrow:before,[dir="ltr"] .tooltip.top-left .tooltip-arrow:after,[dir="ltr"] .tooltip.bottom-left .tooltip-arrow:before,[dir="ltr"] .tooltip.bottom-left .tooltip-arrow:after{left:1rem;right:auto}

[dir="rtl"] .tooltip.top-left .tooltip-arrow:before,[dir="rtl"] .tooltip.top-left .tooltip-arrow:after,[dir="rtl"] .tooltip.bottom-left .tooltip-arrow:before,[dir="rtl"] .tooltip.bottom-left .tooltip-arrow:after{right:1rem;left:auto}

[dir="ltr"] .tooltip.top-right,[dir="ltr"] .tooltip.bottom-right{margin-left:.5rem}

[dir="rtl"] .tooltip.top-right,[dir="rtl"] .tooltip.bottom-right{margin-right:.5rem}

[dir="ltr"] .tooltip.top-right .tooltip-arrow:before,[dir="ltr"] .tooltip.top-right .tooltip-arrow:after,[dir="ltr"] .tooltip.bottom-right .tooltip-arrow:before,[dir="ltr"] .tooltip.bottom-right .tooltip-arrow:after{left:auto;right:.5rem}

[dir="rtl"] .tooltip.top-right .tooltip-arrow:before,[dir="rtl"] .tooltip.top-right .tooltip-arrow:after,[dir="rtl"] .tooltip.bottom-right .tooltip-arrow:before,[dir="rtl"] .tooltip.bottom-right .tooltip-arrow:after{right:auto;left:.5rem}

.tooltip-inner{max-width:200px;padding:.75rem 1rem;text-align:center;text-decoration:none;font-weight:500;line-height:1.5rem;background-color:#0f1f2c;color:#fff;border-radius:.25rem;background-clip:padding-box;box-shadow:0 1px 2px 0 rgba(15,31,44,.36)}

.add-bundle-content-form .dg-modal__body{min-height:21rem}

.add-bundle-content-form .typeahead-list li .target-resource-list-item{border-bottom:1px solid rgba(15,31,44,.18)}

.add-bundle-content-form .typeahead-list li:last-child .target-resource-list-item{border-bottom:none}

.data-table{border-collapse:collapse;border-spacing:0;empty-cells:show;font-size:1rem;position:relative;width:100%}

.data-table caption{margin-top:2rem !important}

[dir="ltr"] .data-table caption{text-align:left;margin-left:2rem !important}

[dir="rtl"] .data-table caption{text-align:right;margin-right:2rem !important}

.data-table caption.top{caption-side:top}

.data-table caption.bottom{caption-side:bottom}

.data-table th{font-weight:600}

[dir="ltr"] .data-table th{text-align:left}

[dir="rtl"] .data-table th{text-align:right}

.data-table td{font-weight:500;margin:0;overflow:visible}

.data-table th,.data-table td{height:3rem}

[dir="ltr"] .data-table th,[dir="ltr"] .data-table td{padding:.5rem .5rem .5rem 0}

[dir="rtl"] .data-table th,[dir="rtl"] .data-table td{padding:.5rem 0 .5rem .5rem}

.data-table tbody tr{border-top:1px solid rgba(15,31,44,.18);border-bottom:1px solid rgba(15,31,44,.18)}

.data-table tbody tr:last-child{border-bottom:none}

.data-table tbody tr .show-on-hover{visibility:hidden}

.keyboard-focus .data-table tbody tr .show-on-hover{visibility:visible}

.data-table tbody tr:hover .show-on-hover,.data-table tbody tr:focus .show-on-hover{visibility:visible}

.data-table--sortable th,.data-table--sortable td{height:5rem}

[dir="ltr"] .data-table--sortable th,[dir="ltr"] .data-table--sortable td{padding:1rem 1rem 1rem 0}

[dir="rtl"] .data-table--sortable th,[dir="rtl"] .data-table--sortable td{padding:1rem 0 1rem 1rem}

.data-table--medium .data-table--sortable th,.data-table--medium .data-table--sortable td{height:3rem}

[dir="ltr"] .data-table--sortable th:first-child,[dir="ltr"] .data-table--sortable td:first-child{padding-left:1.5rem}

[dir="rtl"] .data-table--sortable th:first-child,[dir="rtl"] .data-table--sortable td:first-child{padding-right:1.5rem}

[dir="ltr"] .data-table--sortable th:last-child,[dir="ltr"] .data-table--sortable td:last-child{padding-right:1.5rem}

[dir="rtl"] .data-table--sortable th:last-child,[dir="rtl"] .data-table--sortable td:last-child{padding-left:1.5rem}

.data-table--sortable tbody tr{transition:background-color .15s ease-out}

.data-table--sortable tbody tr:hover{background-color:rgba(15,31,44,.03)}

.data-table--sortable tbody tr:focus-within{background-color:rgba(15,31,44,.03)}

.data-table--sortable tbody tr:last-child{border-bottom:none}

.data-table--sortable tbody tr.cta-row:hover{cursor:pointer}

.data-table--sortable tbody tr.cta-row:hover .btn-cta span{text-decoration:underline}

.data-table--report tbody tr{border-top:none}

.data-table--report th{border-bottom:1px solid rgba(15,31,44,.18)}

.data-table--report td:hover a,.data-table--report th:hover a{color:#0062e3}

.data-table--report .cell-size{width:120px}

[dir="ltr"] .data-table--report-table tr{border-left:2px solid rgba(15,31,44,.18)}

[dir="rtl"] .data-table--report-table tr{border-right:2px solid rgba(15,31,44,.18)}

.data-table--report-table:first-child{border-top:1px solid rgba(15,31,44,.18)}

[dir="ltr"] .data-table--report-table:last-child{border-left:none}

[dir="rtl"] .data-table--report-table:last-child{border-right:none}

[dir="ltr"] .data-table--short th{padding:.25rem .25rem .25rem 0}

[dir="rtl"] .data-table--short th{padding:.25rem 0 .25rem .25rem}

.data-table--short td{height:auto}

[dir="ltr"] .data-table--short td{padding:.25rem .25rem .25rem 0}

[dir="rtl"] .data-table--short td{padding:.25rem 0 .25rem .25rem}

[dir="ltr"] .data-table--short td:first-child{padding-left:.25rem}

[dir="rtl"] .data-table--short td:first-child{padding-right:.25rem}

.data-table--short tbody tr{border-top:none}

.data-table .completion-progress-bar{width:70%}

.data-table .completion-progress-bar .progress{display:block}

[dir="ltr"] .data-table-padding-2 .data-table--sortable th:first-child,[dir="ltr"] .data-table-padding-2 .data-table--sortable td:first-child{padding-left:2rem}

[dir="rtl"] .data-table-padding-2 .data-table--sortable th:first-child,[dir="rtl"] .data-table-padding-2 .data-table--sortable td:first-child{padding-right:2rem}

[dir="ltr"] .data-table-padding-2 .data-table--sortable th:last-child,[dir="ltr"] .data-table-padding-2 .data-table--sortable td:last-child{padding-right:2rem}

[dir="rtl"] .data-table-padding-2 .data-table--sortable th:last-child,[dir="rtl"] .data-table-padding-2 .data-table--sortable td:last-child{padding-left:2rem}

.data-table-padding-2 .data-table--no-shadow-wrapper{box-shadow:none !important;border-radius:0;padding:0}

.data-table-padding-2 .data-table--no-shadow-wrapper caption{margin-top:2rem !important;margin-bottom:1rem}

[dir="ltr"] .data-table-padding-2 .data-table--no-shadow-wrapper caption{text-align:left;margin-left:0 !important}

[dir="rtl"] .data-table-padding-2 .data-table--no-shadow-wrapper caption{text-align:right;margin-right:0 !important}

.data-table-padding-2 .data-table--no-shadow-wrapper caption.top{caption-side:top}

.data-table-padding-2 .data-table--no-shadow-wrapper caption.bottom{caption-side:bottom}

.data-table-padding-2 .data-table--no-shadow-wrapper th,.data-table-padding-2 .data-table--no-shadow-wrapper td{height:3rem}

[dir="ltr"] .data-table-padding-2 .data-table--no-shadow-wrapper th:first-child{padding-left:0}

[dir="rtl"] .data-table-padding-2 .data-table--no-shadow-wrapper th:first-child{padding-right:0}

[dir="ltr"] .data-table-padding-2 .data-table--no-shadow-wrapper th:last-child{padding-right:0}

[dir="rtl"] .data-table-padding-2 .data-table--no-shadow-wrapper th:last-child{padding-left:0}

[dir="ltr"] .data-table-padding-2 .data-table--no-shadow-wrapper td:first-child{padding-left:0}

[dir="rtl"] .data-table-padding-2 .data-table--no-shadow-wrapper td:first-child{padding-right:0}

[dir="ltr"] .data-table-padding-2 .data-table--no-shadow-wrapper td:last-child{padding-right:1rem}

[dir="rtl"] .data-table-padding-2 .data-table--no-shadow-wrapper td:last-child{padding-left:1rem}

[dir="ltr"] .column-range .highcharts-axis-labels span{left:0 !important}

[dir="rtl"] .column-range .highcharts-axis-labels span{right:0 !important}

.column-range.loading .highcharts-yaxis-labels{display:none}

[dir="ltr"] .vbargraph{margin-left:-0.6rem}

[dir="rtl"] .vbargraph{margin-right:-0.6rem}

.highcharts__tooltip{text-align:center;line-height:1.5em}

.highcharts__tooltip__label{font-weight:bold}

.skill-ratings-chart .highcharts-graph{display:none}

[dir="ltr"] .reporting-range-picker__button.btn.btn-dropdown{padding:.5rem 4rem .5rem 1rem}

[dir="rtl"] .reporting-range-picker__button.btn.btn-dropdown{padding:.5rem 1rem .5rem 4rem}

.legend{position:relative}

.legend__symbol--block{width:1.5rem;height:1.5rem;border-radius:2px}

.legend__symbol--point{width:8px;height:8px;border-radius:50%}

.legend__symbol--range{width:1.5rem;height:8px;border-radius:4px}

.legend__symbol--target{width:22px;height:10px;background-image:url("/content/img/targetmarker.svg")}

[dir="ltr"] .legend__symbol--target{transform:rotate(90deg);margin-left:-6px;margin-right:-12px}

[dir="rtl"] .legend__symbol--target{transform:rotate(-90deg);margin-right:-6px;margin-left:-12px}

.legend__label,[class^=legend__symbol--]{display:inline-block;vertical-align:middle}

@media only screen and (max-width: 587px){
[dir="ltr"] .legend__label{margin-left:0 !important}

[dir="rtl"] .legend__label{margin-right:0 !important}}

@media only screen and (max-width: 420px){
[dir="ltr"] .legend .ib{margin-right:0 !important}

[dir="rtl"] .legend .ib{margin-left:0 !important}}

.progress{background-color:rgba(15,31,44,.18)}

.progress .progress-bar{background-color:#00a6a6}

.box,.stacked-cards--double:before,.stacked-cards:after,.tile-card,.input-card,.modal-content,.tile,.data-table--sortable-wrapper,.data-table-padding-2 .data-table--sortable-wrapper{border-radius:.5rem;box-shadow:0px 1px 5px 0px rgba(15,31,44,.25);background-color:#fff}

.tile{height:100%;position:relative}

.tile--clickable{cursor:pointer;transition:box-shadow .1s ease-in-out}

.tile--clickable:hover,.keyboard-focus .tile--clickable:focus{box-shadow:0px 2px 8px 0px rgba(15,31,44,.25)}

.keyboard-focus .tile--clickable.tile:focus,.keyboard-focus .tile--clickable:focus .tile{border-color:rgba(15,31,44,.45)}

.keyboard-focus .tile--clickable.tile:focus .tile__title,.keyboard-focus .tile--clickable:focus .tile .tile__title{text-decoration:underline}

.tile--clickable:hover .tile__main-link{text-decoration:underline}

[dg-sortable]:not([disable-drag=true]) .tile--clickable{cursor:move}

.tile__wrapper{position:relative;height:100%}

.tile__wrapper--collection{width:100%}

.tile__provider-logo{margin:.5rem 1rem 0 1rem;height:3rem}

.tile__meta li{color:rgba(15,31,44,.61);font-weight:500;font-size:1rem}

[dir="ltr"] .tile__meta li{margin-right:.3rem}

[dir="rtl"] .tile__meta li{margin-left:.3rem}

.tile__meta li:nth-child(n):before{content:"•";font-size:.5em;vertical-align:middle}

[dir="ltr"] .tile__meta li:nth-child(n):before{padding-right:.3rem}

[dir="rtl"] .tile__meta li:nth-child(n):before{padding-left:.3rem}

.tile__meta li:first-child:before,.tile__meta li:nth-child(2):before{display:none}

.tile__body{margin-bottom:1rem}

.tile__body--bleed{margin-left:0;margin-right:0}

.tile__body--bleed .tile__details{margin-left:1rem;margin-right:1rem}

.tile__title:focus{text-decoration:underline}

.tile__summary{margin:0 1rem}

.tile__tags{margin:0 1rem}

.tile__footer{border-radius:0 0 .5rem .5rem}

.tile__footer--content{border-radius:0 0 .5rem .5rem;width:100%;z-index:4}

.tile__comment{bottom:0;position:absolute;z-index:4;width:100%}

.tile__comment-dismiss{top:-2rem;position:absolute}

[dir="ltr"] .tile__comment-dismiss{right:0}

[dir="rtl"] .tile__comment-dismiss{left:0}

.tile__comment-dismiss .dg-icon{color:rgba(15,31,44,.61)}

.tile__comment-dismiss:hover .dg-icon,.tile__comment-dismiss:focus .dg-icon,.keyboard-focus .tile__comment-dismiss:focus .dg-icon{color:#0062e3;box-shadow:0 0 2px 0 #0062e3}

.tile__actions{border-radius:4px;height:3.75rem}

.tile__actions:after{content:"";position:absolute;top:0;width:100%;height:1rem;opacity:0;transition:opacity .3s ease-in}

[dir="ltr"] .tile__actions:after{left:0}

[dir="rtl"] .tile__actions:after{right:0}

.tile__actions--shadow:after{opacity:1}

.tile__image{max-height:15rem;overflow:hidden}

.tile--stub{border:none;box-shadow:none;background-color:rgba(15,31,44,.03)}

.tile--stub:hover,.tile--stub:focus{box-shadow:initial}

.tile__placeholder{background-color:rgba(15,31,44,.03);box-shadow:none}

.tile--tag{min-height:22rem}

.tile--skillstandardsskill{min-height:22rem}

.tile--skillstandardssegment{min-height:22rem}

.tile--target{min-height:28rem}

.tile--target--no-footer{padding-bottom:1rem;min-height:12rem}

.tile--target--without-image{min-height:14rem}

.tile--profile{min-height:16rem}

.tile--group{min-height:16rem}

.tile--user{min-height:24rem}

.tile--pathway{min-height:290px}

.tile--provider{min-height:160px}

.tile--any{min-height:22rem}

.tile--content,.tile--input,.tile--outcome{min-height:30rem;margin:0 auto;padding-bottom:0}

.tile--content:hover.tile--clickable .tile__title,.tile__clickable .tile--content:hover .tile__title,.tile--input:hover.tile--clickable .tile__title,.tile__clickable .tile--input:hover .tile__title,.tile--outcome:hover.tile--clickable .tile__title,.tile__clickable .tile--outcome:hover .tile__title{text-decoration:underline}

.tile--content .tile--image-shadow,.tile--input .tile--image-shadow,.tile--outcome .tile--image-shadow{position:relative}

.tile--content .tile--image-shadow:after,.tile--input .tile--image-shadow:after,.tile--outcome .tile--image-shadow:after{content:"";background:linear-gradient(to bottom, rgba(15, 31, 44, 0) 0%, rgba(15, 31, 44, 0.005) 11.7%, rgba(15, 31, 44, 0.018) 22.3%, rgba(15, 31, 44, 0.04) 32%, rgba(15, 31, 44, 0.067) 40.8%, rgba(15, 31, 44, 0.099) 48.8%, rgba(15, 31, 44, 0.134) 56.1%, rgba(15, 31, 44, 0.171) 62.7%, rgba(15, 31, 44, 0.209) 68.7%, rgba(15, 31, 44, 0.246) 74.2%, rgba(15, 31, 44, 0.281) 79.2%, rgba(15, 31, 44, 0.313) 83.8%, rgba(15, 31, 44, 0.34) 88.2%, rgba(15, 31, 44, 0.362) 92.3%, rgba(15, 31, 44, 0.375) 96.2%, rgba(15, 31, 44, 0.38) 100%);height:50%;position:absolute;bottom:0;width:100%}

.tile--content.l_flex{display:flex}

.tile--directory{min-height:28rem;max-width:348px;margin:0 auto}

.tile--achievement{min-height:27rem;padding:5rem 0 2rem 0;text-align:center}

.tile--achievement .type{text-transform:uppercase;font-size:1rem;color:rgba(15,31,44,.61);letter-spacing:1px}

.tile--achievement .tile__body{padding:0 1rem}

.tile--selected{background-color:rgba(0,98,227,.08)}

.is_dragging .tile{box-shadow:0 2px 30px 0 rgba(15,31,44,.25)}

.tile__selection{position:absolute;top:1rem;overflow:hidden;z-index:2}

[dir="ltr"] .tile__selection{right:1rem}

[dir="rtl"] .tile__selection{left:1rem}

.tile__selection-checkbox{position:relative;top:-4rem}

[dir="ltr"] .tile__selection-checkbox{right:0}

[dir="rtl"] .tile__selection-checkbox{left:0}

.tile__selection-checkbox--visible{top:0}

.tile__remove{position:absolute;top:-10px;z-index:2;font-size:1.75rem;height:1.75rem;color:rgba(15,31,44,.61);background:#fff;border-radius:50%}

[dir="ltr"] .tile__remove{right:-10px}

[dir="rtl"] .tile__remove{left:-10px}

.tiles--add{font-size:1rem;color:#0062e3;background-color:rgba(15,31,44,.03);box-shadow:none;border-radius:.5rem;position:relative}

.tiles--add-action{display:block;padding:4rem 0;width:100%;text-align:center}

.tiles--add-action:hover{background-color:rgba(15,31,44,.03)}

.path-tile__wrapper,ul.pathway-tiles>li{display:block}

ul.pathway-tiles>li{position:relative;display:inline-block;vertical-align:top;margin:1.2rem}

[dir="ltr"] ul.pathway-tiles>li:last-child{margin-right:0}

[dir="rtl"] ul.pathway-tiles>li:last-child{margin-left:0}

.path-tile{max-width:21rem;height:25.4166666667rem;margin:0 auto;overflow:hidden;position:relative;width:100%;z-index:0}

.path-tile-bg{z-index:0;background-size:cover;background-position:center center;background-repeat:no-repeat}

.path-tile-image{max-height:21rem;overflow:hidden;transition:max-height .2s ease-in-out}

.path-tile-thumbnail{width:7.0833333333rem;height:7.0833333333rem}

.path-tile-thumbnail:before{position:relative;content:inherit}

.path-tile-badge:first-child{transition:opacity .2s;visibility:visible}

.path-tile:hover .path-tile-badge-transition:first-child,.has-focus .path-tile-badge-transition:first-child{transition:visibility .2s;transition-delay:.3s;visibility:hidden}

.path-tile:hover .path-tile-image-transition,.has-focus .path-tile-image-transition{transition:max-height .2s ease-in-out;transition-delay:.3s;max-height:0}

.path-tile:hover .path-tile-description-transition,.has-focus .path-tile-image-transition~.path-tile-description-transition{transition:all .3s cubic-bezier(0.42, 0, 0, 1);transition-delay:.3s;opacity:1}

.path-tile:hover,.keyboard-focus .path-tile__link:focus{cursor:pointer;box-shadow:0px 2px 8px 0px rgba(15,31,44,.25)}

.path-tile:hover .path-tile-title,.keyboard-focus .path-tile__link:focus .path-tile-title{text-decoration:underline}

.path-tile.path-tile--preview{cursor:default}

.path-tile.path-tile--preview *{cursor:default}

.path-tile.path-tile--preview:hover,.keyboard-focus .path-tile.path-tile--preview__link:focus{box-shadow:0px 1px 5px 0px rgba(15,31,44,.25)}

.path-tile.path-tile--preview:hover .path-tile-title,.keyboard-focus .path-tile.path-tile--preview__link:focus .path-tile-title{text-decoration:none}

.path-tile.path-tile--full-height{height:100% !important}

.path-tile.path-tile--full-width{margin:0;max-width:100%}

.path-tile.path-tile--full-width .image-layout--pathway-tile{max-width:100%;padding-top:0;height:12rem}

.path-tile-image~.path-tile-description{opacity:0;transition:all .2s cubic-bezier(0.42, 0, 0, 1)}

.path-tile-description{position:relative;max-height:8em;overflow:hidden}

.path-tile-description:before{content:"";position:absolute;top:0;width:2.1666666667rem;height:.25rem;background-color:#d9dee2}

[dir="ltr"] .path-tile-description:before{left:1rem}

[dir="rtl"] .path-tile-description:before{right:1rem}

.path-tile-btns{background-color:#fff;position:absolute;padding-bottom:1rem;bottom:0;width:90%;z-index:0}

[dir="ltr"] .path-tile-btns{left:1rem}

[dir="rtl"] .path-tile-btns{right:1rem}

.path-tile-overlay{z-index:2;background-color:#000;opacity:.5}

.path-tile-overlay.no-image{display:none}

.path-tile-content{z-index:1;overflow:hidden}

.path-tile-content [data-icon]{text-shadow:none}

.path-tile-title{word-wrap:break-word;overflow-wrap:break-word;word-break:break-word}

.path-tile.full-width{width:100%}

.path-tile .btn span{font-size:1.0833333333rem}

.path-tile .progress{height:.5rem;padding:0;z-index:2;position:absolute;top:0;width:100%;border-radius:0}

[dir="ltr"] .path-tile .progress{left:0}

[dir="rtl"] .path-tile .progress{right:0}

.path-tile .progress .progress-bar{height:.5rem;border-radius:0;background-color:#0062e3}

.small .path-tile{height:300px !important}

.small .path-tile-title{padding:.5rem 1rem !important}

.small .path-tile-description{padding:.5rem 1rem .5rem 1rem !important;max-height:90px}

.provider__flex-search-image{max-height:50px;max-width:50px}

.pathway-header__endorsed{width:2rem}

[dir="ltr"] .pathway-header__endorsed{margin-right:1rem}

[dir="rtl"] .pathway-header__endorsed{margin-left:1rem}

.pathway-header__info{display:flex;align-items:center;justify-content:center}

.pathway-header__progress{position:absolute}

[dir="ltr"] .pathway-header__progress{transform:translate(-4px, -50%)}

[dir="rtl"] .pathway-header__progress{transform:translate(4px, -50%)}

.pathway-header__progress-circle{position:relative;border:4px solid #fff;border-radius:40px;background-color:#fff}

@media(max-width: 768px){
.pathway-header__progress{position:relative;transform:translate(0, 0);text-align:center;padding-top:2.5rem}}

.pathway-header__description{padding-top:3rem}

@media(max-width: 768px){
.pathway-header__description{padding-top:2rem}}

.pathway-header--no-hero{padding-top:2rem}

.pathway-header--no-hero .pathway-header__wrapper{display:flex;align-items:center}

.pathway-header--no-hero .pathway-header__progress{position:relative;transform:translateY(0);order:1}

.pathway-header--no-hero .pathway-header__meta{order:2;flex-grow:1}

[dir="ltr"] .pathway-header--no-hero .pathway-header__meta{padding-left:2rem}

[dir="rtl"] .pathway-header--no-hero .pathway-header__meta{padding-right:2rem}

.pathway-header--no-hero .pathway-header__info{justify-content:flex-start}

@media(min-width: 769px){
.pathway-header--no-hero{padding-top:4rem}

.pathway-header--no-hero .pathway-header__description{padding-top:0}}

@media(min-width: 769px)and (max-width: 768px){
.pathway-header--no-hero .pathway-header__description{padding-top:2rem}}

@media(min-width: 769px){
.pathway-header--no-hero .pathway-header__actions{position:absolute;top:-6rem}

[dir="ltr"] .pathway-header--no-hero .pathway-header__actions{right:0}

[dir="rtl"] .pathway-header--no-hero .pathway-header__actions{left:0}}

.pathway-section__header{top:calc(5.5rem + 5rem)}

.pathway-section__meta-item{white-space:nowrap}

.pathway-lesson__header{position:relative;justify-content:space-between;z-index:1}

.pathway-lesson__header-actions{position:absolute;top:2rem}

[dir="ltr"] .pathway-lesson__header-actions{right:2rem}

[dir="rtl"] .pathway-lesson__header-actions{left:2rem}

.pathway-lesson__sticky-header{border-top:1rem solid #fff;padding:0 1rem}

.pathway-lesson__sticky-header-container{position:relative}

.pathway-lesson__sticky-header-wrapper{height:0;overflow:visible}

.pathway-lesson__sticky-header-wrapper.is_sticky{top:5.5rem}

.pathway-lesson__step{position:relative}

.pathway-lesson__step--card{background:#fff}

.pathway-lesson__background{position:relative}

.pathway-lesson__background:after{content:"";display:block;width:2px;background-color:rgba(15,31,44,.18);height:calc(100% - 12rem);position:absolute;top:0;margin-top:1.5rem;opacity:0;transition:opacity .4s ease-out;z-index:-1}

[dir="ltr"] .pathway-lesson__background:after{left:0;margin-left:calc(33.333% - 1px)}

[dir="rtl"] .pathway-lesson__background:after{right:0;margin-right:calc(33.333% - 1px)}

@media(max-width: 1024px){
[dir="ltr"] .pathway-lesson__background:after{margin-left:50%}

[dir="rtl"] .pathway-lesson__background:after{margin-right:50%}}

.pathway-lesson__background--in:after{transition:opacity .5s ease-in;opacity:1}

.pathway-lesson__background--short:after{height:calc(100% - 3rem)}

.pathway-lesson__divider{padding-top:2rem;padding-bottom:0;border-bottom:1px solid rgba(15,31,44,.18);margin-bottom:2rem}

.skill-inventory .title{color:#0f1f2c;font-size:1.3333333333rem;font-weight:600;line-height:2rem}

.skill-inventory .subtitle{color:rgba(15,31,44,.61);font-size:1rem;font-weight:500;line-height:1.5rem}

.author-note{transition:background-color .2s ease-out,border-color .2s ease-out;background-color:#fff}

.author-note__textarea{transition:background-color .2s ease-out,height .3s ease-out !important;background-color:#fff;height:6rem;overflow:auto}

.author-note--dim{background-color:rgba(15,31,44,.03);border-color:rgba(15,31,44,.03)}

.author-note--dim .author-note__textarea{background-color:rgba(15,31,44,.03);height:3rem;overflow:hidden}

.author-note__gradient{position:sticky;bottom:0;height:4rem;background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%)}

[dir="ltr"] .author-note__actions{padding-left:1rem;margin-left:auto}

[dir="rtl"] .author-note__actions{padding-right:1rem;margin-right:auto}

.add-items .item-list{border:1px solid rgba(15,31,44,.18);border-radius:.25rem;padding:.25rem 1rem;cursor:pointer;user-select:none}

.add-items .item-list .close-item{color:rgba(15,31,44,.61);vertical-align:middle;font-size:1rem}

[dir="ltr"] .add-items .item-list .close-item{padding-right:.5rem}

[dir="rtl"] .add-items .item-list .close-item{padding-left:.5rem}

.add-items .item-list .item{color:rgba(15,31,44,.61);vertical-align:middle;user-select:none}

.comments{padding:1.5rem;background-color:rgba(15,31,44,.03)}

.comments--embedded{padding:0}

[dir="ltr"] .comments .action-link-item,[dir="ltr"] .comments .action-options__item,[dir="ltr"] .comments .action-options.is_collapsed .action-options__item{margin-right:.75rem}

[dir="rtl"] .comments .action-link-item,[dir="rtl"] .comments .action-options__item,[dir="rtl"] .comments .action-options.is_collapsed .action-options__item{margin-left:.75rem}

.comments .action-link-item:before,.comments .action-options__item:before,.comments .action-options.is_collapsed .action-options__item:before{content:"·";pointer-events:none}

[dir="ltr"] .comments .action-link-item:before,[dir="ltr"] .comments .action-options__item:before,[dir="ltr"] .comments .action-options.is_collapsed .action-options__item:before{margin-right:.5rem}

[dir="rtl"] .comments .action-link-item:before,[dir="rtl"] .comments .action-options__item:before,[dir="rtl"] .comments .action-options.is_collapsed .action-options__item:before{margin-left:.5rem}

.comments .action-link-item:first-of-type:before,.comments .action-options__item:first-of-type:before,.comments .action-options.is_collapsed .action-options__item:first-of-type:before{display:none}

.comments .action-options__button-icon{display:none}

.comments pre{white-space:normal;font-family:"Inter","Helvetica Neue",Helvetica,Arial,sans-serif;font-size:1rem;line-height:1.5}

.hide-main-input .comments-main-input{display:none}

@media(max-width: 768px){
.comments .action-options.is_collapsed .action-options__button .action-options__button-label,.comments .action-options__button .action-options__button-label{display:inline-block}}

[dir="ltr"] .comments__feed--embedded{margin-left:.5rem}

[dir="rtl"] .comments__feed--embedded{margin-right:.5rem}

.comments__replies{margin-top:1rem;margin-bottom:1rem}

[dir="ltr"] .comments__replies{margin-left:2rem;padding-left:1rem}

[dir="rtl"] .comments__replies{margin-right:2rem;padding-right:1rem}

.comments__comment[contenteditable]{font-size:1rem;font-weight:500;border:none;padding:.5rem 0 0 0;color:#0f1f2c;line-height:1.7rem}

.comments__edit[contenteditable]{background-color:#fff;overflow:hidden;border-radius:1.5rem;min-height:3rem;height:6rem;max-height:6rem;padding-top:.75rem;padding-left:1.5rem;padding-right:1.5rem;font-size:1rem}

.comments__post{font-size:1.25rem;font-weight:300;color:#0f1f2c;padding:1rem 1rem 0 1rem}

.comments__post-profile-pic{padding:.3rem 0}

.comments__post-actions{font-size:12px}

.comments__post [contenteditable]{vertical-align:top;border:none;font-size:1.25rem;font-weight:300;color:#0f1f2c;min-height:2rem;line-height:1.6rem}

.comments__post [contenteditable] *{line-height:1.6rem}

.comments__post [contenteditable] a{vertical-align:baseline}

.comments__post-new{padding:1rem;position:relative}

.comments__post-new [contenteditable=true]:empty:before{top:2rem}

[dir="ltr"] .comments__post-new [contenteditable=true]:empty:before{left:5.5rem}

[dir="rtl"] .comments__post-new [contenteditable=true]:empty:before{right:5.5rem}

.comments__content-preview{border-top:1px solid rgba(15,31,44,.18)}

.comments__comment-note{font-weight:400;font-size:1rem;color:rgba(15,31,44,.61)}

.comments__add__box{opacity:0;overflow:hidden;transform:scale(0);width:0;transition:opacity .15s ease-in-out,padding .3s ease-in-out,transform .4s ease-in-out,width .3s ease-in-out}

[dir="ltr"] .comments__add__box{padding-left:0}

[dir="rtl"] .comments__add__box{padding-right:0}

.comments__add--focus .comments__add__box{opacity:1;width:2.5rem;transform:scale(1)}

[dir="ltr"] .comments__add--focus .comments__add__box{padding-left:1rem}

[dir="rtl"] .comments__add--focus .comments__add__box{padding-right:1rem}

.add-comment.contenteditable{transition:height .05s;background-color:#fff;overflow:hidden;border-radius:1.5rem;min-height:3rem;height:3rem;max-height:6rem;padding-top:.75rem;padding-left:1.5rem;padding-right:1.5rem;font-size:1rem;position:relative}

.add-comment.contenteditable[contenteditable=true]:empty:before{top:.75rem}

[dir="ltr"] .add-comment.contenteditable[contenteditable=true]:empty:before{left:1.5rem}

[dir="rtl"] .add-comment.contenteditable[contenteditable=true]:empty:before{right:1.5rem}

.mentions{background-color:#fff;min-width:25rem;max-height:21rem;overflow-y:auto;border:1px solid rgba(15,31,44,.18)}

.mentions .mention{cursor:pointer}

.mentions .mention.active{color:#fff;background-color:#0062e3}

[data-icon=book]:before{content:"+"}

[data-icon=article]:before{content:"P"}

[data-icon=video]:before{content:"z"}

[data-icon=course]:before{content:"|"}

[data-icon=event]:before{content:"]"}

[data-icon=position]:before,[data-icon=experience]:before{content:"q"}

[data-icon=episode]:before{content:"'"}

[data-icon=assessment]:before{content:"a"}

[data-icon=degree]:before{content:"-"}

[data-icon=task]:before{content:"u"}

[data-icon=award]:before{content:"N"}

[data-icon=badge]:before{content:"1"}

[data-icon=accomplishment]:before{content:"N"}

[data-icon=certificate]:before{content:"["}

[data-icon=tag]:before{content:"v"}

[data-icon=group]:before{content:"y"}

.load-wrap{position:relative;min-height:12.5rem;z-index:2}

.content-loading{opacity:.3}

.spinner{margin:4rem auto;position:relative;width:4rem;height:4rem;background:rgba(0,0,0,0) url("/content/img/app/loading.gif") no-repeat center center}

.spinner-overlay{margin-top:15% !important}

.spinner.spinner--short{margin:0 auto}

.spinner.spinner--solo{margin:0}

.spinner-min-height{min-height:4rem}

.side-bar-spinner{position:relative;height:4rem;top:45%;left:0;right:0;bottom:0;background:rgba(0,0,0,0) url("/content/img/app/loading.gif") no-repeat center}

.input-spinner.required:before{content:"";width:2.5rem;height:2.5rem;position:absolute;background:rgba(0,0,0,0) url("/content/img/app/loading.gif") no-repeat center center;bottom:2px}

[dir="ltr"] .input-spinner.required:before{right:2.5rem}

[dir="rtl"] .input-spinner.required:before{left:2.5rem}

[dir="ltr"] .input-spinner.required input[type=text]{padding-right:5rem}

[dir="rtl"] .input-spinner.required input[type=text]{padding-left:5rem}

input.input-spinner{transition:inherit !important}

[dir="ltr"] input.input-spinner{background:rgba(0,0,0,0) url("/content/img/app/loading.gif") no-repeat right 1rem center !important}

[dir="rtl"] input.input-spinner{background:rgba(0,0,0,0) url("/content/img/app/loading.gif") no-repeat left 1rem center !important}

button.button-spinner{background:rgba(15,31,44,.03) url("/content/img/app/loading.gif") no-repeat center center !important}

.btn-spinner{position:relative;top:50%}

[dir="ltr"] .btn-spinner{left:50%}

[dir="rtl"] .btn-spinner{right:50%}

.btn-spinner__wrapper{position:absolute;width:100%;height:100%;top:0;opacity:0;visibility:hidden}

[dir="ltr"] .btn-spinner__wrapper{left:0}

[dir="rtl"] .btn-spinner__wrapper{right:0}

.form__field-invalid .contenteditable,.form__field-invalid textarea{border-color:#de0546 !important}

.form__invalid-msg{font-size:1rem;color:#de0546;margin-top:.5rem}

[dir="ltr"] .form__invalid-msg:before{margin-right:.25em}

[dir="rtl"] .form__invalid-msg:before{margin-left:.25em}

.form__label{font-size:1rem;font-weight:600;margin-bottom:.25rem}

.form__help{color:rgba(15,31,44,.61);font-size:1rem;line-height:1.5rem;margin-top:.1666666667rem}

[dg-ng-field-validation].ng-invalid.ng-touched,.ng-submitted [dg-ng-field-validation].ng-invalid{border-color:#de0546 !important}

.text-wrapper{word-break:break-all}

.form-wrap{position:relative}

.form-wrap .icon-title{margin-bottom:.5em}

[dir="ltr"] .form-wrap .icon-title:before{margin-right:.25rem}

[dir="rtl"] .form-wrap .icon-title:before{margin-left:.25rem}

.form-wrap .item{position:relative}

.form-wrap .item [type=radio]:after,.form-wrap .item [type=checkbox]:after{display:none}

.form-wrap .fixme textarea,.form-wrap .ng-invalid textarea{border:solid 1px #de0546 !important}

.form-wrap .explanation{color:rgba(15,31,44,.61);padding-bottom:.5em;line-height:1.5em}

.form-wrap .helper-text{color:rgba(15,31,44,.61);font-size:11px;padding-bottom:1em}

.form-wrap__strong-checkbox{background-color:rgba(15,31,44,.03);border:1px solid rgba(15,31,44,.18);border-radius:.25rem;display:block;padding:1rem}

.form-wrap label,.form__label{color:#0f1f2c;font-weight:600;font-size:1rem;line-height:2rem}

.form-wrap label strong,.form__label strong{font-weight:normal;color:#0f1f2c}

.form-wrap label.par,.form__label.par{font-weight:500}

.l_faux-right-column .helper-text{position:absolute;width:60%}

[dir="ltr"] .l_faux-right-column .helper-text{left:calc(100% + 5em)}

[dir="rtl"] .l_faux-right-column .helper-text{right:calc(100% + 5em)}

@media(max-width: 768px){
.l_faux-right-column .helper-text{position:relative;width:auto}

[dir="ltr"] .l_faux-right-column .helper-text{left:0}

[dir="rtl"] .l_faux-right-column .helper-text{right:0}}

.form-wrap__group:not(:last-child){margin-bottom:1.5rem}

.form-wrap__group:not(:last-child):first-child{padding-top:0}

.reactive-forms{position:relative}

.reactive-forms__control{margin-bottom:1.5rem}

.reactive-forms__control:last-child{margin-bottom:0}

.reactive-forms__control textarea{min-height:6rem}

.reactive-forms__read-only input[readonly]{border:none;padding:0;height:auto;line-height:2rem}

.reactive-forms dgx-reactive-forms-label{display:block}

.reactive-forms__label{display:flex;color:#0f1f2c;font-weight:600;font-size:1rem;line-height:2rem}

.reactive-forms__label-text{flex:1 0 auto}

.reactive-forms__label--required{font-weight:500;color:rgba(15,31,44,.61)}

.reactive-forms__label-checkbox{display:flex;align-items:center;color:#0f1f2c;font-weight:600;font-size:1rem;line-height:2rem}

.reactive-forms label:not(.reactive-forms__label){color:#0f1f2c;font-weight:600;font-size:1rem;line-height:2rem}

.reactive-forms .ng-invalid.ng-touched input.ng-invalid,.reactive-forms .ng-invalid.ng-touched textarea.ng-invalid{border-color:#de0546 !important}

.reactive-forms .ng-invalid.ng-touched .reactive-forms__group.ng-invalid input,.reactive-forms .ng-invalid.ng-touched .reactive-forms__group.ng-invalid textarea,.reactive-forms .ng-invalid.ng-touched .reactive-forms__group.ng-invalid .field-group__input--tag{border-color:#de0546 !important}

.form-label{display:flex;align-items:baseline;justify-content:space-between}

.form-label-description{font-size:1rem;line-height:1.5rem;font-weight:400;font-style:italic;padding-bottom:.5rem !important;color:#6d767e}

.search-field{font-size:1.1666666667rem;position:relative;height:3rem !important;border-radius:1.5rem;background-color:#fff;border:1px solid rgba(15,31,44,.18);border-color:rgba(15,31,44,.18)}

[dir="ltr"] .search-field{padding-left:3rem}

[dir="rtl"] .search-field{padding-right:3rem}

.search-field input[type=text],.search-field input[type=search]{border:none;background-color:rgba(0,0,0,0);margin:0;width:100%}

[dir="ltr"] .search-field input[type=text],[dir="ltr"] .search-field input[type=search]{padding-left:0;padding-right:1rem}

[dir="rtl"] .search-field input[type=text],[dir="rtl"] .search-field input[type=search]{padding-right:0;padding-left:1rem}

[dir="ltr"] .search-field input[type=text][disabled],[dir="ltr"] .search-field input[type=search][disabled]{border-radius:0 1.5rem 1.5rem 0}

[dir="rtl"] .search-field input[type=text][disabled],[dir="rtl"] .search-field input[type=search][disabled]{border-radius:1.5rem 0 0 1.5rem}

.search-field--short{width:20rem;position:relative}

@media(max-width: 768px){
.search-field--short{width:100%}}

.search-field--rounded{width:16rem;height:3rem !important;position:relative;border-radius:.25rem}

@media(max-width: 768px){
.search-field--rounded{width:100%}}

.search-field--rounded input[type=search]{height:2.8333333333rem}

[dir="ltr"] .search-field--rounded input[type=search]{padding-right:0}

[dir="rtl"] .search-field--rounded input[type=search]{padding-left:0}

.search-field.full-width .dropdown-menu{overflow:hidden;width:100%}

.search-field .typeahead-popup{width:100% !important}

[dir="ltr"] .search-field .typeahead-popup{margin-left:-3rem}

[dir="rtl"] .search-field .typeahead-popup{margin-right:-3rem}

.search-field__typeahead2--simple{z-index:1}

.search-field__typeahead2--simple:before{z-index:2}

.search-field__typeahead2--simple .typeahead-item>a{display:block;padding:.5rem 1rem}

.toggle-switch{position:relative;display:inline-block}

.toggle-switch-btn{width:4rem;height:2rem;padding:.25rem;position:relative;background-color:rgba(15,31,44,.18);border:1px solid rgba(15,31,44,.03);border-radius:1.25rem;transition:all .4s ease}

.toggle-switch-btn.is_on{background-color:#0062e3;border:1px solid rgba(15,31,44,.18)}

.toggle-switch-btn.is_on:before{opacity:1}

.toggle-switch-btn[disabled],.toggle-switch-btn.is_disabled{opacity:.5}

.toggle-switch-thumb{background-color:#fff;width:1.5rem;height:1.5rem;border-radius:50%;box-shadow:0 0 0 1px #ced1d3;position:absolute;top:.1666666667rem;transition:all .4s ease}

[dir="ltr"] .toggle-switch-thumb{left:.1833333333rem}

[dir="rtl"] .toggle-switch-thumb{right:.1833333333rem}

.is_on .toggle-switch-thumb{box-shadow:0 0 0 1px #2e6295}

[dir="ltr"] .is_on .toggle-switch-thumb{left:2.2rem}

[dir="rtl"] .is_on .toggle-switch-thumb{right:2.2rem}

.toggle-switch input[type=checkbox]{position:absolute;visibility:hidden}

.tags-list__list{font-size:0}

.tags-list--one-line{word-wrap:normal;overflow-wrap:normal;word-break:normal}

.tags-list--one-line .tags-list__container{overflow:hidden}

.tags-list--one-line .tags-list__list{display:inline-block;white-space:nowrap;overflow:visible}

.tags-list--one-line .tags-list__item{max-width:none}

.tags-list__item{display:inline-block;word-wrap:break-word;font-size:1rem;font-size:initial}

[dir="ltr"] .tags-list__item{margin-right:.35rem}

[dir="rtl"] .tags-list__item{margin-left:.35rem}

.tags-list__item>a{color:rgba(15,31,44,.61)}

.tags-list__item>a:after{content:","}

.tags-list__item>a:hover,.tags-list__item>a:focus{text-decoration:underline}

.tags-list__item:first-child>a:before{display:none}

[dir="ltr"] .tags-list__item:last-child{margin-right:0}

[dir="rtl"] .tags-list__item:last-child{margin-left:0}

.tags-list__item:last-child>a:after{display:none}

.tags-list__item>a.is_disabled{text-decoration:none;cursor:default}

.tag-endorsed-img{max-height:24px;max-width:48px}

.tag-tile--horizontal{width:100%;padding:2rem 1rem}

.tag-tile--horizontal__rating{padding:0 1rem;position:relative;text-align:center}

.tag-tile--horizontal__new{box-shadow:none;background-color:rgba(15,31,44,.03)}

.tag-tile--horizontal__new--clickable{cursor:pointer}

[dir="ltr"] .tag-tile--horizontal__menu{margin:-0.5rem 1rem 0 0}

[dir="rtl"] .tag-tile--horizontal__menu{margin:-0.5rem 0 0 1rem}

.utility-bar{justify-content:flex-end}

[dir="ltr"] .utility-bar{padding-left:1rem}

[dir="rtl"] .utility-bar{padding-right:1rem}

.utility-bar .btn-utility{border-color:rgba(15,31,44,.18);color:rgba(15,31,44,.61);padding:1px 0 0 0;overflow:visible;height:3.5rem;width:3.5rem;font-size:1.3333333333rem}

.utility-bar .btn-utility:before{padding:0}

.utility-bar .btn-utility .profile-pic{cursor:pointer}

.utility-bar .btn-utility:hover,.utility-bar .btn-utility:focus,.utility-bar .btn-utility[aria-expanded=true],.utility-bar .btn-utility[aria-expanded=false]:hover,.utility-bar .btn-utility[aria-expanded=false]:focus{background-color:rgba(15,31,44,.08)}

.app-header--dark .utility-bar .btn-utility:hover,.product-header--dark .utility-bar .btn-utility:hover,.app-header--dark .utility-bar .btn-utility:focus,.product-header--dark .utility-bar .btn-utility:focus,.app-header--dark .utility-bar .btn-utility[aria-expanded=true],.product-header--dark .utility-bar .btn-utility[aria-expanded=true],.app-header--dark .utility-bar .btn-utility[aria-expanded=false]:hover,.product-header--dark .utility-bar .btn-utility[aria-expanded=false]:hover,.app-header--dark .utility-bar .btn-utility[aria-expanded=false]:focus,.product-header--dark .utility-bar .btn-utility[aria-expanded=false]:focus{background-color:rgba(255,255,255,.2)}

.utility-bar .btn-utility[aria-expanded=false]{background-color:rgba(0,0,0,0)}

[dir="ltr"] .utility-bar-item{padding-right:1rem}

[dir="rtl"] .utility-bar-item{padding-left:1rem}

.utility-bar-item .dropdown{position:relative}

.utility-bar .dropdown-menu__arrow{margin-top:1.6rem}

[dir="ltr"] .utility-bar .dropdown-menu__arrow{margin-right:-0.25rem}

[dir="rtl"] .utility-bar .dropdown-menu__arrow{margin-left:-0.25rem}

.utility-bar .count-bubble{font-size:.9166666667rem;display:block;position:absolute;top:.25rem;border-radius:.75rem;min-width:1.5rem;height:1.5rem;line-height:1.5rem;text-align:center;background-color:#f0b400;color:#fff;padding:0 .2rem}

[dir="ltr"] .utility-bar .count-bubble{right:.25rem}

[dir="rtl"] .utility-bar .count-bubble{left:.25rem}

.global-search{line-height:3.5rem}

.global-search__input[type=text],.global-search__input[type=search]{border:none;background-color:rgba(0,0,0,0);color:#0f1f2c}

.global-search__input[type=text][disabled],.global-search__input[type=search][disabled]{background-color:rgba(0,0,0,0)}

.global-search__input[type=text]:focus,.global-search__input[type=text]::placeholder,.global-search__input[type=search]:focus,.global-search__input[type=search]::placeholder{color:#0f1f2c !important;opacity:1}

.app-header--dark .global-search__input[type=text],.product-header--dark .global-search__input[type=text],.app-header--dark .global-search__input[type=search],.product-header--dark .global-search__input[type=search]{color:#fff}

.app-header--dark .global-search__input[type=text]:focus,.app-header--dark .global-search__input[type=text]::placeholder,.product-header--dark .global-search__input[type=text]:focus,.product-header--dark .global-search__input[type=text]::placeholder,.app-header--dark .global-search__input[type=search]:focus,.app-header--dark .global-search__input[type=search]::placeholder,.product-header--dark .global-search__input[type=search]:focus,.product-header--dark .global-search__input[type=search]::placeholder{color:#fff !important;opacity:1}

.app-header--dark .mobile-mode .global-search__input[type=text],.product-header--dark .mobile-mode .global-search__input[type=text],.app-header--dark .mobile-mode .global-search__input[type=search],.product-header--dark .mobile-mode .global-search__input[type=search]{color:rgba(15,31,44,.61)}

.app-header--dark .mobile-mode .global-search__input[type=text]:focus,.product-header--dark .mobile-mode .global-search__input[type=text]:focus,.app-header--dark .mobile-mode .global-search__input[type=search]:focus,.product-header--dark .mobile-mode .global-search__input[type=search]:focus{color:rgba(15,31,44,.61) !important;opacity:1}

.app-header--dark .mobile-mode .global-search__input[type=text]::placeholder,.product-header--dark .mobile-mode .global-search__input[type=text]::placeholder,.app-header--dark .mobile-mode .global-search__input[type=search]::placeholder,.product-header--dark .mobile-mode .global-search__input[type=search]::placeholder{color:rgba(15,31,44,.25) !important;opacity:1}

.global-search__link{line-height:1.5rem;font-size:1rem;display:inline-block;padding-bottom:1px;vertical-align:bottom;border-bottom:0}

.global-search .scrollable{max-height:29rem}

@media(max-width: 1024px){
.global-search{background-color:#f8f8f9;position:absolute;top:5.5rem;left:0;right:0;border-bottom:1px solid rgba(15,31,44,.18)}

.global-search__input[type=text],.global-search__input[type=search]{width:100%}

.global-search:before{top:auto}

.global-search .search-field{margin:1rem}

.global-search--preview{display:none}}

.meta-list{background:rgba(0,0,0,0);font-size:1rem}

.meta-list li{display:inline-block}

[dir="ltr"] .meta-list li{padding-right:.2em}

[dir="rtl"] .meta-list li{padding-left:.2em}

.meta-list li:before{content:"|";font-size:1.1em;line-height:.5em}

[dir="ltr"] .meta-list li:before{padding-right:.3em}

[dir="rtl"] .meta-list li:before{padding-left:.3em}

.meta-list li:first-child:before{display:none}

[dir="ltr"] .meta-list li:last-child{padding-right:0}

[dir="rtl"] .meta-list li:last-child{padding-left:0}

.meta-list li.active{font-weight:500}

.meta-list--light{color:rgba(15,31,44,.61)}

.meta-list--all-caps{text-transform:uppercase}

.meta-list--bullets{text-transform:none;font-size:1rem}

[dir="ltr"] .meta-list--bullets{padding-right:.4166666667rem}

[dir="rtl"] .meta-list--bullets{padding-left:.4166666667rem}

[dir="ltr"] .meta-list--bullets li{padding-right:.4166666667rem}

[dir="rtl"] .meta-list--bullets li{padding-left:.4166666667rem}

.meta-list--bullets li:before{content:"·"}

[dir="ltr"] .meta-list--bullets li:before{padding-right:.4166666667rem}

[dir="rtl"] .meta-list--bullets li:before{padding-left:.4166666667rem}

.bullet-prefix:before{content:"·";pointer-events:none}

[dir="ltr"] .bullet-prefix:before{margin-right:.3rem}

[dir="rtl"] .bullet-prefix:before{margin-left:.3rem}

.summary-list{font-size:0}

.summary-list>li{display:inline;font-size:1.3333333333rem;line-height:2rem}

.summary-list>li:before{content:"•";padding-right:.3em;padding-left:.3em;font-size:.9em}

.summary-list>li:first-child:before{display:none}

.par--small .summary-list li,.summary-list.par--small li{font-size:1rem;line-height:1.5rem}

.side-lines{color:rgba(15,31,44,.61);font-size:1rem;overflow:hidden;text-align:center}

.side-lines:before,.side-lines:after{background-color:rgba(15,31,44,.18);content:"";display:inline-block;height:1px;position:relative;vertical-align:middle;width:50%}

[dir="ltr"] .side-lines:before{right:.5rem;margin-left:-50%}

[dir="rtl"] .side-lines:before{left:.5rem;margin-right:-50%}

[dir="ltr"] .side-lines:after{left:.5rem;margin-right:-50%}

[dir="rtl"] .side-lines:after{right:.5rem;margin-left:-50%}

.nested-preference-options{border-bottom:1px solid rgba(15,31,44,.18);background:rgba(15,31,44,.03)}

[dir="ltr"] .nested-preference-options{padding-left:1rem}

[dir="rtl"] .nested-preference-options{padding-right:1rem}

.account-verified-icon{color:#00a866;background:#fff}

[dir="ltr"] .account-verified-icon{right:1rem}

[dir="rtl"] .account-verified-icon{left:1rem}

.profile-opportunity-preference .panel{box-shadow:none;border:0}

.profile-opportunity-preference .panel-heading{display:none}

.profile-opportunity-preference .panel-body{border:none !important;padding:0}

.profile-opportunity-preference .panel-group{margin:0}

.inline-action-bar{background:rgba(15,31,44,.61)}

.inline-action-bar .display-text{font-weight:bold;color:#fff}

.notification-menu{max-width:100%;width:29rem;min-height:12rem}

@media(max-width: 768px){
.notification-menu{width:100%}}

.notification-menu section[ng-click]{cursor:pointer}

.notification-menu .scrollable{max-height:60vh;min-height:25vh}

@media(max-width: 768px){
.notification-menu .scrollable{min-height:initial}}

dg-icon [data-icon=arrow-forward-square],dg-icon [data-icon=clock-square],dg-icon [data-icon=speech-bubble-square],df-icon [data-icon=arrow-forward-square],df-icon [data-icon=clock-square],df-icon [data-icon=speech-bubble-square]{color:#00a6a6}

dg-icon [data-icon=minus-square],dg-icon [data-icon=heart-square],df-icon [data-icon=minus-square],df-icon [data-icon=heart-square]{color:#eb3d7d}

dg-icon [data-icon=checkmark-square],df-icon [data-icon=checkmark-square]{color:#00a866}

dg-icon [data-icon=exclamation-square],df-icon [data-icon=exclamation-square]{color:#f0b400}

dg-icon [data-icon=person-tripple-square],dg-icon [data-icon=person-triple-square],dg-icon [data-icon=person-square],df-icon [data-icon=person-tripple-square],df-icon [data-icon=person-triple-square],df-icon [data-icon=person-square]{color:#0062e3}

dg-icon [data-icon=info-square],dg-icon [data-icon=person-double-square],dg-icon [data-icon=tools-square],dg-icon [data-icon=target-square],dg-icon [data-icon=tag-square],df-icon [data-icon=info-square],df-icon [data-icon=person-double-square],df-icon [data-icon=tools-square],df-icon [data-icon=target-square],df-icon [data-icon=tag-square]{color:rgba(15,31,44,.61)}

dg-icon [data-icon=google-square],df-icon [data-icon=google-square]{color:#d6492f}

dg-icon [data-icon=facebook-square],df-icon [data-icon=facebook-square]{color:#3c5a96}

.input-summary{font-size:1rem}

.input-summary .meta-list,.input-summary .tags,.input-summary .hashtags{color:rgba(15,31,44,.61);font-weight:500;font-size:1rem}

.input-summary.color-white .meta-list,.input-summary.color-white .tags,.input-summary.color-white .hashtags{color:#fff}

.input-summary .meta-list{text-transform:none}

.input-summary .meta-list,.input-summary .input-description{overflow:hidden}

.input-group-toolbar{min-height:3.5rem;z-index:2}

.input-group{background-color:#fff}

.input-group .input-group-header{background-color:rgba(15,31,44,.18)}

.input-group .input-group-section{border-bottom:1px solid rgba(15,31,44,.18)}

[dir="ltr"] .input-group .input-group-section{box-shadow:1px 1px 3px rgba(0,0,0,.1)}

[dir="rtl"] .input-group .input-group-section{box-shadow:-1px 1px 3px rgba(0,0,0,.1)}

.input-group textarea{min-height:6rem}

.input-group .input-group .input-group-section{box-shadow:none}

.input-group .input-group .input-group-header{background-color:#fff}

.input-group.is-dormant>.input-group-section>.input-group-content{border:none}

.input-group.is-dormant>.input-group-section>.input-group-header{display:none}

.input-success{position:relative}

.input-success-wrap{position:relative;width:100%;transition:height 500ms ease-out 200ms}

.input-success-wrap .overlay{display:none}

.input-success-button-wrap{position:relative;top:0;bottom:0;width:100%;cursor:default;user-select:none}

[dir="ltr"] .input-success-button-wrap{left:0}

[dir="rtl"] .input-success-button-wrap{right:0}

.input-success-button{z-index:2}

.input-success-dot,.input-success-message,.input-success-check,.input-success-points,.input-success-flipper,.input-success-newb,.input-success-reward{position:absolute;width:100%;right:0;left:0;margin:auto}

.input-success-dot,.input-success-flipper,.input-success-reward{pointer-events:none}

.input-success-dot,.input-success-check,.input-success-points{font-size:1rem;height:2.7em;border-radius:1.35em;text-align:center;display:inline-block}

.input-success-dot,.input-success-check{line-height:2.3em;background-color:#0062e3;border:.2em solid #0062e3;color:#0062e3;z-index:1}

.input-success-dot{opacity:0;bottom:0;transition:width 300ms ease-out,visibility 0ms linear 300ms,bottom 300ms linear 300ms}

.input-success-flipper{position:relative;transition:all 600ms linear}

.input-success-check{visibility:hidden;width:2.7em;bottom:0;transition:visibility 0ms linear 600ms,font-size 200ms cubic-bezier(0.25, 0.25, 0.51, 1.32) 600ms,line-height 200ms cubic-bezier(0.25, 0.25, 0.51, 1.32) 600ms,width 200ms cubic-bezier(0.25, 0.25, 0.51, 1.32) 600ms,height 200ms cubic-bezier(0.25, 0.25, 0.51, 1.32) 600ms,border-radius 200ms cubic-bezier(0.25, 0.25, 0.51, 1.32) 600ms,border 200ms cubic-bezier(0.25, 0.25, 0.51, 1.32) 600ms,background-color 200ms cubic-bezier(0.25, 0.25, 0.51, 1.32) 600ms}

.input-success-points{visibility:hidden;z-index:0;width:2.7em;height:2.7em;background-color:#fff;border:.2em solid #00a866;color:#00a866;font-size:3.5rem;-webkit-font-smoothing:antialiased;transition:visibility 0ms linear 1200ms}

[dir="ltr"] .input-success-points{transform:rotateY(180deg)}

[dir="rtl"] .input-success-points{transform:rotateY(-180deg)}

.input-success-points .serif{line-height:1em}

.input-success-message{opacity:0;visibility:hidden;transition:opacity 300ms ease-in 300ms}

.input-success-stat{display:inline-block;padding:0 .3em}

[dir="ltr"] .input-success-stat{border-left:1px solid rgba(15,31,44,.18)}

[dir="rtl"] .input-success-stat{border-right:1px solid rgba(15,31,44,.18)}

.input-success-stat .serif{line-height:1em;margin-bottom:4px}

[dir="ltr"] .input-success-stat:first-child{border-left:none}

[dir="rtl"] .input-success-stat:first-child{border-right:none}

.input-success-newb{text-align:center;opacity:0;visibility:hidden;transition:opacity 300ms ease-in}

.input-success-reward{z-index:1;top:0;bottom:0;opacity:0;visibility:hidden;transition:opacity 500ms ease-in}

.input-success-reward-message{max-width:350px}

.input-success-kickstart .input-success-wrap{visibility:hidden;overflow:hidden;height:0 !important}

.input-success-kickstart .input-success{min-height:25rem}

.input-success-kickstart .input-success-button-wrap{position:absolute}

.input-success-kickstart .input-success-button,.input-success-kickstart .input-success-buttons-container{visibility:hidden}

.input-success-kickstart .input-success-dot{opacity:1;width:2.7em;bottom:15.55rem}

.input-success-kickstart .input-success-flipper{height:9.45rem}

.input-success-kickstart .input-success-check{visibility:visible;background-color:#fff;font-size:3.5rem}

.input-success-kickstart .input-success-newb{position:relative;opacity:1;visibility:visible}

.input-success-kickstart-complete .input-success-flipper,.input-success-kickstart-complete .input-success-newb,.input-success-kickstart-complete .input-success-reward{pointer-events:all}

.input-success-kickstart-complete.input-success-kickstart-complete--show-results .input-success-message{opacity:1;visibility:visible}

.input-success-kickstart-complete.input-success-kickstart-complete--show-reward .input-success-message,.input-success-kickstart-complete.input-success-kickstart-complete--show-reward .input-success-flipper{opacity:0 !important}

.input-success-kickstart-complete.input-success-kickstart-complete--show-reward .input-success-reward{opacity:1;visibility:visible}

.input-success-kickstart-complete.points-received .input-success-dot{display:none}

[dir="ltr"] .input-success-kickstart-complete.points-received .input-success-flipper{transform:rotateY(180deg)}

[dir="rtl"] .input-success-kickstart-complete.points-received .input-success-flipper{transform:rotateY(-180deg)}

.input-success-kickstart-complete.points-received .input-success-message{opacity:1;visibility:visible}

.input-success-kickstart-complete.points-received .input-success-points{visibility:visible;transition:visibility 0ms linear 300ms}

.input-success-kickstart-complete.points-received .input-success-points.server-error>div{font-family:"Inter","Helvetica Neue",Helvetica,Arial,sans-serif;line-height:1em}

.input-success-kickstart-complete.points-received .input-success-check{opacity:0;transition:opacity 0ms linear 300ms}

.success-check{color:#0062e3;border:2px solid #0062e3;border-radius:50%;width:3rem;height:3rem;line-height:calc(3rem - 4px);font-size:1.5rem}

.divider{text-align:center;position:relative}

.divider__content{display:inline-block;padding-left:.5rem;padding-right:.5rem;position:relative;background:#fff}

.divider:before{content:"";display:block;position:absolute;top:50%;width:100%;border-bottom:1px solid rgba(15,31,44,.18)}

[dir="ltr"] .divider:before{left:0}

[dir="rtl"] .divider:before{right:0}

.icon-spot{text-align:center;border-radius:50%;font-size:1.3333333333rem;width:3rem;height:3rem;line-height:3rem}

.icon-circle{text-align:center;border-radius:50%;font-size:1.3333333333rem;width:2rem;height:2rem;line-height:1.5rem;border:2px solid rgba(15,31,44,.61)}

.temp-message{width:50%;padding:2em 3em 3em;background:#fff;border:1px solid rgba(15,31,44,.18);position:absolute;top:4rem}

[dir="ltr"] .temp-message{left:25%}

[dir="rtl"] .temp-message{right:25%}

@media(max-width: 768px){
.temp-message{position:relative;width:100%;top:0;margin-top:1rem}

[dir="ltr"] .temp-message{left:0}

[dir="rtl"] .temp-message{right:0}}

.badge.badge-notification{background-color:rgba(0,0,0,0);border:1px solid rgba(15,31,44,.18);color:#0f1f2c;font-weight:500;border-radius:.25rem;padding-bottom:.25rem}

.badge.badge-notification.empty{display:none}

.badge.badge-notification.new{background-color:#fcf0cc;border:none;color:#916600;padding:2px 6px}

.badge.badge-notification.label{background-color:#0062e3;border-color:#0062e3;color:#fff}

.profile-pic{border-radius:50%;display:inline-block;line-height:1;overflow:hidden;vertical-align:middle;background-color:#d4d7d9}

[dir="ltr"] .profile-pic{text-align:left}

[dir="rtl"] .profile-pic{text-align:right}

.app-header .app-header__profile-pic .profile-pic,.product-header .product-header__profile-pic .profile-pic{background-color:#d4d7d9}

.app-header--dark .app-header__profile-pic .profile-pic,.product-header--dark .product-header__profile-pic .profile-pic{background-color:rgba(255,255,255,.5)}

.profile-pic__add--small:before{font-size:3rem;vertical-align:middle}

.profile-pic__add--tiny:before{font-size:2rem;vertical-align:middle}

.profile-pic--large{width:12.5rem;height:12.5rem}

.profile-pic__image{width:100%;height:100%}

.profile-pic__engaged{position:absolute;overflow:visible}

.profile-pic__container{position:relative;display:inline-block}

.profile-pic__mentor-pic--small,.profile-pic__mentor-pic--medium{border:.1666666667rem solid #fff}

.profile-pic__mentor-pic--large{border:.25rem solid #fff}

.profile-pic__mentor-image{position:absolute;border-radius:50%;background:#fff;top:-.6666666667rem;line-height:0}

[dir="ltr"] .profile-pic__mentor-image{right:-0.5rem}

[dir="rtl"] .profile-pic__mentor-image{left:-0.5rem}

.profile-pic__mentor-icon{line-height:0}

.image-edit-container{display:block;position:relative;height:100%;width:100%}

.image-edit{cursor:pointer;overflow:hidden;display:inline !important}

.image-edit-input{position:absolute;top:0;right:0;z-index:2;cursor:pointer;bottom:0;left:0;opacity:0;-ms-filter:"alpha(opacity=0)";width:200px}

.image-edit-wrap{height:100%;width:100%;overflow:hidden;display:block;position:relative;backface-visibility:hidden;transform:translate3d(0, 0, 0)}

.image-edit-wrap img{max-width:none;min-height:100%;width:100%}

.keyboard-focus .image-edit-wrap.showFocus{outline:1px dashed}

.profile-pic .original-preview-image-parent{height:100%;width:100%}

.image-edit-helper{position:absolute;width:100%;bottom:0;background-color:rgba(0,98,227,.8);text-align:center;text-transform:uppercase;font-size:12px;transition:top .25s ease}

.image-edit-helper:before{display:block;color:rgba(15,31,44,.03);content:attr(data-change);padding:.5em 0;text-align:center}

.image-edit-helper .button{text-transform:none}

.image-edit-trigger--bottom{background-color:rgba(15,31,44,.61);border-radius:0;color:rgba(255,255,255,.75);position:absolute;bottom:0;left:0;right:0;width:100%}

.image-edit-trigger--bottom:hover{color:#fff}

.image-edit-trigger-drop .image{min-height:80px;min-width:100px}

.image-edit-button{display:none;bottom:2rem;position:absolute;left:1rem;right:1rem}

.image-edit-button:before{position:absolute;display:block;color:rgba(15,31,44,.03);content:attr(data-or);width:100%;top:-3rem;padding:0}

[dir="ltr"] .image-edit-button:before{left:0}

[dir="rtl"] .image-edit-button:before{right:0}

@media(max-width: 1024px){
.image-edit-button:before{display:none}}

.canhover .image-edit .profile-pic{transition:border-radius .25s ease}

.canhover .image-edit .image-edit-helper{top:80%;height:100%}

.canhover .image-edit:hover .profile-pic{border-width:0;border-radius:0}

.canhover .image-edit:hover .image-edit-helper{padding-top:2em;top:0;border:2px dashed #fff}

.canhover .image-edit:hover .image-edit-helper:before{content:attr(data-drag-photo)}

@media(max-width: 1024px){
.canhover .image-edit:hover .image-edit-helper:before{display:none}}

.canhover .image-edit:hover .image-edit-button{display:block;color:#fff}

.image-cropper .popover{display:block;position:absolute;max-width:none;width:300px;min-width:300px}

@media(min-width: 769px){
[dir="ltr"] .image-cropper .popover.d-left{right:100%;left:inherit;margin-left:0;margin-right:10px}

[dir="rtl"] .image-cropper .popover.d-left{left:100%;right:inherit;margin-right:0;margin-left:10px}

[dir="ltr"] .image-cropper .popover.d-right{left:100%}

[dir="rtl"] .image-cropper .popover.d-right{right:100%}

.image-cropper .popover.d-left>.arrow,.image-cropper .popover.d-right>.arrow{top:55px !important}}

@media(max-width: 768px){
.image-cropper .popover.m-bottom{top:100%}

.image-cropper .popover.m-bottom>.arrow{top:-11px !important}

[dir="ltr"] .image-cropper .popover.m-bottom>.arrow{left:65px}

[dir="rtl"] .image-cropper .popover.m-bottom>.arrow{right:65px}}

.image-cropper .cropper-container{width:100% !important}

[dir="ltr"] .image-cropper .cropper-container{left:0 !important}

[dir="rtl"] .image-cropper .cropper-container{right:0 !important}

.simple-pic-wrap{border-radius:3px}

.simple-pic-wrap--org{border:solid 1px rgba(15,31,44,.18)}

.simple-pic-wrap--org .original-preview-image-parent img{display:block;margin:0 auto}

.simple-pic-wrap--no-image-org{background-color:rgba(15,31,44,.03) !important;background-image:url("/content/img/default-org.png");background-position:50% 30%;background-size:60px 60px}

.simple-pic-wrap-pathway{height:130px;width:215px}

.simple-pic-wrap-pathway .original-preview-image-parent{position:relative;top:50%}

[dir="ltr"] .simple-pic-wrap-pathway .original-preview-image-parent{left:50%;transform:translate(-50%, -50%)}

[dir="rtl"] .simple-pic-wrap-pathway .original-preview-image-parent{right:50%;transform:translate(50%, -50%)}

@media(max-width: 480px){
.simple-pic-wrap-pathway{height:75px;width:125px}}

.simple-pic-wrap-input{height:5.6666666667rem;width:10rem}

.simple-pic-wrap-endorsed{background-color:rgba(15,31,44,.08)}

.simple-pic-wrap-endorsed .original-preview-image-parent img{height:24px !important}

.simple-pic-wrap__image-plus{width:3rem;height:3rem;border-radius:3rem;color:#fff;background-color:rgba(15,31,44,.61);display:flex;align-items:center;justify-content:center;position:relative;top:50%}

[dir="ltr"] .simple-pic-wrap__image-plus{left:50%;transform:translate(-50%, -50%)}

[dir="rtl"] .simple-pic-wrap__image-plus{right:50%;transform:translate(50%, -50%)}

.simple-pic-wrap .crop-preview-image-parent{z-index:2;position:relative}

.simple-pic-wrap .original-preview-image-parent{position:relative;z-index:1}

.simple-pic-wrap .image-edit-helper{top:0;background-color:rgba(15,31,44,.18);color:#0f1f2c;z-index:0;transition:none}

.simple-pic-wrap .image-edit-helper:before{font-size:13px;position:absolute;top:42%;left:10%;right:10%;content:attr(data-image);color:#0f1f2c;padding:0;z-index:2}

.simple-pic-wrap.hide-preview .image-edit-wrap img,.simple-pic-wrap.hide-preview img{max-height:110px;max-width:110px;min-height:inherit}

.simple-pic-wrap.hide-preview .original-preview-image-parent{height:121px;text-align:center}

.simple-pic-wrap.hide-preview .original-preview-image-parent img{width:auto;height:auto}

.simple-pic-wrap.hide-preview .image-cropper .popover{width:auto}

.simple-pic-wrap.hide-preview .cropper-container{top:0 !important}

.simple-pic-wrap.hide-preview .image-edit-wrap{border:none}

.simple-pic-wrap.hide-preview .image-edit-helper,.simple-pic-wrap.hide-preview .crop-preview-image-parent{display:none}

.results-list__item{margin-bottom:1rem}

.results-list__input-thumb{max-width:4rem;min-width:4rem}

.results-list__typeahead-wrapper{position:relative}

.results-list__typeahead-wrapper .typeahead-popup{width:100%}

.selected-list{max-height:11rem;overflow-y:scroll}

.selected-list::-webkit-scrollbar{-webkit-appearance:none;width:7px}

.selected-list::-webkit-scrollbar-thumb{border-radius:4px;background-color:rgba(0,0,0,.5);-webkit-box-shadow:0 0 1px rgba(255,255,255,.5)}

.footer-glasses{background-image:url("/content/img/end.svg");width:54px;height:36px;margin:10rem auto 11rem auto}

.isClientProvider .footer-glasses{background-image:url("/content/img/end-powered-by.svg");width:223px;height:23px;margin:8rem auto 9rem auto}

.isMobile .footer-glasses{margin:4rem auto}

.global-add-dropdown{width:100vw}

[dir="ltr"] .dropdown.right>.global-add-dropdown{right:0;left:auto}

[dir="rtl"] .dropdown.right>.global-add-dropdown{left:0;right:auto}

.utility-bar .global-add-dropdown .popover__container{max-width:37rem}

.vertical-nav .global-add-dropdown .popover__container{max-width:37rem;width:37rem}

[dir="ltr"] .vertical-nav .global-add-dropdown .popover__container{text-align:left}

[dir="rtl"] .vertical-nav .global-add-dropdown .popover__container{text-align:right}

.global-add-dropdown.full-width{width:100%}

.mouse-focus .global-add-dropdown .btn.btn-passive:not(.active):not(:active):focus{box-shadow:none;border-color:rgba(15,31,44,.18)}

.mouse-focus .global-add-dropdown .btn.btn-passive:not(.active):not(:active):focus:hover{border-color:rgba(15,31,44,.61)}

.product-header--dark .utility-bar .btn-utility{border-color:rgba(255,255,255,.5);color:#fff}

.product-header--dark .utility-bar .btn-utility:not([disabled]):not(.is_disabled):not(.inactive):not(:active):hover,.product-header--dark .utility-bar .btn-utility:not(.active):not(:active):focus{border-color:#fff;color:#fff}

@media(max-width: 768px){
[dir="ltr"] .product-header .utility-bar .global-notification-dropdown{margin-right:-6.6666666667rem}

[dir="rtl"] .product-header .utility-bar .global-notification-dropdown{margin-left:-6.6666666667rem}

[dir="ltr"] .product-header .utility-bar .global-notification-dropdown.dropdown-menu__arrow:before,[dir="ltr"] .product-header .utility-bar .global-notification-dropdown.dropdown-menu__arrow:after{right:7.35rem !important}

[dir="rtl"] .product-header .utility-bar .global-notification-dropdown.dropdown-menu__arrow:before,[dir="rtl"] .product-header .utility-bar .global-notification-dropdown.dropdown-menu__arrow:after{left:7.35rem !important}}

.feedback{position:fixed;bottom:2px;z-index:2}

[dir="ltr"] .feedback{right:2px}

[dir="rtl"] .feedback{left:2px}

.feedback:hover .feedback-info{display:inline-block}

[dir="ltr"] .mdzr-no-hiddenscroll .feedback{right:17px}

[dir="rtl"] .mdzr-no-hiddenscroll .feedback{left:17px}

.open-feedback{position:absolute;bottom:.5rem;z-index:2}

[dir="ltr"] .open-feedback{right:.5rem}

[dir="rtl"] .open-feedback{left:.5rem}

.open-feedback:before{font-size:2rem}

.feedback-info{display:none}

.draggable{cursor:move !important}

.draggable a{cursor:move !important}

.droppable{position:relative;display:block;min-height:3rem;color:rgba(15,31,44,.61);font-size:1rem;border:2px dashed rgba(15,31,44,.18);text-align:center;border-radius:.25rem}

.droppable.dragover{opacity:.5}

.droppable.is-ok{border-color:#00a866}

.droppable input.hide-file-input{position:absolute;top:0;right:0;z-index:2;width:100%;height:100%;cursor:pointer;bottom:0;left:0;opacity:0;-ms-filter:"alpha(opacity=0)"}

.droppable input.hide-file-input:focus+.file-input-label{outline:1px dashed}

.droppable.cursor--pointer{cursor:pointer}

.droppable.ng-invalid-pattern{border:1px solid #de0546}

.bulk-edit-bar{background-color:rgba(15,31,44,.03)}

.bulk-edit-bar__close{line-height:1.0833333333rem}

dg-carousel{display:block}

.carousel{position:relative}

@media(max-width: 480px){
.carousel__header{align-items:stretch !important}}

.carousel__container{overflow:hidden}

.carousel--list-view .carousel__container{overflow:visible}

.carousel__link{line-height:3rem}

.carousel__button{color:#0062e3;width:3rem;border-radius:50% !important}

.carousel__button[disabled]{color:rgba(15,31,44,.18) !important}

.carousel__list.grid{flex-wrap:nowrap}

.carousel--list-view .carousel__list.grid{flex-wrap:wrap}

.carousel__list:not(.grid){white-space:nowrap;position:relative}

.carousel--list-view .carousel__list:not(.grid){white-space:normal}

.carousel__item[class*=grid__col-]{flex-shrink:0}

.carousel__item:not([class*=grid__col-]){display:inline-block;vertical-align:top;min-height:3.3333333333rem;position:relative;white-space:normal}

.carousel__item--hidden{pointer-events:none}

.carousel--public:before{content:" ";display:block;margin-top:-120px;height:120px;visibility:hidden}

.sidebar{background-color:rgba(15,31,44,.03)}

[dir="ltr"] .sidebar{border-left:1px solid rgba(15,31,44,.18)}

[dir="rtl"] .sidebar{border-right:1px solid rgba(15,31,44,.18)}

.sidebar p{color:rgba(15,31,44,.61);font-size:1rem}

.sidebar-widget{border-bottom:1px solid rgba(15,31,44,.18);padding:2rem;color:rgba(15,31,44,.61)}

.sidebar-widget .sidebar-widget-title,.sidebar-widget .sidebar-widget-subtitle{font-size:1.1666666667rem;color:#0f1f2c;font-weight:500;margin-bottom:.5rem}

.sidebar>div:first-child .sidebar-widget{padding:2.5rem 2rem 3rem}

.sidebar>div:first-child .sidebar-widget .sidebar-widget-title{font-size:1.5rem;color:#0f1f2c;font-weight:400}

.sidebar-widget label{color:rgba(15,31,44,.61);font-weight:normal}

@media(max-width: 768px){
.sidebar-widget{display:none}

.selected-widget .sidebar-widget{display:block}}

.sidebar-mobile-toolbar{display:none;background-color:rgba(15,31,44,.03)}

.sidebar-mobile-toolbar .btn.btn-passive{border:0;color:#fff}

@media(max-width: 768px){
.sidebar-mobile-toolbar{display:block}}

.sidebar-collapse{background-color:rgba(15,31,44,.03)}

.sidebar-collapse li{border:1px solid rgba(15,31,44,.18);border-bottom:none}

.sidebar-collapse li:first-child{border-top:none}

@media(max-width: 768px){
[dir="ltr"] .sidebar{border-left:0}

[dir="rtl"] .sidebar{border-right:0}

.sidebar-widget{padding:1rem !important}

.sidebar~div{width:100%}}

.faq-item-wrapper .search-simple{overflow:inherit}

.faq-item-wrapper .edit-mode{background-color:rgba(15,31,44,.03)}

[dir="ltr"] .faq-item-wrapper .edit-mode{margin-right:-1px}

[dir="rtl"] .faq-item-wrapper .edit-mode{margin-left:-1px}

.faq-items{position:relative}

.faq-items .new-faq{background-color:rgba(15,31,44,.03)}

[dir="ltr"] .faq-items .new-faq{margin-right:-1px}

[dir="rtl"] .faq-items .new-faq{margin-left:-1px}

.faq-items .new-faq p{overflow-y:auto}

.faq-items .faq-item{position:relative;width:100%;opacity:1;transition:all .4s linear}

.faq-items .faq-item.slide{background-color:rgba(15,31,44,.03)}

[dir="ltr"] .faq-items .faq-item.slide{border-left:1px solid #e6e6e6}

[dir="rtl"] .faq-items .faq-item.slide{border-right:1px solid #e6e6e6}

.faq-items .faq-item p{overflow-y:auto}

.faq-items .faq-item p a{display:inline-block;text-decoration:underline;word-wrap:break-word;overflow-wrap:break-word;word-break:break-word}

.faq-items .top-faq-question{display:inline-block}

.faq-items .contact-text{color:rgba(15,31,44,.61)}

.faq-items .faq-item-right{min-width:8.3333333333rem}

[dir="ltr"] .faq-items .faq-item-right .action-btn{margin-left:.5rem}

[dir="rtl"] .faq-items .faq-item-right .action-btn{margin-right:.5rem}

[dir="ltr"] .faq-items .faq-item-right .action-btn:first-child{margin-left:0}

[dir="rtl"] .faq-items .faq-item-right .action-btn:first-child{margin-right:0}

@media(max-width: 768px){
.faq-items .faq-item-right{min-width:3.3333333333rem}

.faq-items .faq-item-right .action-btn{margin:.25rem}}

.text-area-validation:after{top:1rem;margin-top:1rem}

.bm-ext-feature .bm-ext-feature-chrome-extension{border-bottom:1px solid rgba(15,31,44,.18)}

.bm-ext-feature .bm-ext-feature-bookmarklet{background:url("/content/img/temp/browser-bookmarklet-repeat.png") repeat-x top}

.bm-ext-feature .bm-ext-feature-bookmarklet--foreground{width:384px}

.bm-ext-feature .bm-ext-feature-browser-name{white-space:nowrap}

.bm-ext-feature .bm-ext-feature-browser-name-mobile{display:none}

.bm-ext-feature .bm-ext-feature-icon-sm{margin-left:.5rem;margin-right:.5rem;vertical-align:text-bottom;width:1.5rem}

@media(max-width: 768px){
.bm-ext-feature .bm-ext-feature-bookmarklet{clear:both;width:100%}

[dir="ltr"] .bm-ext-feature .bm-ext-feature-inset{margin-left:initial}

[dir="rtl"] .bm-ext-feature .bm-ext-feature-inset{margin-right:initial}

.bm-ext-feature .bm-ext-feature-browser-logo{display:none}

.bm-ext-feature .bm-ext-feature-bookmarklet-text{width:100%}

.bm-ext-feature .bm-ext-feature-browser-help{clear:both;padding-bottom:2rem}

.bm-ext-feature .bm-ext-feature-browser-name{display:none}

.bm-ext-feature .bm-ext-feature-icon-sm{margin:0}

.bm-ext-feature .bm-ext-feature-browser-name-mobile{display:block}}

.textarea-expandable{min-height:4.5em;height:4.5em;padding:.5em 1em}

.textarea-expandable.is_single-line{min-height:2.5em;height:2.5em !important}

.goal-item{border-top:1px solid rgba(15,31,44,.18);padding:1rem 0;position:relative}

.goal-item .goal-title{color:#0f1f2c}

.goal-item.expired .date{color:#de0546}

.goal-item .group-info{font-size:.9166666667rem}

.goals li:first-child .goal-item{border-top:none}

.input-completed .goal-item{padding:0;border-top:none}

.input-completed .goal-item .date{color:rgba(15,31,44,.61) !important}

.input-completed .goal-item .progress{display:none !important}

.prefix-label{position:relative}

.prefix-label label{position:absolute;margin:0;height:3rem;line-height:3rem}

[dir="ltr"] .prefix-label label{padding-left:1rem}

[dir="rtl"] .prefix-label label{padding-right:1rem}

[dir="ltr"] .prefix-label input[type=text]{padding-left:2em}

[dir="rtl"] .prefix-label input[type=text]{padding-right:2em}

.featured-learning__item{min-height:12.5rem}

@media(max-width: 1024px){
.featured-learning__item{min-height:inherit}}

.box-tabs .nav-tabs{padding:0}

.box-tabs .nav-tabs.nav-justified>li{display:table-cell;width:1%}

.box-tabs .nav-tabs>li{border:1px solid rgba(15,31,44,.18);color:rgba(15,31,44,.61);font-weight:normal;vertical-align:middle}

[dir="ltr"] .box-tabs .nav-tabs>li{border-left:none}

[dir="rtl"] .box-tabs .nav-tabs>li{border-right:none}

[dir="ltr"] .box-tabs .nav-tabs>li:first-child{border-left:1px solid rgba(15,31,44,.18)}

[dir="rtl"] .box-tabs .nav-tabs>li:first-child{border-right:1px solid rgba(15,31,44,.18)}

.box-tabs .nav-tabs>li>a{border-bottom:none;display:block;line-height:1.5em;padding:1em;text-align:center}

.box-tabs .nav-tabs>li.active{background-color:rgba(15,31,44,.03)}

.box-tabs .nav-tabs>li.active>a,.box-tabs .nav-tabs>li.active>a:hover,.box-tabs .nav-tabs>li.active>a:focus{background-color:rgba(15,31,44,.03);border-bottom:none}

.box-tabs .tab-content>.active{border-top:none}

@media(max-width: 768px){
.box-tabs .mobile-tabs.mobile-only{display:none !important}

.box-tabs .nav-tabs.no-mobile{display:block !important}}

.sub-tabs{margin-bottom:2rem;border-bottom:1px solid rgba(15,31,44,.18)}

@media(max-width: 768px){
.sub-tabs{border:none}}

.sub-tabs .sub-tab{border:1px solid rgba(15,31,44,.18);color:rgba(15,31,44,.61);padding:1rem 2rem;background-color:rgba(15,31,44,.03);position:relative;bottom:-1px;line-height:1.5rem}

@media(max-width: 768px){
.sub-tabs .sub-tab{margin:2px 0}}

.sub-tabs .sub-tab.active{background-color:#fff;color:#0f1f2c;border-bottom-color:#fff;position:relative;bottom:-1px}

@media(max-width: 768px){
.sub-tabs .sub-tab.active{bottom:inherit;border:1px solid rgba(15,31,44,.18)}}

.tabnav{position:relative;height:3rem;overflow:hidden}

.tabnav__list{height:4.5rem;overflow-y:hidden;overflow-x:scroll;white-space:nowrap;font-size:0}

.tabnav__list::-webkit-scrollbar{display:none}

.tabnav__item{user-select:none;padding:0 1rem;font-size:1.333333333rem;display:inline-block;vertical-align:middle}

[dir="ltr"] .tabnav__item:first-child{padding-left:0}

[dir="rtl"] .tabnav__item:first-child{padding-right:0}

[dir="ltr"] .tabnav__item:last-child{padding-right:0}

[dir="rtl"] .tabnav__item:last-child{padding-left:0}

[dir="ltr"] .tabnav__item ul.dropdown-menu{right:-1.3rem}

[dir="rtl"] .tabnav__item ul.dropdown-menu{left:-1.3rem}

.tabnav__link{color:rgba(15,31,44,.61);display:inline-block;font-size:1.333333333rem;padding:0;line-height:3rem;height:3rem}

.is_active .tabnav__link{color:#0f1f2c;font-weight:600}

.tabnav__link:hover,.tabnav__link:focus{color:#0f1f2c}

.tabnav__last-span{display:inline-block;line-height:1.5em;margin-top:-0.2rem;max-width:11.35rem;overflow:hidden;vertical-align:middle;white-space:nowrap}

.tabnav__scrollbutton{color:rgba(15,31,44,.45);font-size:1rem;position:absolute;bottom:0;line-height:3rem;width:2rem;background:#fff;cursor:pointer}

[dir="ltr"] .tabnav__scrollbutton--left{left:-1px;background:linear-gradient(to right, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 100%)}

[dir="rtl"] .tabnav__scrollbutton--left{right:-1px;background:linear-gradient(to left, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0) 100%)}

[dir="ltr"] .tabnav__scrollbutton--right{right:-1px;text-align:right;background:linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%)}

[dir="rtl"] .tabnav__scrollbutton--right{left:-1px;text-align:left;background:linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%)}

.tabnavs--dark-background .tabnav__scrollbutton{background:rgba(15,31,44,.03) !important}

.skillnav__credential-rating{border:2px solid rgba(15,31,44,.18);color:rgba(15,31,44,.61);border-radius:50%;width:18px;height:18px;font-weight:500;line-height:.7rem;font-size:.6666666667rem;padding:1px 3px 1px 3px;min-height:0px}

.skillnav__pending{border:2px solid rgba(15,31,44,.18);color:#fff;position:relative}

.skillnav__pending:before{content:"";border-radius:50%;height:18px;position:absolute;top:-2px;width:18px}

[dir="ltr"] .skillnav__pending:before{background:linear-gradient(to right, rgba(15, 31, 44, 0.18), rgba(15, 31, 44, 0.18) 50%, rgb(0, 98, 227) 50%);left:-2px}

[dir="rtl"] .skillnav__pending:before{background:linear-gradient(to left, rgba(15, 31, 44, 0.18), rgba(15, 31, 44, 0.18) 50%, rgb(0, 98, 227) 50%);right:-2px}

.skillnav__pending:after{content:"";background-color:#fff;border-radius:50%;height:14px;position:absolute;top:0;width:14px}

[dir="ltr"] .skillnav__pending:after{left:0}

[dir="rtl"] .skillnav__pending:after{right:0}

.feature-box{width:31rem;overflow:hidden}

.feature-box .thumb-medium{min-height:1px}

@media(max-width: 480px){
.feature-box{width:100%}}

.payment-form__message{font-size:4rem}

.payment-form__message--success{font-size:2rem;color:#00a866}

.onboarding__header,.onboarding__footer{background-color:#fff;z-index:1}

.onboarding__header{height:5.5rem}

.onboarding__header__logo{height:3rem}

.onboarding__body{z-index:0}

.onboarding__footer{min-height:7rem}

.onboarding__step-dot{font-size:.75rem;padding:0 .1666666667rem;color:rgba(15,31,44,.45)}

.onboarding__step-dot--active{color:#0062e3}

.onboarding__step-dot--active:before{content:"3"}

.onboarding .tile--target .par--small{color:rgba(15,31,44,.61)}

dg-onboarding-tag-dropdown .panel,dg-onboarding-tag-dropdown .panel-body{border:none !important;box-shadow:none !important}

dg-onboarding-tag-dropdown .panel-heading{position:relative;padding:0}

dg-onboarding-tag-dropdown .panel-title button{padding:10px 15px;display:block;width:100%}

dg-onboarding-tag-dropdown .panel__dropdown-icon{position:absolute}

[dir="ltr"] dg-onboarding-tag-dropdown .panel__dropdown-icon{right:0}

[dir="rtl"] dg-onboarding-tag-dropdown .panel__dropdown-icon{left:0}

.center-fixed-logo{position:fixed;top:50%;width:100%;max-width:864px}

[dir="ltr"] .center-fixed-logo{left:50%;transform:translate(-50%, -50%)}

[dir="rtl"] .center-fixed-logo{right:50%;transform:translate(50%, -50%)}

.modal-open{overflow:hidden}

.modal{overflow:auto;overflow-y:scroll;position:fixed;top:0;right:0;bottom:0;left:0;z-index:1050;-webkit-overflow-scrolling:auto;outline:0}

.modal-dialog{position:relative;width:auto;margin:10px;background:#fff;border-radius:4px}

.modal-content{z-index:999;position:relative;outline:none;padding:1.5em}

.modal-content::after{clear:both;content:"";display:block}

.modal-backdrop{position:fixed;top:0;right:0;bottom:0;left:0;z-index:1040 !important;opacity:.98;background-color:#03233b}

.modal-backdrop.fade{opacity:0}

.modal-backdrop.in{opacity:.4;-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=40)"}

.modal-header{padding:15px;border-bottom:1px solid #e5e5e5;min-height:16.42857143px}

.modal-header .close{margin-top:-2px}

.modal-title{margin:0;line-height:1.42857143}

.modal-body{position:relative;padding:20px}

.modal-footer{margin-top:15px;padding:19px 20px 20px;border-top:1px solid #e5e5e5}

[dir="ltr"] .modal-footer{text-align:right}

[dir="rtl"] .modal-footer{text-align:left}

.modal-footer .btn+.btn{margin-bottom:0}

[dir="ltr"] .modal-footer .btn+.btn{margin-left:5px}

[dir="rtl"] .modal-footer .btn+.btn{margin-right:5px}

[dir="ltr"] .modal-footer .btn-group .btn+.btn{margin-left:-1px}

[dir="rtl"] .modal-footer .btn-group .btn+.btn{margin-right:-1px}

[dir="ltr"] .modal-footer .btn-block+.btn-block{margin-left:0}

[dir="rtl"] .modal-footer .btn-block+.btn-block{margin-right:0}

@media(min-width: 768px){
.modal-dialog{width:auto;max-width:40rem;margin:30px auto}

.modal-sm{width:300px}}

@media(min-width: 992px){
.modal-lg{width:900px;max-width:100%}}

.dg-modal__header{padding:1.5rem;border-bottom:1px solid rgba(15,31,44,.18)}

.dg-modal__header--borderless{padding:2rem 2rem 1.5rem 2rem;border-bottom:none}

.dg-modal__header--centered{text-align:center}

.dg-modal__close-icon{color:rgba(15,31,44,.61);font-size:1rem;position:relative;z-index:1}

[dir="ltr"] .dg-modal__close-icon{padding-left:1rem}

[dir="rtl"] .dg-modal__close-icon{padding-right:1rem}

.dg-modal__close-icon--abs-right{position:absolute}

[dir="ltr"] .dg-modal__close-icon--abs-right{right:0}

[dir="rtl"] .dg-modal__close-icon--abs-right{left:0}

.dg-modal__close-icon--top{top:0}

.dg-modal__body{padding:1rem 1rem 2rem 1rem}

.dg-modal__body-scrollable-content{height:calc(75vh - 12rem);overflow-y:auto;-webkit-overflow-scrolling:touch}

.dg-modal__footer{border-radius:0 0 .25rem .25rem;background-color:rgba(15,31,44,.03);padding:1rem 2rem;border-top:1px solid rgba(15,31,44,.18);display:flex;justify-content:flex-end}

[dir="ltr"] .dg-modal__footer{text-align:right}

[dir="rtl"] .dg-modal__footer{text-align:left}

.dg-modal__footer-content{flex-grow:1}

.dg-modal__footer .btn-passive{color:#0f1f2c}

.modal-content{padding:0}

.modal-dialog{margin-top:7rem;margin-bottom:4rem}

.modal-dialog.modal-small{margin-top:15%;max-width:36rem}

@media(max-width: 768px){
.modal-dialog.modal-small{max-width:inherit;margin-right:1rem;margin-left:1rem}}

.modal-dialog.modal-medium{max-width:40rem}

.modal-dialog.modal-large{max-width:47rem}

.modal-dialog.modal-xlarge{max-width:58rem}

.modal-dialog.modal-huge{max-width:73rem}

.modal-open .modal{background-color:rgba(0,0,0,.2)}

.modal-open .dropdown-menu{z-index:1101}

.modal--layered{padding-top:4rem}

legend{font-size:1rem;font-weight:600}

.nav{margin-bottom:0;list-style:none}

[dir="ltr"] .nav{padding-left:0}

[dir="rtl"] .nav{padding-right:0}

.nav>li{position:relative;display:block}

.nav>li>a{position:relative;display:block;padding:10px 15px 15px}

.nav>li>a:hover,.nav>li>a:focus{text-decoration:none}

.nav>li.disabled>a,.nav>li>a.disabled{color:rgba(15,31,44,.18)}

.nav>li.disabled>a:hover,.nav>li.disabled>a:focus,.nav>li>a.disabled:hover,.nav>li>a.disabled:focus{color:rgba(15,31,44,.45);text-decoration:none;background-color:rgba(0,0,0,0);cursor:not-allowed}

.nav .open>a,.nav .open>a:hover,.nav .open>a:focus{border-color:rgba(15,31,44,.18)}

.nav .nav-divider{height:1px;margin:9px 0;overflow:hidden;background-color:rgba(15,31,44,.18)}

.nav>li>a>img{max-width:none}

.nav-tabs{background:#fff}

[dir="ltr"] .nav-tabs{padding:0 1em 0 2em}

[dir="rtl"] .nav-tabs{padding:0 2em 0 1em}

.nav-tabs::after{clear:both;content:"";display:block}

.hide-tabs .nav-tabs{display:none}

.nav-tabs>li{margin:0 1em}

[dir="ltr"] .nav-tabs>li{float:left}

[dir="rtl"] .nav-tabs>li{float:right}

[dir="ltr"] .nav-tabs>li:first-child{margin-left:0}

[dir="rtl"] .nav-tabs>li:first-child{margin-right:0}

[dir="ltr"] .nav-tabs>li:last-child{margin-right:0}

[dir="rtl"] .nav-tabs>li:last-child{margin-left:0}

.nav-tabs>li>a{line-height:3em;padding:0;width:100%;text-align:center;border-bottom:3px solid rgba(0,0,0,0)}

[dir="ltr"] .nav-tabs>li>a{margin-right:1px}

[dir="rtl"] .nav-tabs>li>a{margin-left:1px}

.nav-tabs>li>a{cursor:pointer;font-weight:500;color:rgba(15,31,44,.61);font-size:12px;text-transform:uppercase}

.tab-content>.tab-pane{display:none}

.tab-content-pane{border-top:1px solid rgba(15,31,44,.18)}

.tab-content>.active{display:block;opacity:100;border-top:1px solid rgba(15,31,44,.18);position:relative;clear:both}

.hide-tabs .tab-content>.active{border-top:none}

.alert__dialog{padding:1rem 3rem 3rem 3rem}

.alert__dialog--header{color:#000;text-align:center}

.alert__dialog--icon{color:#0062e3}

.alert__dialog--icon-secure{background-color:#0062e3;color:#fff;padding:6px;border-radius:33px;font-size:40px;width:66px;height:66px}

.alert__dialog--icon-hide{display:none}

.alert__dialog--icon-unicode{color:#0062e3;font-size:100px;width:66px;height:66px}

.faux-checkbox{position:relative;display:inline-block}

[dir="ltr"] .faux-checkbox{margin:0 .5rem 0 0}

[dir="rtl"] .faux-checkbox{margin:0 0 0 .5rem}

.faux-checkbox-btn{background-color:#fff;padding:0;position:relative;height:1.6666666667rem;width:1.6666666667rem;min-height:1.6666666667rem;box-shadow:none !important}

.faux-checkbox-btn.has-focus{border-color:#0062e3}

.faux-checkbox-check{color:#0062e3;font-size:1.1666666667rem;line-height:1.5rem}

.faux-checkbox input[type=checkbox]{position:absolute;visibility:hidden}

.empty-profile{width:100%;max-width:40rem;margin:0 auto}

.empty-profile-content{position:relative;margin-top:-3.3333333333rem}

.rating-badge{border-radius:50%;width:18px;height:18px;font-family:"Inter","Helvetica Neue",Helvetica,Arial,sans-serif;font-weight:600;font-size:12px}

.rating-badge--hit-target circle{fill:#cceee0;stroke:#cceee0;stroke-width:2px}

.rating-badge--hit-target text{fill:#007547}

.rating-badge--below-target circle{fill:#fcf0cc;stroke:#fcf0cc;stroke-width:2px}

.rating-badge--below-target text{fill:#916600}

.rating-badge--no-target circle{fill:none;stroke:rgba(15,31,44,.45);stroke-width:1px}

.rating-badge--no-target text{fill:rgba(15,31,44,.61)}

.rating-badge--no-rating circle{fill:none;stroke:rgba(15,31,44,.18);stroke-width:2px}

.rating-badge--no-rating path{fill:rgba(15,31,44,.18)}

.input-card__meta li{font-weight:500}

.input-card__meta li .meta-list__provider,.input-card__meta li .input-card__meta__provider,.input-card__meta li.collapse-white-space:before{font-size:1rem}

.input-card__meta li:nth-child(2):before{display:none}

.input-card__meta__provider{font-size:1rem}

.input-card__title{margin-bottom:.5rem}

.input-card__primary-link:hover .input-card--clickable .input-card__title,.input-card__primary-link:focus .input-card--clickable .input-card__title{text-decoration:underline}

.input-card--not-clickable{cursor:default}

.input-card .comments{border-radius:0 0 .5rem .5rem;padding:1.5rem 2rem}

.modal-content .input-card{box-shadow:none}

.tile-card__title{font-weight:600;margin-bottom:2rem}

@media(max-width: 768px){
.tile-card__title{padding-left:1rem;padding-right:1rem}}

.tile-card__body{border-radius:0 0 .5rem .5rem;padding:1.5rem;background-color:rgba(15,31,44,.03)}

.tile-card__body .path-tile{margin:0 auto}

.tile-card__body .tile--target{max-width:28rem;margin:0 auto}

.tile-card__body--rounded{border-radius:.5rem}

.tile-card__body--shadowed{box-shadow:0px 1px 5px 0px rgba(15,31,44,.25)}

.skeleton-container{animation:skeleton-opacity 1s ease-in infinite alternate}

@keyframes skeleton-opacity{
from{opacity:1}

to{opacity:.5}}

.skeleton__circle{background-color:rgba(15,31,44,.18);border-radius:50%;height:24px;width:24px}

.skeleton__circle--large{height:84px;width:84px}

.skeleton__circle--medium{height:36px;width:36px}

.skeleton__circle--small{height:18px;width:18px}

.skeleton__square{width:3rem;height:3rem;background-color:rgba(15,31,44,.18)}

.skeleton__oval{background-color:rgba(15,31,44,.18);border-radius:1rem;height:20px;width:25px}

.skeleton__oval--long{height:20px;width:70px}

.skeleton__line{background-color:rgba(15,31,44,.18);border-radius:4px;height:12px}

.skeleton__line--thin{height:7px}

.skeleton__line--regular{height:.75rem}

.skeleton__line--medium{height:1.5rem}

.skeleton__line--thick{height:36px}

.skeleton__block{background-color:rgba(15,31,44,.18)}

.skeleton__block--large{height:24rem}

.skeleton__block--medium{height:14.5rem}

.skeleton__block--small{height:10.5rem}

.skeleton__block-vertical--large{height:30rem;width:45rem}

.skeleton__block-vertical--medium{height:24rem}

.skeleton__block-vertical--small{height:20.5rem;width:21rem}

.diamond-list{margin:0 auto;font-size:1.5rem}

[dir="ltr"] .diamond-list{border-left:2px solid rgba(15,31,44,.18)}

[dir="rtl"] .diamond-list{border-right:2px solid rgba(15,31,44,.18)}

.diamond-list li{position:relative;line-height:1em;margin-top:2em}

[dir="ltr"] .diamond-list li{padding-left:2.0833333333rem}

[dir="rtl"] .diamond-list li{padding-right:2.0833333333rem}

.diamond-list li:first-child{margin-top:0}

.diamond-list li:before{content:" ";background:rgba(0,0,0,0) url("/content/img/diamond.png") no-repeat center center;display:block;height:23px;width:23px;position:absolute}

[dir="ltr"] .diamond-list li:before{left:-12px}

[dir="rtl"] .diamond-list li:before{right:-12px}

@media(max-width: 768px){
.diamond-list li{font-size:1.3333333333rem}}

.app-header{background-color:#fff;box-sizing:content-box}

.app-header__border{background-color:rgba(15,31,44,.18);width:100%;height:1px}

.app-header__logo{padding:0 1rem;line-height:5.5rem}

.app-header__logo-img{vertical-align:middle}

.app-header__logo-img--glasses{height:2.5rem;width:4rem}

@media(max-width: 350px){
.app-header__logo-img--glasses{height:auto;width:3rem}}

.app-header--provider .app-header__logo{display:none}

.app-header__logo-brand{height:5rem;position:absolute;top:0}

[dir="ltr"] .app-header__logo-brand{left:50%}

[dir="rtl"] .app-header__logo-brand{right:50%}

.app-header__logo-brand-image{height:3rem;position:relative;top:1rem}

[dir="ltr"] .app-header__logo-brand-image{left:-50%}

[dir="rtl"] .app-header__logo-brand-image{right:-50%}

.app-header__logo-brand-image.logo-preview{top:2.5rem}

.app-header--provider .app-header__logo-brand{position:relative;margin-left:2rem;margin-right:2rem}

[dir="ltr"] .app-header--provider .app-header__logo-brand{left:auto}

[dir="rtl"] .app-header--provider .app-header__logo-brand{right:auto}

[dir="ltr"] .app-header--provider .app-header__logo-brand-image{left:0}

[dir="rtl"] .app-header--provider .app-header__logo-brand-image{right:0}

.app-header__nav{flex-grow:1;font-size:0;white-space:nowrap;min-width:0}

[dir="ltr"] .app-header__nav{padding-left:1rem}

[dir="rtl"] .app-header__nav{padding-right:1rem}

.app-header__nav .tabnav__item{padding:0}

.app-header__nav .tabnav__link{border-bottom:none;line-height:3.5rem;height:3.5rem;padding:0 1rem}

.app-header__nav .tabnav__link:hover,.app-header__nav .tabnav__link:focus,.app-header__nav .tabnav__link:active{background-color:rgba(15,31,44,.08);border-radius:6px}

.app-header__nav .tabnav__link:hover:after,.app-header__nav .tabnav__link:focus:after,.app-header__nav .tabnav__link:active:after{display:none}

.app-header__nav .is_active{font-weight:500}

.app-header__nav .is_active .tabnav__link{color:#0f1f2c;position:relative}

.app-header__nav .is_active .tabnav__link:after{background-color:rgba(15,31,44,.45);border-radius:1px;bottom:.5rem;content:"";display:block;height:2px;position:absolute;width:calc(100% - 2.5rem)}

[dir="ltr"] .app-header__nav .is_active .tabnav__link:after{left:1.25rem}

[dir="rtl"] .app-header__nav .is_active .tabnav__link:after{right:1.25rem}

.app-header__nav .is_active .tabnav__link:hover:after,.app-header__nav .is_active .tabnav__link:focus:after{display:none}

.app-header__nav.ng-animate{overflow:hidden}

[dir="ltr"] .isMobile .app-header__nav ul.dropdown-menu{right:1rem}

[dir="rtl"] .isMobile .app-header__nav ul.dropdown-menu{left:1rem}

.app-header .search-field{background:rgba(0,0,0,0)}

@media(min-width: 769px){
.app-header--dark .app-header__nav .tabnav__link{color:rgba(255,255,255,.8)}

.app-header--dark .app-header__nav .tabnav__link:hover,.app-header--dark .app-header__nav .tabnav__link:focus,.app-header--dark .app-header__nav .tabnav__link:active{background-color:rgba(255,255,255,.2);border-radius:6px}

.app-header--dark .app-header__nav .tabnav__link:hover:after,.app-header--dark .app-header__nav .tabnav__link:focus:after,.app-header--dark .app-header__nav .tabnav__link:active:after{display:none}

.app-header--dark .app-header__nav .is_active{font-weight:500}

.app-header--dark .app-header__nav .is_active .tabnav__link{color:#fff}

.app-header--dark .app-header__nav .is_active .tabnav__link:after{background-color:rgba(255,255,255,.75)}}

@media(min-width: 1025px){
.app-header--dark .global-search__input{color:rgba(255,255,255,.75)}

.app-header--dark .global-search__input:hover,.app-header--dark .global-search__input:focus,.app-header--dark .global-search__input:active{color:#fff !important}

.app-header--dark .global-search__input::placeholder{color:rgba(255,255,255,.75) !important}

.app-header--dark .global-search__input:-ms-input-placeholder{color:rgba(255,255,255,.75) !important}

.app-header--dark .search-field{border-color:rgba(255,255,255,.5)}

.app-header--dark .search-field:before{color:#fff}}

.app-header--dark .utility-bar .btn-utility{border-color:rgba(255,255,255,.5);color:#fff}

.app-header--dark .utility-bar .btn-utility:not([disabled]):not(.is_disabled):not(.inactive):not(:active):hover,.app-header--dark .utility-bar .btn-utility:not(.active):not(:active):focus{border-color:#fff;color:#fff}

.app-header--dark .app-header__logo--link:after{color:rgba(255,255,255,.75)}

.app-header--preview{border-radius:.25rem}

@media(max-width: 1272px){
.app-header .user-overview{display:none !important}}

@media(max-width: 768px){
.app-header__logo-brand{display:none !important}

.app-header--provider .app-header__profile{display:none}

.app-header__logo{flex-grow:1;min-width:0}

.app-header__nav--dropdown{flex-grow:0;white-space:normal;background-color:rgba(15,31,44,.03);position:absolute;top:5.5rem;left:0;right:0;padding:1rem 0;border-bottom:1px solid rgba(15,31,44,.18);z-index:5}

.app-header__nav--dropdown .inline-list li{display:block}

.app-header__nav--dropdown .tabnav__link{font-size:1.3333333333rem;line-height:normal;color:rgba(15,31,44,.61);display:block;padding:1rem}

.app-header__nav--dropdown .is_active .tabnav__link{border-bottom:none;font-weight:600;margin-bottom:0}

.app-header__nav--dropdown .is_active .tabnav__link:after{display:none}

.app-header .nav-preview{display:none}}

.breadcrumb{width:100%;padding:.75rem 0;background-color:rgba(15,31,44,.03);font-size:1rem;line-height:1.5rem;color:rgba(15,31,44,.61);position:relative;z-index:1}

.breadcrumb:before,.breadcrumb:after{content:" ";position:absolute;display:block;background-color:rgba(15,31,44,.03);height:100%;top:0;bottom:0}

[dir="ltr"] .breadcrumb:before{left:-100%;right:100%}

[dir="rtl"] .breadcrumb:before{right:-100%;left:100%}

[dir="ltr"] .breadcrumb:after{right:-100%;left:100%}

[dir="rtl"] .breadcrumb:after{left:-100%;right:100%}

.breadcrumb li{display:inline-block}

.breadcrumb__item{position:relative;color:#0f1f2c}

[dir="ltr"] .breadcrumb__item{margin-right:.5rem}

[dir="rtl"] .breadcrumb__item{margin-left:.5rem}

.breadcrumb__item:last-child{font-weight:600}

.breadcrumb__item:last-child:after{display:none}

.breadcrumb__back-btn:before,.breadcrumb__item:after{font-weight:normal;font-size:.8333333333rem}

.breadcrumb__link{color:rgba(15,31,44,.61)}

.breadcrumb__link:hover,.breadcrumb__link:focus{color:#0f1f2c;text-decoration:underline}

.facet-filter{background-color:#f8f8f9}

.facet-filter--alt{background-color:#fff}

.is_fixed .facet-filter--alt{max-height:calc(100vh - 5.5rem - 6rem);overflow:auto;overflow-x:hidden;overflow-y:auto}

.facet-filter legend{margin-bottom:.5rem}

.facet-filter__wrapper{align-items:flex-start !important;padding:.5rem}

[dir="ltr"] .facet-filter__wrapper--has-facets .facet-filter__cell--count{text-align:right}

[dir="rtl"] .facet-filter__wrapper--has-facets .facet-filter__cell--count{text-align:left}

.facet-filter__facet-checkbox{border:1px solid rgba(15,31,44,.18);border-radius:3px;background-color:#fff;font-weight:400;min-height:3rem;display:inline-block;color:rgba(15,31,44,.61);font-size:1rem;padding:9px 1.1rem}

.facet-filter__toggle.btn.btn-dropdown{font-size:1rem}

[dir="ltr"] .facet-filter__toggle.btn.btn-dropdown{padding:.5rem 4rem .5rem 1rem}

[dir="rtl"] .facet-filter__toggle.btn.btn-dropdown{padding:.5rem 1rem .5rem 4rem}

.facet-filter__toggle-count{color:rgba(15,31,44,.61)}

.facet-filter__cell{padding:.5rem}

.facet-filter__cell--count{flex-grow:1}

.facet-filter__count{color:rgba(15,31,44,.61);line-height:3rem;min-width:7rem}

[dir="ltr"] .facet-filter__count{padding-right:1.25rem}

[dir="rtl"] .facet-filter__count{padding-left:1.25rem}

.facet-filter__label{display:table;font-size:1rem;padding:.25rem 0;color:rgba(15,31,44,.61)}

.facet-filter__checkbox{display:table-cell;vertical-align:top}

[dir="ltr"] .facet-filter__checkbox{padding:0 .5rem 0 0}

[dir="rtl"] .facet-filter__checkbox{padding:0 0 0 .5rem}

.facet-filter__name{display:table-cell}

.facet-filter__expander{padding:0 !important}

[dir="ltr"] .facet-filter__expander:before{padding-right:.5rem}

[dir="rtl"] .facet-filter__expander:before{padding-left:.5rem}

.facet-filter__active-list ul li{margin-bottom:.5rem;margin-top:.5rem}

.facet-filter__active-list ul:first-child li:first-child{margin-top:1rem}

.facet-filter__active-filter{color:rgba(15,31,44,.61);cursor:default;font-size:1rem;width:100%}

[dir="ltr"] .facet-filter__active-filter{padding:0 .5rem 0 1.1rem;text-align:left}

[dir="rtl"] .facet-filter__active-filter{padding:0 1.1rem 0 .5rem;text-align:right}

.facet-filter__remove-filter{color:rgba(15,31,44,.18);cursor:pointer;font-weight:bold;font-size:.75rem}

[dir="ltr"] .facet-filter__remove-filter{padding-left:.5rem}

[dir="rtl"] .facet-filter__remove-filter{padding-right:.5rem}

.facet-filter__remove-filter:hover,.facet-filter__remove-filter:focus{color:rgba(15,31,44,.61)}

.facet-filter__dropdown-menu{min-width:17rem;position:absolute;top:4rem}

.facet-filter__dropdown-menu--wide{min-width:21rem}

.facet-filter__toggle--plain{color:rgba(15,31,44,.61);font-size:1rem;padding-left:.5rem;padding-right:.5rem}

.facet-filter__toggle--plain :before{color:rgba(15,31,44,.61)}

.facet-filter__menu,.facet-filter__multimenu{max-height:20rem;max-width:21rem;overflow-y:auto}

.facet-filter__multimenu{margin-bottom:1.5rem}

.facet-filter__menu li{padding:.1rem 0}

.facet-filter__menu li:first-child{padding-top:0}

.facet-filter__menu li:last-child{padding-bottom:0}

.facet-filter__menu li:last-child .facet-filter__multimenu{margin-bottom:0 !important}

.facet-filter__menu li .facet-filter__multimenu li:first-child{margin-top:.5rem}

.social-share-list{text-align:center}

.social-share-list__item{display:inline-block;text-align:center}

.social-share-list__link{color:rgba(15,31,44,.61)}

.social-share-list__icon{display:block;font-size:3rem;padding:0}

.social-share-list__icon--linkedin:before{color:#0077b5}

.social-share-list__icon--facebook:before{color:#3c5a96}

.social-share-list__icon--twitter:before{color:#2aa3ef}

.social-share-list__icon--googleplus:before{color:#d6492f}

.social-share-list__icon--email:before{color:rgba(15,31,44,.61)}

.accordion--simple.accordion--left-toggle .accordion-toggle:after{top:.75rem}

[dir="ltr"] .accordion--simple.accordion--left-toggle .accordion-toggle:after{right:initial;left:0}

[dir="rtl"] .accordion--simple.accordion--left-toggle .accordion-toggle:after{left:initial;right:0}

.accordion--simple .accordion-toggle{padding:0;position:relative;display:block;width:100%}

[dir="ltr"] .accordion--simple .accordion-toggle{text-align:left}

[dir="rtl"] .accordion--simple .accordion-toggle{text-align:right}

.accordion--simple .accordion-toggle:after{content:",";font-family:"degreedicons";position:absolute;top:0;font-size:1rem;font-weight:500;line-height:1.5rem;color:rgba(15,31,44,.61)}

[dir="ltr"] .accordion--simple .accordion-toggle:after{right:0}

[dir="rtl"] .accordion--simple .accordion-toggle:after{left:0}

.accordion--simple .accordion-toggle label{cursor:pointer}

.accordion--simple.accordion--mid-toggle .accordion-toggle:after{top:calc(50% - .75rem)}

.accordion--simple .panel--simple.border{border:1px solid rgba(15,31,44,.18)}

.accordion--simple .panel--simple.border-bottom{border-bottom:1px solid rgba(15,31,44,.18)}

.accordion--simple .panel--simple.border-top{border-top:1px solid rgba(15,31,44,.18)}

.accordion--simple .panel--simple.panel--no-arrow .accordion-toggle:after{content:""}

.accordion--simple .panel-open .accordion-toggle:after{content:"`"}

.accordion--simple .panel{box-shadow:none;-webkit-box-shadow:none}

.accordion--simple .panel-group{margin:0}

.accordion--simple .panel-heading{padding:0}

.accordion--simple .panel-body{padding:0;border:none !important}

.accordion--simple .panel-group .panel+.panel{margin:0}

.expander{color:rgba(15,31,44,.61)}

.expander[data-icon=","][aria-expanded=true]:before{content:"`"}

.expander--right{position:absolute;top:0}

[dir="ltr"] .expander--right{right:0}

[dir="rtl"] .expander--right{left:0}

.integrations__card{min-height:130px;height:100%;border:1px solid rgba(15,31,44,.18);border-radius:3px;background-color:#fff;position:relative}

.integrations__card .icon{font-size:3rem;line-height:3rem}

.explainer-video__button{width:100%;padding-top:54%;height:0;position:relative;display:block}

.explainer-video__label{position:absolute;bottom:.5rem}

[dir="ltr"] .explainer-video__label{right:.5rem}

[dir="rtl"] .explainer-video__label{left:.5rem}

dg-explainer-video{display:block}

.skill-definitions dt{font-weight:600;padding-bottom:1rem}

.skill-definitions dd{color:rgba(15,31,44,.61);padding-bottom:2rem}

.skill-definitions ul{list-style:disc}

[dir="ltr"] .skill-definitions ul{padding:1rem 0 0 2rem}

[dir="rtl"] .skill-definitions ul{padding:1rem 2rem 0 0}

.skill-definitions.par.par--small dt{padding-bottom:0}

.skill-definitions.par.par--small dd{padding-bottom:1rem}

[dir="ltr"] .eval-endorse__profile-pic{left:6px}

[dir="rtl"] .eval-endorse__profile-pic{right:6px}

[dir="ltr"] .eval-endorse__tag-donut{left:-12px}

[dir="rtl"] .eval-endorse__tag-donut{right:-12px}

.eval-endorse__accept-review{color:#00a866}

.eval-endorse__reject-review{color:#f0b400}

.circle-icon-border{border:2px solid #fff}

.eval-endorsement-complete__profile-status{min-height:72px;min-width:72px;flex-direction:column}

.eval-endorsement-complete__profile-status-icon{margin:0 auto;height:72px;width:72px;left:0px;right:0px}

.selection-box{border:rgba(15,31,44,.18) solid 1px;border-radius:3px;margin-bottom:.5rem}

.selection-box:last-child{margin-bottom:0}

.selection-box__label{padding:2rem;cursor:pointer}

.selection-box__label:not(.l_flex):not(.l_flexbar){display:block}

.selection-box__text{font-weight:500}

[dir="ltr"] .selection-box__text{margin-left:.25rem}

[dir="rtl"] .selection-box__text{margin-right:.25rem}

.selection-box__extras{padding-bottom:2rem;min-height:5rem}

.selection-box.is_disabled{background-color:rgba(15,31,44,.03)}

.selection-box.is_disabled .selection-box__text{color:rgba(15,31,44,.18)}

.image-layout__container{background-color:rgba(15,31,44,.03);border-radius:.5rem .5rem 0 0;position:absolute;height:100%;width:100%}

[dir="ltr"] .image-layout__container--pattern{background:linear-gradient(135deg, rgb(233, 245, 243) 0%, rgb(246, 238, 227) 50%, rgb(247, 228, 237) 100%)}

[dir="rtl"] .image-layout__container--pattern{background:linear-gradient(-135deg, rgb(233, 245, 243) 0%, rgb(246, 238, 227) 50%, rgb(247, 228, 237) 100%)}

.image-layout__play{z-index:2;position:absolute;top:50%;font-size:0;padding:.5rem;background-color:rgba(255,255,255,.9);border-radius:50%}

[dir="ltr"] .image-layout__play{left:50%;transform:translateX(-50%) translateY(-50%)}

[dir="rtl"] .image-layout__play{right:50%;transform:translateX(50%) translateY(-50%)}

.image-layout__resource{border-radius:.5rem .5rem 0 0;max-width:100%;object-fit:cover;object-position:top;width:100%}

.image-layout__logo{height:100%;max-width:100%}

.image-layout__pattern{background-position:center;background-repeat:repeat;border-radius:.5rem .5rem 0 0;height:100%;position:absolute;width:100%}

[dir="ltr"] .image-layout__pattern{left:0}

[dir="rtl"] .image-layout__pattern{right:0}

.image-layout__endorsed{position:absolute;bottom:.5rem;height:2rem;max-width:12rem}

[dir="ltr"] .image-layout__endorsed{left:1rem}

[dir="rtl"] .image-layout__endorsed{right:1rem}

.image-layout--centered{text-align:center}

.image-layout--centered .image-layout__resource{border-radius:.5rem;box-shadow:0px 13px 20px 3px rgba(15,31,44,.25);margin:2rem 0;width:auto}

.image-layout--squared .image-layout__container{border-radius:0}

.image-layout--squared:not(.image-layout--centered) .image-layout__resource{border-radius:0}

.image-layout--card{height:14.5833333333rem}

.image-layout--card .image-layout__wrapper{height:14.5833333333rem}

.image-layout--card .image-layout__logo{max-width:50%}

.image-layout--tile{height:12rem}

.image-layout--tile .image-layout__wrapper{height:12rem}

.image-layout--tile .image-layout__logo{max-width:75%}

.image-layout--empty{height:17.5rem}

@media(max-width: 768px){
.image-layout--empty{height:14rem}}

.image-layout--empty--large{height:20rem}

.image-layout--card .image-layout__play,.image-layout--tile .image-layout__play{padding:1rem}

.image-layout--card .image-layout__play .dg-icon,.image-layout--tile .image-layout__play .dg-icon{width:2rem !important;min-height:2rem !important}

.image-layout--directory{padding-top:55.75%}

.image-layout--directory .image-layout__wrapper{position:absolute;top:0;left:0;bottom:0;right:0}

.image-layout--directory .image-layout__pattern{background-size:cover}

.image-layout--pathway-tile{height:auto;position:relative;overflow:hidden;padding-top:59.1%;max-width:21rem}

.image-layout--pathway-tile .image-layout__wrapper{max-width:100%;max-height:100%;position:absolute;top:0;left:0;bottom:0;right:0}

.image-layout--preview{width:10rem;height:6.1666666667rem}

.image-layout--preview .image-layout__wrapper{height:6.1666666667rem}

.image-layout--related{height:7.0833333333rem;width:12.5833333333rem}

.image-layout--related .image-layout__wrapper{height:7.0833333333rem}

.image-layout--preview.image-layout--centered .image-layout__resource,.image-layout--related.image-layout--centered .image-layout__resource{border-radius:.25rem;box-shadow:0px 6px 10px 0px rgba(15,31,44,.25);margin:1rem 0}

.image-layout--preview .image-layout__container,.image-layout--preview .image-layout__resource,.image-layout--preview .image-layout__logo,.image-layout--preview .image-layout__pattern,.image-layout--related .image-layout__container,.image-layout--related .image-layout__resource,.image-layout--related .image-layout__logo,.image-layout--related .image-layout__pattern{border-radius:.5rem}

.image-layout--preview .image-layout__pattern,.image-layout--related .image-layout__pattern{background-size:6.6666666667rem}

.image-layout__provider--lg{width:7rem;height:7rem}

.image-layout__provider--sm{width:6.5rem;height:6.5rem}

.image-layout--resource-card{height:4.8333333333rem;width:8.5833333333rem}

.image-layout--resource-card .image-layout__wrapper{height:4.8333333333rem}

.image-layout--resource-card .image-layout__logo{width:inherit}

.image-layout--resource-card .image-layout__resource{margin:0;box-shadow:none}

.image-layout--resource-card .image-layout__resource,.image-layout--resource-card .image-layout__container,.image-layout--resource-card .image-layout__pattern{border-radius:.3325rem}

[dir="ltr"] .image-layout--resource-card .image-layout__endorsed{left:.5rem}

[dir="rtl"] .image-layout--resource-card .image-layout__endorsed{right:.5rem}

.image-layout--resource-card.image-layout--centered .image-layout__resource--padded{width:100%;border-radius:0}

.image-layout--content-card{height:10.6666666667rem;width:19rem}

.image-layout--content-card .image-layout__wrapper{height:10.6666666667rem}

.image-layout--content-card .image-layout__play{padding:1.3333333333rem}

.image-layout--content-card .image-layout__container,.image-layout--content-card .image-layout__pattern,.image-layout--content-card .image-layout__resource{border-radius:.5rem}

.image-layout--content-card-lg{width:100%;height:29rem}

.image-layout--content-card-lg .image-layout__wrapper{height:29rem}

.image-layout--details-modal{height:12.75rem}

.image-layout--details-modal .image-layout__container,.image-layout--details-modal .image-layout__pattern,.image-layout--details-modal .image-layout__resource{border-radius:.5rem}

.image-layout--details-modal .image-layout__wrapper{height:12.75rem}

.image-layout--list{height:2.25rem;width:4rem}

.image-layout--list .image-layout__wrapper{height:2.25rem}

.image-layout--list.image-layout--centered .image-layout__resource{border-radius:.25rem;box-shadow:none;margin:0}

.image-layout--list .image-layout__container,.image-layout--list .image-layout__resource,.image-layout--list .image-layout__logo,.image-layout--list .image-layout__pattern{border-radius:.5rem}

.image-layout--list .image-layout__logo{padding:0;max-width:90%}

.image-layout--list .image-layout__pattern{background-size:4.1666666667rem}

.markdown{font-size:1.3333333333rem}

.markdown ul{list-style-type:disc;list-style-position:outside}

.markdown ol{list-style-type:decimal;list-style-position:outside}

.markdown a{color:#0062e3;word-wrap:break-word;overflow-wrap:break-word;word-break:break-word}

.markdown a:hover,.markdown a:focus{text-decoration:underline}

.markdown ul,.markdown ol{margin-bottom:1rem}

.markdown li{line-height:1.5}

[dir="ltr"] .markdown li{margin-left:3rem}

[dir="rtl"] .markdown li{margin-right:3rem}

.markdown blockquote{font-style:italic;font-size:1.6666666667rem;margin-bottom:1.5em;position:relative}

.markdown blockquote:before{content:"";background-color:#0062e3;border-radius:3px;height:100%;position:absolute;width:5px}

[dir="ltr"] .markdown blockquote:before{left:-2rem}

[dir="rtl"] .markdown blockquote:before{right:-2rem}

.markdown img{max-width:100%}

.markdown--small{font-size:1rem}

.markdown--small blockquote{font-size:1.5rem}

.markdown *:empty{padding:0;margin:0}

body.has_native-app-invite-banner-top{margin-top:5rem}

body.has_native-app-invite-banner-top .app-header{top:5rem !important}

.native-invite--banner{font-family:sans-serif;height:5rem}

.native-invite--banner .native-invite__wrap{background:#f2f2f2;border:solid 1px #e7e7e7;border-left:none;border-right:none;height:5rem;position:absolute;width:100%}

.native-invite--banner .native-invite__close{background:rgba(0,0,0,0);border:none;color:#bbb;font-size:.8333333333rem;line-height:5rem;padding:0 .5rem;position:absolute;top:0}

[dir="ltr"] .native-invite--banner .native-invite__close{left:0}

[dir="rtl"] .native-invite--banner .native-invite__close{right:0}

.native-invite--banner .native-invite__close:hover,.native-invite--banner .native-invite__close:focus{color:#999}

.native-invite--banner .native-invite__logo{background:#fff url("/content/img/logo_glasses.png") center no-repeat;background-size:75%;border-radius:.8333333333rem;box-shadow:0 0 .25rem rgba(0,0,0,.2);height:3.6666666667rem;position:absolute;top:.5833333333rem;vertical-align:middle;width:3.6666666667rem}

[dir="ltr"] .native-invite--banner .native-invite__logo{left:2rem}

[dir="rtl"] .native-invite--banner .native-invite__logo{right:2rem}

.native-invite--banner .native-invite__title{color:#222;display:inline-block;font-size:1.25rem;line-height:2rem}

.native-invite--banner .native-invite__description{color:#777;display:inline-block;font-size:1rem;line-height:1.25rem}

.native-invite--banner .native-invite__info{display:block;max-width:13.5rem;position:absolute;top:.5833333333rem}

[dir="ltr"] .native-invite--banner .native-invite__info{left:6.5rem;margin-right:7.5rem;padding-left:.25rem}

[dir="rtl"] .native-invite--banner .native-invite__info{right:6.5rem;margin-left:7.5rem;padding-right:.25rem}

.native-invite--banner .native-invite__link{color:#39d;background:rgba(0,0,0,0);border:none;font-size:1.3333333333rem;height:100%;padding:0 1.5rem;position:absolute;top:0}

[dir="ltr"] .native-invite--banner .native-invite__link{right:0}

[dir="rtl"] .native-invite--banner .native-invite__link{left:0}

.native-invite--banner .native-invite__link--button{border:solid 1px #39d;border-radius:.5rem;font-size:1.0833333333rem;line-height:1.25rem;height:initial;padding:.5rem 1.25rem;top:1.2083333333rem}

[dir="ltr"] .native-invite--banner .native-invite__link--button{right:1rem}

[dir="rtl"] .native-invite--banner .native-invite__link--button{left:1rem}

.native-invite{position:fixed;width:100%;z-index:1101}

[dir="ltr"] .native-invite{left:0}

[dir="rtl"] .native-invite{right:0}

.native-invite--top{bottom:initial;top:0}

.dg-sticky--toggle-title .dg-sticky__title{opacity:0}

.dg-sticky--toggle-title.is_fixed .dg-sticky__title{opacity:1}

.toast{position:relative;display:inline-block;min-width:40%;box-shadow:0 1px 3px rgba(0,0,0,.15);border-radius:6px;position:relative;background-color:rgba(15,31,44,.03);margin-bottom:1rem}

[dir="ltr"] .toast{text-align:left}

[dir="rtl"] .toast{text-align:right}

.toast__wrapper{min-height:4rem;height:3rem}

[dir="ltr"] .toast__wrapper--with-button{padding-right:3rem}

[dir="rtl"] .toast__wrapper--with-button{padding-left:3rem}

.toast__close-button{position:absolute;top:0;height:4rem;font-size:.6666666667rem;color:rgba(15,31,44,.61);padding:1.5rem}

[dir="ltr"] .toast__close-button{right:0}

[dir="rtl"] .toast__close-button{left:0}

.toast--bottom{position:fixed;bottom:0;right:3rem;left:3rem;z-index:1101;width:calc(100% - 6rem);text-align:center}

.toast--bottom .dg-animate{position:relative}

.toast--bottom.toast--beneath-modal{z-index:4}

.toast.toast--bottom-right{position:absolute;bottom:0;min-width:auto;background-color:#fff}

[dir="ltr"] .toast.toast--bottom-right{right:.5rem}

[dir="rtl"] .toast.toast--bottom-right{left:.5rem}

.toast.toast--bottom-right .toast__wrapper{min-height:2.5rem}

.toast.toast--bottom-right .toast__message{color:rgba(15,31,44,.61)}

.toast__icon{font-size:1.3333333333rem;line-height:1.3333333333rem}

.toast__icon:before{font-family:"degreedicons";font-weight:normal;-webkit-font-smoothing:antialiased}

.toast__icon--info{color:#0062e3}

.toast__icon--info:before{content:"d"}

.toast__icon--success{color:#00a866}

.toast__icon--success:before{content:"X"}

.toast__icon--error{color:#de0546}

.toast__icon--error:before{content:"K"}

.toast__icon--warn,.toast__icon--warning{color:#916600}

.toast__icon--warn:before,.toast__icon--warning:before{content:"A"}

.sort-list{min-height:30rem}

.sort-list__item{width:100%}

.sort-list__handle{margin-top:-2px}

.sort-list__title{line-height:4.5rem;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}

.sort-list__ellipsis-wrapper{min-width:0}

@media(max-width: 768px){
.sort-list{min-height:auto}}

.tiled-thumbs{width:6.6666666667rem;height:6.6666666667rem;font-size:0;overflow:hidden;border-radius:.3333333333rem}

.tiled-thumbs__thumb{background-color:rgba(15,31,44,.03);background-position:center;background-repeat:repeat-x;background-size:contain;display:inline-block;margin-bottom:.1666666667rem}

[dir="ltr"] .tiled-thumbs__thumb{margin-right:.1666666667rem}

[dir="rtl"] .tiled-thumbs__thumb{margin-left:.1666666667rem}

[dir="ltr"] .tiled-thumbs__thumb:nth-last-child(4)~.tiled-thumbs__thumb:nth-child(2){margin-right:0}

[dir="rtl"] .tiled-thumbs__thumb:nth-last-child(4)~.tiled-thumbs__thumb:nth-child(2){margin-left:0}

[dir="ltr"] .tiled-thumbs__thumb:nth-last-child(3):first-child{margin-right:0}

[dir="rtl"] .tiled-thumbs__thumb:nth-last-child(3):first-child{margin-left:0}

[dir="ltr"] .tiled-thumbs__thumb:nth-last-child(2):first-child{margin-right:0}

[dir="rtl"] .tiled-thumbs__thumb:nth-last-child(2):first-child{margin-left:0}

[dir="ltr"] .tiled-thumbs__thumb:last-child{margin-right:0}

[dir="rtl"] .tiled-thumbs__thumb:last-child{margin-left:0}

.tiled-thumbs__empty{width:100%;height:100%;background-color:rgba(15,31,44,.08)}

.embedded-iframe{border:0;width:100%;height:542px}

.combobox{position:relative;min-width:16.6666666667rem;width:16.6666666667rem}

.combobox__btn{padding:0 calc(1rem - 1px);line-height:calc(3rem - 2px)}

.combobox__btn.btn-dropdown{overflow:hidden;text-overflow:ellipsis}

[dir="ltr"] .combobox__btn.btn-dropdown{padding:0 3rem 0 1rem}

[dir="rtl"] .combobox__btn.btn-dropdown{padding:0 1rem 0 3rem}

.search-field .combobox__btn.btn-dropdown{line-height:3rem}

[dir="ltr"] .search-field .combobox__btn.btn-dropdown{padding-right:2.75rem;margin-right:.75rem}

[dir="rtl"] .search-field .combobox__btn.btn-dropdown{padding-left:2.75rem;margin-left:.75rem}

.combobox__menu{width:100%;max-height:18.5rem;overflow-y:auto}

.combobox__option{min-height:2em;user-select:none;cursor:pointer}

.combobox__option--disabled:hover,.combobox__option--disabled-focus{color:rgba(15,31,44,.61);background-color:rgba(0,0,0,0)}

.combobox__option--add .combobox__option-label{color:#0062e3}

.combobox__option--add .combobox__option-label:before{content:"+ "}

.combobox__group-label{min-height:2em;padding:.5rem 1rem;font-size:1rem;font-weight:600;line-height:1.5em;word-wrap:break-word;overflow-wrap:break-word;word-break:break-word}

.combobox__divider{border-bottom:1px solid rgba(15,31,44,.18);padding-top:.5rem;margin-bottom:.5rem}

dg-select.full-width .combobox,dg-combobox.full-width .combobox,dgx-select.full-width .combobox,dgx-combobox.full-width .combobox{min-width:auto;width:100%}

@media(max-width: 768px){
.m-full-width .combobox{width:100%}}

.stacked-cards{position:relative;z-index:1;margin-bottom:.5rem}

.stacked-cards:after{content:" ";display:block;position:absolute;height:1rem;bottom:-0.5rem;width:calc(100% - 1rem);z-index:-1}

[dir="ltr"] .stacked-cards:after{left:.5rem}

[dir="rtl"] .stacked-cards:after{right:.5rem}

.stacked-cards--double{margin-bottom:1rem}

.stacked-cards--double:before{content:" ";display:block;position:absolute;height:1.5rem;bottom:-1rem;width:calc(100% - 2rem);z-index:-1}

[dir="ltr"] .stacked-cards--double:before{left:1rem}

[dir="rtl"] .stacked-cards--double:before{right:1rem}

.markdown-editor__toolbar{border-top-left-radius:.25rem;border-top-right-radius:.25rem;border:1px solid rgba(15,31,44,.18);border-bottom:none;padding:.5rem;background-color:rgba(15,31,44,.03)}

.markdown-editor__toolbar-group{display:inline-block;padding:0rem 1rem}

[dir="ltr"] .markdown-editor__toolbar-group{border-left:1px solid rgba(15,31,44,.18)}

[dir="rtl"] .markdown-editor__toolbar-group{border-right:1px solid rgba(15,31,44,.18)}

[dir="ltr"] .markdown-editor__toolbar-group:first-child{border-left:none;padding-left:0}

[dir="rtl"] .markdown-editor__toolbar-group:first-child{border-right:none;padding-right:0}

.markdown-editor__button{padding:.5rem 1rem;font-size:0;color:rgba(15,31,44,.61)}

.markdown-editor__button:hover,.markdown-editor__button:focus{color:#0f1f2c}

.markdown-editor__text{background-color:#fff;border:1px solid rgba(15,31,44,.18);border-top-right-radius:0;border-top-left-radius:0;padding:calc(1rem + 1px)}

.markdown-editor__text:focus{border:1px solid rgba(15,31,44,.18)}

.markdown-editor__dropzone--toward .markdown-editor__text{border:2px dashed rgba(15,31,44,.45);padding:1rem}

.markdown-editor__dropzone--over .markdown-editor__text{border:2px dashed #0046b0;background-color:#cce0f9;padding:1rem}

.user-tag{display:inline-flex;border-radius:1rem;border:none;line-height:1.5rem}

[dir="ltr"] .user-tag{padding:.25rem .5rem .25rem 1rem !important}

[dir="rtl"] .user-tag{padding:.25rem 1rem .25rem .5rem !important}

.user-tag__picture{display:inline-block}

[dir="ltr"] .user-tag__picture{margin:-0.25rem .5rem -0.25rem -1rem}

[dir="rtl"] .user-tag__picture{margin:-0.25rem -1rem -0.25rem .5rem}

.user-tag__picture .profile-pic{border-radius:1rem}

.user-tag:hover .user-tag__picture,.user-tag:focus .user-tag__picture,.user-tag:active .user-tag__picture{opacity:.7}

.suggested-users-typeahead{margin:1rem -2px 0 !important;width:calc(100% + 4px) !important;border-color:rgba(15,31,44,.18);border-radius:4px;box-shadow:none}

[dir="ltr"] .suggested-users-typeahead{left:0px !important}

[dir="rtl"] .suggested-users-typeahead{right:0px !important}

.suggested-users-typeahead .typeahead-list{padding:0;overflow-y:visible !important}

.suggested-users-typeahead .typeahead-list>li{border-bottom:1px solid rgba(15,31,44,.18);cursor:pointer}

.suggested-users-typeahead .typeahead-list>li:last-child{border-bottom:none}

.suggested-users-typeahead__scrollable{box-shadow:none;max-height:33vh;overflow-y:auto}

[dir="ltr"] .suggested-users-typeahead__scrollable{left:0px}

[dir="rtl"] .suggested-users-typeahead__scrollable{right:0px}

.badge.badge-pill{border-radius:1rem;padding:.25rem 1rem;line-height:1.5rem;font-weight:600}

.badge.badge-pill--compact{font-size:10px;padding:.25rem .5rem;height:1.5rem;line-height:normal}

.badge.badge-pill.badge-neutral{background-color:rgba(15,31,44,.08);color:#0f1f2c}

.badge.badge-pill.badge-emphasize{background-color:#cce0f9;color:#0046b0}

.badge.badge-pill.badge-success{background-color:#cceee0;color:#007547}

.badge.badge-pill.badge-attention{background-color:#fcf0cc;color:#916600}

.badge.badge-pill.badge-danger{background-color:#f8cdda;color:#ab0341}

dg-user-info h1{display:inline}

[dir="ltr"] dg-user-info h1{margin-right:1rem}

[dir="rtl"] dg-user-info h1{margin-left:1rem}

@media(max-width: 768px){
dg-user-info h1{display:block}

[dir="ltr"] dg-user-info h1{margin-right:0 !important}

[dir="rtl"] dg-user-info h1{margin-left:0 !important}}

.setting-tile__textcontainer{position:relative}

.setting-tile__textcontainer textarea{border:none !important;overflow:hidden}

[dir="ltr"] .setting-tile__textcontainer textarea{padding-right:3em}

[dir="rtl"] .setting-tile__textcontainer textarea{padding-left:3em}

.setting-tile__description{max-width:75%}

.setting-tile__button{position:absolute;top:23%;color:#0062e3}

[dir="ltr"] .setting-tile__button{right:2%}

[dir="rtl"] .setting-tile__button{left:2%}

.replace-focus-modal-desc b{color:#0f1f2c}

.social-pics{display:inline-block;vertical-align:top}

[dir="ltr"] .social-pics--small{margin-right:.75rem}

[dir="rtl"] .social-pics--small{margin-left:.75rem}

[dir="ltr"] .social-pics--tinyish{margin-right:1.6666666667rem}

[dir="rtl"] .social-pics--tinyish{margin-left:1.6666666667rem}

.social-pic__item--small,.social-pic__image--small{height:3rem;width:3rem}

.social-pic__item--tinyish,.social-pic__image--tinyish{height:2.3rem;width:2.3rem}

.social-pic__item{position:relative;display:inline-block;list-style-type:none;vertical-align:top}

[dir="ltr"] .social-pic__item{margin-right:-0.75rem}

[dir="rtl"] .social-pic__item{margin-left:-0.75rem}

.social-pic__item--smallish:not(.social-pic__item-count){border-radius:100%;border:2px solid #fff;background-color:#fff}

[dir="ltr"] .social-pic__item--smallish:not(.social-pic__item-count){margin-right:-1.5rem}

[dir="rtl"] .social-pic__item--smallish:not(.social-pic__item-count){margin-left:-1.5rem}

.social-pic__image{border-radius:50%}

.social-pic__image ::ng-deep .profile-pic{border:2px solid #fff}

.social-pic__count{border-radius:50%;display:flex;align-items:center;justify-content:center;background-color:#d4d7d9;border:2px solid #fff}

.social-pic__count--small{height:3rem;width:3rem}

.social-pic__count--smallish{height:calc(4rem + 4px);width:calc(4rem + 4px)}

.social-pic__count--tinyish{height:2rem;width:2rem}

.osano-cm-window{font-family:"Inter"}

.osano-cm-window .osano-cm-widget{background-image:url("/content/img/ico/ico_cookie.png");background-size:contain;width:40px;height:40px;bottom:1rem;opacity:.9;transition-property:opacity,visibility,transform}

[dir="ltr"] .osano-cm-window .osano-cm-widget{right:.2rem}

[dir="rtl"] .osano-cm-window .osano-cm-widget{left:.2rem}

@media(-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi),(min-resolution: 2dppx){
.osano-cm-window .osano-cm-widget{background-image:url("/content/img/ico/ico_cookie_2x.png")}}

@media(max-width: 1024px){
.osano-cm-window .osano-cm-widget{width:30px !important;height:30px !important}}

.osano-cm-window .osano-cm-widget svg{display:none}

.osano-cm-window .osano-cm-widget:focus,.osano-cm-window .osano-cm-widget:hover{opacity:1;transform:none}

.osano-cm-window .osano-cm-widget:active{transform:translateY(1px)}

.osano-cm-window .osano-cm-info-dialog .osano-cm-info p{color:#000 !important}

.osano-cm-window .osano-cm-info-dialog .osano-cm-info p.osano-cm-header{padding:1em .75em}

.osano-cm-window .osano-cm-info-dialog .osano-cm-info .osano-cm-view p{padding:0}

.osano-cm-window .osano-cm-info-dialog .osano-cm-info .osano-cm-view .osano-cm-list .osano-cm-list-item:nth-child(5){display:none}

.osano-cm-window .osano-cm-dialog{background:#e9f7fe !important;border:5px solid var(--blue-800);padding-top:1rem;padding-bottom:0rem}

.osano-cm-window div.osano-cm-dialog__content.osano-cm-content{display:flex;flex-flow:column;flex:4}

@media(min-width: 768px){
.osano-cm-window div.osano-cm-dialog__content.osano-cm-content{margin-bottom:0}}

.osano-cm-window div.osano-cm-dialog__content.osano-cm-content .osano-cm-message{color:#000;font-family:Antonia-Heavy;font-weight:700;font-size:1rem;order:2;max-width:70rem;width:100%}

.osano-cm-window div.osano-cm-dialog__content.osano-cm-content .osano-cm-storage-policy{order:1;margin-bottom:1rem;font-weight:500;font-size:1.125rem;width:max-content}

.osano-cm-window div.osano-cm-dialog__content.osano-cm-content .osano-cm-list{order:3;margin:0;color:#0a1f2d;font-family:Inter;font-weight:500;font-size:18px;line-height:27px;padding-bottom:1rem}

.osano-cm-window div.osano-cm-dialog__buttons.osano-cm-buttons{display:flex;flex-flow:column}

.osano-cm-window div.osano-cm-dialog__buttons.osano-cm-buttons>button{border:none;outline:none;width:100%;margin:0;padding:.75rem}

.osano-cm-window .osano-cm-button--type_accept-all{background:#0060ec;order:1;font-family:Antonia-Heavy}

.osano-cm-window .osano-cm-button--type_accept{background:#0060ec;font-family:Antonia-Heavy}

.osano-cm-window .osano-cm-button--type_save,.osano-cm-window .osano-cm-button--type_save:hover{background:#e9f7fe;font-weight:500;color:var(--blue-800);order:2;font-weight:500;font-family:Inter}

.osano-cm-window .osano-cm-button--type_deny,.osano-cm-window .osano-cm-button--type_deny:hover{background:#e9f7fe;font-weight:500;font-family:Inter;color:#1065e9}

.osano-cm-window .osano-cm-toggle__switch{background-color:#8c8c8c}

.osano-cm-window .osano-cm-content__message{padding-bottom:0}

.osano-icon-offset .osano-cm-window .osano-cm-widget{bottom:5rem !important}

.profiler-results{display:block !important}

.mp-results .mp-result.active .mp-popup{position:fixed}

.mp-results{max-height:75%;overflow-y:auto;overflow-x:hidden;scrollbar-width:thin;-ms-overflow-style:-ms-autohiding-scrollbar}

.mp-results::-webkit-scrollbar{width:5px}

.mp-results::-webkit-scrollbar-thumb{background:#666}

.sortable-placeholder{position:absolute;visibility:hidden;opacity:0}

dgx-data-filters dgx-search-filter.is_disabled .filter-btn{cursor:default;color:rgba(15,31,44,.45) !important;background-color:rgba(15,31,44,.03) !important;border:1px solid rgba(15,31,44,.18) !important;text-shadow:none}

dgx-data-filters dgx-search-filter.is_disabled .filter-btn.btn-primary{background-color:rgba(0,98,227,.5) !important;border:none !important;color:#cce0f9 !important}

dgx-data-filters dgx-search-filter.is_disabled .filter-btn.btn-secondary{background-color:rgba(15,31,44,.03) !important;color:rgba(0,98,227,.5) !important;border:1px solid rgba(0,98,227,.5) !important}

dgx-combobox.full-width .combobox{min-width:auto;width:100%}

div[uib-modal-window].add-edit-target-modal-wrapper-z-index-override{z-index:1051 !important}

.chmln-campaign-5f6e71b4e6bcd600078a04fe.chmln-step-5f6e71b5e6bcd600078a0502 .chmln-step-positioning-container .chmln-step-media{padding-top:20px !important}

aw-wizard{display:flex;justify-content:flex-start}

aw-wizard .wizard-steps{top:0;display:flex}

aw-wizard.horizontal{flex-direction:column}

aw-wizard.horizontal .wizard-steps{width:100%;flex-direction:row}

aw-wizard.vertical{flex-direction:row}

aw-wizard.vertical .wizard-steps{min-width:calc(100% - 280px);width:80%;height:100%;flex-direction:column}

aw-wizard-step,aw-wizard-completion-step{height:auto;width:100%}

aw-wizard-navigation-bar ul.steps-indicator *{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}

aw-wizard-navigation-bar ul.steps-indicator li{position:relative;pointer-events:none}

aw-wizard-navigation-bar ul.steps-indicator li a .label{color:gray;line-height:14px;font-size:14px;text-decoration:none;text-transform:uppercase;font-weight:bold;transition:.25s}

aw-wizard-navigation-bar ul.steps-indicator li.navigable{pointer-events:auto}

aw-wizard-navigation-bar ul.steps-indicator li.navigable a{cursor:pointer}

aw-wizard-navigation-bar ul.steps-indicator li.navigable a:hover .label{color:#4d4d4d}

aw-wizard-navigation-bar.horizontal ul.steps-indicator{display:flex;flex-direction:row;justify-content:center;margin:0;width:100%;list-style:none}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-2:after{left:25%;right:25%}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-2 li{width:50%}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-3:after{left:16.6666666667%;right:16.6666666667%}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-3 li{width:33.3333333333%}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-4:after{left:12.5%;right:12.5%}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-4 li{width:25%}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-5:after{left:10%;right:10%}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-5 li{width:20%}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-6:after{left:8.3333333333%;right:8.3333333333%}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-6 li{width:16.6666666667%}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-7:after{left:7.1428571429%;right:7.1428571429%}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-7 li{width:14.2857142857%}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-8:after{left:6.25%;right:6.25%}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-8 li{width:12.5%}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-9:after{left:5.5555555556%;right:5.5555555556%}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-9 li{width:11.1111111111%}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-10:after{left:5%;right:5%}

aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-10 li{width:10%}

aw-wizard-navigation-bar.horizontal ul.steps-indicator li{margin:0;padding:0;text-align:center}

aw-wizard-navigation-bar.horizontal ul.steps-indicator li a .label{display:inline-block;padding-top:10px;text-align:center}

aw-wizard-navigation-bar.vertical{max-width:280px;width:20%;height:100%;position:sticky;top:0}

aw-wizard-navigation-bar.vertical ul.steps-indicator{display:flex;flex-direction:column;justify-content:center;list-style:none;margin:auto}

aw-wizard-navigation-bar.vertical ul.steps-indicator li:not(:last-child){margin-bottom:0;padding-bottom:10px}

aw-wizard-navigation-bar.vertical ul.steps-indicator li a{display:flex;flex-direction:row;align-items:center}

[dir="ltr"] aw-wizard-navigation-bar.vertical ul.steps-indicator li a .label{margin-left:15px;text-align:left}

[dir="rtl"] aw-wizard-navigation-bar.vertical ul.steps-indicator li a .label{margin-right:15px;text-align:right}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.vertical ul.steps-indicator li a .label{margin-left:0;margin-right:15px;text-align:right}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.vertical ul.steps-indicator li a .label{margin-right:0;margin-left:15px;text-align:left}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator{padding:24px 0 10px 0}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li:not(:last-child):after{background-color:#e6e6e6;content:"";position:absolute;height:1px;width:calc(100% - 14px);top:-7px}

[dir="ltr"] aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li:not(:last-child):after{left:calc(50% + 7px)}

[dir="rtl"] aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li:not(:last-child):after{right:calc(50% + 7px)}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li:not(:last-child):after{left:auto;right:calc(50% + 7px)}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li:not(:last-child):after{right:auto;left:calc(50% + 7px)}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li .step-indicator{top:-14px;position:absolute;width:14px;height:14px;text-align:center;vertical-align:middle;line-height:14px;transition:.25s;border-radius:100%;background-color:#e6e6e6}

[dir="ltr"] aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li .step-indicator{left:calc(50% - 7px)}

[dir="rtl"] aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li .step-indicator{right:calc(50% - 7px)}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li .step-indicator{left:auto;right:calc(50% - 7px)}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li .step-indicator{right:auto;left:calc(50% - 7px)}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.optional .step-indicator{background-color:#38ef38}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.done .step-indicator{background-color:#393}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.current .step-indicator{background-color:gray}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.editing .step-indicator{background-color:red}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.completed .step-indicator{background-color:#393}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.navigable a:hover .step-indicator{position:absolute;width:14px;height:14px;text-align:center;vertical-align:middle;line-height:14px;transition:.25s;border-radius:100%;background-color:#d9d9d9}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.navigable.optional a:hover .step-indicator{background-color:#20ed20}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.navigable.done a:hover .step-indicator{background-color:#2d862d}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.navigable.current a:hover .step-indicator{background-color:#737373}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.navigable.editing a:hover .step-indicator{background-color:#e60000}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.navigable.completed a:hover .step-indicator{background-color:#2d862d}

aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator{padding:60px 0 10px 0}

aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li:not(:last-child):after{background-color:#e6e6e6;content:"";position:absolute;height:1px;width:calc(100% - 50px);top:-25px}

[dir="ltr"] aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li:not(:last-child):after{left:calc(50% + 25px)}

[dir="rtl"] aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li:not(:last-child):after{right:calc(50% + 25px)}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li:not(:last-child):after{left:auto;right:calc(50% + 25px)}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li:not(:last-child):after{right:auto;left:calc(50% + 25px)}

aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li .step-indicator{top:-50px;position:absolute;width:50px;height:50px;text-align:center;vertical-align:middle;line-height:50px;transition:.25s;border-radius:100%;background-color:#e6e6e6}

[dir="ltr"] aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li .step-indicator{left:calc(50% - 25px)}

[dir="rtl"] aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li .step-indicator{right:calc(50% - 25px)}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li .step-indicator{left:auto;right:calc(50% - 25px)}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li .step-indicator{right:auto;left:calc(50% - 25px)}

aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li.optional .step-indicator{background-color:#38ef38}

aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li.done .step-indicator{background-color:#393}

aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li.current .step-indicator{background-color:gray}

aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li.editing .step-indicator{background-color:red}

aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li.completed .step-indicator{background-color:#393}

aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li.navigable a:hover .step-indicator{position:absolute;width:50px;height:50px;text-align:center;vertical-align:middle;line-height:50px;transition:.25s;border-radius:100%;background-color:#d9d9d9}

aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li.navigable.optional a:hover .step-indicator{background-color:#20ed20}

aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li.navigable.done a:hover .step-indicator{background-color:#2d862d}

aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li.navigable.current a:hover .step-indicator{background-color:#737373}

aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li.navigable.editing a:hover .step-indicator{background-color:#e60000}

aw-wizard-navigation-bar.horizontal.large-filled ul.steps-indicator li.navigable.completed a:hover .step-indicator{background-color:#2d862d}

aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator{padding:60px 0 10px 0}

aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li:not(:last-child):after{background-color:#e6e6e6;content:"";position:absolute;height:1px;width:calc(100% - 50px);top:-25px}

[dir="ltr"] aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li:not(:last-child):after{left:calc(50% + 25px)}

[dir="rtl"] aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li:not(:last-child):after{right:calc(50% + 25px)}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li:not(:last-child):after{left:auto;right:calc(50% + 25px)}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li:not(:last-child):after{right:auto;left:calc(50% + 25px)}

aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li .step-indicator{top:-50px;position:absolute;width:50px;height:50px;text-align:center;vertical-align:middle;line-height:46px;transition:.25s;border-radius:100%;border:2px solid #e6e6e6}

[dir="ltr"] aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li .step-indicator{left:calc(50% - 25px)}

[dir="rtl"] aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li .step-indicator{right:calc(50% - 25px)}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li .step-indicator{left:auto;right:calc(50% - 25px)}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li .step-indicator{right:auto;left:calc(50% - 25px)}

aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li.optional .step-indicator{border:2px solid #38ef38}

aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li.done .step-indicator{border:2px solid #393}

aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li.current .step-indicator{border:2px solid gray}

aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li.editing .step-indicator{border:2px solid red}

aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li.completed .step-indicator{border:2px solid #393}

aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li.navigable a:hover .step-indicator{position:absolute;width:50px;height:50px;text-align:center;vertical-align:middle;line-height:46px;transition:.25s;border-radius:100%;border:2px solid #cdcdcd}

aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li.navigable.optional a:hover .step-indicator{border:2px solid #12e212}

aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li.navigable.done a:hover .step-indicator{border:2px solid #267326}

aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li.navigable.current a:hover .step-indicator{border:2px solid #676767}

aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li.navigable.editing a:hover .step-indicator{border:2px solid #c00}

aw-wizard-navigation-bar.horizontal.large-empty ul.steps-indicator li.navigable.completed a:hover .step-indicator{border:2px solid #267326}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator{padding:60px 0 10px 0}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li:not(:last-child):after{background-color:#e6e6e6;content:"";position:absolute;height:1px;width:calc(100% - 50px);top:-25px}

[dir="ltr"] aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li:not(:last-child):after{left:calc(50% + 25px)}

[dir="rtl"] aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li:not(:last-child):after{right:calc(50% + 25px)}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li:not(:last-child):after{left:auto;right:calc(50% + 25px)}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li:not(:last-child):after{right:auto;left:calc(50% + 25px)}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li .step-indicator{top:-50px;position:absolute;width:50px;height:50px;text-align:center;vertical-align:middle;line-height:50px;transition:.25s;border-radius:100%;background-color:#e6e6e6;color:#000}

[dir="ltr"] aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li .step-indicator{left:calc(50% - 25px)}

[dir="rtl"] aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li .step-indicator{right:calc(50% - 25px)}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li .step-indicator{left:auto;right:calc(50% - 25px)}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li .step-indicator{right:auto;left:calc(50% - 25px)}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.optional .step-indicator{background-color:#38ef38;color:#000}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.done .step-indicator{background-color:#393;color:#000}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.current .step-indicator{background-color:gray;color:#000}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.editing .step-indicator{background-color:red;color:#000}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.completed .step-indicator{background-color:#393;color:#000}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.navigable a:hover .step-indicator{position:absolute;width:50px;height:50px;text-align:center;vertical-align:middle;line-height:50px;transition:.25s;border-radius:100%;background-color:#d9d9d9;color:#000}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.navigable.optional a:hover .step-indicator{background-color:#20ed20;color:#000}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.navigable.done a:hover .step-indicator{background-color:#2d862d;color:#000}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.navigable.current a:hover .step-indicator{background-color:#737373;color:#000}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.navigable.editing a:hover .step-indicator{background-color:#e60000;color:#000}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.navigable.completed a:hover .step-indicator{background-color:#2d862d;color:#000}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator{padding:60px 0 10px 0}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li:not(:last-child):after{background-color:#e6e6e6;content:"";position:absolute;height:1px;width:calc(100% - 50px);top:-25px}

[dir="ltr"] aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li:not(:last-child):after{left:calc(50% + 25px)}

[dir="rtl"] aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li:not(:last-child):after{right:calc(50% + 25px)}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li:not(:last-child):after{left:auto;right:calc(50% + 25px)}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li:not(:last-child):after{right:auto;left:calc(50% + 25px)}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li .step-indicator{top:-50px;position:absolute;width:50px;height:50px;text-align:center;vertical-align:middle;line-height:46px;transition:.25s;border-radius:100%;border:2px solid #e6e6e6;color:#e6e6e6}

[dir="ltr"] aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li .step-indicator{left:calc(50% - 25px)}

[dir="rtl"] aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li .step-indicator{right:calc(50% - 25px)}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li .step-indicator{left:auto;right:calc(50% - 25px)}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li .step-indicator{right:auto;left:calc(50% - 25px)}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.optional .step-indicator{border:2px solid #38ef38;color:#38ef38}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.done .step-indicator{border:2px solid #393;color:#393}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.current .step-indicator{border:2px solid gray;color:gray}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.editing .step-indicator{border:2px solid red;color:red}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.completed .step-indicator{border:2px solid #393;color:#393}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.navigable a:hover .step-indicator{position:absolute;width:50px;height:50px;text-align:center;vertical-align:middle;line-height:46px;transition:.25s;border-radius:100%;border:2px solid #cdcdcd;color:#cdcdcd}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.navigable.optional a:hover .step-indicator{border:2px solid #12e212;color:#12e212}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.navigable.done a:hover .step-indicator{border:2px solid #267326;color:#267326}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.navigable.current a:hover .step-indicator{border:2px solid #676767;color:#676767}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.navigable.editing a:hover .step-indicator{border:2px solid #c00;color:#c00}

aw-wizard-navigation-bar.horizontal.large-empty-symbols ul.steps-indicator li.navigable.completed a:hover .step-indicator{border:2px solid #267326;color:#267326}

[dir="ltr"] aw-wizard-navigation-bar.vertical.small ul.steps-indicator{padding:5px 5px 5px 19px}

[dir="rtl"] aw-wizard-navigation-bar.vertical.small ul.steps-indicator{padding:5px 19px 5px 5px}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.vertical.small ul.steps-indicator{padding:5px 19px 5px 5px}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.vertical.small ul.steps-indicator{padding:5px 5px 5px 19px}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li:not(:last-child):after{background-color:#e6e6e6;content:"";position:absolute;top:14px;height:calc(100% - 14px);width:1px}

[dir="ltr"] aw-wizard-navigation-bar.vertical.small ul.steps-indicator li:not(:last-child):after{left:-7px}

[dir="rtl"] aw-wizard-navigation-bar.vertical.small ul.steps-indicator li:not(:last-child):after{right:-7px}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.vertical.small ul.steps-indicator li:not(:last-child):after{left:auto;right:-7px}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.vertical.small ul.steps-indicator li:not(:last-child):after{right:auto;left:-7px}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li a{min-height:14px}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li .step-indicator{top:0;position:absolute;width:14px;height:14px;text-align:center;vertical-align:middle;line-height:14px;transition:.25s;border-radius:100%;background-color:#e6e6e6}

[dir="ltr"] aw-wizard-navigation-bar.vertical.small ul.steps-indicator li .step-indicator{left:-14px}

[dir="rtl"] aw-wizard-navigation-bar.vertical.small ul.steps-indicator li .step-indicator{right:-14px}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.vertical.small ul.steps-indicator li .step-indicator{left:auto;right:-14px}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.vertical.small ul.steps-indicator li .step-indicator{right:auto;left:-14px}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.optional .step-indicator{background-color:#38ef38}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.done .step-indicator{background-color:#393}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.current .step-indicator{background-color:gray}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.editing .step-indicator{background-color:red}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.completed .step-indicator{background-color:#393}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.navigable a:hover .step-indicator{position:absolute;width:14px;height:14px;text-align:center;vertical-align:middle;line-height:14px;transition:.25s;border-radius:100%;background-color:#d9d9d9}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.navigable.optional a:hover .step-indicator{background-color:#20ed20}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.navigable.done a:hover .step-indicator{background-color:#2d862d}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.navigable.current a:hover .step-indicator{background-color:#737373}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.navigable.editing a:hover .step-indicator{background-color:#e60000}

aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.navigable.completed a:hover .step-indicator{background-color:#2d862d}

[dir="ltr"] aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator{padding:5px 5px 5px 55px}

[dir="rtl"] aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator{padding:5px 55px 5px 5px}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator{padding:5px 55px 5px 5px}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator{padding:5px 5px 5px 55px}

aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li:not(:last-child):after{background-color:#e6e6e6;content:"";position:absolute;top:50px;height:calc(100% - 50px);width:1px}

[dir="ltr"] aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li:not(:last-child):after{left:-25px}

[dir="rtl"] aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li:not(:last-child):after{right:-25px}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li:not(:last-child):after{left:auto;right:-25px}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li:not(:last-child):after{right:auto;left:-25px}

aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li a{min-height:50px}

aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li .step-indicator{top:0;position:absolute;width:50px;height:50px;text-align:center;vertical-align:middle;line-height:50px;transition:.25s;border-radius:100%;background-color:#e6e6e6}

[dir="ltr"] aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li .step-indicator{left:-50px}

[dir="rtl"] aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li .step-indicator{right:-50px}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li .step-indicator{left:auto;right:-50px}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li .step-indicator{right:auto;left:-50px}

aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li.optional .step-indicator{background-color:#38ef38}

aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li.done .step-indicator{background-color:#393}

aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li.current .step-indicator{background-color:gray}

aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li.editing .step-indicator{background-color:red}

aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li.completed .step-indicator{background-color:#393}

aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li.navigable a:hover .step-indicator{position:absolute;width:50px;height:50px;text-align:center;vertical-align:middle;line-height:50px;transition:.25s;border-radius:100%;background-color:#d9d9d9}

aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li.navigable.optional a:hover .step-indicator{background-color:#20ed20}

aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li.navigable.done a:hover .step-indicator{background-color:#2d862d}

aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li.navigable.current a:hover .step-indicator{background-color:#737373}

aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li.navigable.editing a:hover .step-indicator{background-color:#e60000}

aw-wizard-navigation-bar.vertical.large-filled ul.steps-indicator li.navigable.completed a:hover .step-indicator{background-color:#2d862d}

[dir="ltr"] aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator{padding:5px 5px 5px 55px}

[dir="rtl"] aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator{padding:5px 55px 5px 5px}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator{padding:5px 55px 5px 5px}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator{padding:5px 5px 5px 55px}

aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li:not(:last-child):after{background-color:#e6e6e6;content:"";position:absolute;top:50px;height:calc(100% - 50px);width:1px}

[dir="ltr"] aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li:not(:last-child):after{left:-25px}

[dir="rtl"] aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li:not(:last-child):after{right:-25px}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li:not(:last-child):after{left:auto;right:-25px}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li:not(:last-child):after{right:auto;left:-25px}

aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li a{min-height:50px}

aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li .step-indicator{top:0;position:absolute;width:50px;height:50px;text-align:center;vertical-align:middle;line-height:46px;transition:.25s;border-radius:100%;border:2px solid #e6e6e6}

[dir="ltr"] aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li .step-indicator{left:-50px}

[dir="rtl"] aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li .step-indicator{right:-50px}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li .step-indicator{left:auto;right:-50px}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li .step-indicator{right:auto;left:-50px}

aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li.optional .step-indicator{border:2px solid #38ef38}

aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li.done .step-indicator{border:2px solid #393}

aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li.current .step-indicator{border:2px solid gray}

aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li.editing .step-indicator{border:2px solid red}

aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li.completed .step-indicator{border:2px solid #393}

aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li.navigable a:hover .step-indicator{position:absolute;width:50px;height:50px;text-align:center;vertical-align:middle;line-height:46px;transition:.25s;border-radius:100%;border:2px solid #cdcdcd}

aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li.navigable.optional a:hover .step-indicator{border:2px solid #12e212}

aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li.navigable.done a:hover .step-indicator{border:2px solid #267326}

aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li.navigable.current a:hover .step-indicator{border:2px solid #676767}

aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li.navigable.editing a:hover .step-indicator{border:2px solid #c00}

aw-wizard-navigation-bar.vertical.large-empty ul.steps-indicator li.navigable.completed a:hover .step-indicator{border:2px solid #267326}

[dir="ltr"] aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator{padding:5px 5px 5px 55px}

[dir="rtl"] aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator{padding:5px 55px 5px 5px}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator{padding:5px 55px 5px 5px}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator{padding:5px 5px 5px 55px}

aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li:not(:last-child):after{background-color:#e6e6e6;content:"";position:absolute;top:50px;height:calc(100% - 50px);width:1px}

[dir="ltr"] aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li:not(:last-child):after{left:-25px}

[dir="rtl"] aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li:not(:last-child):after{right:-25px}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li:not(:last-child):after{left:auto;right:-25px}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li:not(:last-child):after{right:auto;left:-25px}

aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li a{min-height:50px}

aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li .step-indicator{top:0;position:absolute;width:50px;height:50px;text-align:center;vertical-align:middle;line-height:50px;transition:.25s;border-radius:100%;background-color:#e6e6e6;color:#000}

[dir="ltr"] aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li .step-indicator{left:-50px}

[dir="rtl"] aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li .step-indicator{right:-50px}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li .step-indicator{left:auto;right:-50px}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li .step-indicator{right:auto;left:-50px}

aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li.optional .step-indicator{background-color:#38ef38;color:#000}

aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li.done .step-indicator{background-color:#393;color:#000}

aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li.current .step-indicator{background-color:gray;color:#000}

aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li.editing .step-indicator{background-color:red;color:#000}

aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li.completed .step-indicator{background-color:#393;color:#000}

aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li.navigable a:hover .step-indicator{position:absolute;width:50px;height:50px;text-align:center;vertical-align:middle;line-height:50px;transition:.25s;border-radius:100%;background-color:#d9d9d9;color:#000}

aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li.navigable.optional a:hover .step-indicator{background-color:#20ed20;color:#000}

aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li.navigable.done a:hover .step-indicator{background-color:#2d862d;color:#000}

aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li.navigable.current a:hover .step-indicator{background-color:#737373;color:#000}

aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li.navigable.editing a:hover .step-indicator{background-color:#e60000;color:#000}

aw-wizard-navigation-bar.vertical.large-filled-symbols ul.steps-indicator li.navigable.completed a:hover .step-indicator{background-color:#2d862d;color:#000}

[dir="ltr"] aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator{padding:5px 5px 5px 55px}

[dir="rtl"] aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator{padding:5px 55px 5px 5px}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator{padding:5px 55px 5px 5px}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator{padding:5px 5px 5px 55px}

aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li:not(:last-child):after{background-color:#e6e6e6;content:"";position:absolute;top:50px;height:calc(100% - 50px);width:1px}

[dir="ltr"] aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li:not(:last-child):after{left:-25px}

[dir="rtl"] aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li:not(:last-child):after{right:-25px}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li:not(:last-child):after{left:auto;right:-25px}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li:not(:last-child):after{right:auto;left:-25px}

aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li a{min-height:50px}

aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li .step-indicator{top:0;position:absolute;width:50px;height:50px;text-align:center;vertical-align:middle;line-height:46px;transition:.25s;border-radius:100%;border:2px solid #e6e6e6;color:#e6e6e6}

[dir="ltr"] aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li .step-indicator{left:-50px}

[dir="rtl"] aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li .step-indicator{right:-50px}

[dir="ltr"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li .step-indicator{left:auto;right:-50px}

[dir="rtl"] [dir=rtl] aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li .step-indicator{right:auto;left:-50px}

aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li.optional .step-indicator{border:2px solid #38ef38;color:#38ef38}

aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li.done .step-indicator{border:2px solid #393;color:#393}

aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li.current .step-indicator{border:2px solid gray;color:gray}

aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li.editing .step-indicator{border:2px solid red;color:red}

aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li.completed .step-indicator{border:2px solid #393;color:#393}

aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li.navigable a:hover .step-indicator{position:absolute;width:50px;height:50px;text-align:center;vertical-align:middle;line-height:46px;transition:.25s;border-radius:100%;border:2px solid #cdcdcd;color:#cdcdcd}

aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li.navigable.optional a:hover .step-indicator{border:2px solid #12e212;color:#12e212}

aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li.navigable.done a:hover .step-indicator{border:2px solid #267326;color:#267326}

aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li.navigable.current a:hover .step-indicator{border:2px solid #676767;color:#676767}

aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li.navigable.editing a:hover .step-indicator{border:2px solid #c00;color:#c00}

aw-wizard-navigation-bar.vertical.large-empty-symbols ul.steps-indicator li.navigable.completed a:hover .step-indicator{border:2px solid #267326;color:#267326}

.textarea--extra-short .textarea,.textarea--extra-short textarea:not([dg-elastic]){min-height:4.5rem}

.modal-open .legal--copy{max-height:300px;display:block}

.modal-open .legal--copy p{font-size:1rem;line-height:1.5rem;font-weight:400;color:rgba(15,31,44,.61)}

.modal-open .legal--copy a{text-decoration:underline;color:#0062e3}

.form-wrap dgx-input-session-form-partial .ng-invalid label{color:#0f1f2c}

.use-updated-content-card .tile--content{min-height:auto}

#toast-container{z-index:1000}

.learner-home-focus-skill{border-radius:4px}

[dir="ltr"] .learner-home-focus-skill{text-align:left}

[dir="rtl"] .learner-home-focus-skill{text-align:right}

aw-wizard{margin:0 -1rem -2rem}

aw-wizard-navigation-bar{border-bottom:1px solid rgba(15,31,44,.18);padding:1.25rem 0}

aw-wizard-navigation-bar .steps-indicator{height:2rem;padding:0 3.75rem !important}

aw-wizard-navigation-bar .steps-indicator li{width:auto !important;flex-grow:1}

aw-wizard-navigation-bar .steps-indicator li:after{display:none !important}

aw-wizard-navigation-bar .steps-indicator li:last-child{flex-grow:0}

aw-wizard-navigation-bar .steps-indicator li:last-child .step-indicator{display:none !important}

aw-wizard-navigation-bar .steps-indicator a{display:flex;align-items:center}

aw-wizard-navigation-bar .label{color:rgba(15,31,44,.75) !important;display:block;text-transform:none !important;font-weight:normal !important;background-color:rgba(0,0,0,0);padding:0 !important}

aw-wizard-navigation-bar .step-indicator{position:static !important;top:0 !important;flex-grow:1;background:rgba(15,31,44,.18) !important;height:1px !important;width:auto !important;margin:0 2.0833333333rem !important;border-radius:0 !important}

[dir="ltr"] aw-wizard-navigation-bar .step-indicator{left:0 !important}

[dir="rtl"] aw-wizard-navigation-bar .step-indicator{right:0 !important}

ngb-datepicker.show{opacity:1;visibility:visible}

ngb-datepicker.ngb-datepicker--borderless{border:none}

ngb-datepicker.dropdown-menu{width:25rem;border:.0833333333rem solid rgba(15,31,44,.18) !important;border-radius:.5rem !important;box-shadow:0 .0833333333rem .1666666667rem rgba(15,31,44,.18) !important;transition:background-color .25s ease-in-out !important;will-change:unset !important;padding:1.5rem !important;padding-top:1.2rem !important}

[dir="ltr"] ngb-datepicker.dropdown-menu{transform:translate(-0.1666666667rem, 3rem) !important}

[dir="rtl"] ngb-datepicker.dropdown-menu{transform:translate(0.1666666667rem, 3rem) !important}

ngb-datepicker .ngb-dp-header{padding-top:0;background-color:#fff}

ngb-datepicker ngb-datepicker-month .ngb-dp-today{border:1px solid #0062e3 !important}

ngb-datepicker ngb-datepicker-month .ngb-dp-today .bg-primary{min-width:2rem}

ngb-datepicker ngb-datepicker-month .ngb-dp-weekdays{border-bottom:none;background-color:#fff}

ngb-datepicker ngb-datepicker-month .ngb-dp-weekday{margin:.5rem;font-size:0;font-style:inherit;color:#0f1f2c}

ngb-datepicker ngb-datepicker-month .ngb-dp-weekday::first-letter{font-size:1.1666666667rem}

ngb-datepicker ngb-datepicker-month .ngb-dp-day{margin:.5rem;border-radius:100%;color:#0062e3}

ngb-datepicker ngb-datepicker-month .ngb-dp-day:hover{background-color:#0062e3;color:#fff}

ngb-datepicker ngb-datepicker-month .ngb-dp-day [ngbDatepickerDayView]{height:auto;width:auto;background:none;line-height:3rem}

ngb-datepicker ngb-datepicker-month .ngb-dp-day .btn-light.bg-primary.text-white{color:#fff;background-color:#0062e3;border-radius:100%}

ngb-datepicker ngb-datepicker-navigation{height:3.3333333333rem;justify-content:space-between;align-items:baseline}

ngb-datepicker ngb-datepicker-navigation ngb-datepicker-navigation-select{order:1;flex:none !important;-ms-flex:1 1 9rem !important}

ngb-datepicker ngb-datepicker-navigation ngb-datepicker-navigation-select select:focus::-ms-value{color:rgba(15,31,44,.75)}

ngb-datepicker ngb-datepicker-navigation ngb-datepicker-navigation-select .custom-select{border:1px solid rgba(15,31,44,.18);border-radius:.25rem;font-size:1.25rem;color:rgba(15,31,44,.75);max-width:5.8333333333rem;padding-bottom:.0833333333rem;width:5.6666666667rem;background:url("data:image/svg+xml,%3C%3Fxml version='1.0'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' width='512px' height='512px' viewBox='0 0 451.847 451.847' style='enable-background:new 0 0 451.847 451.847;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751 c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0 c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z' data-original='%23000000' class='active-path' data-old_color='%23000000' fill='%234B5761'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");background-size:1rem;background-repeat:no-repeat;appearance:none;-webkit-appearance:none}

[dir="ltr"] ngb-datepicker ngb-datepicker-navigation ngb-datepicker-navigation-select .custom-select{margin-right:1rem;background-position:calc(100% - 5px) .3333333333rem}

[dir="rtl"] ngb-datepicker ngb-datepicker-navigation ngb-datepicker-navigation-select .custom-select{margin-left:1rem;background-position:calc(100% - (100% - 5px)) .3333333333rem}

ngb-datepicker ngb-datepicker-navigation ngb-datepicker-navigation-select .custom-select::-ms-expand{display:none}

ngb-datepicker ngb-datepicker-navigation .ngb-dp-arrow{order:2;max-width:2.5rem}

ngb-datepicker ngb-datepicker-navigation .ngb-dp-arrow-btn{color:rgba(15,31,44,.75);box-shadow:none !important}

ngb-datepicker ngb-datepicker-navigation .ngb-dp-arrow-btn:hover{box-shadow:none !important}

ngb-datepicker ngb-datepicker-navigation .ngb-dp-arrow.right{order:3}

.ngb-dp-day,.ngb-dp-week-number,.ngb-dp-weekday{width:3rem !important;height:3rem !important}

.ngb-dp-day{margin:0px !important}

.custom-day{border-radius:0px !important;height:3rem;display:flex !important;align-items:center;justify-content:center}

.custom-day--range-end{border-radius:50% !important}

.ngb-dp-weekday{margin:0px !important}

.ngb-dp-day:has(span.custom-day--range-ed){background-color:#e8f0fe}

[dir="ltr"] .ngb-dp-day:has(span.custom-day--range-ed){border-top-left-radius:53px 0px !important;border-bottom-left-radius:1px 20px !important}

[dir="rtl"] .ngb-dp-day:has(span.custom-day--range-ed){border-top-right-radius:53px 0px !important;border-bottom-right-radius:1px 20px !important}

.ngb-dp-day:has(span.custom-day--range-st){background-color:#e8f0fe}

[dir="ltr"] .ngb-dp-day:has(span.custom-day--range-st){border-top-right-radius:53px 0px !important;border-bottom-right-radius:1px 20px !important}

[dir="rtl"] .ngb-dp-day:has(span.custom-day--range-st){border-top-left-radius:53px 0px !important;border-bottom-left-radius:1px 20px !important}

.custom-day.custom-day--range:hover{opacity:1;border-radius:100% !important}

.ngb-dp-day.ng-star-inserted:has(>.custom-day.custom-day--range:hover){background-color:#e8f0fe}

[dir="ltr"] .ngb-dp-day.ng-star-inserted:has(>.custom-day.custom-day--range:hover){border-top-right-radius:53px 0px !important;border-bottom-right-radius:1px 20px !important;border-top-left-radius:0px 53px !important;border-bottom-left-radius:20px 1px !important}

[dir="rtl"] .ngb-dp-day.ng-star-inserted:has(>.custom-day.custom-day--range:hover){border-top-left-radius:53px 0px !important;border-bottom-left-radius:1px 20px !important;border-top-right-radius:0px 53px !important;border-bottom-right-radius:20px 1px !important}

ngb-datepicker ngb-datepicker-month .ngb-dp-week.ng-star-inserted .ngb-dp-day.ng-star-inserted:hover{color:#fff}

.ngb-dp-week .ngb-dp-day{text-decoration:underline;font-weight:600}

.ngb-dp-week .ngb-dp-day.disabled{color:#000 !important;text-decoration:line-through}

.ngb-dp-week .ngb-dp-day.disabled>div{opacity:.5}

.ngb-dp-week .ngb-dp-day.disabled>.custom-day--disabled{opacity:.5}

ngb-tooltip-window.tooltip.show{opacity:1}

ngb-tooltip-window.tooltip.bs-tooltip-top{margin-top:-3px;padding:.5rem 0}

ngb-tooltip-window.tooltip.bs-tooltip-top .tooltip-arrow{bottom:0;width:auto}

ngb-tooltip-window.tooltip.bs-tooltip-top .tooltip-arrow::before{top:0;border-width:.4rem .4rem 0;border-top-color:#000}

ngb-tooltip-window.tooltip.bs-tooltip-bottom{padding:.4rem 0}

ngb-tooltip-window.tooltip.bs-tooltip-bottom .tooltip-arrow{top:0}

ngb-tooltip-window.tooltip.bs-tooltip-bottom .tooltip-arrow::before{bottom:0;border-width:0 .4rem .4rem;border-bottom-color:#000}

ngb-tooltip-window.tooltip.bs-tooltip-left{padding:0 .4rem}

ngb-tooltip-window.tooltip.bs-tooltip-left .tooltip-arrow{width:.4rem;height:.8rem}

[dir="ltr"] ngb-tooltip-window.tooltip.bs-tooltip-left .tooltip-arrow{right:0}

[dir="rtl"] ngb-tooltip-window.tooltip.bs-tooltip-left .tooltip-arrow{left:0}

[dir="ltr"] ngb-tooltip-window.tooltip.bs-tooltip-left .tooltip-arrow::before{left:0;border-width:.4rem 0 .4rem .4rem;border-left-color:#000}

[dir="rtl"] ngb-tooltip-window.tooltip.bs-tooltip-left .tooltip-arrow::before{right:0;border-width:.4rem .4rem .4rem 0;border-right-color:#000}

ngb-tooltip-window.tooltip.bs-tooltip-right{padding:0 .4rem}

ngb-tooltip-window.tooltip.bs-tooltip-right .tooltip-arrow{width:.4rem;height:.8rem}

[dir="ltr"] ngb-tooltip-window.tooltip.bs-tooltip-right .tooltip-arrow{left:0}

[dir="rtl"] ngb-tooltip-window.tooltip.bs-tooltip-right .tooltip-arrow{right:0}

[dir="ltr"] ngb-tooltip-window.tooltip.bs-tooltip-right .tooltip-arrow::before{right:0;border-width:.4rem .4rem .4rem 0;border-right-color:#000}

[dir="rtl"] ngb-tooltip-window.tooltip.bs-tooltip-right .tooltip-arrow::before{left:0;border-width:.4rem 0 .4rem .4rem;border-left-color:#000}

ngb-tooltip-window.tooltip .tooltip-arrow{position:absolute;display:block;width:.8rem;height:.5rem}

ngb-tooltip-window.tooltip .tooltip-arrow::before{position:absolute;content:"";border-color:rgba(0,0,0,0);border-style:solid}

ngb-tooltip-window.faux-popover{min-width:16.6666666667rem}

ngb-tooltip-window.faux-popover .tooltip-inner{padding:0;background-color:#fff;border:1px solid rgba(15,31,44,.18);border-radius:.5rem;background-clip:padding-box;box-shadow:0 1px 2px 0 rgba(15,31,44,.18);max-width:none}

ngb-tooltip-window.faux-popover .tooltip-arrow{width:1.5rem;height:.5rem}

ngb-tooltip-window.faux-popover .tooltip-arrow::after{position:absolute;display:block;content:"";border-color:rgba(0,0,0,0);border-style:solid}

[dir="ltr"] ngb-tooltip-window.faux-popover.bs-tooltip-bottom .tooltip-arrow,[dir="ltr"] ngb-tooltip-window.faux-popover.bs-tooltip-top .tooltip-arrow{left:50%;margin-left:-0.75rem}

[dir="rtl"] ngb-tooltip-window.faux-popover.bs-tooltip-bottom .tooltip-arrow,[dir="rtl"] ngb-tooltip-window.faux-popover.bs-tooltip-top .tooltip-arrow{right:50%;margin-right:-0.75rem}

ngb-tooltip-window.faux-popover.bs-tooltip-left .tooltip-arrow,ngb-tooltip-window.faux-popover.bs-tooltip-right .tooltip-arrow{top:50%;margin-top:-0.75rem;width:.5rem;height:1.5rem}

[dir="ltr"] ngb-tooltip-window.faux-popover.bs-tooltip-bottom.bs-tooltip-bottom-left .tooltip-arrow,[dir="ltr"] ngb-tooltip-window.faux-popover.bs-tooltip-bottom.bs-tooltip-top-left .tooltip-arrow,[dir="ltr"] ngb-tooltip-window.faux-popover.bs.tooltip-top.bs-tooltip-bottom-left .tooltip-arrow,[dir="ltr"] ngb-tooltip-window.faux-popover.bs.tooltip-top.bs-tooltip-top-left .tooltip-arrow{left:1.5rem}

[dir="rtl"] ngb-tooltip-window.faux-popover.bs-tooltip-bottom.bs-tooltip-bottom-left .tooltip-arrow,[dir="rtl"] ngb-tooltip-window.faux-popover.bs-tooltip-bottom.bs-tooltip-top-left .tooltip-arrow,[dir="rtl"] ngb-tooltip-window.faux-popover.bs.tooltip-top.bs-tooltip-bottom-left .tooltip-arrow,[dir="rtl"] ngb-tooltip-window.faux-popover.bs.tooltip-top.bs-tooltip-top-left .tooltip-arrow{right:1.5rem}

[dir="ltr"] ngb-tooltip-window.faux-popover.bs-tooltip-bottom.bs-tooltip-bottom-right .tooltip-arrow,[dir="ltr"] ngb-tooltip-window.faux-popover.bs-tooltip-bottom.bs-tooltip-top-right .tooltip-arrow,[dir="ltr"] ngb-tooltip-window.faux-popover.bs.tooltip-top.bs-tooltip-bottom-right .tooltip-arrow,[dir="ltr"] ngb-tooltip-window.faux-popover.bs.tooltip-top.bs-tooltip-top-right .tooltip-arrow{left:auto;right:1rem}

[dir="rtl"] ngb-tooltip-window.faux-popover.bs-tooltip-bottom.bs-tooltip-bottom-right .tooltip-arrow,[dir="rtl"] ngb-tooltip-window.faux-popover.bs-tooltip-bottom.bs-tooltip-top-right .tooltip-arrow,[dir="rtl"] ngb-tooltip-window.faux-popover.bs.tooltip-top.bs-tooltip-bottom-right .tooltip-arrow,[dir="rtl"] ngb-tooltip-window.faux-popover.bs.tooltip-top.bs-tooltip-top-right .tooltip-arrow{right:auto;left:1rem}

ngb-tooltip-window.faux-popover.bs-tooltip-left.bs-tooltip-left-top .tooltip-arrow,ngb-tooltip-window.faux-popover.bs-tooltip-left.bs-tooltip-right-top .tooltip-arrow,ngb-tooltip-window.faux-popover.bs-tooltip-right.bs-tooltip-left-top .tooltip-arrow,ngb-tooltip-window.faux-popover.bs-tooltip-right.bs-tooltip-right-top .tooltip-arrow{top:1.5rem}

ngb-tooltip-window.faux-popover.bs-tooltip-left.bs-tooltip-left-bottom .tooltip-arrow,ngb-tooltip-window.faux-popover.bs-tooltip-left.bs-tooltip-right-bottom .tooltip-arrow,ngb-tooltip-window.faux-popover.bs-tooltip-right.bs-tooltip-left-bottom .tooltip-arrow,ngb-tooltip-window.faux-popover.bs-tooltip-right.bs-tooltip-right-bottom .tooltip-arrow{top:auto;bottom:1rem}

ngb-tooltip-window.faux-popover.bs-tooltip-top .arrow{top:auto;bottom:0}

ngb-tooltip-window.faux-popover.bs-tooltip-top .arrow::before,ngb-tooltip-window.faux-popover.bs-tooltip-top .arrow::after{border-width:.5rem .75rem 0}

ngb-tooltip-window.faux-popover.bs-tooltip-top .arrow::before{top:0;border-top-color:rgba(15,31,44,.18)}

ngb-tooltip-window.faux-popover.bs-tooltip-top .arrow::after{top:-1px;border-top-color:#fff}

ngb-tooltip-window.faux-popover.bs-tooltip-bottom .arrow{bottom:auto;top:0}

ngb-tooltip-window.faux-popover.bs-tooltip-bottom .arrow::before,ngb-tooltip-window.faux-popover.bs-tooltip-bottom .arrow::after{border-width:0 .75rem .5rem}

ngb-tooltip-window.faux-popover.bs-tooltip-bottom .arrow::before{top:0;border-bottom-color:rgba(15,31,44,.18)}

ngb-tooltip-window.faux-popover.bs-tooltip-bottom .arrow::after{top:1px;border-bottom-color:#fff}

[dir="ltr"] ngb-tooltip-window.faux-popover.bs-tooltip-left .arrow{left:auto;right:0}

[dir="rtl"] ngb-tooltip-window.faux-popover.bs-tooltip-left .arrow{right:auto;left:0}

[dir="ltr"] ngb-tooltip-window.faux-popover.bs-tooltip-left .arrow::before,[dir="ltr"] ngb-tooltip-window.faux-popover.bs-tooltip-left .arrow::after{border-width:.75rem 0 .75rem .5rem}

[dir="rtl"] ngb-tooltip-window.faux-popover.bs-tooltip-left .arrow::before,[dir="rtl"] ngb-tooltip-window.faux-popover.bs-tooltip-left .arrow::after{border-width:.75rem .5rem .75rem 0}

[dir="ltr"] ngb-tooltip-window.faux-popover.bs-tooltip-left .arrow::before{left:0;border-left-color:rgba(15,31,44,.18)}

[dir="rtl"] ngb-tooltip-window.faux-popover.bs-tooltip-left .arrow::before{right:0;border-right-color:rgba(15,31,44,.18)}

[dir="ltr"] ngb-tooltip-window.faux-popover.bs-tooltip-left .arrow::after{left:-1px;border-left-color:#fff}

[dir="rtl"] ngb-tooltip-window.faux-popover.bs-tooltip-left .arrow::after{right:-1px;border-right-color:#fff}

[dir="ltr"] ngb-tooltip-window.faux-popover.bs-tooltip-right .arrow{right:auto;left:0}

[dir="rtl"] ngb-tooltip-window.faux-popover.bs-tooltip-right .arrow{left:auto;right:0}

[dir="ltr"] ngb-tooltip-window.faux-popover.bs-tooltip-right .arrow::before,[dir="ltr"] ngb-tooltip-window.faux-popover.bs-tooltip-right .arrow::after{border-width:.75rem .5rem .75rem 0}

[dir="rtl"] ngb-tooltip-window.faux-popover.bs-tooltip-right .arrow::before,[dir="rtl"] ngb-tooltip-window.faux-popover.bs-tooltip-right .arrow::after{border-width:.75rem 0 .75rem .5rem}

[dir="ltr"] ngb-tooltip-window.faux-popover.bs-tooltip-right .arrow::before{left:0;border-right-color:rgba(15,31,44,.18)}

[dir="rtl"] ngb-tooltip-window.faux-popover.bs-tooltip-right .arrow::before{right:0;border-left-color:rgba(15,31,44,.18)}

[dir="ltr"] ngb-tooltip-window.faux-popover.bs-tooltip-right .arrow::after{left:1px;border-right-color:#fff}

[dir="rtl"] ngb-tooltip-window.faux-popover.bs-tooltip-right .arrow::after{right:1px;border-left-color:#fff}

ngb-modal-backdrop.modal-backdrop.show{opacity:.4}

ngb-modal-window.modal.show{opacity:1}

ngb-modal-window.modal .modal-dialog{background:rgba(0,0,0,0);transform:none !important}

ngb-modal-window.modal .modal-dialog.modal-dialog-centered{display:flex;align-items:center;margin-top:0;margin-bottom:0;min-height:calc(100% - 1rem)}

ngb-modal-window.modal .modal-content{position:relative;width:100%}

ngb-modal-window.modal.lg-modal .modal-dialog{max-width:47rem !important}

ngb-modal-window.modal.xlg-modal .modal-dialog{max-width:58rem !important}

.modal--secondary{z-index:1101 !important}

.modal--tertiary{z-index:1102 !important}

.modal--image-cropper .modal-dialog{width:29rem}

ngb-popover-window.popover{display:block}

ngb-popover-window.popover .arrow{border:none}

ngb-popover-window.popover .arrow::before,ngb-popover-window.popover .arrow::after{display:block;content:"";border-color:rgba(0,0,0,0);border-style:solid}

ngb-popover-window.bs-popover-top>.arrow::after,ngb-popover-window.bs-popover-top>.arrow::before,ngb-popover-window.bs-popover-right>.arrow::after,ngb-popover-window.bs-popover-right>.arrow::before,ngb-popover-window.bs-popover-bottom>.arrow::after,ngb-popover-window.bs-popover-bottom>.arrow::before,ngb-popover-window.bs-popover-left>.arrow::after,ngb-popover-window.bs-popover-left>.arrow::before{border-width:0 .5rem .5rem}

ngb-popover-window.bs-popover-bottom .popover-header::before{position:absolute;top:0;display:block;width:1rem;content:"";border-bottom:1px solid #f7f7f7}

[dir="ltr"] ngb-popover-window.bs-popover-bottom .popover-header::before{left:50%;margin-left:-0.5rem}

[dir="rtl"] ngb-popover-window.bs-popover-bottom .popover-header::before{right:50%;margin-right:-0.5rem}

ngb-popover-window.bs-popover-top{margin-bottom:.5rem}

ngb-popover-window.bs-popover-top>.arrow{bottom:calc((.5rem + 1px)*-1)}

ngb-popover-window.bs-popover-top>.arrow::before{bottom:0;border-top-color:rgba(0,0,0,.25)}

ngb-popover-window.bs-popover-top>.arrow::after{bottom:1px;border-top-color:#fff}

[dir="ltr"] ngb-popover-window.bs-popover-right{margin-left:.5rem}

[dir="rtl"] ngb-popover-window.bs-popover-right{margin-right:.5rem}

[dir="ltr"] ngb-popover-window.bs-popover-right>.arrow{left:calc((.5rem + 1px)*-1)}

[dir="rtl"] ngb-popover-window.bs-popover-right>.arrow{right:calc((.5rem + 1px)*-1)}

[dir="ltr"] ngb-popover-window.bs-popover-right>.arrow::before{left:0;border-right-color:rgba(0,0,0,.25)}

[dir="rtl"] ngb-popover-window.bs-popover-right>.arrow::before{right:0;border-left-color:rgba(0,0,0,.25)}

[dir="ltr"] ngb-popover-window.bs-popover-right>.arrow::after{left:1px;border-right-color:#fff}

[dir="rtl"] ngb-popover-window.bs-popover-right>.arrow::after{right:1px;border-left-color:#fff}

ngb-popover-window.bs-popover-bottom{margin-top:.5rem}

ngb-popover-window.bs-popover-bottom>.arrow{top:calc((.5rem + 1px)*-1)}

ngb-popover-window.bs-popover-bottom>.arrow::before{top:0;border-bottom-color:rgba(0,0,0,.25)}

ngb-popover-window.bs-popover-bottom>.arrow::after{top:1px;border-bottom-color:#fff}

[dir="ltr"] ngb-popover-window.bs-popover-left{margin-right:.5rem}

[dir="rtl"] ngb-popover-window.bs-popover-left{margin-left:.5rem}

[dir="ltr"] ngb-popover-window.bs-popover-left>.arrow{right:calc((.5rem + 1px)*-1)}

[dir="rtl"] ngb-popover-window.bs-popover-left>.arrow{left:calc((.5rem + 1px)*-1)}

[dir="ltr"] ngb-popover-window.bs-popover-left>.arrow::before{right:0;border-left-color:rgba(0,0,0,.25)}

[dir="rtl"] ngb-popover-window.bs-popover-left>.arrow::before{left:0;border-right-color:rgba(0,0,0,.25)}

[dir="ltr"] ngb-popover-window.bs-popover-left>.arrow::after{right:1px;border-left-color:#fff}

[dir="rtl"] ngb-popover-window.bs-popover-left>.arrow::after{left:1px;border-right-color:#fff}

ngb-typeahead-window.dropdown-menu.show{opacity:1;visibility:visible;box-shadow:none;max-height:33vh;overflow-y:auto;width:100%;transition:unset}

ngb-typeahead-window.dropdown-menu .dropdown-item{cursor:pointer;width:100%}

ngb-typeahead-window.dropdown-menu .dropdown-item.active{color:#0f1f2c;text-decoration:none;cursor:pointer;background-color:#f8f8f9}

dgx-group-search-input ngb-typeahead-window.dropdown-menu{max-width:0}

[dir="ltr"] dgx-group-search-input ngb-typeahead-window.dropdown-menu{left:-1rem !important}

[dir="rtl"] dgx-group-search-input ngb-typeahead-window.dropdown-menu{right:-1rem !important}

[dir="ltr"] dgx-opportunities-content-header ngb-typeahead-window.dropdown-menu{left:-3.4rem !important}

[dir="rtl"] dgx-opportunities-content-header ngb-typeahead-window.dropdown-menu{right:-3.4rem !important}

[dir="ltr"] .search-field-wrapper ngb-typeahead-window.dropdown-menu{left:-2.5rem !important}

[dir="rtl"] .search-field-wrapper ngb-typeahead-window.dropdown-menu{right:-2.5rem !important}

[dir="ltr"] dgx-user-search ngb-typeahead-window.dropdown-menu{left:-3rem !important}

[dir="rtl"] dgx-user-search ngb-typeahead-window.dropdown-menu{right:-3rem !important}

dgx-skills-search ngb-typeahead-window.dropdown-menu{border-radius:.25rem;padding-top:.5rem;padding-bottom:.5rem}

dgx-user-search ngb-typeahead-window.dropdown-menu .dropdown-item{border-bottom:1px solid rgba(15,31,44,.18)}

[ngbdropdownmenu]{transition:none !important}

[ngbdropdownmenu].show{opacity:1;visibility:visible}

ngb-progressbar.progress{display:flex;height:1rem;overflow:hidden;line-height:0;font-size:.75rem;border-radius:.25rem}

ngb-progressbar.progress .progress-bar{display:flex;flex-direction:column;justify-content:center;overflow:hidden;color:#fff;text-align:center;white-space:nowrap;transition:width .6s ease}

ngb-progressbar.learning-resource__progressbar .progress{position:absolute;top:0;left:0;right:0;z-index:1;border-radius:.3325rem .3325rem 0 0}

ngb-progressbar.learning-resource__progressbar .bg-pathway{background:#0062e3;border-radius:0}

ngb-accordion.accordion{border-radius:.5rem;box-shadow:0px 1px 5px 0px rgba(15,31,44,.25);background-color:#fff;display:block}

ngb-accordion .accordion-item{border-bottom:1px solid rgba(15,31,44,.18)}

ngb-accordion .accordion-item:last-child{border-bottom:none}

[dir="ltr"] ngb-accordion .accordion-item .accordion-header{padding:1.5rem 0 1.5rem 1.5rem}

[dir="rtl"] ngb-accordion .accordion-item .accordion-header{padding:1.5rem 1.5rem 1.5rem 0}

ngb-accordion .accordion-item .accordion-body{background-color:rgba(15,31,44,.03)}

dgx-tag-dropdown ngb-accordion.accordion{box-shadow:none}

dgx-tag-dropdown ngb-accordion .accordion-item .accordion-header{padding:0}

dgx-tag-dropdown ngb-accordion .accordion-item .accordion-body{background-color:#fff;padding:1rem 1rem}

dgx-follow-target-modal ngb-accordion.accordion{box-shadow:none;border-radius:0}

dgx-follow-target-modal ngb-accordion .accordion-item:last-child{border-bottom:1px solid rgba(15,31,44,.18)}

dgx-follow-target-modal ngb-accordion .accordion-item:first-child{border-top:1px solid rgba(15,31,44,.18)}

dgx-follow-target-modal ngb-accordion .accordion-item .accordion-header{padding:0}

dgx-follow-target-modal ngb-accordion .accordion-item .accordion-body{background-color:rgba(0,0,0,0);padding-bottom:1.5rem}

dgx-content-catalog-duplicates ngb-accordion.accordion{box-shadow:none}

dgx-content-catalog-duplicates ngb-accordion .accordion-item .accordion-body{background-color:#fff}

dgx-tab-navigation .nav-tabs{background-color:unset}

.ms-teams__container{background-color:#fff;display:block;min-height:100%;padding-bottom:.5rem;padding-top:2rem}

.ms-teams__container dgx-profile-filter-bar>div{top:0 !important}

.ms-teams__container dgx-copy-link span.field-group__btn{display:none}

.ms-teams__container ngb-modal-window.modal .modal-dialog.modal-dialog-centered{max-width:60%;max-height:90%}

.ms-teams__container dgx-search-filters.is_fixed{position:static !important;padding:0 !important;background-color:#fff !important;box-shadow:none !important;top:0px !important;width:auto !important;margin-right:1.5rem !important;margin-left:1.5rem !important;margin-bottom:3rem !important}

.ms-teams__container [id^=dummy]{display:none !important}

.ms-teams__container .global-search-ms-teams{width:fit-content}

[dir="ltr"] .ms-teams__container .global-search-ms-teams{float:right}

[dir="rtl"] .ms-teams__container .global-search-ms-teams{float:left}

.ms-teams__container div.small-search-bar{max-width:60%;display:block}

.ms-teams__container div#popoverBodyContainer{z-index:1035}

.ms-teams__container dgx-breadcrumbs{display:block;margin-top:-2rem}

.ms-teams__container dgx-profile-skills{display:block;margin-top:4rem}

.ms-teams__container dgx-profile-skills,.ms-teams__container dgx-profile-assignments,.ms-teams__container dgx-profile-shared{display:block;margin-top:6rem}

.ms-teams__container .org-announcements__container{display:flex;column-gap:1rem;padding:1.5rem;align-items:center}

.ms-teams__container .org-announcements__container dgx-org-announcements{width:100%}

.ms-teams__container dgx-ms-teams-view .consumer-user dgx-profile-plans>div .df-button-primary{display:none}

.ms-teams__container dgx-search-view df-icon.tabnav__scrollbutton{display:none}

@media only screen and (max-width: 720px){
.ms-teams__container footer.l_feedback{display:none !important}

.ms-teams__container .breadcrumb:after,.ms-teams__container .breadcrumb:after{right:0 !important;left:0 !important}}

.ms-teams__container dgx-ms-teams-search{position:relative;top:-25px !important}

.ms-teams__container dgx-search-filters-sidebar .is_fixed{position:relative !important;top:0 !important}

#toast-container{z-index:1500 !important}

@media screen and (-webkit-min-device-pixel-ratio: 0)and (max-width: 769px){
.ebb__container [type=color],.ebb__container [type=date],.ebb__container [type=datetime],.ebb__container [type=datetime-local],.ebb__container [type=email],.ebb__container [type=month],.ebb__container [type=number],.ebb__container [type=password],.ebb__container [type=search],.ebb__container [type=tel],.ebb__container [type=text],.ebb__container [type=time],.ebb__container [type=url],.ebb__container [type=week],.ebb__container input:not([type]),.ebb__container textarea,.ebb__container select,.ebb__container .dg-selection-button{font-size:13px}

.ebb__container #ng-app ngb-modal-window.modal.show{z-index:1000}

.ebb__container #ng-app ngb-modal-backdrop.modal-backdrop.show{z-index:1000 !important}}

.ebb__container .btn--condensed{border-radius:3rem;line-height:1rem;min-height:2rem;padding-left:1rem;padding-right:1rem}

.ebb__container .btn-utility{border-color:rgba(15,31,44,.18);color:rgba(15,31,44,.61);padding:1px 0 0 0;overflow:visible;height:3rem;width:3rem;font-size:1.16667rem}

.ebb__container .h2--condensed{font-size:1.5rem;line-height:1.9rem}

.ebb__container .explanation{color:rgba(15,31,44,.61);font-weight:300;line-height:1.5rem}

.ebb__container .gradient-pattern{background-position:center;background-repeat:repeat;background-size:initial}

[dir="ltr"] .ebb__container .gradient-pattern{background-image:linear-gradient(135deg, rgb(233, 245, 243) 0%, rgb(246, 238, 227) 50%, rgb(247, 228, 237) 100%)}

[dir="rtl"] .ebb__container .gradient-pattern{background-image:linear-gradient(-135deg, rgb(233, 245, 243) 0%, rgb(246, 238, 227) 50%, rgb(247, 228, 237) 100%)}

.ebb__container .gradient-pattern--small{background-size:52%}

[dir="ltr"] .ebb__container .gradient-pattern--article{background-image:url(/content/img/svg-patterns/article-tile.svg),linear-gradient(135deg, rgb(233, 245, 243) 0%, rgb(246, 238, 227) 50%, rgb(247, 228, 237) 100%)}

[dir="rtl"] .ebb__container .gradient-pattern--article{background-image:url(/content/img/svg-patterns/article-tile.svg),linear-gradient(-135deg, rgb(233, 245, 243) 0%, rgb(246, 238, 227) 50%, rgb(247, 228, 237) 100%)}

[dir="ltr"] .ebb__container .gradient-pattern--video{background-image:url(/content/img/svg-patterns/video-tile.svg),linear-gradient(135deg, rgb(233, 245, 243) 0%, rgb(246, 238, 227) 50%, rgb(247, 228, 237) 100%)}

[dir="rtl"] .ebb__container .gradient-pattern--video{background-image:url(/content/img/svg-patterns/video-tile.svg),linear-gradient(-135deg, rgb(233, 245, 243) 0%, rgb(246, 238, 227) 50%, rgb(247, 228, 237) 100%)}

.ebb__container .combobox__value{font-size:1rem}

.ebb__container .app-header__profile-pic{position:relative !important}

[dir="ltr"] .ebb__container .profile-pic--rtl .profile-pic__container .profile-pic__mentor-image{left:unset}

[dir="rtl"] .ebb__container .profile-pic--rtl .profile-pic__container .profile-pic__mentor-image{right:unset}

.toast-center-center{top:50%}

[dir="ltr"] .toast-center-center{left:50%;transform:translate(-50%, -50%)}

[dir="rtl"] .toast-center-center{right:50%;transform:translate(50%, -50%)}

.toast-top-center{top:0;width:100%}

[dir="ltr"] .toast-top-center{right:0}

[dir="rtl"] .toast-top-center{left:0}

.toast-bottom-center{bottom:0;width:100%}

[dir="ltr"] .toast-bottom-center{right:0}

[dir="rtl"] .toast-bottom-center{left:0}

.toast-top-full-width{top:0;width:100%}

[dir="ltr"] .toast-top-full-width{right:0}

[dir="rtl"] .toast-top-full-width{left:0}

.toast-bottom-full-width{bottom:0;width:100%}

[dir="ltr"] .toast-bottom-full-width{right:0}

[dir="rtl"] .toast-bottom-full-width{left:0}

.toast-top-left{top:12px}

[dir="ltr"] .toast-top-left{left:12px}

[dir="rtl"] .toast-top-left{right:12px}

.toast-top-right{top:12px}

[dir="ltr"] .toast-top-right{right:12px}

[dir="rtl"] .toast-top-right{left:12px}

.toast-bottom-right{bottom:12px}

[dir="ltr"] .toast-bottom-right{right:12px}

[dir="rtl"] .toast-bottom-right{left:12px}

.toast-bottom-left{bottom:12px}

[dir="ltr"] .toast-bottom-left{left:12px}

[dir="rtl"] .toast-bottom-left{right:12px}

.toast-title{font-weight:bold}

.toast-message{word-wrap:break-word}

.toast-message a,.toast-message label{color:#fff}

.toast-message a:hover{color:#ccc;text-decoration:none}

.toast-close-button{position:relative;top:-0.3em;font-size:20px;font-weight:bold;color:#fff;text-shadow:0 1px 0 #fff}

[dir="ltr"] .toast-close-button{right:-0.3em;float:right}

[dir="rtl"] .toast-close-button{left:-0.3em;float:left}

.toast-close-button:hover,.toast-close-button:focus{color:#000;text-decoration:none;cursor:pointer;opacity:.4}

button.toast-close-button{padding:0;cursor:pointer;background:transparent;border:0}

.toast-container{pointer-events:none;position:fixed;z-index:999999}

.toast-container *{box-sizing:border-box}

.toast-container .ngx-toastr{position:relative;overflow:hidden;margin:0 0 6px;width:300px;border-radius:3px 3px 3px 3px;background-position:15px center;background-repeat:no-repeat;background-size:24px;box-shadow:0 0 12px #999;color:#fff}

[dir="ltr"] .toast-container .ngx-toastr{padding:15px 15px 15px 50px}

[dir="rtl"] .toast-container .ngx-toastr{padding:15px 50px 15px 15px}

.toast-container .ngx-toastr:hover{box-shadow:0 0 12px #000;opacity:1;cursor:pointer}

.toast-info{background-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+")}

.toast-error{background-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+")}

.toast-success{background-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==")}

.toast-warning{background-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==")}

.toast-container.toast-top-center .ngx-toastr,.toast-container.toast-bottom-center .ngx-toastr{width:300px;margin-left:auto;margin-right:auto}

.toast-container.toast-top-full-width .ngx-toastr,.toast-container.toast-bottom-full-width .ngx-toastr{width:96%;margin-left:auto;margin-right:auto}

.ngx-toastr{background-color:#030303;pointer-events:auto}

.toast-success{background-color:#51a351}

.toast-error{background-color:#bd362f}

.toast-info{background-color:#2f96b4}

.toast-warning{background-color:#f89406}

.toast-progress{position:absolute;bottom:0;height:4px;background-color:#000;opacity:.4}

[dir="ltr"] .toast-progress{left:0}

[dir="rtl"] .toast-progress{right:0}

@media all and (max-width: 240px){
.toast-container .ngx-toastr.div{width:11em}

[dir="ltr"] .toast-container .ngx-toastr.div{padding:8px 8px 8px 50px}

[dir="rtl"] .toast-container .ngx-toastr.div{padding:8px 50px 8px 8px}

.toast-container .toast-close-button{top:-0.2em}

[dir="ltr"] .toast-container .toast-close-button{right:-0.2em}

[dir="rtl"] .toast-container .toast-close-button{left:-0.2em}}

@media all and (min-width: 241px)and (max-width: 480px){
.toast-container .ngx-toastr.div{width:18em}

[dir="ltr"] .toast-container .ngx-toastr.div{padding:8px 8px 8px 50px}

[dir="rtl"] .toast-container .ngx-toastr.div{padding:8px 50px 8px 8px}

.toast-container .toast-close-button{top:-0.2em}

[dir="ltr"] .toast-container .toast-close-button{right:-0.2em}

[dir="rtl"] .toast-container .toast-close-button{left:-0.2em}}

@media all and (min-width: 481px)and (max-width: 768px){
.toast-container .ngx-toastr.div{width:25em}

[dir="ltr"] .toast-container .ngx-toastr.div{padding:15px 15px 15px 50px}

[dir="rtl"] .toast-container .ngx-toastr.div{padding:15px 50px 15px 15px}}

.js-toast-close{font-size:.6666666667rem !important;top:0 !important}

.toast-close-button{top:-0.3em;color:rgba(15,31,44,.61);position:absolute;height:4rem;font-size:1.2rem;padding:1.5rem !important}

[dir="ltr"] .toast-close-button{right:0;float:right}

[dir="rtl"] .toast-close-button{left:0;float:left}

.toast-close-button:hover,.toast-close-button:focus{color:rgba(15,31,44,.61);text-decoration:none;cursor:pointer;opacity:.4}

.toast-container .ngx-toastr{position:relative;overflow:inherit;margin:0 0 1rem;min-width:40%;border-radius:6px;border:1px solid #d4d7d9;background-position:15px center;background-repeat:no-repeat;box-shadow:0 1px 3px rgba(0,0,0,.15);line-height:1.33333rem;background-size:1.33333rem;font-size:1rem;line-height:1.5rem;font-weight:500 !important;color:#0f1f2c;background-color:#f8f8f9}

[dir="ltr"] .toast-container .ngx-toastr{padding:15px 30px 15px 39px;text-align:left}

[dir="rtl"] .toast-container .ngx-toastr{padding:15px 39px 15px 30px;text-align:right}

.toast-container .ngx-toastr:hover{box-shadow:0 1px 3px rgba(0,0,0,.15);opacity:1;cursor:auto}

.toast-info{background-image:url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%3Cpath%20fill%3D%22%230062e3%22%20d%3D%22M12%200.48c-6.364%200-11.521%205.158-11.521%2011.52%200%206.364%205.158%2011.521%2011.521%2011.521%206.361%200%2011.52-5.158%2011.52-11.521%200-6.362-5.159-11.52-11.52-11.52zM13.075%204.639c1.123%200%201.453%200.652%201.453%201.397%200%200.93-0.744%201.79-2.015%201.79-1.063%200-1.57-0.534-1.538-1.418%200-0.745%200.623-1.769%202.1-1.769zM10.198%2018.9c-0.768%200-1.328-0.467-0.792-2.513l0.88-3.63c0.152-0.581%200.178-0.814%200-0.814-0.229%200-1.226%200.401-1.814%200.797l-0.383-0.628c1.866-1.559%204.012-2.473%204.93-2.473%200.768%200%200.895%200.907%200.512%202.304l-1.008%203.816c-0.179%200.674-0.102%200.907%200.077%200.907%200.23%200%200.984-0.278%201.726-0.863l0.434%200.583c-1.816%201.814-3.794%202.513-4.561%202.513z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A")}

.toast-error{background-image:url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%3Cpath%20fill%3D%22%23DE4639%22%20d%3D%22M12%2023.5c-6.351%200-11.5-5.149-11.5-11.5s5.149-11.5%2011.5-11.5v0c6.351%200%2011.5%205.149%2011.5%2011.5s-5.149%2011.5-11.5%2011.5v0zM12%2020.625c4.763%200%208.625-3.862%208.625-8.625s-3.862-8.625-8.625-8.625v0c-4.763%200-8.625%203.862-8.625%208.625s3.862%208.625%208.625%208.625v0zM12%206.25c0.862%200%201.437%200.575%201.437%201.437v4.313c0%200.794-0.644%201.437-1.437%201.437s-1.437-0.644-1.437-1.437v0-4.313c0-0.863%200.575-1.437%201.437-1.437zM12%2014.875c0.794%200%201.437%200.644%201.437%201.437s-0.644%201.437-1.437%201.437v0c-0.794%200-1.437-0.644-1.437-1.437s0.644-1.437%201.437-1.437v0z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A")}

.toast-success{background-image:url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%3Cpath%20fill%3D%22%2300a866%22%20d%3D%22M12%2023.5c-6.351%200-11.5-5.149-11.5-11.5s5.149-11.5%2011.5-11.5v0c6.351%200%2011.5%205.149%2011.5%2011.5s-5.149%2011.5-11.5%2011.5v0zM12%2020.625c4.763%200%208.625-3.862%208.625-8.625s-3.862-8.625-8.625-8.625v0c-4.763%200-8.625%203.862-8.625%208.625s3.862%208.625%208.625%208.625v0zM11.569%2015.881c-0.265%200.299-0.649%200.487-1.078%200.487s-0.813-0.188-1.077-0.485l-0.001-0.002-2.875-2.875c-0.246-0.263-0.397-0.617-0.397-1.006%200-0.814%200.66-1.475%201.475-1.475%200.425%200%200.808%200.18%201.077%200.468l0.001%200.001%201.869%201.869%204.744-4.744c0.237-0.179%200.537-0.288%200.862-0.288%200.794%200%201.437%200.644%201.437%201.437%200%200.325-0.108%200.625-0.29%200.866l0.003-0.004-5.75%205.75z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A")}

.toast-warning{background-image:url("data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%3Cpath%20fill%3D%22%23b88100%22%20d%3D%22M14.588%202.080l8.626%2017.251c0.194%200.377%200.308%200.822%200.308%201.294%200%201.588-1.287%202.875-2.875%202.875-0.007%200-0.014-0-0.021-0h-17.25c-0.006%200-0.013%200-0.020%200-1.588%200-2.875-1.287-2.875-2.875%200-0.472%200.114-0.917%200.315-1.31l-0.008%200.016%208.626-17.251c0.477-0.968%201.456-1.622%202.588-1.622s2.111%200.654%202.58%201.605l0.008%200.017zM11.281%204.381l-7.332%2015.239c-0.039%200.086-0.062%200.186-0.062%200.292%200%200.368%200.277%200.672%200.634%200.714l0.003%200h14.951c0.36-0.042%200.637-0.346%200.637-0.714%200-0.106-0.023-0.206-0.064-0.297l0.002%200.005-7.476-15.239c-0.119-0.242-0.364-0.405-0.647-0.405s-0.528%200.164-0.645%200.401l-0.002%200.004zM12%209.125c0.863%200%201.438%200.575%201.438%201.438v2.875c0%200.794-0.644%201.438-1.438%201.438s-1.438-0.644-1.438-1.438v0-2.875c0-0.863%200.575-1.438%201.438-1.438zM12%2016.313c0.794%200%201.438%200.644%201.438%201.438s-0.644%201.438-1.438%201.438v0c-0.794%200-1.438-0.644-1.438-1.438s0.644-1.438%201.438-1.438v0z%22%3E%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A")}