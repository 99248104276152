:root {
	--neutral-white: #ffffff;
	--neutral-950: #353c42;
	--neutral-900: #3d464d;
	--neutral-800: #44515a;
	--neutral-700: #4e606c;
	--neutral-600: #5b7e7f;
	--neutral-500: #768e9a;
	--neutral-400: #a3b5bd;
	--neutral-300: #c9d3d8;
	--neutral-200: #e2e8eb;
	--neutral-100: #f4f6f7;
	--neutral-50: #fbfbfb;
	--blue-950: #0d4a9b;
	--blue-900: #0854c5;
	--blue-800: #0062e3;
	--blue-700: #0682ff;
	--blue-600: #1ea1ff;
	--blue-500: #48c2ff;
	--blue-400: #83d9ff;
	--blue-300: #b5e7ff;
	--blue-200: #d6f0ff;
	--blue-100: #edf9ff;
	--blue-50: #f3fbff;
	--purple-950: #3c0088;
	--purple-900: #5200bb;
	--purple-800: #6800ee;
	--purple-700: #8322ff;
	--purple-600: #9746ff;
	--purple-500: #ab69ff;
	--purple-400: #8f8dff;
	--purple-300: #d3b1ff;
	--purple-200: #e7d4ff;
	--purple-100: #f1e6ff;
	--purple-50: #f9f5ff;
	--pink-950: #7a0058;
	--pink-900: #a30076;
	--pink-800: #c3008d;
	--pink-700: #ec00ab;
	--pink-600: #ff16be;
	--pink-500: #ff3eca;
	--pink-400: #ff5dd2;
	--pink-300: #ff90e0;
	--pink-200: #ffb9ec;
	--pink-100: #ffe2f7;
	--pink-50: #fff6fd;
	--green-950: #16512f;
	--green-900: #196237;
	--green-800: #1a8244;
	--green-700: #1b9e4f;
	--green-600: #28bf65;
	--green-500: #4fd985;
	--green-400: #8aebb0;
	--green-300: #bdf5d3;
	--green-200: #ddfbe8;
	--green-100: #f0fdf4;
	--green-50: #f9fffb;
	--yellow-950: #743e0f;
	--yellow-900: #894c0a;
	--yellow-800: #a66202;
	--yellow-700: #d18b00;
	--yellow-600: #f0b400;
	--yellow-500: #ffcf0d;
	--yellow-400: #ffe341;
	--yellow-300: #fff286;
	--yellow-200: #fffbc1;
	--yellow-100: #fffde7;
	--yellow-50: #fffef0;
	--red-950: #910a3a;
	--red-900: #aa073d;
	--red-800: #de0546;
	--red-700: #f00e4c;
	--red-600: #ff3160;
	--red-500: #ff6685;
	--red-400: #ff9daf;
	--red-300: #ffc9d3;
	--red-200: #ffe2e6;
	--red-100: #fff0f2;
	--red-50: #fff8f9;

  /* Typography */
	--font-family-default: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	--font-family-medium: 'Inter-Medium';
	--font-family-bold: 'Inter-Bold';
	--font-family-regular: 'Inter-Regular';
	--font-weight-semi-bold: 590;

	/* Global Defaults (mostly colors) */
	--default-border: var(--neutral-100);
	--default-text: var(--neutral-800);
	--default-page-background: var(--neutral-50);
}